import { store } from '../index.js';
import * as CONSTANTS from 'constants';

//=====================================
// FAILED
//=====================================
const FAILED = (res) => {
    if (res.data.errorCode === 9) {
        store.dispatch({
            type: CONSTANTS.SIGNOUT_USER
        });
        return null;
    } else {
        store.dispatch({
            type: CONSTANTS.SHOW_MESSAGE,
            payload: {
                message: res.data.data
                    ? `${res.data.data[0]}, S-${res.data.errorCode}`
                    : `${res.data.message}, S-${res.data.errorCode}`,
                type: 'message'
            }
        });
        return null;
    }
};
const FAILED_ERROR = (error) => {
    console.warn('FAILED ERROR: ', error);
    return null;
};

//=====================================
// FIREBASE GET
//=====================================
export const FIREBASE_GET = async (collection) =>
    await collection
        .get()
        .then((res) => {
            let list = [];
            res.forEach((doc) => {
                list.push(doc.data());
            });
            return list;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE ADD
//=====================================
export const FIREBASE_ADD = async (collection, data) =>
    await collection
        .add(data)
        .then((res) => {
            return true;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE UPDATE
//=====================================
export const FIREBASE_UPDATE = async (collection, checker, id, updateData) =>
    await collection
        .where(`${checker}`, '==', id)
        .get()
        .then((res) => {
            res.forEach((doc) => {
                collection.doc(doc.id).update(updateData);
            });
            return true;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE DELETE
//=====================================
export const FIREBASE_DELETE = async (collection, checker, id) =>
    await collection
        .where(`${checker}`, '==', id)
        .get()
        .then((res) => {
            res.forEach((doc) => {
                collection.doc(doc.id).delete();
            });
            return true;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE BATCH ADD
//=====================================
export const FIREBASE_BATCH_ADD = async (collection, data) =>
    await collection
        .add(data)
        .then((res) => {
            return true;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE BATCH UPDATE
//=====================================
export const FIREBASE_BATCH_UPDATE = async (collection, property, value) =>
    await collection
        .get()
        .then((res) => {
            res.forEach((doc) => {
                let data = doc.data();
                data[property] = value;
                collection.doc(doc.id).update(data);
            });
            return true;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE BATCH DELETE
//=====================================
export const FIREBASE_BATCH_DELETE = async (collection) =>
    await collection
        .get()
        .then((res) => {
            res.forEach((doc) => {
                collection.doc(doc.id).delete();
            });
            return true;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE ALLOW NOTIFICATIONS
//=====================================
export const FIREBASE_ALLOW_NOTIFICATIONS = async (messaging) =>
    (await messaging) &&
    messaging
        .requestPermission()
        .then(() => {
            return messaging.getToken();
        })
        .then((token) => {
            return token;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE GET TOKEN
//=====================================
export const FIREBASE_GET_TOKEN = async (messaging) =>
    await messaging
        .getToken()
        .then((token) => {
            return token;
        })
        .catch((err) => {
            return FAILED_ERROR(error);
        });

//=====================================
// FIREBASE REFRESH TOKEN
//=====================================
export const FIREBASE_REFRESH_TOKEN = async (messaging) =>
    await messaging.onTokenRefresh(() => {
        messaging
            .getToken()
            .then((refreshedToken) => {
                console.warn('Token refreshed.');
            })
            .catch((err) => {
                console.warn('Unable to retrieve refreshed token ', err);
            });
    });
