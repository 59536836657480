import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { Input } from 'reactstrap';
class DropdownHeader extends React.Component {
    render() {
        const { title, input, theme, intl, filter, width, update } = this.props;
        this.styles = styles(theme, width);
        return (
            <div className="d-flex">
                {input && (
                    <Input
                        className={css(this.styles.input)}
                        type="text"
                        placeholder={intl.formatMessage({ id: input })}
                        onChange={(e) => filter(e.target.value)}
                        onKeyDown={(e) => update(e.keyCode)}
                        autoFocus
                    />
                )}
                {title && <h3 className={css(this.styles.title)}>{intl.formatMessage({ id: title })}</h3>}
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(connect(mapStateToProps)(DropdownHeader));

const styles = (theme, width) =>
    StyleSheet.create({
        title: {
            color: '#aaa',
            fontSize: 12
        },
        input: {
            border: 'none',
            boxShadow: 'none',
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            marginBottom: 20,
            width: width - 50,
            color: theme.color.themeColor,
            borderBottom: `solid ${theme.color.borderColor} 1px`,
            backgroundColor: theme.color.compColor,
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 12
            }
        }
    });
