import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { KnowledgeBaseIcon } from 'utilities/icons';

import Header from 'components/Header/dashboard';
class DashboardKnowledgeBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kbArticles: [
                {
                    id: 2,
                    title: 'pages.knowledge.title2',
                    description: 'pages.knowledge.description2',
                    url: 'https://www.janusintl.com/knowledge/storage-smart-entry-mobile-app-user-guide',
                    icon: 'security_update_good'
                },
                {
                    id: 1,
                    title: 'pages.knowledge.title1',
                    description: 'pages.knowledge.description1',
                    url: 'https://www.janusintl.com/knowledge/understanding-digital-keys',
                    icon: 'vpn_key'
                },
                {
                    id: 3,
                    title: 'pages.knowledge.title3',
                    description: 'pages.knowledge.description3',
                    url: 'https://www.janusintl.com/knowledge/basic-app-device-troubleshooting',
                    icon: 'app_shortcut'
                },
                {
                    id: 4,
                    title: 'pages.knowledge.title4',
                    description: 'pages.knowledge.description4',
                    url: 'https://www.janusintl.com/knowledge/how-does-delinquency-work-with-noke-smart-entry',
                    icon: 'credit_card_off'
                }
            ]
        };
    }
    render() {
        let { intl, theme, width } = this.props;
        const { kbArticles } = this.state;
        this.styles = styles(theme, width);
        return (
            <>
                <Header
                    Icon={KnowledgeBaseIcon}
                    title={
                        width > 1575
                            ? 'pages.knowledge.knowledgeBase'
                            : width > 1372
                            ? 'pages.knowledge.knowledgeBaseShort'
                            : 'pages.knowledge.knowledgeBaseShortest'
                    }
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <div className={css(this.styles.kbWrapper)}>
                        {kbArticles &&
                            kbArticles.map((knowledge) => (
                                <a
                                    href={knowledge.url}
                                    target="_blank"
                                    key={knowledge.id}
                                    className={css(this.styles.kbItem)}
                                    rel="noreferrer">
                                    <div className={css(this.styles.kbItemLeft)}>
                                        <i className="material-icons" style={{ fontSize: 26 }}>
                                            {knowledge.icon}
                                        </i>
                                    </div>
                                    <div className={css(this.styles.kbItemRight)}>
                                        <p className={css(this.styles.kbItemTitle)}>
                                            {intl.formatMessage({ id: knowledge.title })}
                                        </p>
                                        <p className={css(this.styles.kbItemDescription)}>
                                            {intl.formatMessage({ id: knowledge.description })}
                                        </p>
                                    </div>
                                </a>
                            ))}
                    </div>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(DashboardKnowledgeBase)));

const styles = (theme, width) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 5,
            fontSize: 20
        },
        kbWrapper: {
            width: '100%',
            marginTop: 10
        },
        kbItem: {
            width: '100%',
            minHeight: 65,
            margin: '10px 0',
            boxShadow: theme.color.boxShadowCorner,
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 4,
            alignItems: 'space-between',
            textDecoration: 'none',
            transition: '.2s all ease-in-out',
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        kbItemLeft: {
            width: 50,
            height: '100%',
            minHeight: 65,
            background: theme.color.themeColor,
            color: theme.color.buttonFontColor,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        kbItemRight: {
            width: 'calc(100% - 50px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            color: theme.color.fontColor,
            padding: '5px 10px'
        },
        kbItemTitle: {
            margin: 0,
            fontWeight: 600,
            fontSize: width > 475 ? 14 : 9,
            color: theme.color.themeColor,
            lineHeight: .9,
            padding: '5px 0 2px 0'
        },
        kbItemDescription: {
            margin: 0,
            fontSize: width > 475 ? 11 : 7,
            lineHeight: 1
        }
    });
