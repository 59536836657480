import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchSecurityListStats = (filter = ['available', 'inuse', 'pending']) => {
    return {
        type: CONSTANTS.FETCH_SECURITY_LIST_STATS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SECURITY_LIST,
            data: { filter }
        }
    };
};

export const fetchSecurityList = (filter = ['available', 'inuse', 'pending']) => {
    return {
        type: CONSTANTS.FETCH_SECURITY_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_SECURITY_LIST,
            data: { filter }
        }
    };
};

export const fetchSecurityDetails = (unitUUID = '') => {
    return {
        type: CONSTANTS.FETCH_SECURITY_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SECURITY_DETAILS,
            data: { unitUUID }
        }
    };
};

export const editSecurity = (data) => {
    return {
        type: CONSTANTS.EDIT_SECURITY,
        payload: {
            url: ENDPOINTS.ENDPOINT_SECURITY_EDIT,
            data
        }
    };
};

export const setSecurityList = (data) => {
    return {
        type: CONSTANTS.SET_SECURITY_LIST,
        payload: data
    };
};

export const setSecurityListStats = (data) => {
    return {
        type: CONSTANTS.SET_SECURITY_LIST_STATS,
        payload: data
    };
};

export const setSecurity = (data) => {
    return {
        type: CONSTANTS.SET_SECURITY,
        payload: data
    };
};

export const setSecurityHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SECURITY_HEADERS,
        payload: data
    };
};

export const setSecurityFilters = (data) => {
    return {
        type: CONSTANTS.SET_SECURITY_FILTERS,
        payload: data
    };
};

export const setActiveSecurity = (data) => {
    return {
        type: CONSTANTS.SET_SECURITY_ACTIVE,
        payload: data
    };
};
