import * as CONSTANTS from 'constants';
import * as UTILS from 'utilities';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    authUser: JSON.parse(sessionStorage.getItem('user_data')),
    site: null,
    createSite: null,
    pms: false,
    showPin: false,
    loginConfirm: null,
    viewedRole: null,
    invalidLoginModal: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            };
        }
        case CONSTANTS.SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                loader: false
            };
        }
        case CONSTANTS.SET_SITE: {
            return {
                ...state,
                site: action.payload,
                pms: UTILS.checkPMS(action.payload)
            };
        }
        case CONSTANTS.SET_CREATE_SITE: {
            return {
                ...state,
                createSite: action.payload,
                pms: UTILS.checkPMS(action.payload)
            };
        }
        case CONSTANTS.SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            };
        }
        case CONSTANTS.HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            };
        }
        case CONSTANTS.ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            };
        }
        case CONSTANTS.ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            };
        }
        case CONSTANTS.SHOW_TWO_FACTOR: {
            return {
                ...state,
                showPin: action.payload
            };
        }
        case CONSTANTS.SET_LOGIN_CONFIRM: {
            return {
                ...state,
                loginConfirm: action.payload
            };
        }
        case CONSTANTS.SET_AUTH_USER: {
            return {
                ...state,
                authUser: action.payload
            };
        }
        case CONSTANTS.SET_VIEW_AS: {
            return {
                ...state,
                viewedRole: action.payload
            };
        }
        case CONSTANTS.SET_LOGIN_ATTEMPT_MODAL: {
            return {
                ...state,
                invalidLoginModal: action.payload
            };
        }
        default:
            return state;
    }
};
