import { testPost, returnTest, createResult, createWarnings, waitForCondition, sleep } from 'components/TestSuite/tests/functions';
import * as ENDPOINTS from 'constants/endpoints';

const unlocksTests = (props) => {

    const entryListEndpoint = ENDPOINTS.ENDPOINT_ENTRY_LIST;
    const unlockMeshEndpoint = ENDPOINTS.ENDPOINT_REMOTE_UNLOCK;
    const targetSiteUUID = '1000168'; // Unit Test Facility siteUUID
    const targetUnitControllerMac = 'C7:A7:E5:7A:60:A8'; // Mac for target unit (unit controller)

    let entries = [];

    const guide = [
        {
            name: 'Switch Sites to Unit Test Facility',
            endpoint: '/site/switch/',
            description: 'Use switchSite redux function to switch sites',
            action: async () => {
                props.setSwitcherLoader({loading: true, text: props.switcherText});
                props.switchSite(targetSiteUUID);
                
                let success = await waitForCondition(() => {
                    let updatedData = JSON.parse(localStorage.getItem('nse_login_data'));
                    return updatedData && updatedData.siteUUID === targetSiteUUID;
                }, 10);
                sleep(1000);
                return success;
            },
        },
        {
            name: 'Fetch entry list',
            // endpoint: entryListEndpoint,
            description: 'Get entries from endpoint ' + entryListEndpoint,
            action: async () => {
                let res = await testPost(entryListEndpoint, {});
                res && res.data && res.data.units && res.data.units.forEach(unit => {
                    unit.locks.forEach(lock => entries.push(lock))
                });

                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                            errorMessage: 'Unable to get entries',
                        },
                    ]),
                    warnings: createWarnings([{
                        condition: entries && entries.length === 0,
                        message: 'No entries found on this site'
                    }]),
                })
            },
        },
        {
            name: 'Open entry in entry list',
            endpoint: unlockMeshEndpoint,
            action: async () => {
                let randomEntry = entries && entries[Math.floor(Math.random() * entries.length)];
                let res = await testPost(unlockMeshEndpoint, {mac: randomEntry.mac, overridePin: '', overrideReason: ''});

                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                            errorMessage: 'Unable to open entry ' + randomEntry.name,
                        },
                    ]),
                })
            }
        },
        {
            name: 'Open unit 106A',
            endpoint: unlockMeshEndpoint,
            action: async () => {
                let res = await testPost(unlockMeshEndpoint, {mac: targetUnitControllerMac, overridePin: '', overrideReason: ''});

                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                            errorMessage: 'Unable to open unit 106A - ' + targetUnitControllerMac,
                        },
                    ]),
                })
            }
        },
    ]

    return guide;
}

export default unlocksTests;
