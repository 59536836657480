import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import CellText from 'components/Table/cell/components/text';
import CellDate from 'components/Table/cell/components/date';

class CellReportsSuspiciousGeneric extends React.Component {
    render() {
        const { intl, data } = this.props;
        const { userName, entryName, activityType, attemptedAt, entryFirstOpenedAt, accessedAt } = data;
        return (
            <>
                <CellText
                    Title={
                        activityType === 'attemptedOutOfZone'
                        ?
                        intl.formatMessage({ id: 'comp.reports.outOfZone' })
                        :
                        activityType === 'didNotGoToUnit'
                        ?
                        intl.formatMessage({ id: 'comp.reports.notUnit' })
                        :
                        activityType === 'didNotLeaveAfterLockingUnit'
                        ?
                        intl.formatMessage({ id: 'comp.reports.notLeave' })
                        :
                        activityType === 'lateNightAccess'
                        ?
                        intl.formatMessage({ id: 'comp.reports.lateAccess' })
                        :
                        activityType === 'multipleEntries'
                        ?
                        intl.formatMessage({ id: 'comp.reports.multipleEntry' })
                        :
                        intl.formatMessage({ id: 'comp.reports.overlockAttempt' })
                    }
                    TitleColor={true}
                    textAlign={'left'} />
                <CellText Title={userName && userName !== "" ? userName : intl.formatMessage({ id: 'form.na' })} TitleColor={true} textAlign={'left'} />
                <CellText Title={entryName && entryName !== "" ? entryName : intl.formatMessage({ id: 'form.na' })} TitleColor={true} textAlign={'left'} />
                <CellDate
                    textAlign={'right'}
                    date={
                        activityType === 'attemptedOutOfZone'
                        ?
                        attemptedAt
                        :
                        activityType === 'didNotGoToUnit'
                        ?
                        entryFirstOpenedAt
                        :
                        activityType === 'didNotLeaveAfterLockingUnit'
                        ?
                        entryFirstOpenedAt
                        :
                        activityType === 'lateNightAccess'
                        ?
                        accessedAt
                        :
                        activityType === 'multipleEntries'
                        ?
                        entryFirstOpenedAt
                        :
                        attemptedAt
                    }
                    showTime={true} /> 
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, theme, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, theme, userTimezone, authUser, site };
};

export default injectIntl(connect(mapStateToProps)(CellReportsSuspiciousGeneric));