import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { FormGroup } from 'reactstrap';
import moment from 'moment-timezone';
import { osName, osVersion, browserName, browserVersion } from 'react-device-detect';

import { hideAuthLoader } from 'actions/Auth';
import { setLoader, submitErrorDetails } from 'actions/App';

import packageJson from '../../../package.json';
import Button from 'components/Button';
import TextAreaInput from 'components/Inputs/textarea';

class ErrorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInput: ''
        };
    }
    errorSubmit = () => {
        const { submitErrorDetails, errorDetails, authUser, setLoader, hideAuthLoader } = this.props;
        const { userInput } = this.state;
        submitErrorDetails({
            type: errorDetails.type,
            code: errorDetails.code,
            subcode: errorDetails.subcode,
            timeStamp: moment().utc().format(),
            version: packageJson.version ? packageJson.version : null,
            OS: osName && osVersion ? `${osName} ${osVersion}` : null,
            environment: browserName && browserVersion ? `${browserName} ${browserVersion}` : null,
            userUUID: authUser ? authUser.uuid : null,
            siteUUID: localStorage.getItem('nse_login_data')
                ? JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                : null,
            claimsCompanyUUID: localStorage.getItem('nse_login_data')
                ? JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
                : null,
            url: window.location ? window.location.href : null,
            endpoint: errorDetails.endpoint,
            jsonSent: errorDetails.jsonSent,
            jsonReceived: errorDetails.jsonReceived,
            userNotes: userInput
        });
        setLoader(false);
        hideAuthLoader();
    };
    viewKnowledgeBase = () => {
        window.location = 'https://www.janusintl.com/knowledge';
    };
    render() {
        const { intl, errorDetails, theme } = this.props;
        const { userInput } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <div
                    style={{
                        width: '100%',
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <span className={css(this.styles.label)}>
                        {intl.formatMessage({ id: 'comp.table.header.description' })}:
                    </span>
                    <span className={css(this.styles.bodyText)}>{errorDetails && errorDetails.description}</span>
                    <span className={css(this.styles.label)}>
                        {intl.formatMessage({ id: 'pages.troubleshoot.Types' })}:
                    </span>
                    <span className={css(this.styles.bodyText)}>
                        {errorDetails && errorDetails.code == 90
                            ? intl.formatMessage({ id: 'form.login.visitKnowledgeBaseLater' })
                            : errorDetails && errorDetails.troubleshoot && errorDetails.troubleshoot}
                    </span>
                    {errorDetails && errorDetails.errorID && errorDetails.errorID !== '' ? (
                        <div style={{ height: 5 }}></div>
                    ) : (
                        <FormGroup>
                            <div style={{ marginTop: 10 }}>
                                <TextAreaInput
                                    title={intl.formatMessage({ id: 'comp.table.header.tellUsMore' }) + ':'}
                                    className={css(this.styles.input)}
                                    name="userInput"
                                    placeholder={''}
                                    onChange={(e) => this.setState({ userInput: e.target.value })}
                                    value={userInput}
                                    labelStyles={{ fontWeight: '700' }}
                                    titleFontSize={13}
                                    autoFocus
                                />
                            </div>
                        </FormGroup>
                    )}
                </div>
                <div style={{ float: 'right' }}>
                    {errorDetails && errorDetails.code && errorDetails.code !== 131 && (
                        <Button text={'comp.button.goBack'} action={() => this.props.dismissError()} size={'sm'} />
                    )}
                    {errorDetails &&
                    (!errorDetails.errorID || (errorDetails && errorDetails.errorID && errorDetails.errorID == '')) ? (
                        <Button text={'comp.button.submitError'} action={() => this.errorSubmit()} size={'sm'} />
                    ) : (
                        <Button
                            text={'pages.users.dismissTraining'}
                            action={() => this.props.dismissError(true)}
                            size={'sm'}
                        />
                    )}
                    {errorDetails && errorDetails.code == 90 && (
                        <Button text={'form.login.knowledgeBase'} action={() => this.viewKnowledgeBase()} size={'sm'} />
                    )}
                </div>
                <div style={{ float: 'left', textTransform: 'capitalize' }}>
                    {errorDetails && errorDetails.errorID && errorDetails.errorID !== '' ? (
                        <div>
                            <span className={css(this.styles.label)}>Smart Entry Error Code:</span>
                            <span className={css(this.styles.bodyText)} style={{ display: 'block' }}>
                                {errorDetails.errorID}{' '}
                                <p
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: 5
                                    }}>{`(${errorDetails.type}-${errorDetails.code}.${errorDetails.subcode})`}</p>
                            </span>
                        </div>
                    ) : (
                        errorDetails &&
                        `Smart Entry Error Code: ${errorDetails.type}-${errorDetails.code}.${errorDetails.subcode}`
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { authUser } = auth;
    const { theme } = app;
    return { authUser, theme };
};

export default injectIntl(
    connect(mapStateToProps, {
        setLoader,
        submitErrorDetails,
        hideAuthLoader
    })(ErrorModal)
);

const styles = (theme) =>
    StyleSheet.create({
        label: {
            color: theme.color.themeColor,
            fontWeight: 600,
            marginTop: 5
        },
        input: {
            boxShadow: 'none',
            borderRadius: 5,
            padding: '5px 7px',
            fontWeight: 400,
            fontSize: 13,
            minHeight: 40,
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor,
            width: '100%',
            marginTop: 20,
            outline: 'none',
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 13
            }
        },
        bodyText: {
            color: theme && theme.color.fontColor,
        }
    });
