import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as ACTIONS_RELEASES from 'actions/Releases';
import * as ACTIONS_APP from 'actions/App';

function* requestReleaseList({ payload }) {
    try {
        yield put(ACTIONS_RELEASES.setReleases(null));
        const releases = yield call(API.POST, payload.url, payload.data);
        if (!releases) {
            yield put(ACTIONS_RELEASES.setReleases([]));
        } else {
            releases.data.features.map(
                (release) => (release.releaseDate = release.releaseDate ? release.releaseDate.split('T')[0] : '')
            );
            let tagList = [];
            let allFeatures = [];
            releases.data.features.map(
                (release) =>
                    release.tags &&
                    release.tags.map((tag) => {
                        tagList.push({ active: false, title: tag, value: [tag] });
                        allFeatures.push(tag);
                    })
            );
            tagList.push({ active: true, title: 'All', value: allFeatures });
            let tagUniqueList = tagList.filter(
                (tag, index, self) => self.findIndex((t) => t.title === tag.title) === index
            );
            yield put(ACTIONS_RELEASES.setReleaseFilters(tagUniqueList));
            yield put(ACTIONS_RELEASES.setReleases(releases.data.features));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_RELEASES.setReleases([]));
    }
}

function* requestCreateRelease({ payload }) {
    try {
        let newPayload = { ...payload.data };
        newPayload.releaseDate = newPayload.releaseDate ? newPayload.releaseDate + 'T00:00:00Z' : '';
        newPayload.seeDate = newPayload.seeDate ? newPayload.seeDate + 'T00:00:00Z' : '';

        let todaysDate = new Date();
        let seeDate = new Date(newPayload.seeDate);
        let seeDatePermission = newPayload.permission;

        const create = yield call(API.POST, payload.url, newPayload);
        if (!create) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noAdd`, 'error'));
        } else {
            if (todaysDate >= seeDate || seeDatePermission) {
                yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
            } else {
                yield put(ACTIONS_APP.showMessage('notifications.info.releases.seeDateLater', 'info_long'));
            }
            yield history.push('/app/releases');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateRelease({ payload }) {
    try {
        let newPayload = { ...payload.data.data };
        newPayload.releaseDate = newPayload.releaseDate ? newPayload.releaseDate + 'T00:00:00Z' : '';
        newPayload.seeDate = newPayload.seeDate ? newPayload.seeDate + 'T00:00:00Z' : '';

        let todaysDate = new Date();
        let seeDate = new Date(newPayload.seeDate);
        let seeDatePermission = newPayload.permission;

        const update = yield call(API.POST, payload.url, newPayload);
        if (!update) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noEdit`, 'error'));
        } else {
            if (todaysDate >= seeDate || seeDatePermission) {
                yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            } else {
                yield put(ACTIONS_APP.showMessage('notifications.info.releases.seeDateLater', 'info_long'));
            }
            yield history.push('/app/releases');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteRelease({ payload }) {
    try {
        const release = yield call(API.POST, payload.url, payload.data);
        if (!release) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noDelete`, 'error'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield history.push('/app/releases');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestReleaseSearchedList({ payload }) {
    try {
        yield put(ACTIONS_RELEASES.setSearchedReleases(null));
        const releases = yield call(API.POST, payload.url, payload.data);
        if (!releases) {
            yield put(ACTIONS_RELEASES.setSearchedReleases([]));
        } else {
            yield put(ACTIONS_RELEASES.setSearchedReleases(releases.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_RELEASES.setSearchedReleases([]));
    }
}

export function* selectReleasesList() {
    yield takeEvery(CONSTANTS.FETCH_RELEASES, requestReleaseList);
}
export function* selectCreateRelease() {
    yield takeEvery(CONSTANTS.CREATE_RELEASE, requestCreateRelease);
}
export function* selectUpdateRelease() {
    yield takeEvery(CONSTANTS.UPDATE_RELEASE, requestUpdateRelease);
}
export function* selectDeleteRelease() {
    yield takeEvery(CONSTANTS.DELETE_RELEASE, requestDeleteRelease);
}
export function* selectReleasesSearchedList() {
    yield takeEvery(CONSTANTS.SEARCH_RELEASES, requestReleaseSearchedList);
}

export default function* rootSaga() {
    yield all([
        fork(selectReleasesList),
        fork(selectCreateRelease),
        fork(selectUpdateRelease),
        fork(selectDeleteRelease),
        fork(selectReleasesSearchedList)
    ]);
}
