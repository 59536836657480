import { testPost, returnTest, createResult, createWarnings, } from 'components/TestSuite/tests/functions';
import * as ENDPOINTS from 'constants/endpoints';

// try connecting to redux vars, like site users or authUser
// currently able to connect to stuff like sessionStorage / localStorage, get ENDPOINTS from reducers

// maybe props can be passed into test funcs?
const usersTests = () => {
    const userData = JSON.parse(sessionStorage.getItem('user_data'));
    const isSupport = userData && userData.role.tier === 1;

    const userEndpoint = isSupport ? ENDPOINTS.ENDPOINT_SUPPORT_SITE_USER_LIST : ENDPOINTS.ENDPOINT_SITE_USER_LIST;
    const roleEndpoint = ENDPOINTS.ENDPOINT_ROLE_LIST;

    const guide = [
        {
            name: 'Users Page Endpoint for Roles',
            description: 'Description 1',
            steps: ['step 1', 'step 2'],
            endpoint: roleEndpoint,
            if: userData, // only show if logged in
            action: async () => {
                let res = await testPost(roleEndpoint, {});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success', 
                        },
                        {
                            condition: res.data && res.data.roles && res.data.roles.length,
                            errorMessage: 'No roles returned from endpoint.'
                        }
                    ]),
                    warnings: [],
                });
            },
        },
        {
            name: `Site User List - ${isSupport ? 'Support' : 'Non-Support'}`,
            description: 'Description 2',
            steps: ['step 1', 'step 2'],
            endpoint: userEndpoint,
            if: userData, // only show if logged in
            action: async () => {
                let res = await testPost(userEndpoint, {});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                            errorMessage: `Endpoint returned non-success message: ${res.errorCode + '.' + res.subcode} - ${res.message}`,
                        },
                        {
                            condition: (!res || !res.data || !res.data.users) || (res.data && res.data.users && res.data.users.filter(user => user.role.siteID !== res.claims.siteID).length === 0),
                            errorMessage: 'Users that dont belong to this site are being returned.'
                        }
                    ]),
                    warnings: createWarnings([{
                        condition: !res || !res.data || !res.data.users || !res.data.users.length,
                        message: 'Users length returned as 0'
                    }]), 
                });
            },
        },
    ]

    return guide;
}

export default usersTests;