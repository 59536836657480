import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import {
    fetchSiteNotes,
    deleteSiteNote,
    fetchSiteNoteComments,
    createSiteNoteComment,
    editSiteNoteComment,
    deleteSiteNoteComment,
    setSupportFacilityHeaders,
    setSupportFacilityCommentsHeaders
} from 'actions/Support';

import * as UTILS from 'utilities';
import { FileIcon } from 'utilities/icons';
import Table from 'components/Table';
import CommentsCell from 'components/Table/cell/containers/units/noteCommentsCell';
import NotesCell from 'components/Table/cell/containers/support/notes';
import Modal from 'components/Modal';
import Button from 'components/Button';
import ToolTips from 'components/ToolTips';
import Header from 'components/Header/dashboard';
import TextAreaInput from 'components/Inputs/textarea';

class DashboardSiteNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNoteModal: false,
            currentNote: null,
            showAddDropdown: false,
            newComment: '',
            currentComment: null,
            changesMadeComment: false,
        };
    }
    componentDidMount() {
        const { fetchSiteNotes, editWidgets, visible, authUser } = this.props;
        authUser &&
            authUser.permissions.includes('support_view_site_notes') &&
            (editWidgets || visible) &&
            fetchSiteNotes();
    }
    handleNoteAction = (data) => {
        const { fetchSiteNoteComments } = this.props;
        fetchSiteNoteComments(data.uuid);
        this.setState({ showNoteModal: true, currentNote: data });
    };
    handleDelete = () => {
        const { currentNote } = this.state;
        const { deleteSiteNote } = this.props;
        deleteSiteNote(currentNote.uuid);
        this.setState({ showNoteModal: false, currentNote: null });
    };
    toggleAddComment = () => {
        const { showAddDropdown } = this.state;
        this.setState({ showAddDropdown: !showAddDropdown });
    };
    addComment = () => {
        const { newComment, currentNote } = this.state;
        const { createSiteNoteComment } = this.props;
        createSiteNoteComment({ noteUUID: currentNote.uuid, comment: newComment });
        this.toggleAddComment();
        this.setState({ newComment: '' });
    };
    editComment = (data) => {
        let { currentComment } = this.state;
        currentComment.comment = data;
        this.setState({ currentComment, changesMadeComment: true });
    };
    saveEditComment = () => {
        const { editSiteNoteComment } = this.props;
        const { currentComment } = this.state;
        editSiteNoteComment({
            id: currentComment.id,
            noteUUID: currentComment.noteUUID,
            comment: currentComment.comment
        });
        this.setState({ currentComment: null });
    };
    deleteComment = () => {
        const { deleteSiteNoteComment } = this.props;
        const { currentComment } = this.state;
        deleteSiteNoteComment({ id: currentComment.id, noteUUID: currentComment.noteUUID });
        this.setState({ currentComment: null });
    };
    handleCommentAction = (data) => {
        this.setState({ currentComment: data });
    };
    render() {
        let { 
            intl, 
            history, 
            theme, 
            width, 
            authUser, 
            site, 
            siteNotesList, 
            siteNoteComments, 
            userTimezone, 
            supportNotesHeaders, 
            supportCommentsHeaders,
            setSupportFacilityHeaders,
            setSupportFacilityCommentsHeaders 
        } = this.props;
        const {
            showNoteModal,
            currentNote,
            showAddDropdown,
            currentComment,
            changesMadeComment,
            newComment
        } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <Modal
                    show={showNoteModal}
                    title={
                        <div className={css(this.styles.iconWrapperModalNote)}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                <FileIcon height={28} width={28} color={theme.color.themeColor} />
                                <p className={css(this.styles.titleModalNote)}>
                                    {UTILS.limitStringLength(
                                        currentNote && currentNote.title,
                                        width > 1000 ? 40 : width > 500 ? 25 : 15
                                    )}
                                </p>
                            </div>
                            <div>
                                {authUser && authUser.permissions.includes('support_view_site_notes') && (
                                    <div
                                        onClick={() => this.props.history.push(`/app/notes/update/${currentNote.uuid}`)}
                                        className={css(this.styles.noteEditIcon)}>
                                        <i className="material-icons" style={{ fontSize: 22 }}>
                                            edit
                                        </i>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    toggle={() => this.setState({ showNoteModal: !showNoteModal, currentNote: null })}>
                    <div className={css(this.styles.buttonWrapperNote)}>
                        <div className={css(this.styles.noteModalWrapper)}>
                            <p className={css(this.styles.descriptionModalLabel)}>
                                {intl.formatMessage({ id: 'comp.table.header.description' })}
                            </p>
                            <p className={css(this.styles.descriptionModalNote)}>{currentNote && currentNote.body}</p>
                            <div className={css(this.styles.descriptionModalNoteRow)}>
                                <div style={{ width: '50%' }}>
                                    <p className={css(this.styles.descriptionModalLabel)}>
                                        {intl.formatMessage({ id: 'form.createdBy' })}
                                    </p>
                                    <p className={css(this.styles.descriptionModalNote)}>
                                        {currentNote &&
                                        currentNote.createdByFirstName &&
                                        currentNote.createdByLasttName &&
                                        currentNote.createdByFirstName.length > 0 &&
                                        currentNote.createdByLasttName.length > 0
                                            ? `${currentNote.createdByFirstName} ${currentNote.createdByLasttName}`
                                            : intl.formatMessage({ id: 'form.na' })}
                                    </p>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <p className={css(this.styles.descriptionModalLabel)}>
                                        {intl.formatMessage({ id: 'form.createdDate' })}
                                    </p>
                                    {currentNote && currentNote.createdDate && <p className={css(this.styles.descriptionModalNote)}>
                                        {UTILS.dateFormatter(currentNote.createdDate, authUser, site)}
                                    </p>}
                                </div>
                            </div>
                        </div>
                        {authUser && authUser.permissions && authUser.permissions.includes('support_view_site_notes') && (
                            <div className={css(this.styles.commentsRow)}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: 28,
                                        width: '100%'
                                    }}>
                                    <h1 className={css(this.styles.headerComments)}>
                                        {intl.formatMessage({ id: 'form.comments' }) || <Skeleton />}
                                    </h1>
                                    {width > 500 && (
                                        <Dropdown
                                            direction={'down'}
                                            isOpen={showAddDropdown}
                                            toggle={() => this.toggleAddComment()}>
                                            <div id="addCommentIcon" className={css(this.styles.commentsAdd)}>
                                                {!currentComment ? (
                                                    <DropdownToggle
                                                        style={{
                                                            backgroundColor: theme.color.compColor,
                                                            border: 'none'
                                                        }}>
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: 24,
                                                                color: theme.color.themeColor
                                                            }}>
                                                            add_circle_outline
                                                        </i>
                                                    </DropdownToggle>
                                                ) : null}
                                            </div>
                                            {!currentComment ? (
                                                <ToolTips target={'addCommentIcon'} message={'form.commentsAdd'} />
                                            ) : null}
                                            <DropdownMenu right className={css(this.styles.commentAddWrapper)}>
                                                <TextAreaInput
                                                    title={intl.formatMessage({ id: 'form.commentsAdd' })}
                                                    name="newComment"
                                                    value={newComment}
                                                    placeholder={intl.formatMessage({ id: 'form.comment' })}
                                                    className={css(this.styles.input)}
                                                    onChange={(e) => this.setState({ newComment: e.target.value })}
                                                />
                                                <Button
                                                    width={140}
                                                    load={false}
                                                    action={() => this.addComment()}
                                                    text={'comp.button.add'}
                                                    margin={'0'}
                                                    float={'right'}
                                                    padding={'10px 20px'}
                                                />
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}
                                </div>
                                {currentComment ? (
                                    <div style={{ height: 179, paddingTop: 10, width: '100%' }}>
                                        {/* <Label className={css(this.styles.label)}>{ intl.formatMessage({ id:"form.commentsEdit" }) }</Label> */}
                                        <TextAreaInput
                                            name="comment"
                                            value={currentComment.comment}
                                            placeholder={intl.formatMessage({ id: 'form.comment' })}
                                            className={css(this.styles.inputEdit)}
                                            onChange={(e) => this.editComment(e.target.value)}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                marginTop: 40,
                                                justifyContent: 'space-between'
                                            }}>
                                            <Button
                                                width={width > 900 && 140}
                                                load={false}
                                                action={() => this.setState({ currentComment: null })}
                                                text={'comp.button.cancel'}
                                                margin={'0'}
                                                padding={'10px 20px'}
                                            />
                                            <Button
                                                width={width > 900 && 140}
                                                load={false}
                                                action={() => this.deleteComment()}
                                                text={width > 800 ? 'form.commentsDelete' : 'comp.button.delete'}
                                                color={'#F60F0F'}
                                                margin={'0'}
                                                padding={'10px 20px'}
                                            />
                                            <Button
                                                width={width > 900 && 140}
                                                load={false}
                                                disabled={!changesMadeComment}
                                                action={() => this.saveEditComment()}
                                                text={width > 800 ? 'comp.button.saveChanges' : 'comp.button.save'}
                                                margin={'0'}
                                                padding={'10px 20px'}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <Table
                                        tableHeight={150}
                                        data={siteNoteComments}
                                        header={supportCommentsHeaders}
                                        headerAction={setSupportFacilityCommentsHeaders}
                                        action={(e) => (e.userID === authUser.id ? this.handleCommentAction(e) : null)}
                                        Cell={CommentsCell}
                                        itemSize={width > 800 ? 38 : 20}
                                    />
                                )}
                            </div>
                        )}
                        {!currentComment && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: 40
                                }}>
                                <Button
                                    width={140}
                                    load={false}
                                    action={() =>
                                        this.setState({
                                            showNoteModal: !showNoteModal,
                                            currentNote: null
                                        })
                                    }
                                    text={'pages.users.dismissTraining'}
                                    margin={'0'}
                                    padding={'10px 20px'}
                                />
                                {authUser && authUser.permissions.includes('support_view_site_notes') && (
                                    <Button
                                        width={140}
                                        load={false}
                                        action={() => this.handleDelete(currentNote)}
                                        text={'comp.button.delete'}
                                        margin={'0'}
                                        padding={'10px 20px'}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </Modal>
                <Header
                    Icon={FileIcon}
                    iconSize={26}
                    title={'pages.sites.siteNotes'}
                    manage={[
                        {
                            icon: 'view_list',
                            color: theme.color.buttonFontColor,
                            text: 'pages.dashboard.all',
                            action: () => history.push('/app/notes/all'),
                            id: 'siteNotes-view_list'
                        }
                    ]}
                />
                <Row style={{ margin: '0 0 0 0', padding: '0 20px 20px 20px' }}>
                    <Table
                        tableHeight={150}
                        data={siteNotesList ? siteNotesList : []}
                        action={(e) => this.handleNoteAction(e)}
                        header={supportNotesHeaders}
                        headerAction={setSupportFacilityHeaders}
                        Cell={NotesCell}
                        itemSize={41}
                    />
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, support }) => {
    const { theme, width, userTimezone } = app;
    const { authUser, site } = auth;
    const { siteNotesList, siteNoteComments, supportNotesHeaders, supportCommentsHeaders } = support;
    return { theme, authUser, site, userTimezone, width, siteNotesList, siteNoteComments, supportNotesHeaders, supportCommentsHeaders };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, {
            fetchSiteNotes,
            deleteSiteNote,
            fetchSiteNoteComments,
            createSiteNoteComment,
            editSiteNoteComment,
            deleteSiteNoteComment,
            setSupportFacilityHeaders,
            setSupportFacilityCommentsHeaders
        })(DashboardSiteNotes)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 5,
            fontSize: 20
        },
        descriptionModalNote: {
            color: theme.color.fontColor,
            fontSize: 14
        },
        iconWrapperModalNote: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        buttonWrapperNote: {
            display: 'block',
            padding: 25,
            margin: '0 auto',
            minHeight: 421
        },
        noteModalWrapper: {
            textAlign: 'left'
        },
        descriptionModalLabel: {
            color: theme.color.themeColor,
            fontSize: 16,
            marginBottom: 0
        },
        titleModalNote: {
            color: theme.color.fontColor,
            textAlign: 'left',
            fontSize: 22,
            margin: '0 0 0 15px'
        },
        descriptionModalNoteRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        noteEditIcon: {
            color: theme.color.themeColor,
            opacity: 1,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        titleHeader: {
            fontSize: 13,
            margin: '5px 0 0 0',
            color: theme.color.themeColor,
            opacity: 1,
            transition: 'all .1s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            },
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        labelComment: {
            color: theme.color.themeColor,
            fontWeight: 600,
            marginBottom: 10
        },
        input: {
            boxShadow: 'none',
            borderRadius: 5,
            padding: '10px 0 5px 10px',
            fontWeight: 400,
            fontSize: 14,
            minHeight: 60,
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor,
            marginBottom: 10,
            marginTop: 20,
            width: '100%',
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 14
            }
        },
        inputEdit: {
            boxShadow: 'none',
            borderRadius: 5,
            padding: '10px 0 5px 10px',
            fontWeight: 400,
            fontSize: 14,
            width: '100%',
            minHeight: 169,
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor,
            // marginBottom: 10,
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 14
            }
        },
        commentsRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            minHeight: 207
        },
        commentsAdd: {
            opacity: 1,
            transition: '0.2s ease-in-out all',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        headerComments: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0',
            fontSize: 16,
            fontFamily: 'Roboto'
        },
        commentAddWrapper: {
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            padding: 20,
            width: 350
        }
    });
