import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
import CellTextLink from 'components/Table/cell/components/textLink';
class ActivityCell extends React.Component {
    render() {
        const { intl, data, width, authUser, site, userTimezone } = this.props;
        const { firstName, lastName, unitName, createdDate, statusChange, userUUID, unitUUID } = data;
        return (
            <>
                <CellTextLink
                    padding={'2px 10px 2px 0'}
                    Title={unitName ? unitName : intl.formatMessage({ id: `db.blank` })}
                    textAlign={'left'}
                    TitleColor={true}
                    link={`/app/units/display/${unitUUID}`}
                />
                {width > 800 && (
                    <CellTextLink
                        padding={'2px 10px 2px 0'}
                        textAlign={'left'}
                        maxCharLength={25}
                        Title={
                            firstName && lastName
                                ? `${firstName.toLowerCase()} ${lastName.toLowerCase()}`
                                : intl.formatMessage({ id: `db.blank` })
                        }
                        TitleColor={true}
                        textTransform={'capitalize'}
                        link={`/app/users/display/${userUUID}`}
                    />
                )}
                {width > 800 && (
                    <CellText
                        padding={'2px 10px 2px 0'}
                        textAlign={'left'}
                        Title={statusChange ? statusChange : intl.formatMessage({ id: `db.blank` })}
                        TitleColor={true}
                        textTransform={'capitalize'}
                    />
                )}
                {width > 800 && (
                    <CellText
                        TitleSize={11}
                        padding={'2px 0 2px 10px'}
                        Cellwidth={'80px'}
                        textAlign={'right'}
                        Title={UTILS.dateTimeFormatter(createdDate, authUser, site, userTimezone)}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, userTimezone, authUser, site };
};

export default injectIntl(connect(mapStateToProps)(ActivityCell));
