import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
class UnitsNotesCell extends React.Component {
    render() {
        let now = moment().utc().format();
        const { data, width, authUser, site } = this.props;
        const { heading, dueDate, createdBySupport, createdByAutomation, createdByTenant, noteUUID } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    Support={createdBySupport}
                    MinCellHeight={45}
                    Automated={createdByAutomation}
                    Tenant={createdByTenant}
                    maxCharLength={width > 300 ? 80 : 20}
                    Title={heading}
                    textAlign={'left'}
                    TitleColor={true}
                    uuid={noteUUID}
                />
                <CellText
                    padding={'2px 0 2px 10px'}
                    Cellwidth={'80px'}
                    Title={UTILS.dateFormatter(dueDate, authUser, site)}
                    textAlign={'right'}
                    TitleColor={true}
                    overdue={moment(now).isAfter(dueDate)}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, authUser, site, userTimezone };
};

export default connect(mapStateToProps)(UnitsNotesCell);
