import * as CONSTANTS from 'constants';

const INIT_STATE = {
    activeSupport: 'stats',
    siteSyncStatus: null,
    siteSyncStatusLogs: null,
    siteSyncLogs: null,
    siteSyncUnits: null,
    supportActivity: null,
    troubleshootList: [],
    siteNotesList: [],
    siteNotesListAll: [],
    siteNote: null,
    siteNoteComments: null,
    siteSyncLogsHeaders: [
        {
            id: 0,
            title: 'comp.table.header.syncKey',
            active: false,
            sortable: true,
            width: 100,
            order: true,
            mobile: false,
            sortTitle: 'syncKey',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.syncStatus',
            active: false,
            sortable: true,
            width: 100,
            order: true,
            mobile: false,
            sortTitle: 'syncStatus',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.description',
            active: false,
            sortable: true,
            width: 400,
            order: true,
            mobile: false,
            sortTitle: 'response',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.syncDate',
            active: true,
            sortable: true,
            width: 100,
            order: false,
            mobile: true,
            sortTitle: 'createdAt',
            style: { textAlign: 'right' }

        }

    ],
    siteSyncHeaders: [//
        {
            id: 0,
            title: 'comp.table.header.externalID',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 150,
            sortTitle: 'ExternalID',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.userID',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 150,
            sortTitle: 'ExternalTenantID',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.user',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            width: 150,
            sortTitle: 'Name',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 150,
            sortTitle: 'Email',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'Phone',
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.has24HrGateAccess',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'Has24HrGateAccess',
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'comp.table.header.lockState',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'LockState',
            style: { textAlign: 'left' }
        },
        {
            id: 7,
            title: 'comp.table.header.gateAccessCode',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'GateAccessCode',
            style: { textAlign: 'left' }
        },
        {
            id: 8,
            title: 'comp.table.header.gateAccessCode2',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'GateAccessCode2',
            style: { textAlign: 'left' }
        },
        {
            id: 9,
            title: 'comp.table.header.changeType',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'ChangeType',
            style: { textAlign: 'left' }
        }
    ],
    supportActivityHeaders: [
        {
            id: 0,
            title: 'comp.table.header.companyId',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'companyID',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.siteId',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'siteID',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.user',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'userID',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.action',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'action',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.date',
            active: true,
            sortable: true,
            order: false,
            mobile: true,
            sortTitle: 'timestamp',
            style: { textAlign: 'right' }
        }
    ],
    supportLogsHeaders: [
        {
            id: 0,
            title: 'comp.table.header.endPoint',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'endPoint',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.message',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 200,
            sortTitle: 'errorMessage',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.companyId',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'companyUUID',
            style: { textAlign: 'left' }
        },
        // {
        //     id: 3,
        //     title: 'comp.table.header.siteId',
        //     active: false,
        //     sortable: true,
        //     order: true,
        //     mobile: false,
        //     sortTitle: 'siteId',
        //     style: { textAlign: 'left' }
        // },
        {
            id: 4,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'email',
            width: 200,
            tablet: true,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.countryCode',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'countryCode',
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            tablet: true,
            sortTitle: 'phone',
            style: { textAlign: 'left' }
        },
        // {
        //     id: 7,
        //     title: 'comp.table.header.pin',
        //     active: false,
        //     sortable: true,
        //     order: true,
        //     mobile: false,
        //     laptop: true,
        //     sortTitle: 'pin',
        //     style: { textAlign: 'left' }
        // },
        // {
        //     id: 8,
        //     title: 'comp.table.header.lockId',
        //     active: false,
        //     sortable: true,
        //     order: true,
        //     mobile: false,
        //     laptop: true,
        //     sortTitle: 'lockId',
        //     style: { textAlign: 'left' }
        // },
        // {
        //     id: 9,
        //     title: 'comp.table.header.unitId',
        //     active: false,
        //     sortable: true,
        //     order: true,
        //     mobile: false,
        //     laptop: true,
        //     sortTitle: 'unitId',
        //     style: { textAlign: 'left' }
        // },
        {
            id: 10,
            title: 'comp.table.header.date',
            active: true,
            sortable: true,
            order: false,
            mobile: true,
            width: 85,
            sortTitle: 'currentTime',
            style: { textAlign: 'right' }
        }
    ],
    troubleshootHeaders: [
        {
            width: 45,
            title: 'blank',
            active: false,
            sortable: false,
            order: true,
            mobile: true
        },
        {
            id: 0,
            title: 'comp.table.header.errorCode',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'code',
            width: 88,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.subcode',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'subcode',
            width: 88,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'type',
            width: 80,
            tablet: true,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.description',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'description',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.troubleshoot',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'troubleshoot',
            width: '40%',
            laptop: true,
            style: { textAlign: 'left' }
        }
    ],
    supportNotesHeaders: [
        {
            id: 0,
            title: 'form.title',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'title',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.date.create',
            width: 80,
            active: true,
            sortable: true,
            order: false,
            mobile: true,
            sortTitle: 'createdDate',
            style: { textAlign: 'right' }
        },
        {
            id: 2,
            title: 'blank',
            width: 35,
            active: false,
            sortable: false,
            order: false,
            mobile: false
        }
    ],
    notesHeadersAll: [
        {
            id: 1,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 55
        },
        {
            id: 1,
            title: 'form.title',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'title',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.body',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'body',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'form.createdBy',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 140,
            sortTitle: 'createdByFirstName',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.date.create',
            width: 80,
            active: true,
            sortable: true,
            order: false,
            mobile: true,
            sortTitle: 'createdDate',
            style: { textAlign: 'right' }
        },
        {
            id: 5,
            title: 'blank',
            width: 35,
            active: false,
            sortable: false,
            order: false,
            mobile: false
        }
    ],
    supportCommentsHeaders: [
        {
            id: 0,
            title: 'form.comment',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'comment',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.user',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'firstName'
        },
        {
            id: 2,
            title: 'comp.table.header.date.create',
            width: 90,
            active: true,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'createdDate'
        }
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_SUPPORT_ACTIVE: {
            return {
                ...state,
                activeSupport: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_LOGS: {
            return {
                ...state,
                supportLogs: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_LOGS_HEADERS: {
            return {
                ...state,
                supportLogsHeaders: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SYNC_STATUS: {
            return {
                ...state,
                siteSyncStatus: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SYNC_STATUS_LOGS: {
            return {
                ...state,
                siteSyncStatusLogs: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SYNC_LOGS: {
            return {
                ...state,
                siteSyncLogs: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_ACTIVITY: {
            return {
                ...state,
                supportActivity: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_ACTIVITY_HEADERS: {
            return {
                ...state,
                supportActivityHeaders: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SYNC_UNITS: {
            return {
                ...state,
                siteSyncUnits: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SYNC_HEADERS: {
            return {
                ...state,
                siteSyncHeaders: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SYNC_LOGS_HEADERS: {
            return {
                ...state,
                siteSyncLogsHeaders: action.payload
            };
        }
        case CONSTANTS.SET_TROUBLESHOOT_LIST: {
            return {
                ...state,
                troubleshootList: action.payload
            };
        }
        case CONSTANTS.SET_SITE_NOTES: {
            return {
                ...state,
                siteNotesList: action.payload
            };
        }
        case CONSTANTS.SET_SITE_NOTES_ALL: {
            return {
                ...state,
                siteNotesListAll: action.payload
            };
        }
        case CONSTANTS.SET_SITE_NOTE: {
            return {
                ...state,
                siteNote: action.payload
            };
        }
        case CONSTANTS.SET_SITE_NOTE_COMMENTS: {
            return {
                ...state,
                siteNoteComments: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_FACILITY_HEADERS: {
            return {
                ...state,
                supportNotesHeaders: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_FACILITY_HEADERS_ALL: {
            return {
                ...state,
                notesHeadersAll: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_FACILITY_COMMENTS_HEADERS: {
            return {
                ...state,
                supportCommentsHeaders: action.payload
            };
        }
        default:
            return state;
    }
};
