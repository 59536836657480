//||||||||||||||||||||||||||||||||||||||||
//|| FETCH SITE MAP                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FETCH_SITE_MAP = '/site/map/get/';
export const PURPOSE_FETCH_SITE_MAP = 'Retrieve sitemap JSON object.';
export const PERMISSIONS_FETCH_SITE_MAP = [];
export const REQUIREMENTS_FETCH_SITE_MAP = ['Token Required'];
export const PARAMETER_FETCH_SITE_MAP = {};
export const RETURN_FETCH_SITE_MAP = {
    sitemaps: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| UPLOAD SITE MAP                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UPLOAD_SITE_MAP = '/site/map/upload/';
export const PURPOSE_UPLOAD_SITE_MAP = 'Upload sitemap JSON object.';
export const PERMISSIONS_UPLOAD_SITE_MAP = [];
export const REQUIREMENTS_UPLOAD_SITE_MAP = ['Token Required'];
export const PARAMETER_UPLOAD_SITE_MAP = {};
export const RETURN_UPLOAD_SITE_MAP = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| EDIT SITE MAP                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_EDIT_SITE_MAP = '/site/map/edit/';
export const PURPOSE_EDIT_SITE_MAP = 'Edit sitemap JSON object.';
export const PERMISSIONS_EDIT_SITE_MAP = [];
export const REQUIREMENTS_EDIT_SITE_MAP = ['Token Required'];
export const PARAMETER_EDIT_SITE_MAP = {};
export const RETURN_EDIT_SITE_MAP = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| DELETE SITE MAP                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_DELETE_SITE_MAP = '/site/map/delete/';
export const PURPOSE_DELETE_SITE_MAP = 'Delete sitemap JSON object.';
export const PERMISSIONS_DELETE_SITE_MAP = [];
export const REQUIREMENTS_DELETE_SITE_MAP = ['Token Required'];
export const PARAMETER_DELETE_SITE_MAP = {};
export const RETURN_DELETE_SITE_MAP = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| EDIT SITE MAP DATA                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_EDIT_SITE_MAP_DATA = '/site/map/edit/data/';
export const PURPOSE_EDIT_SITE_MAP_DATA = 'Edit sitemap name and viewbox.';
export const PERMISSIONS_EDIT_SITE_MAP_DATA = [];
export const REQUIREMENTS_EDIT_SITE_MAP_DATA = ['Token Required'];
export const PARAMETER_EDIT_SITE_MAP_DATA = {};
export const RETURN_EDIT_SITE_MAP_DATA = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| EDIT SITE MAP DATA                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CREATE_SHAPE_SITE_MAP = '/site/map/shapes/create/';
export const PURPOSE_CREATE_SHAPE_SITE_MAP = 'Add new shape to map.';
export const PERMISSIONS_CREATE_SHAPE_SITE_MAP = [];
export const REQUIREMENTS_CREATE_SHAPE_SITE_MAP = ['Token Required'];
export const PARAMETER_CREATE_SHAPE_SITE_MAP = {};
export const RETURN_CREATE_SHAPE_SITE_MAP = {
    notifications: [{}]
};
