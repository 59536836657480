import * as CONSTANTS from 'constants';

const INIT_STATE = {
    reportsList: null,
    reportObject: null,
    reportGatewayData: null,
    reportLockData: null,
    siteLockTotals: null,
    siteGatewayTotals: null,
    siteUnitFilter: 'all',
    siteGatewayFilter: 'all',
    crossSiteSearchResults: null,
    suspiciousReport: null,
    onSiteTimeReport: null,
    suspiciousReportAll: null,
    suspiciousReportTop: null,
    suspiciousReportUser: null,
    suspiciousManagerReport: null,
    suspiciousManagerReportTop: null,
    suspiciousManagerReportUser: null,
    reportSiteHeader: [
        {
            id: 1,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            icon: true,
            sortTitle: 'name',
            sortedTitle: 'name',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'pages.company.Type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'companyName',
            sortedTitle: 'companyName',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'form.address',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: ['city', 'state'],
            sortedTitle: 'address',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.SONumber',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            desktop: true,
            width: 80,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            width: 45,
            title: 'blank',
            active: false,
            sortable: false,
            order: true,
            mobile: false
        },
        {
            id: 6,
            width: 45,
            title: 'blank',
            active: false,
            sortable: false,
            order: true,
            mobile: false
        }
    ],
    reportSiteAdvancedHeader: [
        {
            id: 1,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            width: 205,
            icon: true,
            sortTitle: 'name',
            sortedTitle: 'name',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'pages.company.Type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'companyName',
            sortedTitle: 'companyName',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'form.address',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: ['city', 'state'],
            sortedTitle: 'address',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.locks',
            active: false,
            sortable: false,
            order: true,
            width: 120,
            mobile: false,
            tablet: true,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.gateways',
            active: false,
            sortable: false,
            order: true,
            width: 120,
            mobile: false,
            tablet: true,
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'form.bleVersions',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            style: { textAlign: 'left' },
            desktop: true,
        },
        {
            id: 7,
            title: 'form.wpVersions',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            style: { textAlign: 'left' },
            desktop: true,
        },
        {
            id: 8,
            title: 'form.gatewayVersions',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            style: { textAlign: 'left' },
            desktop: true,
        },
        {
            id: 9,
            title: 'comp.table.header.lowBattery',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            width: 100,
            style: { textAlign: 'center' },
            laptop: true
        },
        {
            id: 10,
            title: 'comp.SONumber',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            desktop: true,
            width: 80,
            style: { textAlign: 'left' }
        },
        {
            id: 11,
            title: 'comp.rolloutTier',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            laptop: true,
            width: 80,
            style: { textAlign: 'left' }
        },
        {
            id: 12,
            width: 45,
            title: 'blank',
            active: false,
            sortable: false,
            order: true,
            mobile: false
        },
        {
            id: 13,
            width: 45,
            title: 'blank',
            active: false,
            sortable: false,
            order: true,
            mobile: false
        }
    ],
    reportLockHeader: [
        {
            id: 0,
            title: 'comp.table.header.online',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'online',
            mobile: true,
            width: 70,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.name',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'name',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'mac'
        },
        {
            id: 3,
            title: 'comp.table.header.batteryStatus',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'batteryStatus'
        },
        {
            id: 4,
            title: 'comp.table.header.wiredStatus',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wiredStatus'
        },
        {
            id: 5,
            title: 'comp.table.header.hwVersionAbbrev',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'bleHWVersion'
        },
        {
            id: 6,
            title: 'comp.table.header.fwVersionAbbrev',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'bleFWVersion'
        },
        /* Hiding Temperature until we get accurate temps */
        // {
        //     id: 7,
        //     title: 'comp.table.header.temp',
        //     active: false,
        //     sortable: true,
        //     order: true,
        //     mobile: false,
        //     sortTitle: 'temperature'
        // },
        {
            id: 10,
            title: 'comp.table.header.updated',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'lastUpdated'
        }
    ],
    reportLockDetailHeader: [
        {
            id: 0,
            title: 'comp.table.header.missing',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'Missing',
            width: 75,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.ble',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'Ble',
            mobile: true
        },
        {
            id: 2,
            title: 'comp.table.header.gateway',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'GatewayMac'
        },
        {
            id: 3,
            title: 'comp.table.header.kind',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'Kind'
        },
        {
            id: 4,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'Mac'
        },
        {
            id: 5,
            title: 'comp.table.header.nextHop',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'NextHop'
        },
        {
            id: 6,
            title: 'comp.table.header.travelTime',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'TravelTime'
        },
        {
            id: 7,
            title: 'comp.table.header.updated',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'Updated'
        },
        {
            id: 8,
            title: 'comp.table.header.wp',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'Wp'
        },
        {
            id: 9,
            title: 'comp.table.header.wpOtap',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'WpOtap'
        },
        {
            id: 10,
            title: 'comp.table.header.wpStack',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'WpStack'
        }
    ],
    reportGatewaysHeader: [
        {
            id: 0,
            title: 'comp.table.header.online',
            active: true,
            sortable: true,
            order: true,
            width: 70,
            sortTitle: 'online',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.serial',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 150,
            sortTitle: 'serial',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.fwVersionAbbrev',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'firmwareVersion',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'form.comments',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            style: { textAlign: 'left' },
            sortTitle: 'comment'
        }
    ],
    reportVersionsHeader: [
        {
            id: 0,
            title: 'comp.table.header.version',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'Version',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.total',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'Total',
            style: { textAlign: 'right' }
        }
    ],
    reportSearchHeader: [
        {
            id: 0,
            width: 55,
            title: 'blank',
            active: false,
            sortable: false,
            order: true,
            mobile: false
        },
        {
            id: 1,
            title: 'comp.table.header.username',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'userFirstName',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'userPhone',
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'userEmail',
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'comp.table.header.role',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'userRoleName',
            style: { textAlign: 'left' }
        },
        {
            id: 7,
            title: 'pages.sites.Type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'siteName',
            style: { textAlign: 'left' }
        },
        {
            id: 8,
            title: 'comp.table.header.unit',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'unitName',
            mobile: true,
            style: { textAlign: 'left' }
        },
    ],
    reportFilters: [
        {
            title: 'filters.all',
            value: ['client', 'employee', 'technician', 'site_manager', 'company_manager'],
            active: true
        }
    ],
    reportOnSiteTimeHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.dateReported',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'dateReported',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.reports.firstActivityTime',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'firstActivity',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.reports.lastActivityTime',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'lastActivity',
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.reports.totalTimeOnSite',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'minutesPassed',
            style: { textAlign: 'left' }
        }
    ],
    reportOutOfZoneHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'pages.entry.Type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'entryName'
        },
        {
            id: 3,
            title: 'comp.reports.timeAttempt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 105,
            sortTitle: 'attemptedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportDidNotGoHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.timeEntry',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 105,
            sortTitle: 'entryFirstOpenedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportDidNotLeaveHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.timeEntry',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 105,
            sortTitle: 'entryFirstOpenedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportMultipleEntryHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.numEntry',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'entryCount',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.reports.timeEntry',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 105,
            sortTitle: 'entryFirstOpenedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportOverlockAttemptsHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'pages.entry.Type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'entryName'
        },
        {
            id: 3,
            title: 'comp.reports.timeAttempt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 105,
            sortTitle: 'attemptedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportTopUsersHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.outOfZone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'attemptedOutOfZoneCount'
        },
        {
            id: 3,
            title: 'comp.reports.notUnit',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'didNotGoToUnitCount'
        },
        {
            id: 4,
            title: 'comp.reports.overlockAttempt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'overlockAccessAttemptCount'
        },
        {
            id: 5,
            title: 'comp.reports.lateAccess',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'lateNightAccessCount'
        },
        {
            id: 6,
            title: 'comp.reports.notLeave',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'didNotLeaveAfterLockingUnitCount'
        },
        {
            id: 7,
            title: 'comp.reports.multipleEntry',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'multipleEntriesCount'
        }
    ],
    reportTopUsersHeaderShort: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.outOfZone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'attemptedOutOfZoneCount'
        },
        {
            id: 3,
            title: 'comp.reports.notUnit',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'didNotGoToUnitCount'
        },
        {
            id: 4,
            title: 'comp.reports.overlockAttempt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'overlockAccessAttemptCount'
        },
        {
            id: 5,
            title: 'comp.reports.lateAccess',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'lateNightAccessCount'
        },
        {
            id: 6,
            title: 'comp.reports.multipleEntry',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'multipleEntriesCount'
        }
    ],
    reportManuallyChangingStatusHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.unit',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'unitName'
        },
        {
            id: 4,
            title: 'comp.reports.oldState',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'oldState'
        },
        {
            id: 5,
            title: 'comp.reports.newState',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'newState'
        },
        {
            id: 6,
            title: 'comp.table.header.date.update',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'updatedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportOverridingOccupiedUnitsHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.unit',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'unitName'
        },
        {
            id: 4,
            title: 'comp.reports.overrideReason',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'overrideReason'
        },
        {
            id: 5,
            title: 'comp.reports.unitUsername',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'unitUserName'
        },
        {
            id: 6,
            title: 'comp.table.header.date.update',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'accessedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportManuallyChangingStatusHeaderUser: [
        {
            id: 2,
            title: 'comp.table.header.unit',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'unitName',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.reports.oldState',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'oldState'
        },
        {
            id: 5,
            title: 'comp.reports.newState',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'newState'
        },
        {
            id: 6,
            title: 'comp.table.header.date.update',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 110,
            sortTitle: 'updatedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportOverridingOccupiedUnitsHeaderUser: [
        {
            id: 2,
            title: 'comp.table.header.unit',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'unitName',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.reports.overrideReason',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'overrideReason'
        },
        {
            id: 5,
            title: 'comp.reports.unitUsername',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'unitUserName'
        },
        {
            id: 6,
            title: 'comp.table.header.date.update',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 110,
            sortTitle: 'accessedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportTopUsersManagerHeader: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.manuallyChangingStatus',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'manuallyChangingStatusCount'
        },
        {
            id: 3,
            title: 'comp.reports.overridingOccupiedUnits',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'overridingOccupiedUnitsCount'
        }
    ],
    reportOutOfZoneHeaderUser: [
        {
            id: 2,
            title: 'pages.entry.Type',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'entryName',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.reports.timeAttempt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'attemptedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportDidNotGoHeaderUser: [
        {
            id: 1,
            title: 'comp.reports.timeEntry',
            active: true,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'entryFirstOpenedAt',
            style: { textAlign: 'left' }
        }
    ],
    reportDidNotLeaveHeaderUser: [
        {
            id: 1,
            title: 'comp.reports.timeEntry',
            active: true,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'entryFirstOpenedAt',
            style: { textAlign: 'left' }
        }
    ],
    reportMultipleEntryHeaderUser: [
        {
            id: 1,
            title: 'comp.reports.numEntry',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'entryCount',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.timeEntry',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'entryFirstOpenedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportOverlockAttemptsHeaderUser: [
        {
            id: 1,
            title: 'pages.entry.Type',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'entryName',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.timeAttempt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'attemptedAt',
            style: { textAlign: 'right' }
        }
    ],
    reportLateAccessHeaderUser: [
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'userName',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.reports.timeAccess',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'accessedAt',
            style: { textAlign: 'right' }
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_REPORT_LIST: {
            return {
                ...state,
                reportsList: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_DETAILS: {
            return {
                ...state,
                reportObject: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_GATEWAY_DATA: {
            return {
                ...state,
                reportGatewayData: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_LOCK_DATA: {
            return {
                ...state,
                reportLockData: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_SITE_HEADERS: {
            return {
                ...state,
                reportSiteHeader: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_SITE_ADVANCED_HEADERS: {
            return {
                ...state,
                reportSiteAdvancedHeader: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_LOCK_HEADERS: {
            return {
                ...state,
                reportLockHeader: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_LOCK_DETAIL_HEADERS: {
            return {
                ...state,
                reportLockDetailHeader: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_GATEWAY_HEADERS: {
            return {
                ...state,
                reportGatewaysHeader: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_VERSION_HEADERS: {
            return {
                ...state,
                reportVersionsHeader: action.payload
            };
        }
        case CONSTANTS.SET_REPORT_FILTERS: {
            return {
                ...state,
                reportFilters: action.payload
            };
        }
        case CONSTANTS.SET_SITE_LOCK_TOTALS: {
            return {
                ...state,
                siteLockTotals: action.payload
            };
        }
        case CONSTANTS.SET_SITE_GATEWAY_TOTALS: {
            return {
                ...state,
                siteGatewayTotals: action.payload
            };
        }
        case CONSTANTS.SET_SITE_UNIT_FILTER: {
            return {
                ...state,
                siteUnitFilter: action.payload
            };
        }
        case CONSTANTS.SET_SITE_GATEWAY_FILTER: {
            return {
                ...state,
                siteGatewayFilter: action.payload
            };
        }
        case CONSTANTS.SET_CROSS_SITE_SEARCH_RESULTS: {
            return {
                ...state,
                crossSiteSearchResults: action.payload
            };
        }
        case CONSTANTS.SET_SUSPICIOUS_REPORT: {
            return {
                ...state,
                suspiciousReport: action.payload
            };
        }
        case CONSTANTS.SET_ONSITE_TIME_REPORT: {
            return {
                ...state,
                onSiteTimeReport: action.payload
            };
        }
        case CONSTANTS.SET_SUSPICIOUS_REPORT_ALL: {
            return {
                ...state,
                suspiciousReportAll: action.payload
            };
        }
        case CONSTANTS.SET_SUSPICIOUS_REPORT_TOP: {
            return {
                ...state,
                suspiciousReportTop: action.payload
            };
        }
        case CONSTANTS.SET_SUSPICIOUS_REPORT_USER: {
            return {
                ...state,
                suspiciousReportUser: action.payload
            };
        }
        case CONSTANTS.SET_SUSPICIOUS_MANAGER_REPORT: {
            return {
                ...state,
                suspiciousManagerReport: action.payload
            };
        }
        case CONSTANTS.SET_SUSPICIOUS_MANAGER_REPORT_TOP: {
            return {
                ...state,
                suspiciousManagerReportTop: action.payload
            };
        }
        case CONSTANTS.SET_SUSPICIOUS_MANAGER_REPORT_USER: {
            return {
                ...state,
                suspiciousManagerReportUser: action.payload
            };
        }
        default:
            return state;
    }
};
