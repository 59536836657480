import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';
import cuid from 'cuid';

export const userSignIn = (data) => {
    return {
        type: CONSTANTS.SIGNIN_USER,
        payload: {
            url: ENDPOINTS.ENDPOINT_LOGIN,
            data
        }
    };
};

export const userSignOut = () => {
    return {
        type: CONSTANTS.SIGNOUT_USER
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: CONSTANTS.SIGNIN_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignOutSuccess = () => {
    return {
        type: CONSTANTS.SIGNOUT_USER_SUCCESS
    };
};

export const forgotPassword = (data) => {
    return {
        type: CONSTANTS.FORGOT_PASSWORD,
        payload: {
            url: ENDPOINTS.ENDPOINT_FORGOT_PASSWORD,
            data
        }
    };
};

export const resetPassword = (data) => {
    return {
        type: CONSTANTS.RESET_PASSWORD,
        payload: {
            url: ENDPOINTS.ENDPOINT_RESET_PASSWORD,
            data
        }
    };
};

export const forgotUserPassword = (data) => {
    return {
        type: CONSTANTS.FORGOT_USER_PASSWORD,
        payload: {
            url: ENDPOINTS.ENDPOINT_FORGOT_PASSWORD,
            data
        }
    };
};

export const resetUserPassword = (data) => {
    return {
        type: CONSTANTS.RESET_USER_PASSWORD,
        payload: {
            url: ENDPOINTS.ENDPOINT_RESET_PASSWORD,
            data
        }
    };
};

export const switchSite = (siteUUID = '', token = false, support = false, unitUUID = false, userUUID = false, redirectHome = false, simplifiedView = null) => {
    let deviceUUID = cuid();
    return {
        type: CONSTANTS.SWITCH_SITE,
        payload: {
            url: ENDPOINTS.ENDPOINT_SITE_SWITCH,
            token,
            support,
            unitUUID,
            userUUID,
            redirectHome,
            simplifiedView,
            data: {
                siteUUID,
                deviceUUID
            }
        }
    };
};

export const refreshAuthToken = () => {
    return {
        type: CONSTANTS.REFRESH_AUTH_TOKEN,
        payload: {
            url: ENDPOINTS.ENDPOINT_REFRESH_AUTH_TOKEN
        }
    };
};

export const setSite = (data) => {
    return {
        type: CONSTANTS.SET_SITE,
        payload: data
    };
};

export const setCreateSite = (data) => {
    return {
        type: CONSTANTS.SET_CREATE_SITE,
        payload: data
    };
};

export const syncSite = ({ site = {}, force = false, notify = false }) => {
    return {
        type: CONSTANTS.SYNC_SITE,
        payload: {
            url: ENDPOINTS.ENDPOINT_SITE_SYNC,
            data: {
                forceSync: force
            },
            site: site,
            notify: notify
        }
    };
};

export const showAuthLoader = () => {
    return {
        type: CONSTANTS.ON_SHOW_LOADER
    };
};

export const hideAuthLoader = () => {
    return {
        type: CONSTANTS.ON_HIDE_LOADER
    };
};

export const resetPinCall = (data) => {
    return {
        type: CONSTANTS.RESET_PIN_CALL,
        payload: {
            url: ENDPOINTS.ENDPOINT_RESET_PIN_CALL,
            data
        }
    };
};

export const fetchAuthPin = ({ pin = '', login = {} }) => {
    return {
        type: CONSTANTS.FETCH_AUTH_PIN,
        payload: {
            url: ENDPOINTS.ENDPOINT_FETCH_AUTH_PIN,
            data: {
                pin
            },
            login
        }
    };
};

export const sendAuthPin = () => {
    return {
        type: CONSTANTS.SEND_AUTH_PIN,
        payload: {
            url: ENDPOINTS.ENDPOINT_SEND_AUTH_PIN,
            data: {}
        }
    };
};

export const showTwoFactor = (data) => {
    return {
        type: CONSTANTS.SHOW_TWO_FACTOR,
        payload: data
    };
};

export const validateUser = (data) => {
    return {
        type: CONSTANTS.VALIDATE_USER,
        payload: {
            url: ENDPOINTS.ENDPOINT_VALIDATE_USER,
            data
        }
    };
};

export const setLoginConfirm = (data) => {
    return {
        type: CONSTANTS.SET_LOGIN_CONFIRM,
        payload: data
    };
};

export const setAuthUser = (data) => {
    return {
        type: CONSTANTS.SET_AUTH_USER,
        payload: data
    };
};

export const setViewAs = (data) => {
    return {
        type: CONSTANTS.SET_VIEW_AS,
        payload: data
    };
};

export const setLoginAttemptModal = (data) => {
    return {
        type: CONSTANTS.SET_LOGIN_ATTEMPT_MODAL,
        payload: data
    };
};
