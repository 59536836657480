import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import Menu from 'components/Menus/filter';
class HeaderFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            show: false
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            let { data } = this.props;
            this.setState({ data });
        }
    }
    render() {
        const { intl, action, theme, width } = this.props;
        const { data, show } = this.state;
        this.styles = styles(theme, width);
        let active = data.find((filt) => filt.active);
        return (
            <div className={css(this.styles.wrapper)}>
                <Dropdown direction={'down'} isOpen={show} toggle={() => this.setState({ show: !show })}>
                    <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                        <span className={css(this.styles.header)}>
                            {' '}
                            - {intl.formatMessage({ id: active ? active.title : 'blank' })}{' '}
                            <i className={`material-icons ${css(this.styles.icon)}`}>arrow_drop_down</i>
                        </span>
                    </DropdownToggle>
                    <DropdownMenu right className={css(this.styles.menuWrapper)}>
                        <div className={css(this.styles.menu)}>
                            <Menu
                                toggle={() => this.setState({ show: !show })}
                                data={data}
                                update={this.updateFilter}
                                action={action}
                            />
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

HeaderFilter.defaultProps = {
    data: []
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(connect(mapStateToProps)(HeaderFilter));

const styles = (theme, width) =>
    StyleSheet.create({
        wrapper: {
            display: 'inline-block',
            marginLeft: 5,
            paddingTop: 0,
            marginTop: 0
        },
        header: {
            color: theme.color.themeColor,
            fontSize: width > 800 ? 14 : 12,
            fontWeight: 400,
            paddingBottom: 5,
            marginBottom: 0,
            textTransform: 'uppercase',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        menuWrapper: {
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            padding: 10,
            width: 250
        },
        menu: {
            maxHeight: 300,
            overflow: 'auto'
        }
    });
