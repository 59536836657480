import React from 'react';
import { connect } from 'react-redux';
import {StyleSheet, css} from 'aphrodite';
import { CheckmarkIcon, HelpIconCircle, CloseIcon, WarningIcon } from 'utilities/icons';

class ModalHeader extends React.Component {
  constructor (props) {
    super (props);
  }
  render() {
    const { children, theme, width, height, style, headerType, toggle } = this.props;
    this.styles = styles(theme, width,height,headerType);

    return (
      children && <div className={css(this.styles.wrapper)} style={style}>
        <div className={css(this.styles.content)}>
          {headerType === 'success' && <div className={css(this.styles.whiteIconWrapper)}><CheckmarkIcon stroke={theme && theme.color.headerColor} height={14} width={14} strokeWidth={1.3} /></div>}
          {headerType === 'info' && <HelpIconCircle fill={'#4788D1'} height={24} width={24} />}
          {headerType === 'error' && <div className={css(this.styles.whiteIconWrapper)}><CloseIcon fill={theme && theme.color.headerColor} height={11} width={13} /></div>}
          {headerType === 'warning' && <WarningIcon fill={'#E97821'} height={24} width={24} />}
          {children}
        </div>
        {toggle && <div className={css(this.styles.closeIcon)} onClick={toggle}>
          <CloseIcon fill={theme.color.fontColor} width={15} height={15} />
        </div>}
      </div>
    )
  }
}

const mapStateToProps = ({ app }) => {
  const { theme, width } = app;
  return { theme, width };
}

export default connect(mapStateToProps)(ModalHeader);

const styles = (theme,width,height,headerType) => StyleSheet.create({
  wrapper: {
    borderBottom: `1px solid ${theme && theme.color.borderColor}`,
    fontSize: 20,
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'space-between',
    minHeight: 63,
    paddingLeft: '1rem',
    color: theme && theme.color.fontColor,
  },
  content: {
    display: 'flex',
    alignItems: 'center', 
    gap: 15,
    width: '100%',
  },
  whiteIconWrapper: {
    backgroundColor: !headerType ? 'transparent' : 
    headerType === 'success' ? '#41C24E' :
    headerType === 'info' ? '#4788D1' :
    headerType === 'error' ? '#C24444' : 
    headerType === 'warning' ? '#E97821' : 
    theme && theme.color.themeColor,
    display: 'grid',
    placeItems: 'center',
    borderRadius: 15,
    width: 27,
    height: 27,
  },
  closeIcon: {
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    marginRight: 22,
    marginLeft: 11,
  }
});
