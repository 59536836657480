import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchActivityRedirects = () => {
    return {
        type: CONSTANTS.FETCH_ACTIVITY_REDIRECTS,
        payload: {
            url: ENDPOINTS.ENDPOINT_ACTIVITY_REDIRECTS_FETCH,
            data: {}
        }
    };
};

export const setActivityRedirects = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_REDIRECTS,
        payload: data
    };
};

export const removeActivityRedirect = (data) => {
    return {
        type: CONSTANTS.REMOVE_ACTIVITY_REDIRECT,
        payload: {
            url: ENDPOINTS.ENDPOINT_REMOVE_ACTIVITY_REDIRECT,
            data
        }
    };
};

export const addActivityRedirect = (data) => {
    return {
        type: CONSTANTS.ADD_ACTIVITY_REDIRECT,
        payload: {
            url: ENDPOINTS.ENDPOINT_ADD_ACTIVITY_REDIRECT,
            data
        }
    };
};

export const getSiteEventPreferences = () => {
    return {
        type: CONSTANTS.GET_SITE_EVENT_PREFERENCES,
        payload: {
            url: ENDPOINTS.ENDPOINT_GET_SITE_EVENT_PREFERENCES,
            data: {}
        }
    };
};

export const setSiteEventPreferences = (data) => {
    return {
        type: CONSTANTS.SET_SITE_EVENT_PREFERENCES,
        payload: data
    };
};

export const editSiteEventPreferences = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_EVENT_PREFERENCES,
        payload: {
            url: ENDPOINTS.ENDPOINT_EDIT_SITE_EVENT_PREFERENCES,
            data: {
                setTypes: data
            }
        }
    };
};

export const setActivityRedirectHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_REDIRECT_HEADERS,
        payload: data
    };
};