const errorCodes = [];

for (var i = 1; i < 91; i++) {
    errorCodes.push({
        id: i,
        errorCode: i,
        name: `S-${i}`,
        description: `errorCodes.description.${i}`,
        troubleshoot: `errorCodes.troubleshoot.${i}`
    });
}

export const ERROR_CODES = errorCodes;
