import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';

import Logo from 'components/Logo';
import Server from 'components/Server';
class AuthTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            showSwitcher: false,
            math: Math.random()
        };
    }
    switchURL = () => {
        let { count } = this.state;
        count = count + 1;
        if (count > 7) {
            this.setState({ showSwitcher: true });
        } else {
            this.setState({ count });
        }
        setTimeout(() => {
            this.setState({ count: 0 });
        }, 5000);
    };
    render() {
        const { theme, width } = this.props;
        const { showSwitcher, math } = this.state;
        this.styles = styles(theme);
        return (
            <Container fluid>
                <Row style={{ height: '100%' }}>
                    {width > 991 && (
                        <Col
                            xs={6}
                            style={{
                                backgroundImage:
                                    math > 0.5
                                        ? `url('/assets/images/Components/Auth/authBackground4.jpeg')`
                                        : `url('/assets/images/Components/Auth/authBackground3.jpeg')`
                            }}
                            className={`indexPage ${css(this.styles.sideWrapper)}`}></Col>
                    )}
                    <Col xs={12} lg={6} className={css(this.styles.formWrapper)}>
                        <div className={css(this.styles.imageWrapper)} onClick={this.switchURL}>
                            <Logo width={'20vw'} height={100} alt={theme.title} src={theme.logo} />
                        </div>
                        {this.props.children}
                    </Col>
                </Row>
                <Server 
                    showSwitcher={showSwitcher} 
                    toggle={(showSwitcher) => this.setState({ showSwitcher })} />
            </Container>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default connect(mapStateToProps)(AuthTemplate);

const styles = (theme) =>
    StyleSheet.create({
        sideWrapper: {
            backgroundColor: theme.color.pageColor
        },
        formWrapper: {
            backgroundColor: theme.color.compColor,
            padding: '10vh 10vw',
            '@media(max-width:991px)': {
                height: '100%'
            },
            '@media(max-width:550px)': {
                padding: '5vh 5vw'
            }
        },
        imageWrapper: {
            backgroundColor: theme.color.buttonFontColor,
            padding: 20,
            borderRadius: 5
        }
    });
