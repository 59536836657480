import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
class CellStatus extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, input, checker, Cellwidth, translate, textAlign, fontSize, goodColor, badColor } = this.props;
        this.styles = styles(textAlign, fontSize, goodColor, badColor);
        return (
            <td width={Cellwidth}>
                <div className={css(checker ? this.styles.green : this.styles.red)}>
                    {translate ? intl.formatMessage({ id: `db.${input}` }) : input}
                </div>
            </td>
        );
    }
}

CellStatus.defaultProps = {
    input: 'Unknown',
    checker: 'Open',
    textAlign: 'center',
    translate: false,
    fontSize: 12,
    goodColor: '#3ac923',
    badColor: 'red'
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(CellStatus)));

const styles = (textAlign, fontSize, goodColor, badColor) =>
    StyleSheet.create({
        green: {
            textTransform: 'capitalize',
            fontWeight: 400,
            fontSize,
            color: goodColor,
            textAlign
        },
        red: {
            textTransform: 'capitalize',
            fontWeight: 400,
            fontSize,
            color: badColor,
            textAlign
        }
    });
