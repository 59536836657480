import * as API from 'api';

const testPost = async (endpoint, data) => {
    let res = await API.POST_TEST(endpoint, data);
    return res;
};

const testLogin = async (endpoint, data) => {
    let res = await API.LOGIN_TEST(endpoint, data);
    return res;
};

const returnTest = (res, test) => {
    if (!res) return res;
    let newRes = res;
    newRes.test = test;
    return newRes;
};

const createResult = (args) => {
    for (let arg of args) {
        if (!arg.condition) return {
            status: 'fail', 
            errorMessage: arg.errorMessage || `Test failed due to false logic: ${arg.condition}`,
        };
    }
    return {status: 'pass', errorMessage: null};
};

const createWarnings = (args) => {
    return args.filter(arg => arg.condition).map(arg => arg.message);
};

const waitForCondition = (condition, time = 10) => {
    let attempts = 0;
    return new Promise(resolve => {
        let timer = setInterval(() => {
            if (condition()) {
                clearInterval(timer);
                resolve({
                    test: {
                        result: {
                            status: 'pass',
                            errorMessage: null,
                        },
                        warnings: []
                    }
                })
            } else {
                if (attempts === time * 2) {
                    clearInterval(timer);
                    resolve({
                        test: {
                            result: {
                                status: 'fail',
                                errorMessage: 'waitForCondition failed, condition never turned true',
                            },
                            warnings: []
                        }
                    })
                }
                attempts += 1;
            }
        }, 500);
    })
};

const sleep = (milliseconds) => {
    let start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds){
            break;
        }
    }
}

export { 
    testPost, 
    testLogin,
    returnTest,
    createResult,
    createWarnings,
    waitForCondition,
    sleep
};
