import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchSiteSyncStatus = (siteUUID = '') => {
    return {
        type: CONSTANTS.FETCH_SITE_SYNC_STATUS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SITE_SYNC_STATUS,
            data: {
                siteUUID
            }
        }
    };
};

export const fetchSiteSyncStatusLogs = (siteUUID = '') => {
    return {
        type: CONSTANTS.FETCH_SITE_SYNC_STATUS_LOGS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SITE_SYNC_STATUS_LOGS,
            data: {
                siteUUID
            }
        }
    };
};


export const syncSiteUnits = () => {
    return {
        type: CONSTANTS.SYNC_SITE_UNITS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SITE_SYNC_UNITS,
            data: {}
        }
    };
};

export const fetchSupportActivity = () => {
    return {
        type: CONSTANTS.FETCH_SUPPORT_ACTIVITY,
        payload: {
            url: ENDPOINTS.ENDPOINT_SUPPORT_ACTIVITY,
            data: {}
        }
    };
};

export const fetchSupportLogs = () => {
    return {
        type: CONSTANTS.FETCH_SUPPORT_LOGS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SUPPORT_LOGS,
            data: {}
        }
    };
};

export const fetchTroubleshootList = () => {
    return {
        type: CONSTANTS.FETCH_TROUBLESHOOT_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_TROUBLESHOOT_LIST,
            data: {}
        }
    };
};

export const setSupportLogs = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_LOGS,
        payload: data
    };
};

export const setSupportLogsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_LOGS_HEADERS,
        payload: data
    };
};

export const setActiveSupport = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_ACTIVE,
        payload: data
    };
};

export const setSiteSyncStatus = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SYNC_STATUS,
        payload: data
    };
};
export const setSiteSyncStatusLogs = (data) => {

    return {
        type: CONSTANTS.SET_SITE_SYNC_STATUS_LOGS,
        payload: data
    };
};

export const setSiteSyncUnits = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SYNC_UNITS,
        payload: data
    };
};

export const setSupportActivity = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_ACTIVITY,
        payload: data
    };
};

export const setSupportActivityHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_ACTIVITY_HEADERS,
        payload: data
    };
};

export const setSiteSyncHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SYNC_HEADERS,
        payload: data
    };
};
export const setSiteSyncLogsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SYNC_LOGS_HEADERS,
        payload: data
    };
};

export const setTroubleshootList = (data) => {
    return {
        type: CONSTANTS.SET_TROUBLESHOOT_LIST,
        payload: data
    };
};

export const sendTrainingAcknowledgement = ({ userUUID = '', acknowledged = false }) => {
    return {
        type: CONSTANTS.SEND_TRAINING_ACKNOWLEDGEMENT,
        payload: {
            url: ENDPOINTS.ENDPOINT_SEND_TRAINING_ACKNOWLEDGEMENT,
            data: {
                userUUID,
                acknowledged
            }
        }
    };
};

export const sendSupportTicket = ({
    tenantName = '',
    ticketDescription = '',
    productIssue = [],
    contactMethod = ''
}) => {
    return {
        type: CONSTANTS.SEND_SUPPORT_TICKET,
        payload: {
            required: {},
            url: ENDPOINTS.ENDPOINT_SEND_SUPPORT_TICKET,
            data: {
                tenantName,
                ticketDescription,
                productIssue,
                contactMethod
            }
        }
    };
};

export const createSiteNote = ({ title = '', body = '' }) => {
    return {
        type: CONSTANTS.CREATE_SITE_NOTE,
        payload: {
            url: '/site/notes/create/',
            data: {
                title,
                body,
                priority: false
            }
        }
    };
};

export const editSiteNote = ({ uuid = '', title = '', body = '', priority = false }) => {
    return {
        type: CONSTANTS.EDIT_SITE_NOTE,
        payload: {
            url: '/site/notes/edit/',
            data: {
                uuid,
                title,
                body,
                priority
            }
        }
    };
};

export const deleteSiteNote = (uuid) => {
    return {
        type: CONSTANTS.DELETE_SITE_NOTE,
        payload: {
            url: '/site/notes/delete/',
            data: {
                uuid
            }
        }
    };
};

export const fetchSiteNotes = () => {
    return {
        type: CONSTANTS.FETCH_SITE_NOTES,
        payload: {
            url: '/site/notes/read/',
            data: {}
        }
    };
};

export const setSiteNotes = (data) => {
    return {
        type: CONSTANTS.SET_SITE_NOTES,
        payload: data
    };
};

export const fetchSiteNotesAll = () => {
    return {
        type: CONSTANTS.FETCH_SITE_NOTES_ALL,
        payload: {
            url: '/site/notes/read/',
            data: {}
        }
    };
};

export const setSiteNotesAll = (data) => {
    return {
        type: CONSTANTS.SET_SITE_NOTES_ALL,
        payload: data
    };
};

export const fetchSiteNote = (uuid) => {
    return {
        type: CONSTANTS.FETCH_SITE_NOTE,
        payload: {
            url: '/site/notes/read/',
            data: {},
            uuid
        }
    };
};

export const setSiteNote = (data) => {
    return {
        type: CONSTANTS.SET_SITE_NOTE,
        payload: data
    };
};

export const fetchSiteNoteComments = (noteUUID) => {
    return {
        type: CONSTANTS.FETCH_SITE_NOTE_COMMENTS,
        payload: {
            url: '/site/notes/comment/read/',
            data: {
                noteUUID
            }
        }
    };
};

export const setSiteNoteComments = (data) => {
    return {
        type: CONSTANTS.SET_SITE_NOTE_COMMENTS,
        payload: data
    };
};

export const createSiteNoteComment = ({ noteUUID = '', comment = '' }) => {
    return {
        type: CONSTANTS.CREATE_SITE_NOTE_COMMENT,
        payload: {
            url: '/site/notes/comment/create/',
            data: {
                noteUUID,
                comment
            }
        }
    };
};

export const editSiteNoteComment = ({ id = 0, noteUUID = '', comment = '' }) => {
    return {
        type: CONSTANTS.EDIT_SITE_NOTE_COMMENT,
        payload: {
            url: '/site/notes/comment/edit/',
            data: {
                id,
                noteUUID,
                comment
            }
        }
    };
};

export const deleteSiteNoteComment = ({ id = 0, noteUUID = '' }) => {
    return {
        type: CONSTANTS.DELETE_SITE_NOTE_COMMENT,
        payload: {
            url: '/site/notes/comment/delete/',
            data: {
                id,
                noteUUID
            }
        }
    };
};

export const setSupportFacilityHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_FACILITY_HEADERS,
        payload: data
    };
};

export const setSupportFacilityHeadersAll = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_FACILITY_HEADERS_ALL,
        payload: data
    };
};


export const setSupportFacilityCommentsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_FACILITY_COMMENTS_HEADERS,
        payload: data
    };
};