import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchCompanyList = () => {
    return {
        type: CONSTANTS.FETCH_COMPANY_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_COMPANY_LIST,
            data: {}
        }
    };
};

export const setCompanyList = (data) => {
    return {
        type: CONSTANTS.SET_COMPANY_LIST,
        payload: data
    };
};

export const createCompany = ({ name = '', frontendHost = '', email = '' }) => {
    return {
        type: CONSTANTS.CREATE_COMPANY,
        payload: {
            url: ENDPOINTS.ENDPOINT_CREATE_COMPANY,
            data: {
                name,
                frontendHost,
                email
            },
            required: {
                name
            }
        }
    };
};

export const editCompanyAppColor = (
    appColor = '',
    companyUUID = JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
) => {
    return {
        type: CONSTANTS.EDIT_COMPANY_APP_COLOR,
        payload: {
            url: ENDPOINTS.ENDPOINT_EDIT_COMPANY_APP_COLOR,
            data: {
                appColor,
                companyUUID
            },
            required: {
                appColor
            }
        }
    };
};

export const editCompany = ({ uuid = '', name = '', frontendHost = '', email = '' }) => {
    return {
        type: CONSTANTS.EDIT_COMPANY,
        payload: {
            url: ENDPOINTS.ENDPOINT_EDIT_COMPANY,
            data: {
                uuid,
                name,
                frontendHost,
                email
            },
            required: {
                name
            }
        }
    };
};

export const setCompanySettings = (data) => {
    return {
        type: CONSTANTS.SET_COMPANY_SETTINGS,
        payload: data
    };
};

export const fetchCompanyStats = () => {
    return {
        type: CONSTANTS.FETCH_COMPANY_STATS,
        payload: {
            url: '/company/stats/totals/',
            data: {}
        }
    };
};

export const setCompanyStats = (data) => {
    return {
        type: CONSTANTS.SET_COMPANY_STATS,
        payload: data
    };
};
