import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { removeUserNotifications } from 'actions/Notifications';

import Expand from 'react-expand-animated';
import Table from 'components/Table';
import NotificationCell from 'components/Table/cell/containers/users/notificationsManagerDash';
import ToolTips from 'components/ToolTips';
class DashboardNotificationsET extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            notificationsHeaders: [
                {
                    id: 0,
                    title: 'form.title',
                    active: false,
                    order: true,
                    mobile: true,
                    width: '34%',
                    style: { textAlign: 'left' }
                },
                {
                    id: 1,
                    title: 'form.details',
                    active: false,
                    order: true,
                    mobile: true,
                    width: '42%',
                    style: { textAlign: 'left', padding:'0px 2px' }
                },
                {
                    id: 2,
                    title: 'comp.table.header.date',
                    active: false,
                    order: true,
                    mobile: true,
                    width: '17%',
                    style: { textAlign: 'left'}
                },
                {
                    id: 3,
                    title: 'blank',
                    active: false,
                    sortable: false,
                    order: false,
                    mobile: false,
                    width: '6%'
                },
            ],
            facility: props.facility
        }
    }
    clearAll = (e) => {
        e.stopPropagation();
        const { removeUserNotifications } = this.props;
        const { facility } = this.state;
        removeUserNotifications(facility.notifications.map(n => n.id), true);
    }
    removeOne = (id) => {
        let notifications = this.state.facility.notifications.filter(n => n.id !== id);
        let notificationCount = this.state.facility.notificationCount - 1;
        this.setState({facility: {...this.state.facility, notifications, notificationCount}});
    }
    render() {
        const { intl, theme } = this.props;
        const { open, notificationsHeaders, facility } = this.state;
        return (
            <>
                <div
                    style={
                        open
                        ?
                        {
                            background:
                            theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                                ? 'rgba(255,255,255,0.15)'
                                : theme && theme.color && theme.color.name && theme.color.name.includes('light1')
                                ? '#fcfcfc'
                                : theme && theme.color && theme.color.name && theme.color.name.includes('light2')
                                ? '#e7e7ec'
                                : '#f9f9f9',
                            textDecoration: 'none',
                            filter: 'drop-shadow(0 2px 10px 2px #ddd)',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    }
                    onClick={() => facility.notificationCount > 0 && this.setState({open: !this.state.open})}>
                    <Row style={{borderBottom: `solid 1px ${theme.color.borderColor}`, padding: '5px 0px', margin: '0px'}}>
                        <Col xs={4}>
                            {facility.name}
                        </Col>
                        <Col xs={4}>
                            {facility.notificationCount == 1 ? `1 ${intl.formatMessage({ id: 'pages.notifications.Type' })}` : `${facility.notificationCount} ${intl.formatMessage({ id: 'pages.notifications.Types' })}`}
                        </Col>
                        <Col xs={4}>
                            <div
                                id={`clearOne-${facility.id}`}
                                onClick={(e) => this.clearAll(e)}
                                style={{float:'right', color: theme.color.themeColor, cursor: 'pointer'}}>{intl.formatMessage({ id: 'pages.dashboard.clearAll' })}</div>
                            <ToolTips placement='left' target={`clearOne-${facility.id}`} message={'pages.dashboard.clearOneTooltip'} />
                        </Col>
                    </Row>
                </div>
                <Expand open={open}>
                    <div style={{padding: '0px 17px'}}>
                        <Table
                            data={facility.notifications}
                            header={notificationsHeaders}
                            Cell={NotificationCell}
                            toggle={(id)=> this.removeOne(id)} />
                    </div>
                </Expand>
            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, { removeUserNotifications })(
            DashboardNotificationsET
        )
    )
);