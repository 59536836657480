//||||||||||||||||||||||||||||||||||||||||
//|| ROLE LIST                          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ROLE_LIST = '/roles/get/';
export const PURPOSE_ROLE_LIST = "Fetch a list of the site's roles.";
export const PERMISSIONS_ROLE_LIST = [];
export const REQUIREMENTS_ROLE_LIST = ['Token Required'];
export const PARAMETER_ROLE_LIST = {};
export const RETURN_ROLE_LIST = {
    roles: [
        {
            name: ''
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| ROLE DETAILS                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ROLE_DETAILS = '/roles/byid/';
export const PURPOSE_ROLE_DETAILS = "Fetch site's role details.";
export const PERMISSIONS_ROLE_DETAILS = [];
export const REQUIREMENTS_ROLE_DETAILS = ['Token Required'];
export const PARAMETER_ROLE_DETAILS = {
    Id: 0
};
export const RETURN_ROLE_DETAILS = {
    name: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| ROLE PERMISSION LIST               ||
//||||||||||||||||||||||||||||||||||||||||
// Returns an array of permission details for the current site
// We store all permissions and their uses, but we have a “hidden” field – so that anything we don’t want the company manager, etc. to assign to any role ever won’t be returned
export const ENDPOINT_ROLE_PERMISSIONS_LIST = '/permissions/get/';
export const PURPOSE_ROLE_PERMISSIONS_LIST = "Fetch a list of the site's permissions.";
export const PERMISSIONS_ROLE_PERMISSIONS_LIST = [];
export const REQUIREMENTS_ROLE_PERMISSIONS_LIST = ['Token Required'];
export const PARAMETER_ROLE_PERMISSIONS_LIST = {};
export const RETURN_ROLE_PERMISSIONS_LIST = {
    permissions: [
        {
            permission: '',
            name: '',
            descriptionsOfUse: ['']
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| ROLE CREATE                        ||
//||||||||||||||||||||||||||||||||||||||||
// If a role with sent name already exists at current site, input error will be thrown
// Permissions are sanitized – they must match BE supported permissions
// If sent role is tier ‘tenant’ (int 0), and sent ‘defaultTenantRole’ is true – other tenant roles on site will have their default status set to false
// Returns the created role on success
export const ENDPOINT_ROLE_CREATE = '/roles/create/';
export const PURPOSE_ROLE_CREATE = 'Create a site role.';
export const PERMISSIONS_ROLE_CREATE = [];
export const REQUIREMENTS_ROLE_CREATE = ['Token Required'];
export const PARAMETER_ROLE_CREATE = {
    permissions: [''],
    role: {
        id: 0,
        name: '',
        tier: 0,
        siteID: 0,
        defaultTenantRole: false
    }
};
export const RETURN_ROLE_CREATE = {
    date: {}
};

//||||||||||||||||||||||||||||||||||||||||
//|| ROLE EDIT                          ||
//||||||||||||||||||||||||||||||||||||||||
// Role id must exist at current site, or input error will be thrown
// Permissions are sanitized – they must match BE supported permissions
// You can’t update a role’s ‘defaultTenantRole’ to false directly – there must always be one
// If you update a tenant role’s ‘defaultTenantRole’ to true, it will update other tenant roles on site to false indirectly
// Resends impacted access codes if hours access has changed
// Returns the updated role on success
export const ENDPOINT_ROLE_EDIT = '/roles/update/';
export const PURPOSE_ROLE_EDIT = 'Edit a site role.';
export const PERMISSIONS_ROLE_EDIT = [];
export const REQUIREMENTS_ROLE_EDIT = ['Token Required'];
export const PARAMETER_ROLE_EDIT = {
    permissions: [''],
    role: {
        id: 0,
        name: '',
        tier: 0,
        siteID: 0,
        defaultTenantRole: false
    }
};
export const RETURN_ROLE_EDIT = {
    result: 'success',
    date: {}
};

//||||||||||||||||||||||||||||||||||||||||
//|| ROLE DELETE                        ||
//||||||||||||||||||||||||||||||||||||||||
// Role id must be > 0
// Role id must exist at current site, or input error will be thrown
// You cannot delete a role if its ‘defaultTenantRole’ is true
// You cannot delete a non-tenant role if any user still has it
// If you are deleting a tenant role, moves every tenant on the to-be-deleted role to the default
// Resends impacted access codes if hours access has changed
export const ENDPOINT_ROLE_DELETE = '/roles/delete/';
export const PURPOSE_ROLE_DELETE = 'Delete a site role.';
export const PERMISSIONS_ROLE_DELETE = [];
export const REQUIREMENTS_ROLE_DELETE = ['Token Required'];
export const PARAMETER_ROLE_DELETE = {
    roleID: 0
};
export const RETURN_ROLE_DELETE = {
    result: 'success'
};
