import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchUserNotifications = (page = 1, limit = 100) => {
    return {
        type: CONSTANTS.FETCH_USER_NOTIFICATIONS,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_NOTIFICATIONS,
            page: JSON.parse(page),
            data: {
                offset: page === 1 ? 0 : (JSON.parse(page) - 1) * JSON.parse(limit),
                limit: JSON.parse(limit)
            }
        }
    };
};

export const fetchUserNotificationsCompany = ({ page = 1, limit = 100, siteUUIDs = [], notificationTypes = [], enterpriseNotifications= false }) => {
    return {
        type: CONSTANTS.FETCH_USER_NOTIFICATIONS_COMPANY,
        payload: {
            url: '/user/notifications/internal/get/all/',
            page: Number(page),
            enterpriseNotifications,
            data: {
                offset: page === 1 ? 0 : (Number(page) - 1) * Number(limit),
                limit: Number(limit),
                siteUUIDs: siteUUIDs,
                notificationTypes: notificationTypes
            }
        }
    };
};

export const updateUserNotifications = (data) => {
    return {
        type: CONSTANTS.UPDATE_USER_NOTIFICATIONS,
        payload: {
            url: ENDPOINTS.ENDPOINT_UPDATE_USER_NOTIFICATIONS,
            data
        }
    };
};

export const removeUserNotifications = (dismissIds = [], manager = false) => {
    return {
        type: CONSTANTS.REMOVE_USER_NOTIFICATIONS,
        payload: {
            url: ENDPOINTS.ENDPOINT_REMOVE_USER_NOTIFICATIONS,
            data: {
                dismissIds: dismissIds
            },
            manager
        }
    };
};

export const removeUserNotificationsAll = () => {
    return {
        type: CONSTANTS.REMOVE_USER_NOTIFICATIONS_ALL,
        payload: {
            url: ENDPOINTS.ENDPOINT_REMOVE_USER_NOTIFICATIONS_ALL,
            data: {}
        }
    };
};

export const registerNotificationsDevice = (token = '') => {
    return {
        type: CONSTANTS.REGISTER_NOTIFICATIONS_DEVICE,
        payload: {
            url: ENDPOINTS.ENDPOINT_NOTIFICATIONS_REGISTER_DEVICE,
            data: {
                uuid: `C${JSON.parse(localStorage.getItem('nse_login_data')).company}U${
                    JSON.parse(sessionStorage.getItem('user_data')).id
                }T${token}`,
                token
            }
        }
    };
};

export const createInternalNotification = ({ title = '', body = '' }) => {
    return {
        type: CONSTANTS.CREATE_INTERNAL_NOTIFICATION,
        payload: {
            url: ENDPOINTS.ENDPOINT_CREATE_INTERNAL_NOTIFICATION,
            data: {
                siteUUIDs: [JSON.parse(localStorage.getItem('nse_login_data')).siteUUID],
                title: title,
                body: body,
                type: 'alert'
            }
        }
    };
};

export const testNotificationsDevice = () => {
    return {
        type: CONSTANTS.TEST_NOTIFICATIONS_DEVICE,
        payload: {
            url: ENDPOINTS.ENDPOINT_NOTIFICATIONS_TEST_DEVICE,
            data: {}
        }
    };
};

export const setUserNotifications = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS,
        payload: data
    };
};

export const setUserNotificationsCompany = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_COMPANY,
        payload: data
    };
};

export const setUserNotificationsEnterprise = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_ENTERPRISE,
        payload: data
    };
};

export const setUserNotificationsViewed = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_VIEWED,
        payload: data
    };
};

export const setUserNotificationsNotViewed = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_NOT_VIEWED,
        payload: data
    };
};

export const setUserNotificationsSetting = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_SETTINGS,
        payload: data
    };
};

export const setNotificationsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_NOTIFICATIONS_HEADERS,
        payload: data
    };
};

export const fetchUserNotificationsSuite = () => {
    return {
        type: CONSTANTS.FETCH_USER_NOTIFICATIONS_SUITE,
        payload: {
            url: '/user/notifications/read/',
            data: {}
        }
    };
};
export const setUserNotificationsSuite = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_SUITE,
        payload: data
    };
};
export const supportFetchUserNotificationsSuite = (userUUID = 0) => {
    return {
        type: CONSTANTS.SUPPORT_FETCH_USER_NOTIFICATIONS_SUITE,
        payload: {
            url: '/support/user/notifications/read/',
            data: { userUUID }
        }
    };
};
export const supportSetUserNotificationsSuite = (data) => {
    return {
        type: CONSTANTS.SUPPORT_SET_USER_NOTIFICATIONS_SUITE,
        payload: data
    };
};
export const updateUserNotificationsSuite = (notifications = {}) => {
    return {
        type: CONSTANTS.UPDATE_USER_NOTIFICATIONS_SUITE,
        payload: {
            url: '/user/notifications/update/',
            data: notifications
        }
    };
};
export const updateUserNotificationsSuiteSilently = (notifications = {}) => {
    return {
        type: CONSTANTS.UPDATE_USER_NOTIFICATIONS_SUITE_SILENTLY,
        payload: {
            url: '/user/notifications/update/',
            data: notifications
        }
    };
};
export const supportUpdateUserNotificationsSuite = (notifications = {}) => {
    return {
        type: CONSTANTS.SUPPORT_UPDATE_USER_NOTIFICATIONS_SUITE,
        payload: {
            url: '/support/user/notifications/update/',
            data: notifications
        }
    };
};
export const supportUpdateUserNotificationsSuiteSilently = (notifications = {}) => {
    return {
        type: CONSTANTS.SUPPORT_UPDATE_USER_NOTIFICATIONS_SUITE_SILENTLY,
        payload: {
            url: '/support/user/notifications/update/',
            data: notifications
        }
    };
};

// USER NOTIFICATIONS

export const fetchUserNotificationsPush = (userUUID) => {
    return {
        type: CONSTANTS.FETCH_USER_NOTIFICATIONS_PUSH,
        payload: {
            url:
                sessionStorage.getItem('user_data') &&
                JSON.parse(sessionStorage.getItem('user_data')) &&
                JSON.parse(sessionStorage.getItem('user_data')).uuid &&
                JSON.parse(sessionStorage.getItem('user_data')).uuid !== userUUID
                    ? '/support/user/notifications/push/get/'
                    : '/user/notifications/push/get/',
            data: {
                userUUID,
                limit: 500,
                offset: 0
            }
        }
    };
};
export const setUserNotificationsPush = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_PUSH,
        payload: data
    };
};

export const fetchUserNotificationsEmail = (userUUID) => {
    return {
        type: CONSTANTS.FETCH_USER_NOTIFICATIONS_EMAIL,
        payload: {
            url:
                sessionStorage.getItem('user_data') &&
                JSON.parse(sessionStorage.getItem('user_data')) &&
                JSON.parse(sessionStorage.getItem('user_data')).uuid &&
                JSON.parse(sessionStorage.getItem('user_data')).uuid !== userUUID
                    ? '/support/user/notifications/email/get/'
                    : '/user/notifications/email/get/',
            data: {
                userUUID,
                limit: 500,
                offset: 0
            }
        }
    };
};
export const setUserNotificationsEmail = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_EMAIL,
        payload: data
    };
};

export const fetchUserNotificationsSms = (userUUID) => {
    return {
        type: CONSTANTS.FETCH_USER_NOTIFICATIONS_SMS,
        payload: {
            url:
                sessionStorage.getItem('user_data') &&
                JSON.parse(sessionStorage.getItem('user_data')) &&
                JSON.parse(sessionStorage.getItem('user_data')).uuid &&
                JSON.parse(sessionStorage.getItem('user_data')).uuid !== userUUID
                    ? '/support/user/notifications/sms/get/'
                    : '/user/notifications/sms/get/',
            data: {
                userUUID,
                limit: 500,
                offset: 0
            }
        }
    };
};
export const setUserNotificationsSms = (data) => {
    return {
        type: CONSTANTS.SET_USER_NOTIFICATIONS_SMS,
        payload: data
    };
};

export const fetchNotificationsTypes = () => {
    return {
        type: CONSTANTS.FETCH_NOTIFICATIONS_TYPES,
        payload: {
            url: '/user/notifications/internal/get/all/types/',
            data: {}
        }
    };
};

export const createOnsiteNotification = ({ title = '', body = '' }) => {
    return {
        type: CONSTANTS.CREATE_INTERNAL_NOTIFICATION,
        payload: {
            url: '/user/notifications/push/new/',
            data: {
                title: title,
                body: body
            }
        }
    };
};

export const setNotificationsTypes = (data) => {
    return {
        type: CONSTANTS.SET_NOTIFICATIONS_TYPES,
        payload: data
    };
};

export const setManagerNotificationFilters = (data) => {
    return {
        type: CONSTANTS.SET_MANAGER_NOTIFICATION_FILTERS,
        payload: data
    };
};
