import { testLogin, returnTest, createResult, } from 'components/TestSuite/tests/functions';
import cuid from 'cuid';

const loginTests = ({userAccounts, checkUserLoginEnv}) => {

    const validateEndpoint = '/login/validate/'; 
    const loginEndpoint = '/login/';

    const editedUserAccounts = [...userAccounts.map(user => {
        user.deviceUUID = cuid();
        return user;
    })]

    const guide = editedUserAccounts && [
        ...editedUserAccounts.map(user => ({
            name: 'Validate ' + user.email,
            description: user.type,
            endpoint: validateEndpoint,
            if: checkUserLoginEnv(user),
            action: async () => {
                let res = await testLogin(validateEndpoint, {
                    companyUUID: "-1",
                    countryCode: user.countryCode || "",
                    deviceUUID: user.deviceUUID, // 
                    email: user.email || "",
                    phone: user.phone || "",
                });
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.data !== 'no_ob_found', 
                            errorMessage: 'User not found'
                        },
                        {
                            condition: res.result === 'success', 
                            errorMessage: 'Endpoint returned non success result'
                        },
                    ]),
                });
            },
        })),
        ...editedUserAccounts.map(user => ({
            name: 'Login with ' + user.email,
            description: user.type,
            endpoint: loginEndpoint,
            if: checkUserLoginEnv(user),
            action: async () => {
                let res = await testLogin(loginEndpoint, {
                    companyUUID: "-1",
                    deviceUUID: user.deviceUUID,
                    email: user.email || null,
                    phone: user.phone || null,
                    countryCode: user.countryCode || null,
                    password: user.password,

                });
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.data !== 'invalid_password', 
                            errorMessage: 'Incorrect user password'
                        },
                        {
                            condition: res.result === 'success', 
                            errorMessage: 'Endpoint returned non success result'
                        },
                    ]),
                })
            },
        })),
    ]

    return guide;
}

export default loginTests;
