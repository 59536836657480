import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { switchSite } from 'actions/Auth';
import { setSwitcherLoader } from 'actions/App';

import Search from 'components/Search';
class CellText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
            filteredSites: null
        }
    }
    expand = (e) => {
        e.stopPropagation();
        this.setState({popoverOpen: !this.state.popoverOpen});
    }
    linkToItem = (e, type, siteUUID, unitUUID) => {
        e.stopPropagation();
        const { switchSite, setSwitcherLoader, intl, userUUID } = this.props;
        sessionStorage.removeItem('manager_dash');
        setSwitcherLoader({loading: true, text: intl.formatMessage({ id: 'comp.button.switchingSite' })});
        type == 'unit'
        ?
        switchSite(`${siteUUID}`, false, false, unitUUID, false, false)
        :
        switchSite(`${siteUUID}`, false, false, false, userUUID, false)
    }
    render() {
        let {
            theme,
            Title,
            Cellwidth,
            update,
            width,
            textAlign,
            TitleColor,
            TitleSize,
            textTransform,
            padding,
            margin,
            id,
            units,
            sites,
            multiple,
            intl
        } = this.props;
        const { popoverOpen, filteredSites } = this.state;
        this.styles = styles(
            theme,
            width,
            textAlign,
            TitleColor,
            TitleSize,
            textTransform,
            padding,
            margin,
            multiple
        );
        return (
            <td width={Cellwidth} onClick={(e) => ((units && units.length && units.length > 1) || (sites && sites.length && sites.length > 1)) && this.expand(e)}>
                <div
                    id={id}
                    className={css(this.styles.wrapper, update && this.styles.hover)}>
                    <h5
                        title={Title}
                        className={css(this.styles.title)}>
                        <span
                            className={css(this.styles.titleText)}>
                            {Title}
                            {multiple && <i className="material-icons">arrow_drop_down</i>}
                        </span>
                    </h5>
                </div>
                <Popover
                    placement='left'
                    target={id}
                    isOpen={popoverOpen}
                    trigger="legacy"
                    modifiers={{preventOverflow: {enabled: false}, flip: {enabled: false}}}
                >
                    <PopoverHeader>
                        <div>
                            {units && <div>{intl.formatMessage({id: 'comp.table.header.units'})}</div>}
                            {sites && sites.length && sites.length > 1 && <div style={{borderBottom: `solid ${theme.color.themeColor} 1px`}} onClick={(e) => e.stopPropagation()}>
                                <Search
                                    update={(filteredSites) => this.setState({ filteredSites })}
                                    data={sites}
                                    searchType={['siteName']}
                                    placeholder={`${intl.formatMessage({ id: `comp.search.default` })} ${intl.formatMessage({ id: `form.site` })}`}
                                    invertIcon={true}
                                />
                             </div>}
                        </div>
                    </PopoverHeader>
                    <PopoverBody style={{paddingTop: 3}}>
                        {units && units.map(u => (
                            <div style={{cursor: 'pointer'}} key={u.unitUUID} onClick={(e) => this.linkToItem(e, 'unit', u.siteID, u.unitUUID)}>{u.shared ? <span>{`${u.unitName} (${intl.formatMessage({id: 'db.shared'})} ${intl.formatMessage({id: 'form.access'})})`}</span> : <span>{u.unitName}</span>}</div>
                        ))}
                        {sites && <div className={css(this.styles.siteHeader)}>
                            <span>{intl.formatMessage({ id: `comp.table.header.name` })}</span>
                            <i style={{color: theme.color.themeColor}} className="material-icons">arrow_drop_down</i>
                        </div>}
                        {sites && !filteredSites && sites.map(s => (
                            <div className={css(this.styles.siteName)} key={`${s.siteID}`} onClick={(e) => this.linkToItem(e, 'site', s.siteID)}>{<span>{s.siteName}</span>}</div>
                        ))}
                        {filteredSites && filteredSites.map(s => (
                            <div className={css(this.styles.siteName)} key={`${s.siteID}`} onClick={(e) => this.linkToItem(e, 'site', s.siteID)}>{<span>{s.siteName}</span>}</div>
                        ))}
                    </PopoverBody>
                </Popover>
            </td>
        );
    }
}

CellText.defaultProps = {
    Title: 'Unknown',
    TitleColor: false,
    textAlign: 'center',
    TitleSize: 12,
    textTransform: 'capitalize',
    udpate: null,
    padding: '2px 10px',
    margin: 0
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(withRouter(connect(mapStateToProps, {switchSite, setSwitcherLoader})(CellText)));

const styles = (theme, width, textAlign, TitleColor, fontSize, textTransform, padding, margin, multiple) =>
    StyleSheet.create({
        wrapper: {
            padding,
            margin: margin,
            maxHeight: 55,
            position: 'relative'
        },
        title: {
            fontSize: width > 500 ? fontSize : width > 300 ? 12 : 10,
            fontWeight: 400,
            margin: 0,
            padding: 0,
            textAlign,
            color: TitleColor && theme.color.fontColor,
            textTransform,
            textDecoration: multiple ? 'underline' : 'none'
        },
        titleIcon: {
            fontSize: width > 500 ? fontSize : width > 300 ? 12 : 10,
            fontWeight: 400,
            margin: 0,
            padding: 0,
            textAlign,
            color: TitleColor && theme.color.fontColor,
            textTransform,
            minHeight: 35,
            maxHeight: 35,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 3,
            wordBreak: 'normal'
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        siteHeader: {
            borderBottom: `solid 1px #D3D3D3`,
        },
        siteName: {
            display: 'flex',
            cursor: 'pointer',
            height: 30,
            alignItems: 'center',
            borderBottom: `solid 1px #B8B8B8`,
        }
    });
