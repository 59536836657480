//||||||||||||||||||||||||||||||||||||||||
//|| CREATE RELEASE                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CREATE_RELEASE = '/features/create/';
export const PURPOSE_CREATE_RELEASE = 'Create a new release note';
export const PERMISSIONS_CREATE_RELEASE = [];
export const REQUIREMENTS_CREATE_RELEASE = ['Token Required'];
export const PARAMETER_CREATE_RELEASE = {
    versionID: '',
    featureTitle: '',
    featureDescription: '',
    externalLink: '',
    imageLink: '',
    releaseDate: '',
    tags: []
};
export const RETURN_CREATE_RELEASE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| RELEASE LIST                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_RELEASE_LIST = '/features/read/';
export const PURPOSE_RELEASE_LIST = 'Fetch a list of all release notes.';
export const PERMISSIONS_RELEASE_LIST = [];
export const REQUIREMENTS_RELEASE_LIST = ['Token Required'];
export const PARAMETER_RELEASE_LIST = {};
export const RETURN_RELEASE_LIST = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UPDATE RELEASE                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UPDATE_RELEASE = '/features/update/';
export const PURPOSE_UPDATE_RELEASE = 'Update a release note';
export const PERMISSIONS_UPDATE_RELEASE = [];
export const REQUIREMENTS_UPDATE_RELEASE = ['Token Required'];
export const PARAMETER_UPDATE_RELEASE = {
    versionID: '',
    featureTitle: '',
    featureDescription: '',
    externalLink: '',
    imageLink: '',
    releaseDate: '',
    tags: []
};
export const RETURN_UPDATE_RELEASE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| DELETE RELEASE                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_DELETE_RELEASE = '/features/delete/';
export const PURPOSE_DELETE_RELEASE = 'Delete a release note';
export const PERMISSIONS_DELETE_RELEASE = [];
export const REQUIREMENTS_DELETE_RELEASE = ['Token Required'];
export const PARAMETER_DELETE_RELEASE = {
    id: 0
};
export const RETURN_DELETE_RELEASE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| RELEASE SEARCH LIST                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_RELEASE_SEARCHED_LIST = '/features/search/';
export const PURPOSE_RELEASE_SEARCHED_LIST = 'Fetch a list of searched release notes by tag.';
export const PERMISSIONS_RELEASE_SEARCHED_LIST = [];
export const REQUIREMENTS_RELEASE_SEARCHED_LIST = ['Token Required'];
export const PARAMETER_RELEASE_SEARCHED_LIST = {
    tags: []
};
export const RETURN_RELEASE_SEARCHED_LIST = {
    result: 'success'
};
