import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
class CellIcon extends React.Component {
    constructor(props) {
        super(props);
    }
    parentAction = (e) => {
        e.stopPropagation();
        const { action } = this.props;
        action();
    };
    render() {
        const {
            Icon,
            theme,
            height,
            width,
            Cellwidth,
            fill,
            color,
            stroke,
            update,
            checker,
            icon,
            action,
            borderRadius,
            backgroundColor
        } = this.props;
        this.styles = styles(theme, width, height, borderRadius, backgroundColor, icon);
        return (
            <td width={Cellwidth}>
                <div
                    onMouseDown={(e) => action && this.parentAction(e)}
                    className={css(this.styles.wrapper, checker && this.styles.hover)}
                    onClick={() => checker && update()}>
                    {icon ? (
                        <i
                            className="material-icons"
                            style={{
                                width: width,
                                height: height,
                                fontSize: height - 15,
                                color: fill,
                                marginTop: icon == 'ballot' ? 5 : 0
                            }}>
                            {icon}
                        </i>
                    ) : (
                        Icon && (
                            <Icon
                                fill={fill || theme.color.buttonFontColor}
                                color={color || theme.color.themeColor}
                                width={width / 2}
                                height={height / 2}
                                stroke={stroke}
                            />
                        )
                    )}
                </div>
            </td>
        );
    }
}

CellIcon.defaultProps = {
    Icon: null,
    width: 40,
    height: 40,
    borderRadius: '50%',
    fill: null,
    color: null,
    backgroundColor: null,
    stroke: null,
    update: null,
    checker: null
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(connect(mapStateToProps)(CellIcon));

const styles = (theme, width, height, borderRadius, backgroundColor, icon) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: backgroundColor ? backgroundColor : theme && theme.color ? theme.color.themeColor : '#0070CE',
            borderRadius,
            width,
            height,
            display: 'block',
            margin: '5px auto',
            padding: icon ? '1.5px 0 0 .5px' : `${height / 4}px ${width / 4}px`,
            overflow: 'hidden'
        },
        hover: {
            ':hover': {
                cursor: 'pointer'
            }
        }
    });
