import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore from './store';
import { history } from './store/history';
import App from 'app';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import packageInfo from '../package.json';
import withGAHistoryTrack from 'components/Analytics';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const domain = window.location.href;
if((domain.includes('com') || domain.includes('party')) && !window.navigator.webdriver)
    {
        LogRocket.init('runtfp/janus-react', {
          release: packageInfo.version,
          network: {
              requestSanitizer: (request) => {
                  if (
                      request.url.toLowerCase().indexOf('login') !== -1 ||
                      request.url.toLowerCase().indexOf('password') !== -1
                  ) {
                      request.body = null;
                  }
                  if (request.headers['Authorization']) {
                      request.headers['Authorization'] = '';
                  }
                  return request;
              },
              responseSanitizer: (response) => {
                  let parsedResponseBody = JSON.parse(response.body);
                  if (parsedResponseBody['token']) {
                      parsedResponseBody['token'] = '';
                      response.body = JSON.stringify(parsedResponseBody);
                  }
                  return response;
              }
          }
      })
      setupLogRocketReact(LogRocket)
    } else {
        null
    };
domain.includes('run')
    ? Sentry.init({ 
        dsn: 'https://d1b064014eb84f1491713022609fdee9@sentry.io/1827076', 
        environment: 'dev',
        ignoreErrors: [
            "Loading chunk",
            "ChunkLoadError",
            "Extension context invalidated",
            "ResizeObserver loop limit exceeded"
        ],
    })
    : domain.includes('dev')
    ? Sentry.init({ 
        dsn: 'https://d1b064014eb84f1491713022609fdee9@sentry.io/1827076', 
        environment: 'dev',
        ignoreErrors: [
            "Loading chunk",
            "ChunkLoadError",
            "Extension context invalidated",
            "ResizeObserver loop limit exceeded"
        ],  
    })
    : domain.includes('noke')
    ? Sentry.init({ 
        dsn: 'https://d1b064014eb84f1491713022609fdee9@sentry.io/1827076', 
        environment: 'prod',
        ignoreErrors: [
            "Loading chunk",
            "ChunkLoadError",
            "Extension context invalidated",
            "ResizeObserver loop limit exceeded"
        ], 
    })
    : null;
LogRocket.getSessionURL((sessionURL) => {
    Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
});

export const store = configureStore();

const container = document.getElementById('app-site');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={withGAHistoryTrack(App)} />
            </Switch>
        </ConnectedRouter>
    </Provider>,
);
