import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';
class CellEmpty extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, tableHeight, height, theme } = this.props;
        this.styles = styles(theme, tableHeight, height);
        return (
            <table>
                <tbody>
                    <tr className={css(this.styles.trow)}>
                        <td width={'100%'}>
                            <div
                                className={css(this.styles.wrapper)}
                                style={{ height: tableHeight ? tableHeight : height }}>
                                {intl.formatMessage({ id: 'comp.table.cell.empty' })}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

CellEmpty.defaultProps = {
    tableHeight: null
};

const mapStateToProps = ({ app }) => {
    const { theme, height } = app;
    return { theme, height };
};

export default injectIntl(withRouter(connect(mapStateToProps)(CellEmpty)));

const styles = (theme, tableHeight, height) =>
    StyleSheet.create({
        wrapper: {
            padding: 50,
            margin: 0,
            position: 'relative',
            textAlign: 'center',
            // for some reason the height doesn't take effect here (i think?) so I'm setting it as an inline style in the JSX. -Brayden
            height: tableHeight ? tableHeight : height,
            color: theme.color.fontColor,
            animationName: fadeIn,
            animationDuration: '1.2s'
        },
        trow: {
            display: 'table',
            width: '100%',
            tableLayout: 'fixed'
        }
    });
