import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';

import Box from 'components/Box';
import MyStores from 'components/EnterpriseDashboard/MyStores';
import Notifications from 'components/EnterpriseDashboard/Notifications';
import Employees from 'components/EnterpriseDashboard/EmployeesOnline';
import CrossSiteSearch from 'components/EnterpriseDashboard/CrossSiteSearch';
import SuspiciousActivity from 'components/EnterpriseDashboard/SuspiciousActivity';
class DashboardDisplay extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        document.title = 'Dashboard | Smart Entry';
    }
    render() {
        const { width } = this.props;
        return (
            <Container fluid>
                <Row style={{paddingBottom: 17}}>
                    <Col xs={12} xl={6} style={{paddingRight: width < 1200 ? 17 : 0}}>
                        <Box customHeight={500}>
                            <MyStores />
                        </Box>
                    </Col>
                    <Col xs={12} xl={6}>
                        <Box customHeight={500}>
                            <CrossSiteSearch />
                        </Box>
                    </Col>
                    <Col xs={12} xl={6} style={{paddingRight: width < 1200 ? 17 : 0}}>
                        <Box customHeight={500}>
                            <Notifications />
                        </Box>
                    </Col>
                    <Col xs={12} xl={6}>
                        <Box customHeight={500}>
                            <SuspiciousActivity />
                        </Box>
                    </Col>
                    {/* <Col xs={12}>
                        <Box>
                            <Employees />
                        </Box>
                    </Col> */}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width, height } = app;
    return { theme, width, height };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(DashboardDisplay)));
