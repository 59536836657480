import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as ACTIONS_WIFI from 'actions/Wifi';
import * as ACTIONS_APP from 'actions/App';

function* requestFetchWifi({ payload }) {
    try {
        yield put(ACTIONS_WIFI.setWifi(null));
        const wifi = yield call(API.POST, payload.url, payload.data);
        if (!wifi || !wifi.data) {
            yield put(ACTIONS_WIFI.setWifi([]));
        } else {
            yield put(ACTIONS_WIFI.setWifi(wifi.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateWifi({ payload }) {
    try {
        const wifiCreate = yield call(API.POST, payload.url, payload.data);
        if (!wifiCreate) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noAdd', 'warning'));
        } else if (!wifiCreate.data) {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.push(`/app/settings/wifi/all`);
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.push(`/app/settings/wifi/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditWifi({ payload }) {
    try {
        const wifiEdit = yield call(API.POST, payload.url, payload.data);
        if (!wifiEdit) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noEdit', 'warning'));
        } else if (!wifiEdit.data) {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield history.push(`/app/settings/wifi/all`);
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield history.push(`/app/settings/wifi/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteWifi({ payload }) {
    try {
        const wifiDelete = yield call(API.POST, payload.url, payload.data);
        if (!wifiDelete) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noDelete', 'warning'));
        } else if (!wifiDelete.data) {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(ACTIONS_WIFI.fetchWifi());
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(ACTIONS_WIFI.fetchWifi());
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectFetchWifi() {
    yield takeEvery(CONSTANTS.FETCH_WIFI, requestFetchWifi);
}
export function* selectCreateWifi() {
    yield takeEvery(CONSTANTS.CREATE_WIFI, requestCreateWifi);
}
export function* selectEditWifi() {
    yield takeEvery(CONSTANTS.EDIT_WIFI, requestEditWifi);
}
export function* selectDeleteWifi() {
    yield takeEvery(CONSTANTS.DELETE_WIFI, requestDeleteWifi);
}

export default function* rootSaga() {
    yield all([fork(selectFetchWifi), fork(selectCreateWifi), fork(selectEditWifi), fork(selectDeleteWifi)]);
}
