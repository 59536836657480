import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { 
    fetchPmsSync, 
    setSyncUnitsHeaders
} from 'actions/Units';

import { ProgressIcon } from 'utilities/icons';

import Table from 'components/Table';
import SyncUnitsCell from 'components/Table/cell/containers/dashboard/syncPms';
import Header from 'components/Header/dashboard';
class DashboardSharedUnits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterList: null
        };
    }
    componentDidMount() {
        const { fetchPmsSync, editWidgets, visible } = this.props;
        (editWidgets || visible) && fetchPmsSync();
    }
    recentUpdatesSearch = (filterList) => {
        this.setState({ filterList });
    };
    render() {
        const { syncPmsChanges, editWidgets, syncUnitsHeaders, setSyncUnitsHeaders  } = this.props;
        const { filterList } = this.state;
        return (
            <>
                <Header
                    sharedView={'pmsSync'}
                    Icon={ProgressIcon}
                    iconSize={34}
                    title={'comp.form.recentUpdates'}
                    editWidgets={editWidgets}
                    syncPmsChanges={syncPmsChanges}
                    syncUnitsHeaders={syncUnitsHeaders}
                    recentUpdatesSearch={this.recentUpdatesSearch}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <Table
                            tableHeight={183}
                            data={filterList ? filterList : syncPmsChanges}
                            header={syncUnitsHeaders}
                            headerAction={setSyncUnitsHeaders}
                            Cell={SyncUnitsCell}
                            moreItemsLoading={false}
                            itemSize={editWidgets ? 50 : 38}
                            hasNextPage={false}
                            force={true}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, units }) => {
    const { theme, width } = app;
    const { syncPmsChanges, syncUnitsHeaders } = units;
    return { theme, width, syncPmsChanges, syncUnitsHeaders };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { 
        fetchPmsSync,
        setSyncUnitsHeaders
    })(DashboardSharedUnits))
);
