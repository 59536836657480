import React from 'react';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { CheckmarkIcon, HelpIconCircle, CloseIcon, WarningIcon } from 'utilities/icons';
class NewNotificationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            palette: {
                success: {
                    light: { border: '#B3EEA5', background: '#E0FBE0', iconWrapper: '#97E286' },
                    dark: { border: '#4C9F38', background: '#77BC67', iconWrapper: '#4C9F38' }
                },
                info: {
                    light: { border: '#92C0F5', background: '#E1EEFF', iconWrapper: '#77ABE6' },
                    dark: { border: '#437EC1', background: '#6A99CE', iconWrapper: '#437EC1' }
                },
                warning: {
                    light: { border: '#ECDE84', background: '#FFFADE', iconWrapper: '#ECDE84' },
                    dark: { border: '#C9B745', background: '#D1C054', iconWrapper: '#AFA147' }
                },
                error: {
                    light: { border: '#F3B2B2', background: '#FFE9E9', iconWrapper: '#EB9494' },
                    dark: { border: '#C26262', background: '#C87D7D', iconWrapper: '#C26262' }
                }
            }
        };
    }
    render() {
        const { notifications, theme, intl, height } = this.props;
        const { palette } = this.state;
        this.styles = styles(theme, height, palette);
        return (
            <div className={css(this.styles.notificationsContainer)}>
                {notifications &&
                    notifications.map((notif) => (
                        <div
                            className={css(
                                this.styles.notificationBanner,
                                this.styles[`${notif.type}Banner`],
                                notif.show ? this.styles.notificationEnterClass : this.styles.notificationLeaveClass
                            )}
                            key={notif.message + notif.id}>
                            <div
                                style={{
                                    display: 'grid',
                                    alignItems:
                                        (notif.title && notif.message && notif.message.length > 30) ||
                                        (notif.message && notif.message.length > 50)
                                            ? 'start'
                                            : 'center'
                                }}>
                                <div
                                    className={css(this.styles.iconWrapper)}
                                    style={{
                                        backgroundColor:
                                            palette[notif.type][
                                                theme &&
                                                theme.color &&
                                                theme.color.name &&
                                                theme.color.name.includes('dark')
                                                    ? 'dark'
                                                    : 'light'
                                            ].iconWrapper
                                    }}>
                                    {notif.type === 'success' && (
                                        <div className={css(this.styles.whiteIconWrapper)}>
                                            <CheckmarkIcon
                                                stroke={
                                                    palette[notif.type][
                                                        theme &&
                                                        theme.color &&
                                                        theme.color.name &&
                                                        theme.color.name.includes('dark')
                                                            ? 'dark'
                                                            : 'light'
                                                    ].iconWrapper
                                                }
                                                width={11}
                                                height={9}
                                                strokeWidth={1.5}
                                            />
                                        </div>
                                    )}
                                    {notif.type === 'info' && <HelpIconCircle fill={'white'} width={17} height={17} />}
                                    {notif.type === 'error' && (
                                        <div className={css(this.styles.whiteIconWrapper)}>
                                            <CloseIcon
                                                fill={
                                                    palette[notif.type][
                                                        theme &&
                                                        theme.color &&
                                                        theme.color.name &&
                                                        theme.color.name.includes('dark')
                                                            ? 'dark'
                                                            : 'light'
                                                    ].iconWrapper
                                                }
                                                width={9}
                                                height={9}
                                            />
                                        </div>
                                    )}
                                    {notif.type === 'warning' && <WarningIcon fill={'white'} width={15} height={15} />}
                                </div>
                            </div>
                            <div className={css(this.styles.notifTextContainer)}>
                                <div className={css(this.styles.notifTitle)}>{notif.title}</div>
                                <div className={css(this.styles.notifMessage)}>
                                    {notif.message}
                                    <span style={{ fontStyle: 'oblique', fontSize: 13 }}>
                                        {notif.quantity > 1 &&
                                            ` (${notif.quantity} ${intl.formatMessage({
                                                id: 'notifications.occurrence.types'
                                            })})`}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={css(this.styles.closeNotifIcon)}
                                onClick={() => {
                                    this.props.deleteNotification(notif.id);
                                }}>
                                <CloseIcon
                                    width={15}
                                    height={15}
                                    fill={
                                        palette[notif.type][
                                            theme &&
                                            theme.color &&
                                            theme.color.name &&
                                            theme.color.name.includes('dark')
                                                ? 'dark'
                                                : 'light'
                                        ].border
                                    }
                                />
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width, height } = app;
    return { theme, width, height };
};

export default injectIntl(withRouter(connect(mapStateToProps)(NewNotificationContainer)));

// animations
const notificationEnter = {
    '0%': {
        opacity: 0,
        transform: 'translateX(30px)'
    },
    '50%': {
        opacity: 1,
        transform: 'translateX(0px)'
    },
    '100%': {
        opacity: 1,
        transform: 'translateX(0px)'
    }
};
const notificationLeave = {
    '0%': {
        opacity: 1,
        transform: 'translateX(0px)'
    },
    '50%': {
        opacity: 0,
        transform: 'translateX(30px)'
    },
    '100%': {
        opacity: 0,
        transform: 'translateX(30px)'
    }
};

const styles = (theme, height, palette) =>
    StyleSheet.create({
        notificationsContainer: {
            display: 'block',
            position: 'fixed',
            top: 0,
            right: 0,
            width: 360,
            paddingRight: 15,
            paddingTop: 0,
            height: height,
            zIndex: '999999999999999999999999999999999999999999999',
            pointerEvents: 'none',
            overflow: 'hidden',
            '@media(max-width:380px)': {
                width: '100%',
                paddingRight: 0
            }
        },
        notificationBanner: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: '31px 1fr',
            gap: 13,
            color: theme.color.fontColor,
            borderRadius: 17,
            padding: 13,
            margin: 8,
            cursor: 'default',
            pointerEvents: 'auto',
            boxShadow: `3px 3px 8px 1px rgba(0,0,0,0.09)`,
            transition: 'box-shadow 0.2s ease-in-out',
            ':hover': {
                boxShadow: `3px 3px 8px 1px rgba(0,0,0,0.12)`
            }
        },
        infoBanner: {
            backgroundColor:
                palette.info[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].background,
            border: `1px solid ${
                palette.info[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].border
            }`
        },
        successBanner: {
            backgroundColor:
                palette.success[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].background,
            border: `1px solid ${
                palette.success[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].border
            }`
        },
        warningBanner: {
            backgroundColor:
                palette.warning[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].background,
            border: `1px solid ${
                palette.warning[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].border
            }`
        },
        errorBanner: {
            backgroundColor:
                palette.error[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].background,
            border: `1px solid ${
                palette.error[
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark') ? 'dark' : 'light'
                ].border
            }`
        },
        iconWrapper: {
            display: 'grid',
            placeItems: 'center',
            borderRadius: '50%',
            width: 31,
            height: 31
        },
        whiteIconWrapper: {
            display: 'grid',
            placeItems: 'center',
            width: 17,
            height: 17,
            borderRadius: '50%',
            backgroundColor: 'white'
        },
        // notification text
        notifTextContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'left',
            width: '100%',
            paddingRight: 32 // remove this if no dismiss notification X
        },
        notifTitle: {
            fontWeight: 'bold',
            fontSize: 14
        },
        notifMessage: {
            fontSize: 13,
            lineHeight: 1.4
        },
        // close button for notification banner
        closeNotifIcon: {
            position: 'absolute',
            display: 'grid',
            placeItems: 'center',
            top: 'calc(50% - 1px)',
            right: 15,
            transform: 'translateY(-50%)',
            borderRadius: 5,
            width: 27,
            height: 27,
            ':hover': {
                cursor: 'pointer',
                backgroundColor:
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'rgba(50, 50, 50, 0.025)'
            }
        },
        // animations
        notificationEnterClass: {
            animationName: [notificationEnter],
            animationDuration: '500ms',
            animationTimingFunction: 'ease-in-out'
        },
        notificationLeaveClass: {
            animationName: [notificationLeave],
            animationDuration: '500ms',
            animationTimingFunction: 'ease-in-out'
        }
    });
