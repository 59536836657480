import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_HELP from 'actions/Help';

function* requestFetchApiDocumentation({ payload }) {
    try {
        yield put(ACTIONS_HELP.setApiDocumentation(null));
        const documentation = yield call(API.POST, payload.url, payload.data);
        if (!documentation || !documentation.data || !documentation.data.apiDocumentation) {
            yield put(ACTIONS_HELP.setApiDocumentation([]));
        } else {
            yield put(ACTIONS_HELP.setApiDocumentation(documentation.data.apiDocumentation));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_HELP.setApiDocumentation([]));
    }
}

function* requestCreateApiDocumentation({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
            yield history.push(`/app/help/documentation/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditApiDocumentation({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.push(`/app/help/documentation/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteApiDocumentation({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield history.push(`/app/help/documentation/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectFetchApiDocumentation() {
    yield takeEvery(CONSTANTS.FETCH_API_DOCUMENTATION, requestFetchApiDocumentation);
}

export function* selectCreateApiDocumentation() {
    yield takeEvery(CONSTANTS.CREATE_API_DOCUMENTATION, requestCreateApiDocumentation);
}

export function* selectEditApiDocumentation() {
    yield takeEvery(CONSTANTS.EDIT_API_DOCUMENTATION, requestEditApiDocumentation);
}

export function* selectDeleteApiDocumentation() {
    yield takeEvery(CONSTANTS.DELETE_API_DOCUMENTATION, requestDeleteApiDocumentation);
}

export default function* rootSaga() {
    yield all([
        fork(selectFetchApiDocumentation),
        fork(selectCreateApiDocumentation),
        fork(selectEditApiDocumentation),
        fork(selectDeleteApiDocumentation)
    ]);
}
