import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import packageInfo from '../../../../package.json';
import { fadeInLeft } from 'react-animations';
import moment from 'moment-timezone';

import * as UTILS from 'utilities';
import Weather from 'components/Weather';
import LanguageSwitcher from 'components/LanguageSwitcher';
class MenuFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: moment().utc().format()
        }
    }
    componentDidMount () {
        this.interval = setInterval(() => {
            this.updateTime();
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    updateTime = () => {
        this.setState({ time: moment().utc().format() });
    }
    render() {
        const { theme, authUser, site, userTimezone, width, height } = this.props;
        const { time } = this.state;
        this.styles = styles(theme, width, height);

        const local = localStorage.getItem('nse_local') || 'en';

        return (
            <div className={css(this.styles.wrapper, this.styles.fadeInLeft)}>
                <div className={css(this.styles.contentWrapperTop)}>
                    <Weather />
                    <span className={css(this.styles.contentWrapperDate)}>
                        {authUser && authUser.useSiteTime === true && site
                            ? moment.tz(`${time}`, `${site.timeZone}`).format('LT z')
                            : moment.tz(`${time}`, userTimezone).format('LT z')}{' '}
                        |{' '}
                        {UTILS.dateFormatter(time, authUser, site)}
                    </span>
                </div>
                <div className={css(this.styles.imgWrapper)}>
                    <img
                        alt="Noke"
                        src={
                            theme
                                ? 'assets/images/Logos/secured_by_noke.png'
                                : 'https://storage.googleapis.com/janus-nokebucket/assets/images/Logos/secured_by_noke.png'
                        }
                        className={css(this.styles.image)}
                    />
                    <div className={css(this.styles.langWrapper)}>
                        <LanguageSwitcher />
                    </div>
                </div>
                <div className={css(this.styles.contentWrapper)}>
                    NOKĒ {new Date().getFullYear()} | {local !== 'de' && this.props.intl.formatMessage({ id: 'comp.footer.version' })}{' '}
                    {packageInfo.version}
                </div>
            </div>
        );
    }
}

MenuFooter.defaultProps = {
    padding: '10px 20px',
    height: 160,
    width: '100%'
};

const mapStateToProps = ({ app, auth }) => {
    const { theme, userTimezone } = app;
    const { authUser, site } = auth;
    return { theme, userTimezone, authUser, site };
};

export default injectIntl(connect(mapStateToProps)(MenuFooter));

const styles = (theme, width, height) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.menuColor,
            height,
            padding: '0px 17px 0px 17px',
            width,
            color: theme.color.fontColor,
            '@media(max-height:800px)': {
                height: 140
            }
        },
        langWrapper: {
            display: 'block',
            float: 'right',
            marginBottom: 5
        },
        imgWrapper: {
            marginBottom: 10,
            marginTop: 10,
            '@media(max-height:800px)': {
                marginBottom: 5,
                marginTop: 5,
            }
        },
        contentWrapper: {
            marginBottom: 5,
            color: theme.color.subFontColor,
            width: '100%',
            maxWidth: '100%',
            fontSize: 12,
            display: 'block',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            '@media(max-height:800px)': {
                marginBottom: 0,
                fontSize: 11,
            }
        },
        contentWrapperTop: {
            color: theme.color.subFontColor,
        },
        image: {
            width: 78
        },
        fadeInLeft: {
            animationName: fadeInLeft,
            animationDuration: '1.2s'
        },
        contentWrapperDate: {
            fontSize: 12,
            '@media(max-height:800px)': {
                fontSize: 11,
            }
        },
    });
