import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as ICONS from 'utilities/icons';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_SECURITY from 'actions/Security';
import * as ACTIONS_USERS from 'actions/Users';

const getSecurityHeaders = (state) => state.units.unitsHeader;
const getLockHeaders = (state) => state.units.locksHeader;
const getSecurityObject = (state) => state.units.unitObject;

function* requestSecurityStats({ payload }) {
    try {
        yield put(ACTIONS_SECURITY.setSecurityListStats(null));
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data || !units.data.units) {
            return;
        } else {
            units.data = units.data.units.filter((unit) => {
                return unit.locks && unit.locks[0] && unit.locks[0].hwType == 'alarm';
            });
            let stats = {
                total: units.data.length,
                occupied: units.data.filter((item) => item.rentalState === 'inuse').length,
                available: units.data.filter((item) => item.rentalState === 'available').length,
                pending: units.data.filter((item) => item.rentalState === 'pending').length
            };
            yield put(ACTIONS_SECURITY.setSecurityListStats(stats));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSecurityList({ payload }) {
    try {
        yield put(ACTIONS_SECURITY.setSecurityList(null));
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data || !units.data.units) {
            yield put(ACTIONS_SECURITY.setSecurityList([]));
        } else {
            units.data = units.data.units.filter((unit) => {
                return unit.locks && unit.locks[0] && unit.locks[0].hwType == 'alarm';
            });
            units.data.map((unit) => {
                unit.User.name = `${unit.User.firstName} ${unit.User.lastName}`;
            });
            units.data = UTILS.cleanUpArray(units.data, 'id');
            const unitHeaders = yield select(getSecurityHeaders);
            const activeHeader = unitHeaders.find((item) => item.active);
            units.data = UTILS.sortList(!activeHeader.order, units.data, activeHeader.sortTitle);
            yield put(ACTIONS_SECURITY.setSecurityList(units.data));
        }
    } catch (error) {
        yield put(ACTIONS_SECURITY.setSecurityList([]));
        console.warn(error);
    }
}

function* requestSecurityDetails({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserDetails(null));
        yield put(ACTIONS_SECURITY.setSecurity(null));
        const unit = yield call(API.POST, payload.url, payload.data);
        if (!unit) {
            yield history.goBack();
        } else {
            if (unit.data.unit.User) {
                let { firstName, lastName } = unit.data.unit.User;
                unit.data.unit.User.name = `${firstName} ${lastName}`;
            }
            const lockHeaders = yield select(getLockHeaders);
            const activeHeader = lockHeaders.find((item) => item.active);
            unit.data.unit.locks = UTILS.sortList(!activeHeader.order, unit.data.unit.locks, activeHeader.sortTitle);
            yield put(ACTIONS_SECURITY.setSecurity(unit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateSecurity({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            const unit = yield select(getSecurityObject);
            if (update.data.User) {
                let { firstName, lastName } = update.data.User;
                update.data.User.name = `${firstName} ${lastName}`;
            }
            const lockHeaders = yield select(getLockHeaders);
            const activeHeader = lockHeaders.find((item) => item.active);
            update.data.locks = UTILS.sortList(!activeHeader.order, update.data.locks, activeHeader.sortTitle);
            let newData = {
                unit: update.data,
                sharedUsers: unit.sharedUsers
            };
            yield put(ACTIONS_SECURITY.setSecurity(newData));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectSecurityListStats() {
    yield takeEvery(CONSTANTS.FETCH_SECURITY_LIST_STATS, requestSecurityStats);
}

export function* selectSecurityList() {
    yield takeEvery(CONSTANTS.FETCH_SECURITY_LIST, requestSecurityList);
}

export function* selectSecurityDetails() {
    yield takeEvery(CONSTANTS.FETCH_SECURITY_DETAILS, requestSecurityDetails);
}

export function* selectUpdateSecurity() {
    yield takeEvery(CONSTANTS.EDIT_SECURITY, requestUpdateSecurity);
}

export default function* rootSaga() {
    yield all([
        fork(selectSecurityListStats),
        fork(selectSecurityList),
        fork(selectSecurityDetails),
        fork(selectUpdateSecurity)
    ]);
}
