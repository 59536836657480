import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

class Portal extends React.Component {
  componentWillUnmount() {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }

  render() {
    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement('div');
      document.body.appendChild(this.defaultNode);
    }
    
    return ReactDOM.createPortal(
      this.props.children,
      this.props.node || this.defaultNode,
    );
  }
}

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default connect(mapStateToProps)(Portal);
