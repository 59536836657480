import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as ACTIONS_CAMERAS from 'actions/Cameras';

function* requestFetchCameras({ payload }) {
    try {
        yield put(ACTIONS_CAMERAS.setCameras(null));
        const cameras = yield call(API.POST, payload.url, payload.data);
        if (!cameras) {
            yield put(ACTIONS_CAMERAS.setCameras([]));
        } else if (!cameras.data) {
            yield put(ACTIONS_CAMERAS.setCameras([]));
        } else {
            cameras.data.map((item) => {
                item.devthumbnail =
                item.thumbnail && item.thumbnail !== ''
                    ? `https://storage.googleapis.com/nse-dev-een/${item.thumbnail}.jpeg`
                    : '/assets/images/Components/Internal/video-unvailable.png';
                item.prodthumbnail =
                item.thumbnail && item.thumbnail !== ''
                    ? `https://storage.googleapis.com/nse-prod-een/${item.thumbnail}.jpeg`
                    : '/assets/images/Components/Internal/video-unvailable.png';
                item.thumbnail =
                    item.thumbnail && item.thumbnail !== ''
                        ? `https://storage.googleapis.com/eagle_eye/${item.thumbnail}.jpeg`
                        : '/assets/images/Components/Internal/video-unvailable.png';
            });
            yield put(ACTIONS_CAMERAS.setCameras(cameras.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchCameraToken({ payload }) {
    try {
        yield put(ACTIONS_CAMERAS.setCameraToken(null));
        const token = yield call(API.POST, payload.url, payload.data);
        if (!token) {
            yield put(ACTIONS_CAMERAS.setCameraToken(null));
        } else if (!token.data || !token.data.token) {
            yield put(ACTIONS_CAMERAS.setCameraToken(null));
        } else {
            yield put(ACTIONS_CAMERAS.setCameraToken(token.data.token));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectFetchCameras() {
    yield takeEvery(CONSTANTS.FETCH_CAMERAS, requestFetchCameras);
}

export function* selectFetchCameraToken() {
    yield takeEvery(CONSTANTS.FETCH_CAMERA_TOKEN, requestFetchCameraToken);
}

export default function* rootSaga() {
    yield all([fork(selectFetchCameras), fork(selectFetchCameraToken)]);
}
