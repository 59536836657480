import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const saveZone = ({ uuid = '', name = '', unitUUIDs = [0], Relays = [0] }) => {
    return {
        type: CONSTANTS.SAVE_ZONE,
        payload: {
            url: ENDPOINTS.ENDPOINT_ZONE_SAVE,
            data: {
                companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                uuid: uuid,
                name,
                unitUUIDs,
                Relays
            }
        }
    };
};

export const deleteZone = (UUID = '') => {
    return {
        type: CONSTANTS.DELETE_ZONE,
        payload: {
            url: ENDPOINTS.ENDPOINT_ZONE_DELETE,
            data: { UUID }
        }
    };
};

export const fetchZoneList = (filter = ['online', 'offline']) => {
    return {
        type: CONSTANTS.FETCH_ZONE_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_ZONE_LIST,
            filter,
            data: {}
        }
    };
};

export const fetchZoneDetails = (zoneUUID = '') => {
    return {
        type: CONSTANTS.FETCH_ZONE_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_ZONE_LIST,
            data: {},
            zoneUUID
        }
    };
};

export const setZoneList = (data = []) => {
    return {
        type: CONSTANTS.SET_ZONE_LIST,
        payload: data
    };
};

export const setZoneKeypadList = (data = []) => {
    return {
        type: CONSTANTS.SET_ZONE_KEYPAD_LIST,
        payload: data
    };
};

export const setZoneDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_ZONE_DETAILS,
        payload: data
    };
};

export const setZoneHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ZONE_HEADERS,
        payload: data
    };
};

export const setZoneUnitHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ZONE_UNIT_HEADERS,
        payload: data
    };
};

export const setZoneEntryHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ZONE_ENTRY_HEADERS,
        payload: data
    };
};

export const resendZoneCode = (zoneId = 0) => {
    return {
        type: CONSTANTS.RESEND_ZONE_CODE,
        payload: {
            url: '/zone/accesscodes/send/',
            data: {
                zoneID: zoneId
            }
        }
    };
};
