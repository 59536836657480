import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import Headers from 'components/Table/header';
import Row from 'components/Table/cell';
import SkeletonCell from 'components/Table/cell/skeleton';
import Empty from 'components/Table/cell/components/empty';
import 'react-loading-skeleton/dist/skeleton.css';
class Table extends React.Component {
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.rowWidthRef = React.createRef();
        this.state = {
            data: props.data,
            rowWidth: null,
            header: props.header,
            scrolled: false
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            let { data } = this.props;
            this.setState({ data });
        }
        if (prevProps.header !== this.props.header) {
            let { header } = this.props;
            this.setState({ header });
        }
        setTimeout(() => {
            this.props.activeList &&
                this.props.activeList.length != 0 &&
                this.props.data &&
                this.props.activeList.length != this.props.data.length &&
                !this.state.scrolled &&
                this.scrollToActive(
                    this.props.data.findIndex(
                        (item) => item.uuid === this.props.activeList[this.props.activeList.length - 1]
                    )
                );
        }, 5);
    }
    componentDidMount() {
        this.setState({ rowWidth: this.rowWidthRef.current.offsetWidth });
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps.force) {
            return true;
        } else if (this.state.data === nextProps.data) {
            return false;
        } else {
            return true;
        }
    }
    setList = (data) => {
        this.setState({ data });
    };
    render() {
        const {
            Cell,
            action,
            headerAction,
            headerFontSize,
            toggle,
            tableHeight,
            overrideTableHeight,
            loadMore,
            hasNextPage,
            itemSize,
            overflowX,
            tableWidth,
            theme,
            maxHeight,
            border
        } = this.props;
        const { data, header, rowWidth } = this.state;
        this.styles = styles(theme, tableHeight, maxHeight, border, itemSize);
        const itemCount = data ? (hasNextPage ? data.length + 1 : data.length) : 0;
        let numItems = Math.floor((overrideTableHeight || tableHeight) / itemSize);
        return (
            <>
                <div style={{ overflowX: overflowX }}>
                    <table className={css(this.styles.table)} ref={this.rowWidthRef}>
                        <thead style={{ width: tableWidth }} className={css(this.styles.thead)}>
                            {header && (
                                <Headers
                                    data={data}
                                    header={header}
                                    sort={this.setList}
                                    action={headerAction}
                                    headerFontSize={headerFontSize}
                                />
                            )}
                        </thead>
                        {/* this borderStyle element is in a weird spot because for whatever reason, on certain screen resolutions the bottom border of
                    an element with a display of table, table-row or table-cell will appear as double the value. i have no idea why, my guess is it's because
                    of some random bootstrap style that i cant find for some reason, or it's a pixel density thing. if you find out please tell me :) -brayden */}
                        {/* UPDATE: commenting this out appears to not cause any problems anymore. something must have changed */}
                        {/* {header && <div className={css(this.styles.borderStyle)}></div>} */}
                    </table>
                    <div style={{ width: tableWidth }} className={css(this.styles.tbody)}>
                        {data ? (
                            Cell && data.length > 0 ? (
                                <InfiniteLoader
                                    isItemLoaded={(index) => index < data.length}
                                    itemCount={itemCount}
                                    loadMoreItems={loadMore}>
                                    {({ onItemsRendered, ref }) => (
                                        <FixedSizeList
                                            height={overrideTableHeight || tableHeight}
                                            width={'100%'}
                                            itemCount={itemCount}
                                            itemSize={itemSize}
                                            onItemsRendered={onItemsRendered}
                                            ref={ref}>
                                            {({ index, style }) => (
                                                <div style={style} className={css(this.styles.row)}>
                                                    {data[index] && (
                                                        <Row
                                                            active={
                                                                (this.props.activeList &&
                                                                    this.props.activeList.includes(data[index].uuid)) ||
                                                                (this.props.activeList &&
                                                                    this.props.activeList.includes(data[index].id)) ||
                                                                (this.props.activeList &&
                                                                    this.props.activeList.includes(data[index].UUID)) ||
                                                                (this.props.activeList &&
                                                                    this.props.activeList.includes(
                                                                        data[index].entryUUID
                                                                    )) ||
                                                                (this.props.activeList &&
                                                                    this.props.activeList.includes(
                                                                        data[index].lockUUID
                                                                    )) ||
                                                                false
                                                            }
                                                            highlightActive={this.props.highlightActive}
                                                            action={action}
                                                            data={data[index]}
                                                            itemSize={itemSize}
                                                            Cell={Cell}
                                                            index={index}
                                                            toggle={toggle}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </FixedSizeList>
                                    )}
                                </InfiniteLoader>
                            ) : (
                                <Empty tableHeight={overrideTableHeight || tableHeight} />
                            )
                        ) : (
                            <div className={css(this.styles.skeletonLoader)}>
                                {numItems &&
                                    numItems > 0 &&
                                    [...Array(numItems + 2)].map((e, i) => (
                                        <SkeletonCell
                                            header={header && header}
                                            Cell={Cell && Cell}
                                            rowWidth={rowWidth}
                                            itemSize={itemSize}
                                            key={i}
                                            height={itemSize}
                                            data={data}
                                        />
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
    scrollToActive = (active) => {
        this.setState({ scrolled: true });
        active && this.listRef.current && this.listRef.current.scrollToItem(active, 'center');
    };
}

Table.defaultProps = {
    tableHeight: 300,
    maxHeight: null,
    force: true,
    border: true,
    bottomAction: false,
    bottomView: false,
    toggle: null,
    activeList: [],
    itemSize: 55,
    overflowX: 'hidden',
    tableWidth: '100%'
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default connect(mapStateToProps)(Table);

const styles = (theme, tableHeight, maxHeight, border) =>
    StyleSheet.create({
        table: {
            width: '100%'
        },
        tbody: {
            display: 'block',
            maxHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
            borderBottom: border ? `solid 1px ${theme.color.borderColor}` : 'none',
            position: 'relative'
        },
        thead: {
            display: 'table',
            tableLayout: 'fixed'
        },
        borderStyle: {
            width: '100%',
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            display: 'block'
        },
        skeletonLoader: {
            background: theme.color.compColor,
            overflow: 'hidden',
            height: maxHeight || tableHeight
        }
    });
