import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { injectIntl } from 'react-intl';
import * as ENDPOINTS from 'constants/endpoints';
import { StyleSheet, css } from 'aphrodite';

import { showMessage } from 'actions/App';

import Modal from 'components/Modal';
import Button from 'components/Button';
import SelectInput from 'components/Inputs/select';
import TextInput from 'components/Inputs/text';
class ServerIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCustom: sessionStorage.getItem('nse_url_type') === 'custom',
            customURL: sessionStorage.getItem('nse_url'),
            customVersion: sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : '',
        };
    }
    handleServerChange = (url, notCustom) => {
        const { intl, showMessage } = this.props;
        sessionStorage.setItem('nse_url', url);
        showMessage(
            `${intl.formatMessage({ id: 'notifications.success.server' })} ${url}`,
            'success-message'
        );
        notCustom && this.setState({ showCustom: false, customURL: url });
        sessionStorage.setItem('nse_url_type', notCustom ? 'server' : 'custom');
        this.setState({ showCustom: false });
        this.props.toggle();
    };
    handleVersionChange = () => {
        const { customVersion } = this.state;
        const { intl, toggle, showMessage } = this.props;
        sessionStorage.setItem('nse_version', customVersion);
        showMessage(
            `${intl.formatMessage({ id: 'notifications.success.frontend' })} ${customVersion && customVersion !== "" ? customVersion : intl.formatMessage({ id: 'pages.schedules.defaultShort' })}`,
            'success-message'
        );
        this.setState({ showCustom: false });
        toggle();
    }
    render() {
        const { intl, theme, toggle, showSwitcher } = this.props;
        const { showCustom, customURL, customVersion } = this.state;
        const URL_SET = sessionStorage.getItem('nse_url');
        const URL_TYPE = sessionStorage.getItem('nse_url_type');
        const url = window.location.href;
        this.styles = styles(theme);
        return (
            <Modal 
                show={showSwitcher} 
                toggle={() => toggle(!showSwitcher)}>
                  {url.includes('signin') && <Row style={{ padding: '25px 50px 0 50px' }}>
                    <Col xs="12" sm="12">
                        <FormGroup>
                            <Label className={css(this.styles.label)}>
                                {intl.formatMessage({ id: 'form.releases.frontend' })} {intl.formatMessage({ id: 'comp.footer.version' })}
                            </Label>
                            {/* <Input
                                type="text"
                                placeholder={intl.formatMessage({
                                    id: 'comp.footer.version'
                                })}
                                defaultValue={customVersion}
                                onChange={(e) => this.setState({ customVersion: e.target.value })}
                                autoFocus
                            /> */}
                            <TextInput
                                placeholder={intl.formatMessage({ id: 'comp.footer.version' })}
                                fontSize={14}
                                type={'text'}
                                value={customVersion}
                                margin={'10px 0px 0px 0px'}
                                onChange={(e) => this.setState({ customVersion: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                </Row>}
                <Row style={{ padding: url.includes('signin') ? '0 50px 0 50px' : '40px 50px 0 50px' }}>
                    <Col xs="12" sm="12">
                        <FormGroup style={{ display: 'block' }}>
                            <Label className={css(this.styles.label)}>
                                {intl.formatMessage({ id: 'form.releases.backend' })} {intl.formatMessage({ id: 'comp.footer.version' })}
                            </Label>
                            <SelectInput
                                //title={`${intl.formatMessage({ id: 'form.releases.backend' })} ${intl.formatMessage({ id: 'comp.footer.version' })}`}
                                disabledOption={intl.formatMessage({ id: 'auth.server.select' })}
                                options={[
                                    { text: intl.formatMessage({ id: 'auth.server.backend' }), key: 0, value: ENDPOINTS.URL_BACKEND },
                                    { text: intl.formatMessage({ id: 'auth.server.run' }), key: 1, value: ENDPOINTS.URL_RUN },
                                    { text: intl.formatMessage({ id: 'auth.server.dev' }), key: 2, value: ENDPOINTS.URL_BACKEND_DEV },
                                    { text: intl.formatMessage({ id: 'auth.server.custom' }), key: 3, value: 'custom' },
                                ]}
                                name="env"
                                value={URL_TYPE === 'custom' ? 'custom' : URL_SET}
                                onChange={(e) =>
                                    {if(e.target.value === 'custom'){
                                        this.setState({ showCustom: true })
                                        sessionStorage.setItem('nse_url_type', 'custom')
                                    } else {
                                        this.handleServerChange(e.target.value, true)
                                    }}
                                }
                                fontSize={14}
                            />
                            {/* <Label className={css(this.styles.label)}>
                                {intl.formatMessage({ id: 'form.releases.backend' })} {intl.formatMessage({ id: 'comp.footer.version' })}
                            </Label>
                            <Input
                                type="select"
                                name="env"
                                onChange={(e) =>
                                    e.target.value === 'custom'
                                        ? this.setState({ showCustom: true })
                                        : this.handleServerChange(e.target.value, true)
                                }
                                defaultValue={URL_TYPE === 'custom' ? 'custom' : URL_SET}>
                                <option hidden>{intl.formatMessage({ id: 'auth.server.select' })}</option>
                                <option value={ENDPOINTS.URL_BACKEND}>
                                    {intl.formatMessage({ id: 'auth.server.backend' })}
                                </option>
                                <option value={ENDPOINTS.URL_RUN}>
                                    {intl.formatMessage({ id: 'auth.server.run' })}
                                </option>
                                <option value={ENDPOINTS.URL_BACKEND_DEV}>
                                    {intl.formatMessage({ id: 'auth.server.dev' })}
                                </option>
                                <option value={'custom'}>{intl.formatMessage({ id: 'auth.server.custom' })}</option>
                            </Input> */}
                        </FormGroup>
                        {showCustom && (
                            <FormGroup>
                                {/* <Input
                                    type="text"
                                    placeholder={intl.formatMessage({
                                        id: 'auth.server.custom.url'
                                    })}
                                    defaultValue={customURL}
                                    onChange={(e) => this.setState({ customURL: e.target.value })}
                                    autoFocus
                                /> */}
                                <TextInput
                                    placeholder={intl.formatMessage({ id: 'auth.server.custom.url' })}
                                    fontSize={14}
                                    type={'text'}
                                    value={customURL}
                                    margin={'10px 0px 0px 0px'}
                                    onChange={(e) => this.setState({ customURL: e.target.value })}
                                />
                                {/* <Button
                                    color={theme.color.themeColor}
                                    width={140}
                                    load={false}
                                    action={() => this.handleServerChange(customURL)}
                                    text={'comp.button.update'}
                                    float={'right'}
                                    margin={'20px 20px 0 0'}
                                    padding={'10px 20px'}
                                /> */}
                                
                            </FormGroup>
                        )}
                    </Col>
                </Row>
                <Row style={{ padding: '0 50px 25px 50px' }}>
                    <Col xs="12" sm="12">
                        <Button
                            color={theme.color.themeColor}
                            width={140}
                            load={false}
                            disabled={!showCustom && customVersion == "" && (sessionStorage.getItem('nse_version') && sessionStorage.getItem('nse_version') == "")}
                            action={() => {
                                if(showCustom && customVersion.length > 0) {
                                    this.handleVersionChange(customURL)
                                    this.handleServerChange(customURL)
                                } else if (!showCustom) {
                                    this.handleVersionChange(customURL)
                                } else {
                                    this.handleServerChange(customURL)
                                }
                            }}
                            text={'comp.button.update'}
                            float={'right'}
                            margin={'0 20px 0 0'}
                            padding={'10px 20px'}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    }
}

ServerIndex.defaultProps = {
    showSwitcher: false
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(connect(mapStateToProps, { showMessage })(ServerIndex));

const styles = (theme) =>
    StyleSheet.create({

        label: {
            color: theme.color.themeColor,
            fontSize: 14,
            marginBottom: 10,
        },
    });
