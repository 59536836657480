import React from 'react';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
class CellClose extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { Cellwidth, update } = this.props;
        this.styles = styles();
        return (
            <td width={Cellwidth}>
                <i onClick={() => update()} className={`material-icons ${css(this.styles.wrapper)}`}>
                    close
                </i>
            </td>
        );
    }
}

export default withRouter(CellClose);

const styles = () =>
    StyleSheet.create({
        wrapper: {
            display: 'block',
            margin: '0 auto',
            fontSize: 12,
            ':hover': {
                cursor: 'pointer'
            }
        }
    });
