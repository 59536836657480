import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { setShowPortalSearch } from 'actions/App';
import * as ICONS from 'utilities/icons';
import AssignUnitContinuation from './continuations/assignUnit';
import { fadeIn } from 'react-animations';

class PortalSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
            filteredFeatures: null,
            continuedScreen: false,
            showUnitAssignModal: false,
            features: [
                {
                    name: 'remoteOpenTooltip',
                    title: 'permission.remote_open',
                    howToFind: 'search.remoteOpen.howToFind',
                    icon: <ICONS.EntryIcon color={'#fff'} width={25} height={25} />,
                    url: '/app/home/all',
                    permission: 'remote_open', 
                    // featureFlag is also a property
                },
                {
                    name: 'addReleaseNotes',
                    title: 'form.releases.add',
                    howToFind: 'search.addReleaseNotes.howToFind',
                    icon: <ICONS.NewsIcon color={'#fff'} width={25} height={25} />,
                    url: '/app/settings/theme/all',
                    permission: 'support_manage_releases',
                },
                {
                    // dont include name if you dont want tooltip
                    title: 'search.editPersonalUserDetails.name',
                    howToFind: 'search.editPersonalUserDetails.howToFind',
                    icon: <ICONS.UserIcon color={'#fff'} width={23} height={23} />,
                    url: '/app/users/update/[userId]/info/[userId]', // using brackets references variable in clickFeature
                },
                {
                    title: 'search.changeBrandingAndLogos.name',
                    howToFind: 'search.changeBrandingAndLogos.howToFind',
                    icon: <ICONS.ImageIcon color={'#fff'} width={23} height={23} />,
                    url: '/app/settings/branding/all',
                    permission: 'manage_sites',
                    keywords: [
                        'form.themeColor', 
                        'search.changeBrandingAndLogos.keyword1', 
                        'search.changeBrandingAndLogos.keyword2', 
                        'form.facilityImg', 
                        'form.companyBrandLogo'
                    ],
                    
                },
                {
                    // dont include name if you dont want tooltip
                    name: 'assignUnit',
                    title: 'form.assign.unit',
                    howToFind: 'search.assignUnit.howToFind',
                    icon: <ICONS.UnitIcon color={'#fff'} width={23} height={23} />,
                    url: '/app/settings/branding/all',
                    permission: 'manage_unit_assignments',
                    continue: () => {
                        this.setState({continuedScreen: true, showUnitAssignModal: true});
                    },
                    confirm: this.clickAssignUnit,
                },
                {
                    name: 'createNewRole',
                    title: 'comp.roles.create.role',
                    howToFind: 'search.addReleaseNotes.howToFind',
                    icon: <ICONS.UserIcon color={'#fff'} width={23} height={23} />,
                    url: '/app/settings/theme/all',
                    permission: 'manage_roles',
                },
            ]
        };
    }

    hidePortalSearch = () => {
        this.props.setShowPortalSearch(false);
        this.goBackToSearch();
    }

    clickFeature = (i) => {
        if (i == null) return; 
        const { history, authUser } = this.props;
        const clickedFeature = this.state.features[i];
        const keywords = ['userId']; 

        // these variables get called in the block of code below, they are important
        const userId = authUser && authUser.id;

        // change URL to feature URL
        if (history && history.location !== clickedFeature.url) {
            let temp = clickedFeature.url;
            for (let keyword of keywords) {
                const formattedKeyword = '[' + keyword + ']';
                // note; eval is considered unsafe, however I can't figure out a solution without it
                while (temp && temp.includes(formattedKeyword)) {
                    temp = temp.replace(formattedKeyword, eval(keyword));
                }
                
            }
            history.push(temp);
        }
        

        // close portal search
        this.hidePortalSearch();
    }

    goBackToSearch = () => {
        // need to dynamically change the modal vars to false
        this.setState({continuedScreen: false, showUnitAssignModal: false});
    }

    onInputChange = (e) => {
        const { intl } = this.props;
        const v = e.target.value.toLowerCase();
        const filteredResults = this.state.features.filter(feature => {
            const featureName = intl.formatMessage({id: feature.title});
            const includesName = featureName && featureName.toLowerCase().includes(v);
            const includesKeyword = feature.keywords && feature.keywords.filter(keyword => {
                const translatedKeyword = keyword && intl.formatMessage({id: keyword}).toLowerCase();
                return translatedKeyword && translatedKeyword.toLowerCase().includes(v);
            }).length > 0;
            return includesName || includesKeyword;
        })
        this.setState({filteredFeatures: filteredResults, inputText: e.target.value});
    }

    findIndexOfFeature = (title) => {
        const { features } = this.state;
        const found = features && features.findIndex(feature => feature.title === title);
        return found;
    }
    findFeature = (title) => {
        const { features } = this.state;
        const found = features && features.find(feature => feature.title === title);
        return found;
    }

    // continuations
    clickAssignUnit = (unit) => {
        const { history } = this.props;
        const feature = this.findFeature('form.assign.unit');

        const url = '/app/units/display/' + unit.id;
        if (history && history.location.pathname.includes('app/units/display')) {
            // this is necessary because app doesnt load new unit page if it goes from unit details directly
            history.push('/app/settings/theme/all');
            setTimeout(() => {
                history.push(url);
            }, 10);
        } else {
            history && history.push(url);
        }
        this.hidePortalSearch();

       
    }

    render() {
        const { intl, theme, authUser } = this.props;
        const { features, filteredFeatures, inputText, showUnitAssignModal, continuedScreen } = this.state;
        const chosenFeatures = filteredFeatures == null ? features : filteredFeatures;
        this.styles = styles(theme);

        return (
            <div className={css(this.styles.wrapper)}>
                {!continuedScreen ? <>
                    <div className={css(this.styles.searchArea)}>
                        <div className={css(this.styles.searchLeft)}>
                            <div className={css(this.styles.searchIcon)}>
                                <span 
                                    style={{padding: 0}}
                                    className={'material-icons'}>
                                    search
                                </span>
                            </div>
                            <input type={'text'} autoFocus={true} value={inputText} onChange={this.onInputChange} placeholder={intl.formatMessage({id: 'search.textboxText'})} className={css(this.styles.searchText)} />
                        </div>
                        <div className={css(this.styles.searchRight)}>
                            <div className={css(this.styles.searchClose)} onClick={this.hidePortalSearch}>
                                <span 
                                    style={{padding: 0, fontSize: 19}}
                                    className={'material-icons'}>
                                    close
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={css(this.styles.resultsAreaOverflow)}>
                        <div className={css(this.styles.resultsArea)}>
                            {chosenFeatures.length ? chosenFeatures.map((feature, i) => 
                                feature && authUser && 
                                // check to see if user has permission for feature
                                (!feature.permission || authUser.permissions.includes(feature.permission)) && 
                                // check to see if user has featureFlag for feature
                                (!feature.featureFlag || authUser.featureflagAssignments && authUser.featureflagAssignments.includes(feature.featureFlag)) && 
                                <div 
                                    onClick={() => { feature.continue ? feature.continue() : this.clickFeature(this.findIndexOfFeature(feature.title)) }}
                                    className={css(this.styles.featureRow)} 
                                    key={`search${feature.name}${i}`}>
                                    <div className={css(this.styles.rowIcon)}>{feature.icon || <ICONS.LightBulb color={'#fff'} width={25} height={25} />}</div>
                                    <div className={css(this.styles.rowBottom)}>
                                        <div className={css(this.styles.rowTitle)}>{intl.formatMessage({id: feature.title})}</div>
                                        <div className={css(this.styles.rowHowToFind)}>{intl.formatMessage({id: feature.howToFind})}</div>
                                    </div>
                                </div>)
                                :
                                <div className={css(this.styles.noResults)}>
                                    <div>{intl.formatMessage({id: 'comp.table.cell.empty'})}</div>
                                </div>}
                        </div>
                    </div>
                </> : 
                <div className={css(this.styles.continuedWrapper)}>
                    <div className={css(this.styles.continuedTopBar)}>
                        <div className={css(this.styles.topBarItem)} onClick={this.goBackToSearch}>
                            <div><span style={{fontSize: 16, color: theme && theme.color.themeColor}} className="material-icons">arrow_back_ios</span></div>
                            <div>{intl.formatMessage({id: 'pages.users.create.back'})}</div>
                        </div>
                        <div className={css(this.styles.topBarItem)} onClick={this.hidePortalSearch}>
                            <div><span style={{fontSize: 16, color: theme && theme.color.themeColor}} className="material-icons">close</span></div>
                            <div>{intl.formatMessage({id: 'comp.button.close'})}</div>
                        </div>
                    </div>
                    {showUnitAssignModal && <AssignUnitContinuation confirm={(unit) => this.findFeature('form.assign.unit') && this.findFeature('form.assign.unit').confirm(unit)} />}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, theme, showPortalSearch } = app;
    const { authUser } = auth;
    return { width, theme, showPortalSearch, authUser };
};

export default withRouter(injectIntl(connect(mapStateToProps, {
    setShowPortalSearch
    
})(PortalSearch)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            width: 410,
            position: 'absolute',
            top: 6,
            right: 15,
            zIndex: 5, // ? worth doing?
            animationName: fadeIn,
            animationDuration: '.2s',
        },
        searchArea: {
            display: 'flex',
            borderBottom: `1px solid ${theme && theme.color.borderColor}`,
            padding: '11px 15px',
            gap: 11,
            justifyContent: 'space-between',
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundColor: theme && theme.color.headerColor,
            border: `1px solid ${theme && theme.color.borderColor}`,
            borderRadius: 15,
        },
        searchLeft: {
            display: 'flex',
            gap: 10,
        },
        searchIcon: {
            color: theme && theme.color.themeColor,
        },
        searchText: {
            border: 'none', 
            backgroundColor: theme && theme.color.headerColor,
            width: 305,
            outline: 'none',
            color: theme && theme.color.fontColor, // not doing anything? 
        },
        searchRight: {
            // paddingRight: 25,
        },
        searchClose: {
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderRadius: 20,
            color: theme && theme.color.fontColor,
            cursor: 'pointer',
            width: 25,
            height: 25,
            display: 'grid',
            placeItems: 'center',
            opacity: 0.8,
            ':hover': {
                opacity: 0.6,
            }
        },

        resultsAreaOverflow: {
            overflow: 'hidden',
            borderRadius: 15,
            marginTop: 15,
            boxShadow: theme && theme.color.name.includes('dark') ? '1px 1px 5px rgba(0, 0, 0, 0.2)' :'1px 1px 5px rgba(0, 0, 0, 0.1)',
        },
        resultsArea: {
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 440,
            backgroundColor: theme && theme.color.headerColor,
            border: `1px solid ${theme && theme.color.borderColor}`,
            borderRadius: 15,
        },
        
        featureRow: {
            display: 'flex',
            padding: '9px 15px',
            gap: 15,
            borderBottom: `1px solid ${theme && theme.color.borderColor}`,
            cursor: 'pointer',
            color: theme && theme.color.fontColor,
            ':hover': {
                backgroundColor: 'rgba(0,0,0,0.02)'
            },
            ':last-child': {
                borderBottom: 'none',
            }
        },
        rowIcon: {
            width: 40,
            height: 40,
            color: theme && theme.color.buttonFontColor,
            borderRadius: 50,
            backgroundColor: theme && theme.color.themeColor,
            display: 'grid',
            placeItems: 'center',
        },
        rowTitle: {
            fontSize: 15,
            fontWeight: 'bold',
            marginBottom: -2
        },
        rowHowToFind: {
            fontSize: 13,
        },

        noResults: {
            padding: 30,
            textAlign: 'center',
        },

        continuedWrapper: {
            backgroundColor: theme && theme.color.headerColor,
            border: `1px solid ${theme && theme.color.borderColor}`,
            borderRadius: 15,
            boxShadow: theme && theme.color.boxShadow,
            minHeight: 410,

        },
        continuedTopBar: {
            padding: '0px 15px',
            paddingTop: 10,
            display: 'flex',
            justifyContent: 'space-between',
            color: theme && theme.color.fontColor,
        },
        topBarItem: {
            display: 'flex',
            gap: 2,
            textTransform: 'uppercase',
            alignItems: 'center',
            cursor: 'pointer',
            transition: '0.2s opacity ease-in-out',
            fontFamily: 'Poppins',
            ':hover': {
                opacity: 0.8,
            }
        }
    })