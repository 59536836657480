import React from 'react';
import { connect } from 'react-redux';

import * as UTILS from 'utilities';
import { editSiteNote } from 'actions/Support';

import CellText from 'components/Table/cell/components/text';
import CellStar from 'components/Table/cell/components/star';
class SiteNotesCell extends React.Component {
    updatePriority = () => {
        const { data, editSiteNote } = this.props;
        let note = JSON.parse(JSON.stringify(data));
        note.priority = !data.priority;
        editSiteNote(note);
    };
    render() {
        const { data, width, authUser, site } = this.props;
        const { title, createdDate, createdByFirstName, createdByLasttName, priority, uuid } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    maxCharLength={width > 1430 ? 80 : width > 1330 ? 30 : width > 991 ? 20 : width > 500 ? 40 : 20}
                    Title={title}
                    Description={width > 300 ? `${createdByFirstName} ${createdByLasttName}` : null}
                    textAlign={'left'}
                    TitleColor={true}
                />
                <CellText
                    Cellwidth={'85px'}
                    Title={UTILS.dateFormatter(createdDate, authUser, site)}
                    textAlign={'right'}
                    TitleColor={true}
                />
                {width > 800 && (
                    <CellStar
                        Cellwidth={'30px'}
                        action={this.updatePriority}
                        priority={priority}
                        tooltip={true}
                        tooltipText={priority ? 'tooltip.removeFavorite' : 'tooltip.addFavorite'}
                        tooltipId={`site-${uuid}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width } = app;
    const { authUser, site } = auth;
    return { width, authUser, site };
};

export default connect(mapStateToProps, { editSiteNote })(SiteNotesCell);
