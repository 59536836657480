import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const editGateway = (mac = '', serial = '', comment = '', name = '') => {
    return {
        type: CONSTANTS.EDIT_GATEWAY,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_EDIT,
            data: {
                mac,
                serial,
                comment,
                name
            }
        }
    };
};

export const fetchGatewayDetails = (gatewayUUID = '') => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_LIST,
            data: {},
            gatewayUUID
        }
    };
};

export const fetchGatewayImage = (gatewayUUID = '') => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_IMAGE,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_IMAGE,
            data: {
                gatewayUUID
            }
        }
    };
};

export const setGatewayImage = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_IMAGE,
        payload: data
    };
};

export const setGatewayList = (data = []) => {
    return {
        type: CONSTANTS.SET_GATEWAY_LIST,
        payload: data
    };
};

export const setGatewayDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_GATEWAY_DETAILS,
        payload: data
    };
};
