import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import { CheckmarkIcon } from 'utilities/icons';
class CheckboxInput extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, theme, checked, label, onClick, overrideLabel, fontSize, size, style } = this.props;
        this.styles = styles(fontSize);

        return (
            <div className={css(this.styles.wrapper)} style={style} onClick={onClick} checked={checked}>
                {/* checkboxWrapper div is required because sometimes the checkboxes get squished in low width divs */}
                <div className={css(this.styles.checkboxWrapper)}>
                    <div
                        className={css(this.styles.checkbox)}
                        style={{
                            width: size,
                            height: size,
                            borderRadius: 5,
                            border: `1px solid ${checked ? theme.color.themeColor : theme.color.fontColor}`,
                            backgroundColor: checked ? theme.color.themeColor : 'transparent',
                            marginRight: 8,
                            display: 'grid',
                            placeItems: 'center',
                        }}>
                        {checked && (
                            <CheckmarkIcon
                                width={'70%'}
                                height={'70%'}
                                stroke={theme && theme.color.buttonFontColor}
                                strokeWidth={1.5}
                            />
                        )}
                    </div>
                </div>
                {label && (
                    <div className={css(this.styles.label)}>
                        {overrideLabel ? label : intl.formatMessage({ id: label })}
                    </div>
                )}
            </div>
        );
    }
}

CheckboxInput.defaultProps = {
    fontSize: 13,
    size: 15
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(connect(mapStateToProps)(CheckboxInput));

const styles = (fontSize) =>
    StyleSheet.create({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            cursor: 'pointer',
            userSelect: 'none'
        },
        checkbox: {
            ':hover': {
                opacity: 0.8
            }
        },
        label: {
            fontSize: fontSize
        }
    });
