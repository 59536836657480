import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NewNotificationContainer from './container';
import { injectIntl } from 'react-intl';
import { hideMessage, setLoader } from 'actions/App';
import { fetchUnitList } from 'actions/Units';

import { generateTextID } from 'utilities';
class NotificationsNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            timers: []
        };
    }
    componentDidUpdate() {
        if (this.props.showMessage) {
            this.props.setLoader(false);
            this.filterMessage();
            this.props.hideMessage();
        }
        const { notifications, timers } = this.state;
        // check notifications to see if there is a timer assigned to it. if there isn't, assign one. if there is, don't do anything
        for (let i in notifications) {
            let timerMatch = timers.find((timer) => timer.id === notifications[i].id);
            !timerMatch &&
                timers.push({
                    id: notifications[i].id,
                    timer: setTimeout(() => {
                        this.deleteNotification(notifications[i].id);
                    }, notifications[i].timeOut)
                });
        }
    }
    addNotification = (type = 'success', message, title, timeOut = 8000, callback = null, priority = false) => {
        const { notifications, timers } = this.state;
        let tempNotifs = JSON.parse(JSON.stringify(notifications));
        let tempTimers = JSON.parse(JSON.stringify(timers));
        const duplicateIndex =
            tempNotifs &&
            tempNotifs.findIndex((notif) => notif.type === type && notif.show && notif.message === message);
        const id = generateTextID();
        if (duplicateIndex !== -1) {
            tempNotifs[duplicateIndex].quantity += 1;
            tempNotifs[duplicateIndex].timeOut = timeOut;
            let tempTimerIndex = tempTimers.findIndex((timer) => timer.id === tempNotifs[duplicateIndex].id);
            if (tempTimerIndex !== -1) {
                // reset timer?
                clearTimeout(this.state.timers[tempTimerIndex].timer);
                tempTimers[tempTimerIndex].timer = null;
                tempTimers[tempTimerIndex].timer = setTimeout(() => {
                    this.deleteNotification(tempNotifs[duplicateIndex].id);
                }, tempNotifs[duplicateIndex].timeOut);
                this.setState({
                    notifications: tempNotifs,
                    timers: tempTimers
                });
            }
        }
        if (duplicateIndex === -1) {
            this.setState((prevState) => ({
                notifications: [
                    ...prevState.notifications,
                    {
                        id,
                        type,
                        quantity: 1,
                        show: true,
                        message,
                        title,
                        callback,
                        priority,
                        timeOut: timeOut
                    }
                ]
            }));
        }
    };
    deleteNotification = (id) => {
        const { notifications } = this.state;
        let tempNotifs = JSON.parse(JSON.stringify(notifications));
        let targetIndex = tempNotifs && tempNotifs.findIndex((notif) => notif.id === id);
        // dont attempt to delete notif if it doesn't exist
        if (targetIndex === -1) return;
        // change show property to false for exit animation
        tempNotifs[targetIndex].show = false;
        this.setState(
            { notifications: tempNotifs },
            // delete notif 500 ms after show is changed to false
            () => {
                setTimeout(() => {
                    let tempNotifs2 = JSON.parse(JSON.stringify(this.state.notifications));
                    let targetIndex2 = tempNotifs2 && tempNotifs2.findIndex((notif) => notif.id === id);
                    tempNotifs2.splice(targetIndex2, 1);
                    this.setState({ notifications: tempNotifs2 });
                }, 495);
            }
        );
    };
    filterMessage = () => {
        const { intl, titleMessage, contentMessage, typeMessage } = this.props;
        switch (typeMessage) {
            case 'info':
                return this.addNotification('info', intl.formatMessage({ id: `${contentMessage}` }), titleMessage);
            case 'info_long':
                return this.addNotification(
                    'info',
                    intl.formatMessage({ id: `${contentMessage}` }),
                    titleMessage,
                    15000,
                    null,
                    true
                );
            case 'success':
                return this.addNotification('success', intl.formatMessage({ id: `${contentMessage}` }), titleMessage);
            case 'success-message':
                return this.addNotification('success', contentMessage, titleMessage);
            case 'warning':
                return this.addNotification('warning', intl.formatMessage({ id: `${contentMessage}` }), titleMessage);
            case 'rental_status_changed':
                return this.props.fetchUnitList();
            case 'pms_sync_finished':
                return this.addNotification('success', intl.formatMessage({ id: `${contentMessage}` }), titleMessage);
            case 'note':
                if (titleMessage === 'alarm:') {
                    let message = JSON.parse(contentMessage);
                    return this.addNotification(
                        'info',
                        `${intl.formatMessage({ id: 'pages.zones.Type' })}: ${message.zone}, ${intl.formatMessage({
                            id: 'pages.devices.Type'
                        })}: ${message.unitName}, ${intl.formatMessage({ id: 'pages.locks.Type' })}: ${message.lock}`,
                        intl.formatMessage({ id: 'notifications.incoming.alarm' }),
                        10000
                    );
                } else if (titleMessage === 'limited:') {
                    let message = JSON.parse(contentMessage);
                    return this.addNotification(
                        'info',
                        `${message.firstName} ${message.lastName} ${intl.formatMessage({
                            id: 'notifications.incoming.type.accessed'
                        })} ${intl.formatMessage({ id: 'pages.devices.type' })} ${
                            message.unitName
                        } ${intl.formatMessage({
                            id: `notifications.incoming.type.${message.type}`
                        })}.`,
                        intl.formatMessage({ id: 'notifications.incoming.limited' }),
                        10000
                    );
                } else {
                    return this.addNotification('info', contentMessage, titleMessage, 10000);
                }
            case 'message':
                return this.addNotification('info', contentMessage, titleMessage);
            case 'message-error':
                return this.addNotification('error', contentMessage, titleMessage);
            case 'error':
                return this.addNotification(
                    'error',
                    this.props.intl.formatMessage({ id: `${contentMessage}` }),
                    titleMessage
                );
        }
    };
    render() {
        const { notifications } = this.state;
        return <NewNotificationContainer notifications={notifications} deleteNotification={this.deleteNotification} />;
    }
}

const mapStateToProps = ({ app }) => {
    const { titleMessage, contentMessage, showMessage, typeMessage } = app;
    return { titleMessage, contentMessage, showMessage, typeMessage };
};

export default injectIntl(
    withRouter(connect(mapStateToProps, { hideMessage, setLoader, fetchUnitList })(NotificationsNew))
);
