import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { 
    fetchFacilityFeedback, 
    setFeedbackHeaders 
} from 'actions/App';

import { 
    CustomerFeedbackIcon, 
    UserFeedbackIcon 
} from 'utilities/icons';
import * as UTILS from 'utilities';
import Table from 'components/Table';
import FeedbackCell from 'components/Table/cell/containers/dashboard/feedback';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Header from 'components/Header/dashboard';
class DashboardFacilityFeedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentFeedback: null,
            showFeedbackModal: false
        };
    }
    componentDidMount() {
        const { fetchFacilityFeedback } = this.props;
        fetchFacilityFeedback();
    }
    handleFeedbackAction = (data) => {
        this.setState({ showFeedbackModal: true, currentFeedback: data });
    };
    render() {
        let { intl, theme, authUser, site, facilityFeedback, userTimezone, width, feedbackHeaders } = this.props;
        const { showFeedbackModal, currentFeedback } = this.state;
        this.styles = styles(theme, width);
        return (
            <>
                <Modal
                    show={showFeedbackModal}
                    title={
                        <div className={css(this.styles.iconWrapperModalNote)}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                <CustomerFeedbackIcon height={40} width={40} color={theme.color.themeColor} />
                                <p className={css(this.styles.titleModalNote)}>
                                    {currentFeedback && currentFeedback.siteName}
                                </p>
                                <div style={{ marginLeft: 15 }}>
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: 18, color: theme.color.themeColor }}>
                                        {currentFeedback &&
                                        currentFeedback.rating &&
                                        Number(currentFeedback.rating) >= 1
                                            ? 'star'
                                            : 'star_outline'}
                                    </i>
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: 18, color: theme.color.themeColor }}>
                                        {currentFeedback &&
                                        currentFeedback.rating &&
                                        Number(currentFeedback.rating) >= 2
                                            ? 'star'
                                            : 'star_outline'}
                                    </i>
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: 18, color: theme.color.themeColor }}>
                                        {currentFeedback &&
                                        currentFeedback.rating &&
                                        Number(currentFeedback.rating) >= 3
                                            ? 'star'
                                            : 'star_outline'}
                                    </i>
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: 18, color: theme.color.themeColor }}>
                                        {currentFeedback &&
                                        currentFeedback.rating &&
                                        Number(currentFeedback.rating) >= 4
                                            ? 'star'
                                            : 'star_outline'}
                                    </i>
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: 18, color: theme.color.themeColor }}>
                                        {currentFeedback &&
                                        currentFeedback.rating &&
                                        Number(currentFeedback.rating) >= 5
                                            ? 'star'
                                            : 'star_outline'}
                                    </i>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    }
                    toggle={() =>
                        this.setState({
                            showFeedbackModal: !showFeedbackModal,
                            currentFeedback: null
                        })
                    }>
                    <div className={css(this.styles.buttonWrapperNote)}>
                        <div className={css(this.styles.noteModalWrapper)}>
                            <p className={css(this.styles.descriptionModalLabel)}>
                                {intl.formatMessage({ id: 'form.feedback' })}
                            </p>
                            <p className={css(this.styles.descriptionModalNote)}>
                                {currentFeedback && currentFeedback.body}
                            </p>
                            <div className={css(this.styles.descriptionModalNoteRow)}>
                                <div style={{ width: '50%' }}>
                                    <p className={css(this.styles.descriptionModalLabel)}>
                                        {intl.formatMessage({ id: 'form.user' })}
                                    </p>
                                    <p
                                        onClick={() =>
                                            this.props.history.push(`/app/users/display/${currentFeedback.userUUID}`)
                                        }
                                        className={css(this.styles.descriptionModalNoteUser)}>
                                        {currentFeedback &&
                                        currentFeedback.userFirstName &&
                                        currentFeedback.userLastName &&
                                        currentFeedback.userFirstName.length > 0 &&
                                        currentFeedback.userLastName.length > 0
                                            ? `${currentFeedback.userFirstName} ${currentFeedback.userLastName}`
                                            : intl.formatMessage({ id: 'form.na' })}
                                    </p>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <p className={css(this.styles.descriptionModalLabel)}>
                                        {intl.formatMessage({ id: 'comp.table.header.date' })}
                                    </p>
                                    {currentFeedback && currentFeedback.dateSubmitted && <p className={css(this.styles.descriptionModalNote)}>
                                        {UTILS.dateFormatter(currentFeedback.dateSubmitted, authUser, site)}
                                    </p>}
                                </div>
                            </div>
                            {currentFeedback && currentFeedback.deviceParsed && (
                                <div className={css(this.styles.descriptionModalNoteRow)}>
                                    {currentFeedback.deviceParsed && currentFeedback.deviceParsed.platform && (
                                        <div style={{ width: '50%' }}>
                                            <p className={css(this.styles.descriptionModalLabel)}>
                                                {intl.formatMessage({ id: 'form.platform' })}
                                            </p>
                                            <p className={css(this.styles.descriptionModalNote)}>
                                                {currentFeedback.deviceParsed && currentFeedback.deviceParsed.platform}
                                            </p>
                                        </div>
                                    )}
                                    {currentFeedback.deviceParsed && currentFeedback.deviceParsed.appVersion && (
                                        <div style={{ width: '50%' }}>
                                            <p className={css(this.styles.descriptionModalLabel)}>
                                                {intl.formatMessage({
                                                    id: 'comp.footer.version'
                                                })}
                                            </p>
                                            <p className={css(this.styles.descriptionModalNote)}>
                                                {currentFeedback.deviceParsed &&
                                                    currentFeedback.deviceParsed.appVersion}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                marginTop: 40
                            }}>
                            <Button
                                width={140}
                                load={false}
                                action={() =>
                                    this.setState({
                                        showFeedbackModal: !showFeedbackModal,
                                        currentFeedback: null
                                    })
                                }
                                text={'pages.users.dismissTraining'}
                                margin={'0'}
                                padding={'10px 20px'}
                            />
                        </div>
                    </div>
                </Modal>
                <Header Icon={UserFeedbackIcon} iconSize={38} title={'pages.feedback.feedbackRecent'} />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Table
                        tableHeight={150}
                        data={facilityFeedback ? facilityFeedback : []}
                        action={(e) => this.handleFeedbackAction(e)}
                        header={feedbackHeaders}
                        Cell={FeedbackCell}
                        itemSize={35}
                    />
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme, width, facilityFeedback, userTimezone, feedbackHeaders } = app;
    const { authUser, site } = auth;
    return { theme, authUser, site, width, facilityFeedback, userTimezone, feedbackHeaders };
};

export default withRouter(injectIntl(connect(mapStateToProps, { 
    fetchFacilityFeedback, 
    setFeedbackHeaders 
})(DashboardFacilityFeedback)));

const styles = (theme, width) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 5,
            fontSize: 20
        },
        descriptionModalNote: {
            color: theme.color.fontColor,
            textTransform: 'capitalize',
            fontSize: 14
        },
        descriptionModalNoteUser: {
            color: theme.color.fontColor,
            textTransform: 'capitalize',
            fontSize: 14,
            opacity: 1,
            ':hover': {
                opacity: 0.8,
                cursor: 'pointer'
            }
        },
        iconWrapperModalNote: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        buttonWrapperNote: {
            display: 'block',
            padding: 25,
            //margin: '0 auto'
        },
        noteModalWrapper: {
            textAlign: 'left'
        },
        descriptionModalLabel: {
            color: theme.color.themeColor,
            fontSize: width > 475 ? 16 : 12,
            marginBottom: 0
        },
        titleModalNote: {
            color: theme.color.fontColor,
            textAlign: 'left',
            fontSize: width > 475 ? 22 : 16,
            margin: '0 0 0 15px'
        },
        descriptionModalNoteRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    });
