//||||||||||||||||||||||||||||||||||||||||
//|| ZONE LIST                          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ZONE_LIST = '/zones/';
export const PURPOSE_ZONE_LIST = 'Fetch a list of zones for a site.';
export const PERMISSIONS_ZONE_LIST = [];
export const REQUIREMENTS_ZONE_LIST = ['Token Required'];
export const PARAMETER_ZONE_LIST = {
    companyId: 0
};
export const RETURN_ZONE_LIST = {
    zones: [
        {
            Id: 0,
            Name: '',
            SiteId: 0,
            Units: []
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| ZONE SAVE                          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ZONE_SAVE = '/zone/save/';
export const PURPOSE_ZONE_SAVE = 'Save a zone.';
export const PERMISSIONS_ZONE_SAVE = [];
export const REQUIREMENTS_ZONE_SAVE = ['Token Required'];
export const PARAMETER_ZONE_SAVE = {
    companyId: 0,
    id: 0,
    name: '',
    unitIds: [0, 1]
};
export const RETURN_ZONE_SAVE = {
    Id: 0,
    Name: '',
    SiteId: 0,
    Units: [
        {
            User: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            },
            accessType: '',
            companyId: 0,
            details: '',
            detailsDepth: 0,
            detailsPrice: 0,
            detailsWidth: 0,
            externalId: '',
            id: 0,
            jsonType: '',
            lastUpdated: '',
            locks: [
                {
                    bleAppVersion: '',
                    bleHwVersion: '',
                    companyId: 0,
                    gatewayId: 0,
                    gwDelinquent: 0,
                    hwState: '',
                    hwType: '',
                    id: 0,
                    mac: '',
                    name: '',
                    offlineKey: '',
                    siteId: 0,
                    syncFlag: '',
                    time: '',
                    unlockCmd: '',
                    voltageBattery: '',
                    voltageWired: '',
                    wpAppVersion: '',
                    wpOtapVersion: '',
                    wpStackVersion: ''
                }
            ],
            name: '',
            rentalState: '',
            siteId: 0
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| ZONE DELETE                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ZONE_DELETE = '/zone/delete/';
export const PURPOSE_ZONE_DELETE = 'Delete a zone.';
export const PERMISSIONS_ZONE_DELETE = [];
export const REQUIREMENTS_ZONE_DELETE = ['Token Required'];
export const PARAMETER_ZONE_DELETE = {
    companyId: 0,
    id: 0
};
export const RETURN_ZONE_DELETE = {
    result: 'success'
};
