import React from 'react';
import { StyleSheet, css } from 'aphrodite';
class Logo extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { alt, src, height, padding } = this.props;
        this.styles = styles(height, padding);
        return (
            <div className={css(this.styles.imageWrapper)}>
                <img alt={alt} src={src} className={css(this.styles.image)} />
            </div>
        );
    }
}

Logo.defaultProps = {
    padding: '10px 0px',
    height: 60,
    alt: 'SMART ENTRY',
    src: 'assets/images/Themes/Demo/logo.png'
};

export default Logo;

const styles = (height, padding) =>
    StyleSheet.create({
        imageWrapper: {
            width: '100%',
            padding,
            height
        },
        image: {
            display: 'block',
            margin: '0 auto',
            height: '100%'
        }
    });
