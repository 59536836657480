import React from 'react';
import { history } from '../../store/history';

function withGAHistoryTrack(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
        }

        componentDidMount() {
            const { location } = history;
            const page = location.pathname + location.search;
            if (typeof window.ga === 'function') {
                window.ga('set', 'page', page);
                window.ga('send', 'pageview');
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default withGAHistoryTrack;
