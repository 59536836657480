import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
class UsersCellLoitering extends React.Component {
    render() {
        const { intl, data, authUser, site, userTimezone } = this.props;
        const { firstName, lastName, onSiteUpdated, type } = data;
        return (
            <>
                <CellText
                    Title={`${firstName} ${lastName}`}
                    Description={intl.formatMessage({ id: `db.${type}` })}
                    textAlign={'left'}
                    TitleColor={true}
                />
                <CellText
                    textAlign={'right'}
                    Cellwidth={'145px'}
                    TitleSize={11}
                    Title={UTILS.dateTimeFormatter(onSiteUpdated, authUser, site, userTimezone)}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { authUser, site } = auth;
    const { userTimezone } = app;
    return { authUser, site, userTimezone };
};

export default injectIntl(connect(mapStateToProps)(UsersCellLoitering));
