import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchSiteMap = () => {
    return {
        type: CONSTANTS.FETCH_SITE_MAP,
        payload: {
            url: ENDPOINTS.ENDPOINT_FETCH_SITE_MAP,
            data: {}
        }
    };
};

export const setSiteMap = (data) => {
    return {
        type: CONSTANTS.SET_SITE_MAP,
        payload: data
    };
};

export const uploadSiteMap = (data) => {
    return {
        type: CONSTANTS.UPLOAD_SITE_MAP,
        payload: {
            url: ENDPOINTS.ENDPOINT_UPLOAD_SITE_MAP,
            data
        }
    };
};

export const editSiteMap = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_MAP,
        payload: {
            url: ENDPOINTS.ENDPOINT_EDIT_SITE_MAP,
            data
        }
    };
};

export const deleteSiteMap = (data) => {
    return {
        type: CONSTANTS.DELETE_SITE_MAP,
        payload: {
            url: ENDPOINTS.ENDPOINT_DELETE_SITE_MAP,
            data
        }
    };
};

export const setMap = (data) => {
    return {
        type: CONSTANTS.SET_MAP,
        payload: data
    };
};

export const editSiteMapData = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_MAP_DATA,
        payload: {
            url: ENDPOINTS.ENDPOINT_EDIT_SITE_MAP_DATA,
            data
        }
    };
};

export const createShapeSiteMap = (data) => {
    return {
        type: CONSTANTS.CREATE_SHAPE_SITE_MAP,
        payload: {
            url: ENDPOINTS.ENDPOINT_CREATE_SHAPE_SITE_MAP,
            data
        }
    };
};

export const uploadSiteMapSvg = (data) => {
    return {
        type: CONSTANTS.UPLOAD_SITE_MAP_SVG,
        payload: {
            url: '/site/map/upload/file/',
            data
        }
    };
};

export const downloadSiteMapSvg = (mapUUID) => {
    return {
        type: CONSTANTS.DOWNLOAD_SITE_MAP_SVG,
        payload: {
            url: '/site/map/download/',
            data: {
                mapUUID
            }
        }
    };
};
