import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
class OutageDisplay extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, app } = this.props;
        this.styles = styles();
        return (
            <>
                <div className={app ? css(this.styles.outageApp) : css(this.styles.outageSignin)}>
                    <div style={{ opacity: 1 }}>
                        <i className="material-icons" style={{ color: 'white', marginRight: 5 }}>
                            error
                        </i>
                        {intl.formatMessage({ id: 'comp.button.outageBanner' })}
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(OutageDisplay));

const styles = () =>
    StyleSheet.create({
        outageSignin: {
            position: 'absolute',
            top: 0,
            width: '100vw',
            minHeight: 10,
            background: '#FF1919',
            opacity: 0.8,
            padding: '5px 5vw',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 14,
            zIndex: 999
        },
        outageApp: {
            position: 'absolute',
            bottom: 0,
            width: '100vw',
            minHeight: 10,
            background: '#FF1919',
            opacity: 0.8,
            padding: '5px 5vw',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 14,
            zIndex: 999
        }
    });
