import * as CONSTANTS from 'constants';

const INIT_STATE = {
    gatewayList: null,
    gatewayObject: null,
    gatewayImage: null,
    commandStats: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_GATEWAY_LIST: {
            return {
                ...state,
                gatewayList: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_IMAGE: {
            return {
                ...state,
                gatewayImage: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_DETAILS: {
            return {
                ...state,
                gatewayObject: action.payload
            };
        }
        default:
            return state;
    }
};
