//||||||||||||||||||||||||||||||||||||||||
//|| ACCESS CODE LIST                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACCESS_CODES_LIST = '/support/accesscodes/';
export const PURPOSE_ACCESS_CODES_LIST = 'Fetch a list of access codes.';
export const PERMISSIONS_ACCESS_CODES_LIST = [];
export const REQUIREMENTS_ACCESS_CODES_LIST = ['Token Required'];
export const PARAMETER_ACCESS_CODES_LIST = {
    siteId: 0
};
export const RETURN_ACCESS_CODES_LIST = {
    data: []
};

//||||||||||||||||||||||||||||||||||||||||
//|| ACCESS CODE DETAILS                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACCESS_CODES_DETAILS = '/support/accesscodes/details/';
export const PURPOSE_ACCESS_CODES_DETAILS = 'Fetch details for an access codes.';
export const PERMISSIONS_ACCESS_CODES_DETAILS = [];
export const REQUIREMENTS_ACCESS_CODES_DETAILS = ['Token Required'];
export const PARAMETER_ACCESS_CODES_DETAILS = {
    accessCodeId: 0
};
export const RETURN_ACCESS_CODES_DETAILS = {
    data: {}
};

//||||||||||||||||||||||||||||||||||||||||
//|| ACCESS CODE EDIT                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACCESS_CODES_EDIT = '/user/accesscode/edit/';
export const PURPOSE_ACCESS_CODES_EDIT = 'Edit entry.';
export const PERMISSIONS_ACCESS_CODES_EDIT = [];
export const REQUIREMENTS_ACCESS_CODES_EDIT = ['Token Required'];
export const PARAMETER_ACCESS_CODES_EDIT = {
    userId: 0,
    unitId: 0,
    accessCode: ''
};
export const RETURN_ACCESS_CODES_EDIT = {
    result: 'success'
};
