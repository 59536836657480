import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import { CheckmarkIcon, WarningIcon, CloseIcon } from 'utilities/icons';

class TestCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            requests: null,
            showCategoryStatus: false,
        };
    }
    componentDidMount () {
        const { requests } = this.state;
        const { tests, funcRef } = this.props;
        if (requests || !tests) return;
        this.setState({requests: tests.map(test => ({
            status: 'none', 
            action: test.action, 
            response: null,
        }))});

        if (funcRef && !funcRef.current) {
            funcRef.current = this.runAllTests;
        }
    }
    updateStatus = (i, status) => {
        let requests = [...this.state.requests];
        requests[i].status = status;
        this.setState({ requests });
    }
    updateResponse = (i, response) => {
        let requests = [...this.state.requests];
        requests[i].response = response;
        this.setState({ requests });
    }
    startReq = async (i, runAll) => {
        const { requests } = this.state;
        this.updateStatus(i, 'pending');
        !runAll && this.setState({showCategoryStatus: false});
        let req;
        try {
            req = await this.state.requests[i].action();
        } catch (e) {
            this.updateStatus(i, 'rejected');
            console.error('Catch when trying to perform test action.');
            console.error(e);
        }
        if (req && req.result !== 'success') this.updateStatus(i, 'rejected');
        if (req && req.result === 'success') this.updateStatus(i, 'fulfilled');
        this.updateResponse(i, req);
        runAll && i + 1 !== requests.length && this.startReq(i + 1, true);
        !this.checkTestsCompleted() && this.setState({showCategoryStatus: true});
    }
    runAllTests = (e) => {
        e && e.stopPropagation();
        this.setState({showCategoryStatus: false});
        this.startReq(0, true);
    }
    getTestsLength = () => {
        const { tests } = this.props;
        return tests && tests.filter(test => test.if || test.if === undefined).length;
    }
    checkTestsCompleted = () => {
        const { requests } = this.state;
        // return number of tests that have not been completed. if 0, it means all are completed.
        return requests.filter(req => {
            return req.response === null;
        }).length;
    }
    checkTestsFailing = () => {
        const { requests } = this.state;
        // check if at least one test is failing.
        return requests && requests.filter((req) => {
            // this might be different if you're doing a non req test? 
            return !(req && req.response && req.response.test && req.response.test.result.status === 'pass');
        }).length;
    }
    checkTestsPassing = () => {
        const { requests } = this.state;
        return requests && requests.filter((req) => {
            return req && req.response && req.response.test && req.response.test.result.status === 'pass';
        }).length;
    }
    checkTestsWarning = () => {
        const { requests } = this.state;
        return requests && requests.filter((req) => {
            return req && req.response && req.response.test && req.response.test.warnings && req.response.test.warnings.length;
        }).length;
    }
    toggleCollapse = () => {
        this.setState({collapsed: !this.state.collapsed});
    }
    
    render() {
        const { theme, title, tests, condition, allowIndividualTests } = this.props;
        const { requests, showCategoryStatus, collapsed } = this.state;
        this.styles = styles(theme, showCategoryStatus, this.checkTestsFailing, this.checkTestsWarning);

        return (
            !condition ? null : tests && tests.filter(test => test.if === undefined || test.if).length > 0 && <div className={css(this.styles.wrapper)}>
                <div className={css(this.styles.categoryWrapper)} onClick={this.toggleCollapse}>
                    <div className={css(this.styles.flexLeft)}>
                        <div className={css(this.styles.categoryTitle)}>{title}</div>
                    </div>
                    <div className={css(this.styles.flexRight)}>
                        <div className={css(this.styles.categoryScore)}>{this.checkTestsPassing()}/{tests && this.getTestsLength()}</div>
                        <div onClick={this.runAllTests} className={css(this.styles.runAllTestsBtn)}>
                            <span className="material-icons">play_circle_outline</span>
                        </div>
                    </div>
                </div>
                {!collapsed && tests && requests && tests.map((test, i) => (test.if === undefined || test.if) && <div className={css(this.styles.testItem)} key={title+'test'+i}>
                    <div className={css(this.styles.testItemTitle)}>
                        <div>{(requests[i].status === 'none' || requests[i].status === 'pending') ? <div className={css(this.styles.statusCircle)}></div> : 
                            requests[i].response && requests[i].response.test.result && requests[i].response.test.result.status === 'pass' ? 
                            <div className={css(this.styles.statusCircle, this.styles.statusPass)}>
                                <CheckmarkIcon
                                    width={'50%'}
                                    height={'50%'}
                                    stroke={theme && theme.color.buttonFontColor}
                                    strokeWidth={1.5}
                                />
                            </div> : 
                            <div className={css(this.styles.statusCircle, this.styles.statusFail)}>
                                <CloseIcon
                                    width={'50%'}
                                    height={'50%'}
                                    fill={theme && theme.color.buttonFontColor}
                                />
                            </div>}
                        </div>
                        <div className={css(this.styles.testName)}>{test.name}</div>
                        {allowIndividualTests && <div className={css(this.styles.runTestBtn)} onClick={() => this.startReq(i)}>Run test</div>}

                    </div>
                    <div className={css(this.styles.testData)}>
                        <div>{test.description}</div>
                        <div className={css(this.styles.stepContainer)}>{test.steps && test.steps.map((step, i) => 
                            <div className={css(this.styles.stepItem)} key={'teststep'+i}>- {step}</div>)}
                        </div>
                        <div>{test.endpoint}</div>
                        {requests[i].response && requests[i].response.test && <div>
                            {requests[i].response.test.result.errorMessage && <div className={css(this.styles.testErrorMessage)}>{requests[i].response.test.result.errorMessage}</div>}
                            {requests[i].response && requests[i].response.result === 'success' && requests[i].response.test.warnings && requests[i].response.test.warnings.map((warning, i) => <div className={css(this.styles.warningMessage)} key={'testwarning' + i}>
                                <WarningIcon width={13} height={13} fill={'orange'} />
                                <div style={{paddingTop: 1}}>{warning}</div>
                            </div>)}
                        </div>}

                    </div>
                </div>)}
            </div>
        );
    }
}

TestCategory.defaultProps = {
    condition: true,
    allowIndividualTests: true,
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(withRouter(connect(mapStateToProps, {})(TestCategory)));

const styles = (theme, showCategoryStatus, checkTestsFailing, checkTestsWarning) =>
    StyleSheet.create({
        wrapper: {},
        categoryWrapper: {
            cursor: 'pointer',
            borderBottom: `1px solid ${theme && theme.color.borderColor}`,
            padding: '10px 5px 5px 5px',
            display: 'flex',
            justifyContent: 'space-between',
            ':hover': {
                backgroundColor: theme && theme.color && theme.color.name && theme.color.name.includes('dark') ?
                    'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.01)'
            }
        },
        categoryTitle: {
            fontSize: 15,
            fontWeight: 'bold',
            color: showCategoryStatus && checkTestsFailing() && 'red'
        },
        categoryStatus: {
            fontSize: 12,
        },

        flexLeft: {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
        },
        flexRight: {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
        },
        categoryScore: {
            color: showCategoryStatus && (checkTestsFailing() ? 'red' : checkTestsWarning() && 'orange')
        },

        runAllTestsBtn: {
            color: theme && theme.color.themeColor,
            ':hover': {
                opacity: 0.8,
            }
        },

        testItem: {
            paddingTop: 8,
        },
        testItemTitle: {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            position: 'relative'
        },

        testData: {
            borderLeft: `2px solid ${theme && theme.color.borderColor}`,
            paddingLeft: 12,
            marginLeft: 6,
            margin: '3px 0 3px 6px',
            paddingTop: 1,
            paddingBottom: 1,
            fontSize: 11,
        },
        stepContainer: {
        },
        stepItem: {
            marginLeft: 10,
        },

        statusCircle: {
            width: 15,
            height: 15,
            borderRadius: 8,
            display: 'grid',
            placeItems: 'center',
            backgroundColor: theme && theme.color.borderColor,

        },
        statusFail: {
            backgroundColor: 'red',
        },
        statusPass: {
            backgroundColor: 'green',
        },

        testName: {
            paddingRight: 60,
        },
        runTestBtn: {
            backgroundColor: theme && theme.color.themeColor,
            color: theme && theme.color.buttonFontColor,
            padding: '3px 6px',
            borderRadius: 5,
            width: 55,
            textAlign: 'center',
            cursor: 'pointer',
            marginTop: 2,
            fontSize: 11,
            position: 'absolute',
            right: 0,
            ':hover': {
                opacity: 0.8,
            }
        },
        testErrorMessage: {
            color: 'red',
            marginTop: 5,
        },
        warningMessage: {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            marginTop: 5,
        },
    });
