import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Expand from 'react-expand-animated';
import Table from 'components/Table';
import Cell from 'components/Table/cell/containers/reports/suspiciousGeneric';

class DashboardSuspiciousActivityET extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            suspiciousActivityHeaders: [
                {
                    id: 0,
                    title: 'comp.activity.type',
                    active: false,
                    order: true,
                    mobile: true,
                    style: { textAlign: 'left' }
                },
                {
                    id: 1,
                    title: 'comp.table.header.user',
                    active: false,
                    order: true,
                    mobile: true,
                    style: { textAlign: 'left' }
                },
                {
                    id: 2,
                    title: 'comp.table.header.unit',
                    active: false,
                    order: true,
                    mobile: true,
                    style: { textAlign: 'left' }
                },
                {
                    id: 3,
                    title: 'comp.table.header.date',
                    active: false,
                    order: true,
                    mobile: true,
                    style: { textAlign: 'right' }
                }
            ]
        }
    }
    render() {
        const { intl, theme, report } = this.props;
        const { open, suspiciousActivityHeaders } = this.state;
        return (
            <>
                <div
                    style={
                        open
                        ?
                        {
                            background:
                            theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                                ? 'rgba(255,255,255,0.15)'
                                : theme && theme.color && theme.color.name && theme.color.name.includes('light1')
                                ? '#fcfcfc'
                                : theme && theme.color && theme.color.name && theme.color.name.includes('light2')
                                ? '#e7e7ec'
                                : '#f9f9f9',
                            textDecoration: 'none',
                            filter: 'drop-shadow(0 2px 10px 2px #ddd)',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    }
                    onClick={() => report.reports.length > 0 && this.setState({open: !this.state.open})}>
                    <Row style={{borderBottom: `solid 1px ${theme.color.borderColor}`, padding: '5px 0px', margin: '0px'}}>
                        <Col xs={2}>
                            {report.site.name}
                        </Col>
                        <Col xs={2}>
                            {report.eventCount == 1 ? `1 ${intl.formatMessage({ id: 'form.event' })}` : `${report.eventCount} ${intl.formatMessage({ id: 'form.events' })}`}
                        </Col>
                        <Col xs={8}>
                        </Col>
                    </Row>
                </div>
                <Expand open={open}>
                    <div style={{padding: '0px 17px'}}>
                        <Table
                            data={report.reports}
                            action={this.handleTableAction}
                            header={suspiciousActivityHeaders}
                            Cell={Cell}/>
                    </div>
                </Expand>
            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, { })(
            DashboardSuspiciousActivityET
        )
    )
);