import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import Draggable from 'react-draggable';
import TestCategory from 'components/TestSuite/category';
import usersTests from './tests/users';
import messagesTests from './tests/messages';
import loginTests from './tests/login';
import unlocksTests from './tests/unlocks';
import * as ENDPOINTS from 'constants/endpoints';
import { switchSite, userSignIn } from 'actions/Auth';
import { setSwitcherLoader } from 'actions/App';
import cuid from 'cuid';

import { setTestSuite } from 'actions/App';
import { BugIcon, UserIcon } from 'utilities/icons';

import { fadeIn } from 'react-animations';

// functions as env file
const usersFile = './users.js';

// import file without it not having to exist
const dynamicLoad = (file, cb) => {
    // not sure why, but adding webpackIgnore: true makes it looks in /assets/js/
    import(/*webpackIgnore: true*/`${file}`)
    .then((res) => {
        cb(res && res.default, null);
    })
    .catch((error) => {
        cb(null, error);
        console.error("Couldn't load file - ", error);
    });
};

class TestSuite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userAccounts: null,
            showQuickLogin: false,
        };
    }
    // if a way can be made so that these are automatically made, that would be great.
    usersRef = React.createRef();
    messagesRef = React.createRef();
    loginRef = React.createRef();

    componentDidMount () {
        dynamicLoad(usersFile, (data, error) => {
            if (error || !data) return;
            const { accounts } = data;
            this.setState({ userAccounts: accounts });
        })
    }

    isOnLocalhost = () => {
        if (!location) return false;
        return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    };
    goToTestComponentsPage = () => {
        this.props.history.push('/app/testing');
    }
    runAllCategories = () => {
        // current gets assigned to be runAllTests() in the child TestCategory
        const USER_DATA = JSON.parse(sessionStorage.getItem('user_data'));

        USER_DATA && this.usersRef.current();
        USER_DATA && this.messagesRef.current();
        !USER_DATA && this.loginRef.current();
    }
    closeTestSuite = (e) => {
        e.stopPropagation();
        this.props.setTestSuite(false);
    }

    quickLogin = (user) => {
        const { userSignIn } = this.props;
        
        const reqObject = {
            companyUUID: "-1",
            deviceUUID: cuid(),
            email: user.email || null,
            phone: user.phone || null,
            countryCode: user.countryCode || null,
            password: user.password,
        };
        userSignIn(reqObject);

        // if you comment out the following line, it *almost* lets you switch accounts on the spot while being logged in,
        // but there's some problems with the theme / localStorage updating it seems like.
        this.setState({showQuickLogin: false});
    }
    checkUserLoginEnv = (user) => {
        const URL_SET = sessionStorage.getItem('nse_url');
        if (!URL_SET) return false;
        if (user.env === 'dev' && URL_SET.includes('dev')) return true;
        if (user.env === 'run' && URL_SET.includes('run')) return true;
        if (user.env === 'prod' && URL_SET.includes('com')) return true;
        return false;
    }

    render() {
        const { userAccounts, showQuickLogin } = this.state;
        const { theme, switchSite, setSwitcherLoader, intl } = this.props;
        this.styles = styles(theme);

        const USER_DATA = JSON.parse(sessionStorage.getItem('user_data'));
        const URL_SET = sessionStorage.getItem('nse_url');

        return ( this.isOnLocalhost() && 
            <Draggable>
                <div className={css(this.styles.wrapper, this.styles.fadeIn)}>
                    <div className={css(this.styles.testSuiteHeader)}>
                        <div className={css(this.styles.testSuiteIcon)}>
                            <BugIcon 
                                stroke={theme.color.buttonFontColor} 
                                strokeWidth={20}
                                height={'70%'}
                                width={'70%'} />
                        </div>
                        {USER_DATA ? <div className={css(this.styles.headerTextWrapper)}>
                            <div className={css(this.styles.headerTopText)}>Logged in as</div>
                            <div className={css(this.styles.headerMainText)}>{USER_DATA.firstName} {USER_DATA.lastName}</div>
                            <div className={css(this.styles.headerBottomText)}>{USER_DATA.role.name} - Tier {USER_DATA.role.tier}</div>
                        </div> : 
                        <div className={css(this.styles.headerTextWrapper)}>
                            <div className={css(this.styles.headerMainText)}>Not Logged In</div>
                            <div className={css(this.styles.headerBottomText)}>Log in to access other tests</div>
                        </div>}
                    </div>
                    <div className={css(this.styles.closeIcon)} onClick={(e) => this.closeTestSuite(e)}><span style={{fontSize: 15,}} className="material-icons">close</span></div>
                    {!showQuickLogin && <div className={css(this.styles.testsWrapper)}>
                        {/* when adding a new category, you need to add a ref for it and run current() in the mount */}
                        {userAccounts && userAccounts.length && <TestCategory
                            title={'Check Validate and Login'}
                            condition={!USER_DATA}
                            funcRef={this.loginRef}
                            tests={loginTests({
                                userAccounts,
                                checkUserLoginEnv: this.checkUserLoginEnv,
                            })} />}
                        <TestCategory 
                            title={'Users Page'} 
                            condition={USER_DATA}
                            funcRef={this.usersRef}
                            tests={usersTests()} />
                        <TestCategory
                            title={'Messages'}
                            condition={USER_DATA}
                            funcRef={this.messagesRef}
                            tests={messagesTests()} />
                        <TestCategory
                            title={'Unlocks - Unit Test Facility'}
                            condition={USER_DATA && URL_SET === ENDPOINTS.URL_BACKEND_DEV} // only show if logged in and on dev
                            allowIndividualTests={false}
                            tests={unlocksTests({
                                switchSite, 
                                setSwitcherLoader,
                                switcherText: intl.formatMessage({ id: 'comp.button.switchingSite' })
                            })} />
                    </div>}
                    {showQuickLogin && <div className={css(this.styles.quickLoginArea)}>
                        {userAccounts && userAccounts.map((user, i) => 
                            this.checkUserLoginEnv(user) && <div className={css(this.styles.quickLoginUser)} key={'userTestAccountItem' + i}>
                                <div className={css(this.styles.flexQuickLogin)}>
                                    <div className={css(this.styles.quickLoginUserIcon)}><UserIcon width={15} height={15} color={theme && theme.color.buttonFontColor} /></div>
                                    <div>
                                        <div className={css(this.styles.quickLoginUserName)}>{user.email || (user.phone ? user.countryCode + user.phone : 'No email or phone')}</div>
                                        <div className={css(this.styles.quickLoginUserType)}>{user.type}</div>
                                    </div>
                                </div>
                                <button onClick={() => this.quickLogin(user)} className={css(this.styles.quickLoginUserLogin)}>Log in</button>
                            </div>
                        )}
                    </div>}
                    {USER_DATA && <div className={css(this.styles.testSuiteFooter)} onClick={this.goToTestComponentsPage}>Test Components</div>}
                    {!USER_DATA && userAccounts && <div className={css(this.styles.testSuiteFooter)} onClick={() => this.setState({showQuickLogin: !showQuickLogin})}>{!showQuickLogin ? 'Quick Log In' : 'View Tests'}</div>}
                    {!showQuickLogin && <div className={css(this.styles.testSuiteRunAll)} onClick={this.runAllCategories}>Run All Tests</div>}
                </div>
            </Draggable>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(withRouter(connect(mapStateToProps, {
    setTestSuite, 
    switchSite, 
    setSwitcherLoader,
    userSignIn,
})(TestSuite)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme && theme.color.headerColor,
            color: theme && theme.color.fontColor,
            border: `1px solid ${theme && theme.color.borderColor}`,
            right: 20,
            top: 70,
            width: 350,
            height: 600,
            position: 'fixed',
            borderRadius: 5,
            zIndex: 999999,
            boxShadow: '3px 3px 10px 1px rgba(0,0,0,0.07)',
            overflow: 'hidden',
        },
        testSuiteHeader: {
            background: theme && theme.color.themeColor,
            color: theme && theme.color.buttonFontColor,
            fontFamily: 'Poppins', 
            height: 90,
            display: 'grid', 
            alignItems: 'center',
            gridTemplateColumns: 'auto 1fr',
        },
        testSuiteIcon: {
            width: 70,
            height: 70,
            backgroundColor: 'rgba(0,0,0,0.1)',
            display: 'grid',
            placeItems: 'center',
            borderRadius: 100,
            margin: '0 3px 0 13px'
        },
        headerTextWrapper: {
            paddingLeft: 10,
        },
        headerTopText: {
            fontSize: 12,
            position: 'relative',
            bottom: -4,
        },
        headerMainText: {
            fontSize: 23,
            fontWeight: 600,
            whiteSpace: 'nowrap',
        },
        headerBottomText: {
            fontSize: 14,
            position: 'relative',
            bottom: 4,
            whiteSpace: 'nowrap',
        },
        testsWrapper: {
            padding: 10,
            height: 510, // change this maybe
            overflowY: 'auto',
            paddingTop: 0,
            paddingBottom: 70,
        },
        testSuiteFooter: {
            // height: 60,
            background: theme && theme.color.pageColor,
            color: theme && theme.color.fontColor,
            fontFamily: 'Poppins',
            fontSize: 12,
            textAlign: 'center',
            cursor: 'pointer',
            position: 'absolute',
            right: 15,
            bottom: 15,
            padding: '10px 15px',
            borderRadius: 5,
            opacity: 0.9,
            ':hover': {
                opacity: 1,
            }
        },
        testSuiteRunAll: {
            background: theme && theme.color.themeColor,
            color: theme && theme.color.buttonFontColor,
            fontFamily: 'Poppins',
            fontSize: 12,
            textAlign: 'center',
            cursor: 'pointer',
            position: 'absolute',
            left: 15,
            bottom: 15,
            padding: '10px 15px',
            borderRadius: 5,
            opacity: 0.9,
            ':hover': {
                opacity: 1,
            }
        },

        quickLoginArea: {
            height: 510,
            padding: '0px 10px 70px',
        },
        quickLoginUser: {
            padding: '7px 0',
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme && theme.color.borderColor}`,
        },
        flexQuickLogin: {
            display: 'flex',
            gap: 10,
            alignItems: 'center',
        },
        quickLoginUserIcon: {
            background: theme && theme.color.themeColor,
            borderRadius: 25,
            width: 27,
            height: 27,
            display: 'grid', 
            placeItems: 'center',
        },
        quickLoginUserName: {
            fontSize: 13,
        },
        quickLoginUserType: {
            fontSize: 11,
        },
        quickLoginUserLogin: {
            background: theme && theme.color.themeColor,
            borderRadius: 5,
            padding: '3px 7px',
            color: theme && theme.color.buttonFontColor,
            fontSize: 12,
        },

        closeIcon: {
            position: 'absolute',
            top: 5,
            right: 8,
            color: theme && theme.color.buttonFontColor,
            cursor: 'pointer',
        },

        fadeIn: {
            animationName: fadeIn,
            animationDuration: '0.2s'
        }
    });
