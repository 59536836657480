//||||||||||||||||||||||||||||||||||||||||
//|| COMPANY LIST                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_COMPANY_LIST = '/support/companies/';
export const PURPOSE_COMPANY_LIST = 'Fetch company list and all thier sites.';
export const PERMISSIONS_COMPANY_LIST = ['support_view_all_companies'];
export const REQUIREMENTS_COMPANY_LIST = ['Token Required'];
export const PARAMETER_COMPANY_LIST = {};
export const RETURN_COMPANY_LIST = {
    companies: [
        {
            name: ''
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| CREATE COMPANY                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CREATE_COMPANY = '/company/setup/';
export const PURPOSE_CREATE_COMPANY = 'Create company.';
export const PERMISSIONS_CREATE_COMPANY = [];
export const REQUIREMENTS_CREATE_COMPANY = ['Token Required'];
export const PARAMETER_CREATE_COMPANY = {
    id: 0,
    name: '',
    frontendHost: '',
    email: ''
};
export const RETURN_CREATE_COMPANY = {};

//||||||||||||||||||||||||||||||||||||||||
//|| EDIT COMPANY                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_EDIT_COMPANY = '/company/setup/';
export const PURPOSE_EDIT_COMPANY = 'Create company.';
export const PERMISSIONS_EDIT_COMPANY = [];
export const REQUIREMENTS_EDIT_COMPANY = ['Token Required'];
export const PARAMETER_EDIT_COMPANY = {
    id: 0,
    name: '',
    frontendHost: '',
    email: ''
};
export const RETURN_EDIT_COMPANY = {};

//||||||||||||||||||||||||||||||||||||||||
//|| EDIT COMPANY APP COLOR             ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_EDIT_COMPANY_APP_COLOR = '/company/appcolor/';
export const PURPOSE_EDIT_COMPANY_APP_COLOR = 'Edit a app color for a company.';
export const PERMISSIONS_EDIT_COMPANY_APP_COLOR = [];
export const REQUIREMENTS_EDIT_COMPANY_APP_COLOR = ['Token Required', 'manage_company'];
export const PARAMETER_EDIT_COMPANY_APP_COLOR = {
    appColor: '',
    companyId: 0
};
export const RETURN_EDIT_COMPANY_APP_COLOR = {
    result: 'success'
};
