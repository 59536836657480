import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import ToolTips from 'components/ToolTips';

class DashboardDateSelector extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, theme, width, handleDateSelection, active, widgetId } = this.props;
        this.styles = styles(theme, width, active);
        return (
            <div className={css(this.styles.dateRow)}>
                <div id={`TwentyFourHoursSelector-${widgetId}`}>
                    <p
                        onClick={() => handleDateSelection('day')}
                        className={css(this.styles.footerItem, active === 'day' && this.styles.footerItemActive)}>
                        {intl.formatMessage({ id: 'form.select.last.24.short' })}
                    </p>
                </div>
                <ToolTips target={`TwentyFourHoursSelector-${widgetId}`} message={'comp.sidebar.stats.todayLast'} />
                <div id={`WeekSelector-${widgetId}`}>
                    <p
                        onClick={() => handleDateSelection('week')}
                        className={css(
                            this.styles.footerItem,
                            this.styles.middleFooterItem,
                            active === 'week' && this.styles.footerItemActive
                        )}>
                        {intl.formatMessage({ id: 'form.select.last.7.short' })}
                    </p>
                </div>
                <ToolTips target={`WeekSelector-${widgetId}`} message={'comp.sidebar.stats.weekLast'} />
                <div id={`MonthSelector-${widgetId}`}>
                    <p
                        onClick={() => handleDateSelection('month')}
                        className={css(this.styles.footerItem, active === 'month' && this.styles.footerItemActive)}>
                        {intl.formatMessage({ id: 'form.select.last.30.short' })}
                    </p>
                </div>
                <ToolTips target={`MonthSelector-${widgetId}`} message={'comp.sidebar.stats.monthLast'} />
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(DashboardDateSelector)));

const styles = (theme, width, active) =>
    StyleSheet.create({
        dateRow: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            fontSize: width > 1250 ? 11 : width > 500 ? 10 : 9,
            border: `1px solid ${theme.color.borderColor}`,
            borderRadius: width > 1250 ? 6 : 4,
            overflow: 'hidden',
            height: 'min-content',
            alignItems: 'center',
            textAlign: 'center',
            ':hover': {
                cursor: 'pointer',
            }
        },
        footerItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: width > 1250 ? 26 : 23,
            minHeight: width > 1250 ? 22 : 19,
            margin: 0,
            color: theme.color.fontColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                opacity: 0.8,
                background: theme.color.borderColor,
            }
        },
        footerItemActive: {
            backgroundColor: theme.color.themeColor,
            color: theme.color.buttonFontColor,
            borderColor: theme.color.themeColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                opacity: 0.8,
                backgroundColor: theme.color.themeColor,
            }
        },
        middleFooterItem: {
            borderLeft: `1px solid ${active !== 'day' ? theme.color.borderColor : theme.color.themeColor}`,
            borderRight: `1px solid ${active !== 'month' ? theme.color.borderColor : theme.color.themeColor}`
        }
    });
