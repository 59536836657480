import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_SETTINGS from 'actions/Settings';
import * as ACTIONS_COMPANY from 'actions/Company';

const getSite = (state) => state.auth.site;

function* requestCompanyList({ payload }) {
    try {
        yield put(ACTIONS_COMPANY.setCompanyList(null));
        let companies = yield call(API.POST, payload.url, payload.data);
        if (!companies) {
            yield put(ACTIONS_COMPANY.setCompanyList([]));
        } else {
            yield put(ACTIONS_COMPANY.setCompanyList(companies.data));
            let site = yield select(getSite);
            let company = companies.data[0];
            if (site) {
                company = companies.data.find((item) => item.uuid === site.CompanyUUID);
            } else {
                let user = JSON.parse(sessionStorage.getItem('user_data'));
                let userSite = user.sites.find(
                    (grab) => grab.uuid === JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                );
                company = companies.data.find((item) => item.uuid === userSite.CompanyUUID);
            }
        }
    } catch (error) {
        yield put(ACTIONS_COMPANY.setCompanyList([]));
        console.warn(error);
    }
}

function* requestCreateCompany({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const company = yield call(API.POST, payload.url, payload.data);
        if (!company) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield put(ACTIONS_COMPANY.fetchCompanyList());
            yield put(ACTIONS_SETTINGS.setCompanyCreateData(company.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditCompany({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const company = yield call(API.POST, payload.url, payload.data);
        if (!company) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield history.push('/app/settings/company/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditCompanyAppColor({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const company = yield call(API.POST, payload.url, payload.data);
        if (!company) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchCompanyStats({ payload }) {
    try {
        yield put(ACTIONS_COMPANY.setCompanyStats(null));
        const settings = yield call(API.POST, payload.url, payload.data);
        if (!settings || !settings.data) {
            yield put(ACTIONS_COMPANY.setCompanyStats(null));
        } else {
            yield put(ACTIONS_COMPANY.setCompanyStats(settings.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectCompanyList() {
    yield takeEvery(CONSTANTS.FETCH_COMPANY_LIST, requestCompanyList);
}

export function* selectCompanyCreate() {
    yield takeEvery(CONSTANTS.CREATE_COMPANY, requestCreateCompany);
}

export function* selectCompanyEdit() {
    yield takeEvery(CONSTANTS.EDIT_COMPANY, requestEditCompany);
}

export function* selectCompanyEditAppColor() {
    yield takeEvery(CONSTANTS.EDIT_COMPANY_APP_COLOR, requestEditCompanyAppColor);
}

export function* selectFetchCompanyStats() {
    yield takeEvery(CONSTANTS.FETCH_COMPANY_STATS, requestFetchCompanyStats);
}

export default function* rootSaga() {
    yield all([
        fork(selectCompanyList),
        fork(selectCompanyCreate),
        fork(selectCompanyEdit),
        fork(selectCompanyEditAppColor),
        fork(selectFetchCompanyStats)
    ]);
}
