import React from 'react';
import moment from 'moment';

import CellText from 'components/Table/cell/components/text';
import CellDate from 'components/Table/cell/components/date';
import CellIcon from 'components/Table/cell/components/icon';
import { UnitIcon } from 'utilities/icons';
import { withRouter } from 'react-router-dom';

class LowBatteryCell extends React.Component {
    handleClick = () => {
        this.props.history.push(`/app/units/display/${this.props.data.unitUUID}`)
    }
    render() {
        let now = moment().utc().format();
        const { data, theme } = this.props;
        const { lockName, initiallyReported } = data;
        return (
            <>
                <CellIcon
                        Cellwidth={'45px'}
                        backgroundColor='white'
                        Icon={UnitIcon}
                        fill={theme.color.buttonFontColor}
                    />
                <CellText
                    update={this.handleClick}
                    Title={lockName}
                    textAlign={'left'}
                    TitleColor={true}
                />
                {(initiallyReported && initiallyReported !== '0001-01-01T00:00:00Z') &&
                <CellDate
                    date = {initiallyReported}
                    showTime = {false}
                />}
            </>
        );
    }
}

export default withRouter(LowBatteryCell);
