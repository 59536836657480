import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
class CellRating extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { theme, Cellwidth, rating } = this.props;
        this.styles = styles();
        return (
            <td width={Cellwidth}>
                <div className={css(this.styles.wrapper, this.styles.hover)}>
                    <div className={css(this.styles.button)}>
                        <i className="material-icons" style={{ fontSize: 18, color: theme.color.themeColor }}>
                            {rating && rating >= 1 ? 'star' : 'star_outline'}
                        </i>
                        <i className="material-icons" style={{ fontSize: 18, color: theme.color.themeColor }}>
                            {rating && rating >= 2 ? 'star' : 'star_outline'}
                        </i>
                        <i className="material-icons" style={{ fontSize: 18, color: theme.color.themeColor }}>
                            {rating && rating >= 3 ? 'star' : 'star_outline'}
                        </i>
                        <i className="material-icons" style={{ fontSize: 18, color: theme.color.themeColor }}>
                            {rating && rating >= 4 ? 'star' : 'star_outline'}
                        </i>
                        <i className="material-icons" style={{ fontSize: 18, color: theme.color.themeColor }}>
                            {rating && rating >= 5 ? 'star' : 'star_outline'}
                        </i>
                    </div>
                </div>
            </td>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(connect(mapStateToProps)(CellRating));

const styles = () =>
    StyleSheet.create({
        wrapper: {
            padding: '2px 10px',
            margin: 0,
            maxHeight: 55,
            overflow: 'hidden'
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        button: {
            height: 25,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .2s ease-in-out'
        }
    });
