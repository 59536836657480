import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { UserIcon } from 'utilities/icons';

import CellIcon from 'components/Table/cell/components/iconLink';
import CellText from 'components/Table/cell/components/text';
import CellTextWithPopover from 'components/Table/cell/components/textWithPopover';
class CellSearchResults extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, data, width, theme } = this.props;
        const { userUUID, userFirstName, userLastName, userPhone, userEmail, userRoleName, units, sites } = data;
        return (
            <>
                {width > 800 && (
                    <CellIcon
                        Cellwidth={'45px'}
                        Icon={UserIcon}
                        backgroundColor={theme.color.themeColor}
                        fill={theme.color.themeColor}
                        color={theme.color.buttonFontColor}
                    />
                )}
                <CellText Title={`${userFirstName} ${userLastName}`} textAlign={'left'} TitleColor={true} />
                <CellText Title={userPhone ? userPhone : intl.formatMessage({ id: 'form.na' })} textAlign={'left'} TitleColor={true} />
                <CellText padding={'2px 0 2px 10px'} Title={userEmail ? userEmail : intl.formatMessage({ id: 'form.na' })} textAlign={'left'} TitleColor={true} />
                <CellText Title={userRoleName} textAlign={'left'} TitleColor={true} />                
                <CellTextWithPopover
                    id={`SitesPopover-${userUUID}`}
                    userUUID={userUUID}
                    Title={sites && sites.length > 1
                        ?
                        `${intl.formatMessage({id: 'filters.units.multiple'})}`
                        :
                        sites && sites.length === 1
                        ?
                        sites[0].siteName
                        :
                        intl.formatMessage({ id: 'form.na' })}
                    multiple={sites && sites.length > 1
                        ?
                        true
                        :
                        false}
                    textAlign={'left'}
                    TitleColor={true}
                    sites={sites} />
                <CellTextWithPopover
                    id={`UnitsPopover-${userUUID}`}
                    userUUID={userUUID}
                    Title={units && units.length > 1
                        ?
                        `${intl.formatMessage({id: 'filters.units.multiple'})}`
                        :
                        units && units.length === 1
                        ?
                        units[0].shared
                        ?
                        `${units[0].unitName} (${intl.formatMessage({id: 'db.shared'})} ${intl.formatMessage({id: 'form.access'})})`
                        :
                        units[0].unitName
                        :
                        intl.formatMessage({ id: 'form.na' })}
                    multiple={units && units.length > 1
                        ?
                        true
                        :
                        false}
                    textAlign={'left'}
                    TitleColor={true}
                    units={units} />

            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { width, theme } = app;
    return { width, theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(CellSearchResults)));
