import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';

import languageData from 'lngProvider/data';
import { setLanguage } from 'actions/App';
class LanguageList extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { locale, theme } = this.props;
        this.styles = styles(theme);
        return (
            <div className={css(this.styles.wrapper)}>
                <ul className="list-unstyled">
                    {languageData.map((language) => (
                        <li
                            key={'LANG' + language.locale}
                            className={css(this.styles.list, language.locale === locale && this.styles.active)}
                            onClick={() => this.props.setLanguage(language.locale)}>
                            <h4 className={css(this.styles.select)}>{language.name}</h4>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, locale } = app;
    return { theme, locale };
};

export default connect(mapStateToProps, { setLanguage })(LanguageList);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.compColor
        },
        active: {
            backgroundColor: theme.color.themeColor,
            color: theme.color.buttonFontColor
        },
        list: {
            color: theme.color.fontColor,
            ':hover': {
                opacity: '.7',
                cursor: 'pointer'
            }
        },
        select: {
            margin: 0,
            padding: 10,
            fontSize: 10,
            borderBottom: `solid 1px ${theme.color.borderColor}`
        }
    });
