const guide = {
    en: 'en-US',
    da: 'da-DA',
    de: 'de-DE',
    pt: 'pt-PT',
    es: 'es-ES',
    fr: 'fr-FR',
    se: 'se-SE',
    no: 'no-NO',
    pl: 'pl-PL',
    nl: 'nl-NL',
    et: 'et-ET',
    it: 'it-IT',
    lt: 'lt-LT',
    fi: 'fi-FI'
}

const dynamicLoad = async (file, cb) => {
    import(`${file}`)
    .then((res) => {
        cb(res, null);
    })
    .catch((error) => {
        cb(null, error);
        console.error("Couldn't load file - ", error);
    });
};

const getLang = async (locale) => {
    const p = new Promise((resolve, reject) => {
        dynamicLoad(`./entries/${guide[locale]}`, (data, error) => { 
            if (error) reject();
            resolve(data);
        });
    })
    let data = await p;
    return data;
};

export { getLang };
