//||||||||||||||||||||||||||||||||||||||||
//|| SECURITY LIST                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SECURITY_LIST = '/site/units/';
export const PURPOSE_SECURITY_LIST = 'Fetch list of units for the site.';
export const PERMISSIONS_SECURITY_LIST = [];
export const REQUIREMENTS_SECURITY_LIST = ['Token Required'];
export const PARAMETER_SECURITY_LIST = {
    filter: ['available', 'inuse', 'pending'],
    companyId: 0
};
export const RETURN_SECURITY_LIST = {
    page: 0,
    pages: 0,
    units: [
        {
            User: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            },
            accessType: '',
            companyId: 0,
            details: '',
            detailsDepth: 0,
            detailsPrice: 0,
            detailsWidth: 0,
            externalId: '',
            id: 0,
            jsonType: '',
            lastUpdated: '',
            locks: [
                {
                    bleAppVersion: '',
                    bleHwVersion: '',
                    companyId: 0,
                    gatewayId: 0,
                    gwDelinquent: 0,
                    hwState: '',
                    hwType: '',
                    id: 0,
                    mac: '',
                    name: '',
                    offlineKey: '',
                    siteId: 0,
                    syncFlag: '',
                    time: '',
                    unlockCmd: '',
                    voltageBattery: '',
                    voltageWired: '',
                    wpAppVersion: '',
                    wpOtapVersion: '',
                    wpStackVersion: ''
                }
            ],
            name: '',
            rentalState: '',
            siteId: 0
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| SECURITY DETAILS                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SECURITY_DETAILS = '/lock/profile/';
export const PURPOSE_SECURITY_DETAILS = 'Fetch unit details.';
export const PERMISSIONS_SECURITY_DETAILS = [];
export const REQUIREMENTS_SECURITY_DETAILS = ['Token Required'];
export const PARAMETER_SECURITY_DETAILS = {
    unitId: 0,
    companyId: 0
};
export const RETURN_SECURITY_DETAILS = {
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| SECURITY EDIT                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SECURITY_EDIT = '/unit/edit/';
export const PURPOSE_SECURITY_EDIT = 'Edit unit.';
export const PERMISSIONS_SECURITY_EDIT = [];
export const REQUIREMENTS_SECURITY_EDIT = ['Token Required'];
export const PARAMETER_SECURITY_EDIT = {
    unitId: 0,
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};
export const RETURN_SECURITY_EDIT = {
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};
