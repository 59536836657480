import * as CONSTANTS from 'constants';

const INIT_STATE = {
    zoneList: [],
    zoneKeypadList: [],
    zoneSelected: null,
    zoneObject: null,
    zoneHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'Name',
            mobile: true,
            width: 200,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.entry',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'entryLength'
        },
        {
            id: 2,
            title: 'comp.table.header.units',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'unitLength'
        },
        {
            id: 3,
            title: 'comp.table.header.relays',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'relayLength'
        }
    ],
    zoneUnitHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: 200,
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'rentalState'
        },
        {
            id: 2,
            title: 'comp.table.header.user',
            width: 150,
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'user',
            mobile: true,
            icon: true,
            style: { textAlign: 'right' }
        }
    ],
    zoneEntryHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'hwType'
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_ZONE_LIST: {
            return {
                ...state,
                zoneList: action.payload
            };
        }
        case CONSTANTS.SET_ZONE_KEYPAD_LIST: {
            return {
                ...state,
                zoneKeypadList: action.payload
            };
        }
        case CONSTANTS.SET_ZONE_DETAILS: {
            return {
                ...state,
                zoneObject: action.payload
            };
        }
        case CONSTANTS.SET_ZONE_HEADERS: {
            return {
                ...state,
                zoneHeader: action.payload
            };
        }
        case CONSTANTS.SET_ZONE_UNIT_HEADERS: {
            return {
                ...state,
                zoneUnitHeader: action.payload
            };
        }
        case CONSTANTS.SET_ZONE_ENTRY_HEADERS: {
            return {
                ...state,
                zoneEntryHeader: action.payload
            };
        }
        default:
            return state;
    }
};
