import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ToolTips from 'components/ToolTips';
import DateSelector from 'components/Dashboard/dateSelector';
import Dropdown from 'components/Dropdowns';
import Menu from 'components/Menus/users/onsite';
import Search from 'components/Search';
import TempCodeSelector from 'components/Dashboard/tempCodeSelector';
class DashboardHeader extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let {
            intl,
            theme,
            width,
            site,
            authUser,
            Icon,
            title,
            translatedTitle,
            manage,
            icon,
            iconSize,
            subtitle,
            timeLimit,
            adjustTime,
            handleDateSelection,
            showToggle,
            onToggleChange,
            active,
            activityView,
            updateActivityView,
            activityOverview,
            updateActivityOverview,
            sharedView,
            updateSharedView,
            syncPmsChanges,
            sharedUnits,
            syncUnitsHeaders,
            sharedUnitsHeaders,
            recentUpdatesSearch,
            sharedUnitsSearch,
            unitStatus,
            updateUnitStatus,
            editWidgets,
            widgetId
        } = this.props;
        this.styles = styles(theme);
        let syncActive = syncUnitsHeaders && syncUnitsHeaders.find((filt) => filt.active);
        let sharedActive = sharedUnitsHeaders && sharedUnitsHeaders.find((filt) => filt.active);
        return (
            <>
                <div className={css(this.styles.boxTop)}>
                    <div className={css(this.styles.iconBox)}>
                        <Icon
                            height={iconSize}
                            width={iconSize}
                            color={
                                theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                                    ? theme.color.fontColor
                                    : theme.color.themeColor
                            }
                        />
                    </div>
                    {manage && (
                        <div className={css(this.styles.iconList)}>
                            {manage.map((item, i) => (
                                <div key={i} onClick={item.action}>
                                    <i
                                        id={item.id ? item.id : item.icon}
                                        className="material-icons"
                                        style={{
                                            fontSize: 22,
                                            color: item.color ? item.color : theme.color.themeColor,
                                            marginLeft: 3
                                        }}>
                                        {item.icon ? item.icon : icon}
                                    </i>
                                    {item.text && <ToolTips target={item.id ? item.id : item.icon} message={item.text} />}
                                </div>
                            ))}
                        </div>
                    )}
                    {activityView && activityView == 'usersOnsite' && (
                        <div className={css(this.styles.iconList)}>
                            <Dropdown menu={Menu} height={110} padding={'0 20px 0 20px'}>
                                <i
                                    style={{ color: theme.color.buttonFontColor }}
                                    className={`material-icons ${css(this.styles.icon)}`}
                                    id="add_circle_outline">
                                    more_horiz
                                </i>
                            </Dropdown>
                        </div>
                    )}
                    {sharedView && (
                        <div className={css(this.styles.searchBar)}>
                            {sharedView == 'pmsSync' && width > 500 ? (
                                <div className={editWidgets ? css(this.styles.shortSearch) : css(this.styles.search)}>
                                    <Search
                                        update={(filterList) => recentUpdatesSearch(filterList)}
                                        filter={syncUnitsHeaders ? syncUnitsHeaders.map((head) => head.sortTitle) : []}
                                        searchTitle={syncActive.title}
                                        searchType={
                                            syncUnitsHeaders ? syncUnitsHeaders.map((head) => head.sortTitle) : []
                                        }
                                        data={syncPmsChanges}
                                        color={true}
                                        iconSize={20}
                                        noPadding={true}
                                        autoFocus={false}
                                    />
                                </div>
                            ) : width > 500 && (
                                <div className={editWidgets ? css(this.styles.shortSearch) : css(this.styles.search)}>
                                    <Search
                                        update={(sharedFilterList) => sharedUnitsSearch(sharedFilterList)}
                                        filter={
                                            sharedUnitsHeaders ? sharedUnitsHeaders.map((head) => head.sortTitle) : []
                                        }
                                        searchTitle={sharedActive.title}
                                        searchType={
                                            sharedUnitsHeaders ? sharedUnitsHeaders.map((head) => head.sortTitle) : []
                                        }
                                        data={sharedUnits}
                                        color={true}
                                        iconSize={20}
                                        noPadding={true}
                                        autoFocus={false}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {timeLimit && site && site.loiteringAlert && site.loiteringAlert == 'true' && (
                        <div className={css(this.styles.timeBox)}>
                            <select
                                className={editWidgets ? css(this.styles.inputShort) : css(this.styles.input)}
                                name="type"
                                type="select"
                                onChange={(e) => adjustTime(e)}
                                value={timeLimit}>
                                <option value={180}>
                                    {intl.formatMessage({ id: 'comp.sidebar.stats.todayLastTwo' })}
                                </option>
                                <option value={720}>
                                    {intl.formatMessage({
                                        id: 'comp.sidebar.stats.todayLastTwelve'
                                    })}
                                </option>
                                <option value={1440}>
                                    {intl.formatMessage({
                                        id: 'comp.sidebar.stats.todayLastTwentyFour'
                                    })}
                                </option>
                            </select>
                            <i
                                className="material-icons"
                                style={{
                                    float: 'right',
                                    position: 'absolute',
                                    color: theme.color.themeColor,
                                    top: 2,
                                    right: 2,
                                    fontSize: 18,
                                    background: 'transparent',
                                    pointerEvents: 'none'
                                }}>
                                arrow_drop_down
                            </i>
                        </div>
                    )}
                    {activityView && updateActivityView && (
                        <div className={css(this.styles.timeBox)}>
                            <select
                                className={editWidgets ? css(this.styles.inputShort) : css(this.styles.input)}
                                name="type"
                                type="select"
                                onChange={(e) => updateActivityView(e.target.value)}
                                value={activityView}>
                                <option value={'recentActivity'}>
                                    {intl.formatMessage({ id: 'pages.dashboard.activityRecent' })}
                                </option>
                                <option value={'usersOnsite'}>
                                    {intl.formatMessage({ id: 'pages.dashboard.onsiteNow' })}
                                </option>
                            </select>
                            <i
                                className="material-icons"
                                style={{
                                    float: 'right',
                                    position: 'absolute',
                                    color: theme.color.themeColor,
                                    top: 2,
                                    right: 2,
                                    fontSize: 18,
                                    background: 'transparent',
                                    pointerEvents: 'none'
                                }}>
                                arrow_drop_down
                            </i>
                        </div>
                    )}
                    {activityOverview && updateActivityOverview && (
                        <div className={css(this.styles.timeBox)}>
                            <select
                                className={editWidgets ? css(this.styles.inputShort) : css(this.styles.input)}
                                name="type"
                                type="select"
                                onChange={(e) => updateActivityOverview(e.target.value)}
                                value={activityOverview}>
                                <option value={'overview'}>
                                    {intl.formatMessage({ id: 'pages.dashboard.activityOverview' })}
                                </option>
                                <option value={'tenant'}>
                                    {intl.formatMessage({ id: 'pages.dashboard.activeTenants' })}
                                </option>
                            </select>
                            <i
                                className="material-icons"
                                style={{
                                    float: 'right',
                                    position: 'absolute',
                                    color: theme.color.themeColor,
                                    top: 2,
                                    right: 2,
                                    fontSize: 18,
                                    background: 'transparent',
                                    pointerEvents: 'none'
                                }}>
                                arrow_drop_down
                            </i>
                        </div>
                    )}
                    {unitStatus && updateUnitStatus && (
                        <div className={css(this.styles.timeBox)}>
                            <select
                                className={editWidgets ? css(this.styles.inputShort) : css(this.styles.input)}
                                name="type"
                                type="select"
                                onChange={(e) => updateUnitStatus(e.target.value)}
                                placeholder={'&#xF002;'}
                                value={unitStatus}>
                                <option value={'unitStatus'}>
                                    {intl.formatMessage({ id: 'comp.table.header.unitStatus' })}
                                </option>
                                <option value={'lockType'}>
                                    {intl.formatMessage({ id: 'comp.table.header.lockType' })}
                                </option>
                                {authUser && authUser.permissions.includes('support_view_gateways') && (
                                    <option value={'lockStatus'}>
                                        {intl.formatMessage({
                                            id: 'comp.table.header.lockStatus'
                                        })}
                                    </option>
                                )}
                            </select>
                            <i
                                className="material-icons"
                                style={{
                                    float: 'right',
                                    position: 'absolute',
                                    color: theme.color.themeColor,
                                    top: 2,
                                    right: 2,
                                    fontSize: 18,
                                    background: 'transparent',
                                    pointerEvents: 'none',
                                }}>
                                arrow_drop_down
                            </i>
                        </div>
                    )}
                </div>
                {(title || translatedTitle) && (
                    <div className={css(this.styles.titleBox)}>
                        {translatedTitle ? translatedTitle : intl.formatMessage({ id: `${title}` })}
                        {subtitle ? `: ${subtitle}` : null}
                    </div>
                )}
                {handleDateSelection && active && width > 350 && (
                    <div className={css(this.styles.dateBox)}>
                        <DateSelector handleDateSelection={handleDateSelection} widgetId={widgetId} active={active} />
                    </div>
                )}
                {
                showToggle && width > 350 && ( 
                    <div className={css(this.styles.dateBox)}><TempCodeSelector onToggleChange = {onToggleChange}/>
                </div> 
            )}
            </>
        );
    }
}

DashboardHeader.defaultProps = {
    icon: 'edit',
    iconSize: 34
};

const mapStateToProps = ({ app, auth }) => {
    const { theme, width } = app;
    const { site, authUser } = auth;
    return { theme, width, site, authUser };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(DashboardHeader)));

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            width: '100%',
            textAlign: 'center',
            fontSize: 16
        },
        text: {
            width: '100%',
            textAlign: 'left',
            marginBottom: 0
        },
        button: {
            color: theme.color.buttonFontColor,
            background: theme.color.themeColor,
            outline: 'none',
            border: 'none',
            textAlign: 'center',
            margin: 0,
            padding: '10px 15px',
            minWidth: '100%'
        },
        box: {
            minHeight: 240,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
            justifyContent: 'space-between',
            padding: '30px 20px 20px 20px'
        },
        boxTop: {
            display: 'flex',
            justifyContent: 'center',
            background: theme.color.themeColor,
            paddingTop: 10,
            position: 'relative'
        },
        iconBox: {
            background: theme.color.compColor,
            border: '3px solid' + theme.color.themeColor,
            borderRadius: '50%',
            height: 55,
            width: 55,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: -25
        },
        iconList: {
            position: 'absolute',
            top: 8.5,
            right: 10,
            color: theme.color.buttonFontcolor,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row'
        },
        iconListLeft: {
            position: 'absolute',
            top: 8.5,
            left: 10,
            color: theme.color.buttonFontcolor,
            cursor: 'pointer'
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: theme.color.themeColor,
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            marginTop: 30
        },
        dateBox: {
            position: 'absolute',
            top: 48,
            right: 8
        },
        timeBox: {
            position: 'absolute',
            top: 10,
            left: 12,
            color: theme.color.buttonFontcolor,
            cursor: 'pointer'
        },
        input: {
            border: 'none',
            boxShadow: 'none',
            padding: '3px 14px 3px 7px',
            borderRadius: 15,
            fontWeight: 400,
            fontSize: 11,
            minWidth: 125,
            height: 20,
            marginBottom: 10,
            color: theme.color.fontColor,
            background: theme.color.compColor,
            outline: 'none',
            '@media(max-width:1300px)': {
                width: 10,
                minWidth: 10,
            },
            '@media(max-width:992px)': {
                width: 'auto',
                minWidth: 120
            },
            '@media(max-width:600px)': {
                width: 10,
                minWidth: 10,
            }
        },
        inputShort: {
            border: 'none',
            boxShadow: 'none',
            padding: '3px 14px 3px 7px',
            borderRadius: 15,
            fontWeight: 400,
            fontSize: 11,
            width: 10,
            minWidth: 10,
            height: 20,
            marginBottom: 10,
            color: theme.color.fontColor,
            background: theme.color.compColor,
            outline: 'none',
            '@media(max-width:1300px)': {
                width: 10,
                minWidth: 10,
            },
            '@media(max-width:992px)': {
                width: 10,
                minWidth: 10,
            },
            '@media(max-width:600px)': {
                width: 10,
                minWidth: 10,
            }
        },
        searchBar: {
            position: 'absolute',
            top: 6.5,
            right: 2,
            color: theme.color.buttonFontcolor,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row'
        },
        search: {
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
            padding: '3px 7px',
            borderRadius: 15,
            fontWeight: 400,
            fontSize: 11,
            width: 150,
            height: 20,
            '@media(max-width:1300px)': {
                width: 120
            },
            '@media(max-width:992px)': {
                width: 150
            }
        },
        shortSearch: {
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
            padding: '3px 5px',
            borderRadius: 15,
            fontWeight: 400,
            fontSize: 11,
            width: 40,
            height: 20,
            '@media(max-width:1300px)': {
                width: 40
            },
            '@media(max-width:992px)': {
                width: 40
            }
        }
    });
