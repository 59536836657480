import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
class DashboardRecentActivityCell extends React.Component {
    render() {
        const { intl, data, width, authUser, site, userTimezone } = this.props;
        const { user, action, recordedDate, event } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    maxCharLength={
                        width > 1450 ? width / 120 : width > 1200 ? width / 140 : width > 991 ? width / 120 : width / 18
                    }
                    maxDescLength={
                        width > 1600
                            ? width / 74
                            : width > 1450
                            ? width / 80
                            : width > 991
                            ? width / 110
                            : width > 500
                            ? width / 12
                            : width / 20
                    }
                    SupportActivity={event && event.by && event.by.isSupportUser}
                    Title={user && user !== ' ' ? user : intl.formatMessage({ id: `form.na` })}
                    Description={`${intl.formatMessage({
                        id: `db.${action}`,
                        defaultMessage: ' '
                    })}${data.event.accessCode && data.event.accessCode !== '0' ? `: ${data.event.accessCode}` : ''}`}
                    textAlign={'left'}
                    TitleColor={true}
                />
                <CellText
                    textAlign={'right'}
                    padding={'2px 0 2px 10px'}
                    TitleSize={width > 300 ? 11 : 8}
                    Cellwidth={'80px'}
                    Title={recordedDate && recordedDate !== "" ? UTILS.dateTimeFormatter(recordedDate, authUser, site, userTimezone) : intl.formatMessage({ id: `form.na` })}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, userTimezone, authUser, site };
};

export default injectIntl(connect(mapStateToProps)(DashboardRecentActivityCell));
