import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';

import * as UTILS from 'utilities';
class CellDate extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { date, Cellwidth, theme, width, textAlign, fontSize, authUser, site, showTime, doNotLocalize } = this.props;
        this.styles = styles(theme, width, textAlign, fontSize);
        return (
            <td width={Cellwidth}>
                <div className={css(this.styles.wrapper)}>
                    {doNotLocalize ? <p className={css(this.styles.date)}>{UTILS.dateFormatter(moment(date).utc())}</p> : 
                    <p className={css(this.styles.date)}>{UTILS.dateFormatter(date, authUser, site)}</p>}
                    {showTime && <p className={css(this.styles.time)}>{moment(date).format('h:mm a')}</p>}
                </div>
            </td>
        );
    }
}

CellDate.defaultProps = {
    textAlign: 'right',
    fontSize: 12,
    showTime: true,
    doNotLocalize: false
};

const mapStateToProps = ({ app, auth }) => {
    const { theme, width } = app;
    const { authUser, site, } = auth;
    return { theme, width, authUser, site };
};

export default withRouter(connect(mapStateToProps)(CellDate));

const styles = (theme, width, textAlign, fontSize) =>
    StyleSheet.create({
        wrapper: {
            padding: 0,
            margin: 0
        },
        date: {
            fontSize: width > 300 ? fontSize : 10,
            fontWeight: 400,
            color: theme.color.fontColor,
            margin: '0 5px 0 0',
            padding: 0,
            textAlign
        },
        time: {
            fontSize: width > 300 ? fontSize : 10,
            fontWeight: 400,
            color: theme.color.fontColor,
            margin: '0 5px 0 0',
            padding: 0,
            textAlign
        }
    });
