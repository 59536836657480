import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as CONSTANTS from 'constants';
import * as API from 'api';
import * as ACTIONS_WIZARD from 'actions/HelpWizard';
import * as ACTIONS_APP from 'actions/App';

function* requestWizardContactSupport({ payload }) {
    try {
        const response = yield call(API.POST, payload.url, payload.data);
        if (!response && !response.data) {
            yield put(ACTIONS_APP.showMessage(`db.error`, 'warning'));
        } else {
            return;
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestGetUserWizardResponses({ payload }) {
    try {
        yield put(ACTIONS_WIZARD.setUserWizardResponses({}));
        const response = yield call(API.POST, payload.url, payload.data);
        if (!response && !response.data) {
            return;
        } else {
            yield put(ACTIONS_WIZARD.setUserWizardResponses(response.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectWizardContactSupport() {
    yield takeEvery(CONSTANTS.WIZARD_CONTACT_SUPPORT, requestWizardContactSupport);
}

export function* selectGetUserWizardResponses() {
    yield takeEvery(CONSTANTS.GET_USER_WIZARD_RESPONSES, requestGetUserWizardResponses);
}

export default function* rootSaga() {
    yield all([
        fork(selectWizardContactSupport),
        fork(selectGetUserWizardResponses)
    ]);
}
