import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { switchSite } from 'actions/Auth';
import { setSwitcherLoader } from 'actions/App';
import { removeUserNotifications } from 'actions/Notifications';

import CellText from 'components/Table/cell/components/text';
import CellDate from 'components/Table/cell/components/date';
import CellClose from 'components/Table/cell/components/close';
class UserNotificationsCellEnterpriseDashboard extends React.Component {
    constructor(props) {
        super(props);
    }
    handleClick = () => {
        const { data, site, intl, history, switchSite, setSwitcherLoader } = this.props;
        const { eventsId, siteID } = data;
        eventsId && eventsId !== ''
            ? () => {
                    sessionStorage.removeItem('manager_dash');
                    setSwitcherLoader({loading: true, text: intl.formatMessage({ id: 'comp.button.switchingSite' })});
                    siteID && siteID !== site.id && switchSite(siteID.toString(), false, false, false, false, true);
                    history.push(`/app/activity/notification/${eventsId}`);
                }
            : null
    }
    removeNotification = (id) => {
        this.props.removeUserNotifications([id]);
        this.props.toggle(id);
    };
    render() {
        const { intl, data } = this.props;
        const { id, createdDate, title, type, body } = data;
        return (
            <>
                <CellText
                    Title={type && type == 'feature' ? `${type}: ${title}` : title}
                    TitleColor={true}
                    textAlign={'left'}
                    padding= {'-2px -10px'}
                    Cellwidth={'34%'}
                    update={this.handleClick} />
                <CellText
                    Title={body}
                    TitleColor={true}
                    textAlign={'left'}
                    padding= {'2px 4px'}
                    Cellwidth={'43%'}
                    update={this.handleClick} />
                <CellDate textAlign={'left'} padding= {'0px 8px'} Cellwidth={'17%'} date={createdDate} showTime={true} />
                <CellClose Cellwidth={'5%'} update={() => this.removeNotification(id)} />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, height } = app;
    const { site } = auth;
    return { width, height, site };
};

export default withRouter(injectIntl(connect(mapStateToProps, {switchSite, setSwitcherLoader, removeUserNotifications})(UserNotificationsCellEnterpriseDashboard)));
