import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';

import { UserIcon, SharedUserIcon } from 'utilities/icons';

import { fetchSiteUserList, fetchEmployeesOnline } from 'actions/Users';

import Spinner from 'components/Spinner';
class DashboardRecentUsers extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { authUser, fetchSiteUserList, fetchEmployeesOnline } = this.props;
        authUser &&
            authUser.permissions.includes('view_users') &&
            !this.props.editWidgets &&
            fetchSiteUserList(undefined, true);
        authUser && authUser.permissions.includes('manage_employees') && fetchEmployeesOnline();
    }
    handleUserAction = (data) => {
        sessionStorage.removeItem('manager_dash');
        this.props.history.push(`/app/users/display/${data.uuid}`);
    };
    render() {
        const { intl, theme, authUser, editWidgets, employeesOnline, site, userTimezone } = this.props;
        this.styles = styles(theme);
        return (
            <>
                <div className={css(this.styles.titleBox)}>
                    <SharedUserIcon color={theme.color.themeColor} height={30} width={30} />
                    <h1 className={css(this.styles.header)}>
                        {intl.formatMessage({ id: 'filters.users.employee' })}{' '}
                        {intl.formatMessage({ id: 'comp.table.header.online' })}
                    </h1>
                </div>
                {employeesOnline ? (
                    <div className={css(this.styles.itemRow)}>
                        {employeesOnline && employeesOnline.length > 0 ? (
                            employeesOnline.map((recentUser, i) => (
                                <div
                                    key={'USERLIST' + recentUser.uuid + i}
                                    className={css(this.styles.item)}
                                    onClick={() => this.handleUserAction(recentUser)}>
                                    <div className={css(this.styles.icon)}>
                                        <UserIcon
                                            fill={theme.color.themeColor}
                                            color={theme.color.buttonFontColor}
                                            width={editWidgets ? 15 : 25}
                                            height={editWidgets ? 15 : 25}
                                        />
                                    </div>
                                    <div className={css(this.styles.text)}>
                                        <p className={css(this.styles.title)}>{recentUser.name}</p>
                                        <p className={css(this.styles.title)}>{recentUser.site}</p>
                                        <p className={css(this.styles.count)}>
                                            <span className={css(this.styles.countLogin)}>
                                                {intl.formatMessage({ id: 'form.lastLoggedIn' })}:
                                            </span>
                                            {recentUser &&
                                            recentUser.lastLoginDate &&
                                            authUser &&
                                            authUser.useSiteTime &&
                                            site
                                                ? moment(recentUser.lastLoginDate).tz(site.timeZone).format('hh:mm A')
                                                : recentUser &&
                                                  recentUser.lastLoginDate &&
                                                  moment(recentUser.lastLoginDate).tz(userTimezone).format('hh:mm A')}
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div
                                style={{
                                    minHeight: 110,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    animationName: fadeIn,
                                    animationDuration: '1.2s'
                                }}>
                                <p>{intl.formatMessage({ id: 'comp.table.cell.emptyEmployees' })}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ minHeight: 115 }}>
                        <Spinner />
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, users }) => {
    const { theme, userTimezone } = app;
    const { authUser, site } = auth;
    const { employeesOnline } = users;
    return { theme, userTimezone, authUser, site, employeesOnline };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { fetchSiteUserList, fetchEmployeesOnline })(DashboardRecentUsers))
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.fontColor,
            marginBottom: 15,
            marginLeft: 10
        },
        itemRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            marginBottom: 5
        },
        item: {
            width: '29%',
            margin: '1% 1%',
            minHeight: 150,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderRadius: 5,
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            boxShadow: theme.color.boxShadowCorner,
            ':hover': {
                cursor: 'pointer',
                transform: 'translateY(-2px)',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:400px)': {
                width: '23%'
            }
        },
        icon: {
            width: '100%',
            height: 50,
            backgroundColor: theme.color.themeColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        text: {
            display: 'flex',
            padding: 5,
            maxHeight: 100,
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            marginBottom: 0,
            textAlign: 'center',
            fontSize: 11,
            width: '100%',
            maxWidth: '100%',
            fontWeight: 600,
            textTransform: 'capitalize',
            overflow: 'hidden',
            wordBreak: 'break-word',
            '@media(max-width:1350px)': {
                fontSize: 9
            }
        },
        count: {
            marginBottom: 5,
            fontWeight: 600,
            textAlign: 'center',
            fontSize: 11,
            textTransform: 'capitalize',
            display: 'flex',
            flexDirection: 'row',
            '@media(max-width:1350px)': {
                fontSize: 9
            }
        },
        countLogin: {
            margin: '0 3px 5px 0',
            fontWeight: 400,
            textAlign: 'center',
            fontSize: 11,
            textTransform: 'capitalize',
            '@media(max-width:1350px)': {
                fontSize: 9
            }
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
        }
    });
