import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { setLoader, showLoader } from 'actions/App';

class TempCodeSelector extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, theme, width, onToggleChange, showLoader, site } = this.props;
        this.styles = styles(theme, width);
        return (
            <div className={css(this.styles.dateRow)}>
                <div>
                    <p
                         onClick={() => {if (!showLoader) {this.props.setLoader(true); onToggleChange('true')}}}
                        className={css(this.styles.footerItem, site.allowDailyAccessCodes =='true' && this.styles.footerItemActive )}>
                        {intl.formatMessage({ id: 'form.on' })}
                    </p>
                </div>
                <div>
                    <p
                         onClick={() => {if (!showLoader) {this.props.setLoader(true); onToggleChange('false')}}}
                        className={css(this.styles.footerItem, site.allowDailyAccessCodes =='false' && this.styles.footerItemActive)}>
                        {intl.formatMessage({ id: 'form.off' })}
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme, width, showLoader } = app;
    const { site } = auth;
    return { theme, width, site, showLoader };
};

export default withRouter(injectIntl(connect(mapStateToProps, {setLoader})(TempCodeSelector)));

const styles = (theme, width) =>
    StyleSheet.create({
        dateRow: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            fontSize: width > 1250 ? 11 : width > 500 ? 10 : 9,
            border: `1px solid ${theme.color.borderColor}`,
            borderRadius: width > 1250 ? 6 : 4,
            overflow: 'hidden',
            height: 'min-content',
            alignItems: 'center',
            textAlign: 'center',
            ':hover': {
                cursor: 'pointer',
            }
        },
        footerItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: width > 1250 ? 26 : 23,
            minHeight: width > 1250 ? 22 : 19,
            margin: 0,
            color: theme.color.fontColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                opacity: 0.8,
                background: theme.color.borderColor,
            }
        },
        footerItemActive: {
            backgroundColor: theme.color.themeColor,
            color: theme.color.buttonFontColor,
            borderColor: theme.color.themeColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                opacity: 0.8,
                backgroundColor: theme.color.themeColor,
            }
        }
    });
