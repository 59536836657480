import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { FormGroup } from 'reactstrap';

import { GlobalSearchIcon } from 'utilities/icons';

import { fetchCrossSiteSeachResults } from 'actions/Reports';
import { switchSite } from 'actions/Auth';
import { setSwitcherLoader, showMessage } from 'actions/App';

import Expand from 'react-expand-animated';
import Table from 'components/Table';
import searchResultsCell from 'components/Table/cell/containers/reports/searchResults';
import TextInput from 'components/Inputs/text';
class DashboardCrossSiteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSearch: '',
            showResults: false
        };
    }
    handleSearch = () => {
        const { fetchCrossSiteSeachResults, showMessage } = this.props;
        const { userSearch } = this.state;
        if (userSearch !== '' && userSearch.trim() !== '' && userSearch.trim().length && userSearch.trim().length > 2) {
            this.setState({ showResults: true });
            fetchCrossSiteSeachResults(userSearch);
        } else {
            showMessage(`notifications.error.blank.searchText`, 'warning');
        }
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSearch();
        }
    };
    linkToItem = (data) => {
        const { switchSite, setSwitcherLoader, intl } = this.props;
        sessionStorage.removeItem('manager_dash');
        setSwitcherLoader({loading: true, text: intl.formatMessage({ id: 'comp.button.switchingSite' })});
        data.sites && data.sites.length && data.sites.length > 0
        ?
        switchSite(`${data.sites[0].siteID}`, false, false, false, data.userUUID, false)
        :
        console.warn('no site');
    };
    render() {
        const { userSearch, showResults } = this.state;
        const { intl, theme, crossSiteSearchResults, reportSearchHeader } = this.props;
        this.styles = styles(theme);
        return (
            <>
                <div className={css(this.styles.titleBox)}>
                    <GlobalSearchIcon color={theme.color.themeColor} />
                    <h1 className={css(this.styles.header)}>{intl.formatMessage({ id: 'comp.search.default' })}</h1>
                </div>
                <div>
                    <FormGroup
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-between',
                            marginBottom: 5
                        }}>
                        <TextInput
                            placeholder={intl.formatMessage({ id: 'comp.search.crossSite' })}
                            fontSize={12}
                            type="text"
                            name="userSearch"
                            value={userSearch}
                            width={'100%'}
                            margin={'0px 0px 0px 0px'}
                            noMargin={true}
                            onChange={(e) => this.setState({ userSearch: e.target.value })}
                            onKeyDown={(e) => this.handleKeyDown(e)}
                        />
                        <div className={css(this.styles.searchIcon)} onClick={() => this.handleSearch()}>
                            <i
                                className="material-icons"
                                style={{
                                    fontSize: 28,
                                    fontWeight: '400',
                                    color: theme.color.themeColor
                                }}>
                                search
                            </i>
                        </div>
                    </FormGroup>
                </div>
                <Expand open={showResults}>
                    <div className={css(this.styles.resultsBox)}>
                        <Table
                            tableHeight={343}
                            maxHeight={343}
                            border={false}
                            itemSize={55}
                            data={crossSiteSearchResults}
                            header={reportSearchHeader}
                            action={this.linkToItem}
                            Cell={searchResultsCell}
                        />
                    </div>
                </Expand>
            </>
        );
    }
}

const mapStateToProps = ({ app, reports }) => {
    const { theme } = app;
    const { crossSiteSearchResults, reportSearchHeader } = reports;
    return { theme, crossSiteSearchResults, reportSearchHeader };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { fetchCrossSiteSeachResults, switchSite, setSwitcherLoader, showMessage })(DashboardCrossSiteSearch))
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.fontColor,
            marginBottom: 15,
            marginLeft: 10
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
        },
        input: {
            border: 'none',
            boxShadow: 'none',
            borderRadius: 0,
            padding: '10px 0 5px 0',
            fontWeight: 400,
            fontSize: 14,
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor,
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 14
            }
        },
        resultsBox: {
            minHeight: 100
        },
        searchIcon: {
            paddingTop: 8,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        }
    });
