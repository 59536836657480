import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import moment from 'moment';
import { history } from '../store/history';
import { countries } from 'countries-list';
import { LOGIN, POST, POST_TOKEN } from '../api';
import {
    SIGNIN_USER,
    SIGNOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    REFRESH_AUTH_TOKEN,
    FORGOT_USER_PASSWORD,
    RESET_USER_PASSWORD,
    RESET_USER_PIN,
    SWITCH_SITE,
    SYNC_SITE,
    RESET_PIN_CALL,
    FETCH_AUTH_PIN,
    SEND_AUTH_PIN,
    VALIDATE_USER
} from 'constants';
import {
    userSignInSuccess,
    userSignOutSuccess,
    showAuthLoader,
    hideAuthLoader,
    switchSite,
    setSite,
    sendAuthPin,
    showTwoFactor,
    setLoginConfirm,
    setViewAs,
    setAuthUser
} from 'actions/Auth';
import {
    showMessage,
    setLoader,
    setCountry,
    fetchWeather,
    fetchWeatherWeek,
    setAvailableWidgets,
    fetchAirQuality,
    setSwitcherLoader
} from 'actions/App';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_ROLES from 'actions/Roles';
import * as ACTIONS_USERS from 'actions/Users';
import * as ACTIONS_UNITS from 'actions/Units';
import * as ACTIONS_ACTIVITY from 'actions/Activity';
import * as ACTIONS_NOTIFICATIONS from 'actions/Notifications';
import * as ACTIONS_GATEWAY from 'actions/Gateways';
import * as ACTIONS_SETTINGS from 'actions/Settings';
import * as CONSTANTS from 'constants';

const themeState = (state) => state.app.theme;

function* signOut() {
    try {
        yield put(setLoader(false));
        localStorage.setItem('nse_notify', JSON.stringify({ token: null, from: 'michael scott was here' }));
        sessionStorage.removeItem('user_data');
        localStorage.removeItem('nse_login_data');
        sessionStorage.removeItem('manager_dash');
        sessionStorage.removeItem('nse_version');
        yield put(userSignOutSuccess(null));
        yield put(ACTIONS_NOTIFICATIONS.setUserNotifications(null));
        yield put(ACTIONS_GATEWAY.setGatewayList(null));
        yield put(showTwoFactor(false));
        yield history.push('/signin');
    } catch (error) {
        console.warn(error);
    }
}

function* signIn({ payload }) {
    try {
        sessionStorage.removeItem('user_data'); 
        const required = payload.data.phoneLogin
            ? {
                  companyUUID: payload.data.companyUUID ? payload.data.companyUUID : '-1',
                  countryCode: payload.data.countryCode,
                  phone: payload.data.phone,
                  password: payload.data.password,
                  deviceUUID: payload.data.deviceUUID
              }
            : {
                  companyUUID: payload.data.companyUUID ? payload.data.companyUUID : '-1',
                  email: payload.data.email,
                  password: payload.data.password,
                  deviceUUID: payload.data.deviceUUID
              };
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }

        var nse_login_data_Site = localStorage.getItem('nse_login_obj');
        required.siteUUID = nse_login_data_Site ? JSON.parse(nse_login_data_Site).siteUUID : ''

        yield put(showAuthLoader());
        sessionStorage.removeItem('manager_dash');
        yield put(setAvailableWidgets(null));
        yield put(ACTIONS_SETTINGS.setAllSchedules(null));
        yield put(ACTIONS_ACTIVITY.setActivityView(null));
        yield put(ACTIONS_UNITS.setUnitListStats(null));
        yield put(ACTIONS_UNITS.setUnitListLockStats(null));
        yield put(ACTIONS_USERS.setUserFilterSchedule('all'));
        yield put(ACTIONS_UNITS.setUnitListLockStatusStats(null));
        yield put(ACTIONS_APP.setDashboardWidgets(null));
        yield put(ACTIONS_APP.setAvailableWidgets(null));
        const signInUser = yield call(LOGIN, payload.url, required, payload);
        if (!signInUser) {
            return;
        }
        if (!signInUser.data.roles) {
            signInUser.data.roles = [];
        }
        if (!signInUser.data.featureflagAssignments) {
            signInUser.data.featureflagAssignments = [];
        }
        sessionStorage.removeItem('login_confirm');
        sessionStorage.removeItem('initial_phone_login');
        let serverUrl = sessionStorage.getItem('nse_url');
        let urlType = sessionStorage.getItem('nse_url_type');

        // custom means user manually inputed custom backend url
        // localhost will default to dev router
        // if it is aws.noke url we need to make sure to pre-pend nse-router
        // otherwise we just need to pre-pend router.
        urlType && urlType === 'custom'
        ?
        sessionStorage.setItem('nse_url', serverUrl)
        :
        window.location.hostname.includes('localhost')
        ?
        sessionStorage.setItem('nse_url', 'https://router.smartentry.noke.dev')
        :
        window.location.hostname.includes('aws.noke.')
        ?
        sessionStorage.setItem('nse_url', `https://${window.location.hostname.replace('frontend', 'nse-router')}`)
        :
        sessionStorage.setItem('nse_url', `https://router.${window.location.hostname}`);
        urlType && urlType !== 'custom' && sessionStorage.setItem('nse_url_type', 'server');
        if (!signInUser.data.roles) {
            signInUser.data.roles = [];
        }
        if (!signInUser.data.featureflagAssignments) {
            signInUser.data.featureflagAssignments = [];
        }
        const loginData = {
            company: signInUser.data.companyUUID,
            companyUUID: signInUser.data.companyUUID,
            site: signInUser.data.defaultSiteUUID,
            siteUUID: signInUser.data.defaultSiteUUID,
            country: payload.data.country && payload.data.country,
            phone: signInUser.data.phone && signInUser.data.phone,
            email: signInUser.data.email,
            token: signInUser.token ? signInUser.token : null
        };
        localStorage.setItem('nse_login_data', JSON.stringify(loginData));
        localStorage.setItem('nse_login_obj', JSON.stringify(loginData));

        // if nse_local isn't set, set to english (since its the default)
        !localStorage.getItem('nse_local') && localStorage.setItem('nse_local', 'en');

        if (!signInUser) {
            yield put(showMessage('notifications.failed.role'));
        }
        let supportUser = signInUser.data.roles.find((role) => role.tier === 1);
        let globalInstaller = signInUser.data.roles.find((role) => role.name === 'Global Installer');
        if (supportUser) {
            signInUser.data.role = supportUser;
        } else if (globalInstaller) {
            signInUser.data.role = globalInstaller;
        } else {
            signInUser.data.role = signInUser.data.roles.find((role) => role.siteUUID === loginData.site);
        }
        signInUser.data.token = signInUser.token;
        signInUser.data.sites = signInUser.data.sites.map((site) =>
            site.uuid == signInUser.data.defaultSiteUUID
                ? site
                : {
                        city: site.city,
                        country: site.country,
                        hours: site.hours,
                        id: site.id,
                        name: site.name,
                        pms: site.pms,
                        postalCode: site.postalCode,
                        state: site.state,
                        streetAddress: site.streetAddress,
                        suite: site.suite,
                        timeZone: site.timeZone,
                        uuid: site.uuid,
                        has3K: site.has3K,
                        companyUUID: site.companyUUID
                    }
        );
        if (!signInUser.data.permissions) {
            signInUser.data.permissions = [];
        }
        if (signInUser.data.resetPassFlag || signInUser.data.state === 'update_required') {
            signInUser.data.Login = payload.data;
        }
        if (payload.data.country) {
            yield put(setCountry(payload.data.country));
        }
        let authedDevice = localStorage.getItem('auth_device') ? localStorage.getItem('auth_device') : null;
        if (
            (signInUser.data.use2FactorAuth && !authedDevice) ||
            (signInUser.data.use2FactorAuth && authedDevice && !authedDevice.includes(signInUser.data.uuid))
        ) {
            yield put(showTwoFactor(true));
            yield put(sendAuthPin());
            return;
        } else if (signInUser.data.use2FactorAuth && authedDevice && authedDevice.includes(signInUser.data.uuid)) {
            yield put(showTwoFactor(false));
        }
        if (!signInUser.data.role) {
            yield put(showMessage('notifications.failed.role'));
            yield call(signOut);
            return;
        }
        yield put(userSignInSuccess(signInUser.data));
        sessionStorage.setItem('user_data', JSON.stringify(signInUser.data));
        let site = signInUser.data.sites.find((site) => site.uuid === signInUser.data.defaultSiteUUID);
        // let customVersion = site && site.nseRouterVersion && site.nseRouterVersion.name && site.nseRouterVersion.name !== "" ? site.nseRouterVersion.name : sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : null;
        site && site.nseRouterVersion && site.nseRouterVersion.name && site.nseRouterVersion.name !== "" && sessionStorage.setItem('nse_version', site.nseRouterVersion.name)
        const scope = Sentry.getCurrentScope();
        scope.setUser({
            email: signInUser.data.email,
            id: signInUser.data.uuid,
            username: `${signInUser.data.firstName} ${signInUser.data.lastName}`
        })
        scope.setExtra('sessionURL', LogRocket.sessionURL);
        LogRocket.identify(`${signInUser.data.uuid}`, {
            username: `${signInUser.data.firstName} ${signInUser.data.lastName}`,
            email: signInUser.data.email
        });
        if (signInUser.data.sites.length === 0) {
            yield put(showMessage('notifications.error.blank.sites'));
        } else if (signInUser.data.sites.length > 0) {
            signInUser.data.token = signInUser.token;
            signInUser.data.phoneFull = signInUser.data.phone;
            signInUser.data.phone = payload.data.phone && payload.data.phone;
            signInUser.data.countryCode = payload.data.country && payload.data.country.phone[0].toString();
            if (!signInUser.data.permissions) {
                signInUser.data.permissions = [];
            }
            if (signInUser.data.resetPassFlag || signInUser.data.state === 'update_required') {
                signInUser.data.Login = payload.data;
            } else {
                delete signInUser.data.Login;
            }
            let serverUrl = sessionStorage.getItem('nse_url');
            let urlType = sessionStorage.getItem('nse_url_type');
 
            // custom means user manually inputed custom backend url
            // localhost will default to dev router
            // if it is aws.noke url we need to make sure to pre-pend nse-router
            // otherwise we just need to pre-pend router.
            urlType && urlType === 'custom'
            ?
            sessionStorage.setItem('nse_url', serverUrl)
            :
            window.location.hostname.includes('localhost')
            ?
            sessionStorage.setItem('nse_url', 'https://router.smartentry.noke.dev')
            :
            window.location.hostname.includes('aws.noke.')
            ?
            sessionStorage.setItem('nse_url', `https://${window.location.hostname.replace('frontend', 'nse-router')}`)
            :
            sessionStorage.setItem('nse_url', `https://router.${window.location.hostname}`);
            urlType && urlType !== 'custom' && sessionStorage.setItem('nse_url_type', 'server');
            signInUser.data.sites = signInUser.data.sites.map((site) =>
                site.uuid == signInUser.data.defaultSiteUUID
                    ? site
                    : {
                            city: site.city,
                            country: site.country,
                            hours: site.hours,
                            id: site.id,
                            name: site.name,
                            pms: site.pms,
                            postalCode: site.postalCode,
                            state: site.state,
                            streetAddress: site.streetAddress,
                            suite: site.suite,
                            timeZone: site.timeZone,
                            uuid: site.uuid,
                            companyUUID: site.companyUUID
                        }
            );
            sessionStorage.setItem('user_data', JSON.stringify(signInUser.data));
            localStorage.setItem('nse_notify', JSON.stringify({ token: null, from: 'michael scott was here' }));
            Sentry.getCurrentScope().setUser({
                email: signInUser.data.email,
                id: signInUser.data.uuid,
                username: `${signInUser.data.firstName} ${signInUser.data.lastName}`
            });
            LogRocket.identify(`${signInUser.data.uuid}`, {
                username: `${signInUser.data.firstName} ${signInUser.data.lastName}`,
                email: signInUser.data.email
            });
            const loginData = {
                company: signInUser.data.companyUUID,
                companyUUID: signInUser.data.companyUUID,
                site: signInUser.data.defaultSiteUUID,
                siteUUID: signInUser.data.defaultSiteUUID,
                country: payload.data.country && payload.data.country,
                phone: signInUser.data.phone && signInUser.data.phone,
                email: signInUser.data.email,
                token: signInUser.token ? signInUser.token : null
            };
            localStorage.setItem('nse_login_data', JSON.stringify(loginData));
            localStorage.setItem('nse_login_obj', JSON.stringify(loginData));
            if (payload.data.country) {
                yield put(setCountry(payload.data.country));
            }
            yield put(fetchWeather({ lat: `${site.latitude}`, lon: `${site.longitude}` }));
            yield put(fetchAirQuality({ lat: `${site.latitude}`, lon: `${site.longitude}` }));
            yield put(fetchWeatherWeek({ lat: `${site.latitude}`, lon: `${site.longitude}` }));
            const theme = yield select(themeState);
            let themeStorage = JSON.parse(localStorage.getItem('nse_theme'))
            site.map = [
                {
                    color: theme ? theme.color.themeColor : themeStorage ? themeStorage.color.themeColor : '#0070CE',
                    lat: site.latitude && site.latitude !== '' ? JSON.parse(site.latitude) : 0,
                    lng: site.longitude && site.longitude !== '' ? JSON.parse(site.longitude) : 0
                }
            ];
            site.actualMap = [
                {
                    color: theme ? theme.color.themeColor : themeStorage ? themeStorage.color.themeColor : '#0070CE',
                    lat: site.actualLatitude && site.actualLatitude !== '' ? JSON.parse(site.actualLatitude) : 0,
                    lng: site.actualLongitude && site.actualLongitude !== '' ? JSON.parse(site.actualLongitude) : 0
                }
            ];
            yield put(setSite(site));
            yield put(setViewAs(null));
            if (
                signInUser &&
                signInUser.data &&
                signInUser.data.role &&
                signInUser.data.role.tier &&
                signInUser.data.role.tier > 1 &&
                signInUser.data.permissions &&
                signInUser.data.permissions.includes('manage_employees') &&
                signInUser.data.permissions.includes('manage_users') &&
                signInUser.data.featureflagAssignments.includes('enterprise_dashboard') &&
                signInUser.data.sites &&
                signInUser.data.sites.length > 1 &&
                signInUser.data.state == 'active'
            ) {
                if (localStorage.getItem('token_expiration_url')) {
                    let url = localStorage.getItem('token_expiration_url');
                    localStorage.removeItem('token_expiration_url');
                    yield history.push('/' + url.replace('signin', ''));
                } else {
                    sessionStorage.setItem('manager_dash', true);
                    yield history.push('/dashboard');
                }
            } else {
                sessionStorage.removeItem('manager_dash');
                // if(customVersion && window.location.href && !window.location.href.includes('localhost')){
                //     let referenceURL = window.location.origin;
                //     window.location = `${referenceURL}/?v=${customVersion}`
                //     localStorage.setItem('nse_notify', JSON.stringify({ token: null, from: 'michael scott was here' }));
                // } else {
                    yield history.push('/app/home');
                    localStorage.setItem('nse_notify', JSON.stringify({ token: null, from: 'michael scott was here' }));
                // } 
            }
            // if user got kicked for expired token lets get them back where they were before that happened
            if (localStorage.getItem('token_expiration_url')) {
                let url = localStorage.getItem('token_expiration_url');
                localStorage.removeItem('token_expiration_url');
                yield history.push('/' + url.replace('signin', ''));
            }
        } else {
            required.phoneLogin = payload.data.phoneLogin;
            yield history.push({ pathname: '/site', state: { login: required, sites: signInUser.data.sites } });
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestForgotPassword({ payload }) {
    try {
        const required = payload.data.phoneLogin
            ? {
                  companyUUID: payload.data.companyUUID ? payload.data.companyUUID : '-1',
                  countryCode: payload.data.countryCode,
                  phone: payload.data.phone
              }
            : {
                  companyUUID: payload.data.companyUUID ? payload.data.companyUUID : '-1',
                  email: payload.data.email
              };
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        yield put(showAuthLoader());
        const forgot = yield call(LOGIN, payload.url, required, payload);
        if (!forgot) {
            return;
        } else if (forgot.result === 'failure') {
            yield put(showMessage(forgot.message, 'message'));
        } else {
            yield put(showMessage('notifications.success.sent', 'success'));
            history.push('/signin/pin');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResetPassword({ payload }) {
    try {
        const required = {
            firstName: payload.data.FirstName,
            lastName: payload.data.LastName,
            password: payload.data.Password
        };
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        if (payload.data.Password.length < 8) {
            yield put(showMessage('auth.reset.sort.password', 'warning'));
            return;
        }
        if (payload.data.Password != payload.data.PasswordVerify) {
            yield put(showMessage('auth.reset.newVerifyPassword', 'warning'));
            return;
        }
        yield put(showAuthLoader());
        const signInUser = yield call(POST, payload.url, required);
        if (signInUser.result === 'failure') {
            yield put(showMessage(signInUser.message, 'message'));
            yield put(hideAuthLoader());
        }
        if (!signInUser.data.roles) {
            signInUser.data.roles = [];
        }
        if (!signInUser.data.featureflagAssignments) {
            signInUser.data.featureflagAssignments = [];
        }
        let user = JSON.parse(sessionStorage.getItem('user_data'));
        delete user.Login;
        user.token = signInUser.token;
        user.state = signInUser.data.state;
        user.firstName = signInUser.data.firstName;
        user.lastName = signInUser.data.lastName;
        // feature flags were originally not getting set here, but we made a backend adjustment to send them in this unique situation
        user.featureflagAssignments = signInUser.data.featureflagAssignments && signInUser.data.featureflagAssignments.length && signInUser.data.featureflagAssignments.length > 0 ? signInUser.data.featureflagAssignments : [];
        sessionStorage.setItem('user_data', JSON.stringify(user));
        yield put(userSignInSuccess(user));
        yield put(showMessage('notifications.success.edit', 'success'));
        if (
            user &&
            user &&
            user.role &&
            user.role.tier &&
            user.role.tier > 1 &&
            user.permissions &&
            user.permissions.includes('manage_employees') &&
            user.permissions.includes('manage_users') &&
            user.featureflagAssignments.includes('enterprise_dashboard') &&
            user.sites &&
            user.sites.length > 1 &&
            user.state == 'active'
        ) {
            if (localStorage.getItem('token_expiration_url')) {
                let url = localStorage.getItem('token_expiration_url');
                localStorage.removeItem('token_expiration_url');
                yield history.push('/' + url.replace('signin', ''));
            } else {
                sessionStorage.setItem('manager_dash', true);
                yield history.push('/dashboard');
            }
        } else {
            sessionStorage.removeItem('manager_dash');
            // if(customVersion && window.location.href && !window.location.href.includes('localhost')){
            //     let referenceURL = window.location.origin;
            //     window.location = `${referenceURL}/?v=${customVersion}`
            //     localStorage.setItem('nse_notify', JSON.stringify({ token: null, from: 'michael scott was here' }));
            // } else {
                yield history.push('/app/home');
                localStorage.setItem('nse_notify', JSON.stringify({ token: null, from: 'michael scott was here' }));
            // } 
        }
        yield put(hideAuthLoader());
    } catch (error) {
        console.warn(error);
    }
}

function* requestForgotUserPassword({ payload }) {
    try {
        const forgot = yield call(POST, payload.url, payload.data);
        if (!forgot) {
            yield put(showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(
                payload.data.phone
                    ? showMessage('notifications.success.sentText', 'success')
                    : showMessage('notifications.success.sent', 'success')
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResetUserPassword({ payload }) {
    try {
        if (payload.data.UserPwd === '') {
            yield put(showMessage('auth.reset.blank.password'));
            return;
        }
        if (payload.data.NewUserPwd === '' || payload.data.ConfirmNewPassword === '') {
            yield put(showMessage('auth.reset.blank.password'));
            return;
        }
        if (payload.data.NewUserPwd.length < 8 || payload.data.ConfirmNewPassword.length < 8) {
            yield put(showMessage('auth.reset.sort.password'));
            return;
        }
        if (payload.data.NewUserPwd != payload.data.ConfirmNewPassword) {
            yield put(showMessage('auth.reset.newVerifyPassword'));
            return;
        }
        delete payload.data.ConfirmNewPassword;
        const password = yield call(POST, payload.url, payload.data, payload.token);
        if (password.message) {
            if (password.message === 'Token is expired') {
                yield call(signOut);
            } else {
                yield put(showMessage(password.message, 'message'));
            }
        } else {
            yield put(showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResetUserPin({ payload }) {
    try {
        if (payload.data.UserPwd === '') {
            yield put(showMessage('auth.reset.blank.password'));
            return;
        }
        if (payload.data.NewPin === '' || payload.data.ConfirmNewPin === '') {
            yield put(showMessage('auth.reset.blank.pin'));
            return;
        }
        if (payload.data.NewPin.length < 4 || payload.data.ConfirmNewPin.length < 4) {
            yield put(showMessage('auth.reset.sort.pin'));
            return;
        }
        if (payload.data.NewPin != payload.data.ConfirmNewPin) {
            yield put(showMessage('auth.reset.newVerifyPin'));
            return;
        }
        delete payload.data.ConfirmNewPin;
        const pin = yield call(POST, payload.url, payload.data, payload.token);
        if (pin.message) {
            if (pin.message === 'Token is expired') {
                yield call(signOut);
            } else {
                yield put(showMessage(pin.message, 'message'));
            }
        } else {
            yield put(showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSwitchSite({ payload }) {
    try {
        yield put(setAvailableWidgets(null));
        yield put(ACTIONS_APP.setDashboardWidgets(null));
        yield put(ACTIONS_SETTINGS.setAllSchedules(null));
        yield put(ACTIONS_ACTIVITY.setActivityView(null));
        yield put(ACTIONS_UNITS.setUnitListStats(null));
        yield put(ACTIONS_UNITS.setUnitListLockStats(null));
        yield put(ACTIONS_USERS.setUserFilterSchedule('all'));
        yield put(ACTIONS_UNITS.setUnitListLockStatusStats(null));
        yield put(ACTIONS_APP.setAvailableWidgets(null));
        const user = yield call(payload.token ? POST_TOKEN : POST, payload.url, payload.data, payload.token);
        if (!user) {
            if (payload.token) {
                yield call(signOut);
                yield put(ACTIONS_APP.setSwitcherLoader({loading: false, text: ''}));
                return;
            }
            return;
        } else {
            let newSite = user.data.sites.filter((site) => site.uuid == payload.data.siteUUID)[0];
            yield put(fetchWeather({ lat: `${newSite.latitude}`, lon: `${newSite.longitude}` }));
            yield put(fetchAirQuality({ lat: `${newSite.latitude}`, lon: `${newSite.longitude}` }));
            yield put(fetchWeatherWeek({ lat: `${newSite.latitude}`, lon: `${newSite.longitude}` }));
            user.data.token = user.token;
            user.data.sites = user.data.sites.map((site) =>
                site.uuid == payload.data.siteUUID
                    ? site
                    : site && {
                          city: site.city,
                          country: site.country,
                          hours: site.hours,
                          id: site.id,
                          name: site.name,
                          pms: site.pms,
                          postalCode: site.postalCode,
                          state: site.state,
                          streetAddress: site.streetAddress,
                          suite: site.suite,
                          timeZone: site.timeZone,
                          uuid: site.uuid,
                          has3K: site.has3K,
                          companyUUID: site.companyUUID
                      }
            );
            sessionStorage.setItem('user_data', JSON.stringify(user.data));
            let serverUrl = sessionStorage.getItem('nse_url');
            let urlType = sessionStorage.getItem('nse_url_type');

            // custom means user manually inputed custom backend url
            // localhost will default to dev router
            // if it is aws.noke url we need to make sure to pre-pend nse-router
            // otherwise we just need to pre-pend router.
            urlType && urlType === 'custom'
            ?
            sessionStorage.setItem('nse_url', serverUrl)
            :
            window.location.hostname.includes('localhost')
            ?
            sessionStorage.setItem('nse_url', 'https://router.smartentry.noke.dev')
            :
            window.location.hostname.includes('aws.noke.')
            ?
            sessionStorage.setItem('nse_url', `https://${window.location.hostname.replace('frontend', 'nse-router')}`)
            :
            sessionStorage.setItem('nse_url', `https://router.${window.location.hostname}`);
            urlType && urlType !== 'custom' && sessionStorage.setItem('nse_url_type', 'server');
            if (payload.simplifiedView) {
                if (payload.simplifiedView == 'ON') {
                    user.data.simplifiedView = true;
                } else {
                    user.data.simplifiedView = false;
                }
            }
            if (!user.data.permissions) {
                user.data.permissions = [];
            }
            if (!user.data.roles) {
                user.data.roles = [];
            }
            if (!user.data.featureflagAssignments) {
                user.data.featureflagAssignments = [];
            }
            let supportnewUser = user.data.roles.find((role) => role.tier === 1);
            let globalInstaller = user.data.roles.find((role) => role.name === 'Global Installer');
            if (supportnewUser) {
                user.data.role = supportnewUser;
            } else if (globalInstaller) {
                user.data.role = globalInstaller;
            } else {
                user.data.role = user.data.roles.find((role) => role.siteUUID === newSite.uuid);
            }
            user.data.token = user.token;
            user.data.sites = user.data.sites.map((site) =>
                site.uuid == payload.data.siteUUID
                    ? site
                    : site && {
                          city: site.city,
                          country: site.country,
                          hours: site.hours,
                          id: site.id,
                          name: site.name,
                          pms: site.pms,
                          postalCode: site.postalCode,
                          state: site.state,
                          streetAddress: site.streetAddress,
                          suite: site.suite,
                          timeZone: site.timeZone,
                          uuid: site.uuid,
                          has3K: site.has3K,
                          companyUUID: site.companyUUID
                      }
            );
            sessionStorage.setItem('user_data', JSON.stringify(user.data));
            if (payload.token) {
                let nse_login_data = {
                    company: newSite.companyUUID,
                    companyUUID: newSite.companyUUID,
                    email: user.data.email,
                    phone: user.data.phone,
                    site: newSite.uuid,
                    siteUUID: newSite.uuid,
                    country: Object.values(countries)[232],
                    token: payload.token
                };
                localStorage.setItem('nse_login_data', JSON.stringify(nse_login_data));
                localStorage.setItem('nse_login_obj', JSON.stringify(nse_login_data));
            }
            yield put(userSignInSuccess(user.data));
            if (!user.data.role) {
                yield put(showMessage('notifications.failed.role'));
                yield call(signOut);
                return;
            }
            const theme = yield select(themeState);
            let themeStorage = JSON.parse(localStorage.getItem('nse_theme'))
            let nse_login_data = JSON.parse(localStorage.getItem('nse_login_data'));
            nse_login_data.site = newSite.uuid;
            nse_login_data.siteUUID = newSite.uuid;
            nse_login_data.company = newSite.companyUUID;
            nse_login_data.companyUUID = newSite.companyUUID;
            localStorage.setItem('nse_login_data', JSON.stringify(nse_login_data));
            localStorage.setItem('nse_login_obj', JSON.stringify(nse_login_data));
            newSite.map = [
                {
                    color: theme ? theme.color.themeColor : themeStorage ? themeStorage.color.themeColor : '#0070CE',
                    lat: newSite.latitude && newSite.latitude !== '' ? JSON.parse(newSite.latitude) : 0,
                    lng: newSite.longitude && newSite.longitude !== '' ? JSON.parse(newSite.longitude) : 0
                }
            ];
            newSite.actualMap = [
                {
                    color: theme ? theme.color.themeColor : themeStorage ? themeStorage.color.themeColor : '#0070CE',
                    lat:
                        newSite.actualLatitude && newSite.actualLatitude !== ''
                            ? JSON.parse(newSite.actualLatitude)
                            : 0,
                    lng:
                        newSite.actualLongitude && newSite.actualLongitude !== ''
                            ? JSON.parse(newSite.actualLongitude)
                            : 0
                }
            ];
            yield put(setSite(newSite));
            yield put(ACTIONS_NOTIFICATIONS.setUserNotifications([]));
            yield put(ACTIONS_GATEWAY.setGatewayList(null));
            yield put(ACTIONS_ROLES.setRoleList(null));
            yield put(ACTIONS_USERS.setUserFilters([]));
            yield put(ACTIONS_APP.fetchTheme(newSite.companyUUID));
            user.data && user.data.permissions && user.data.permissions.includes('manage_sites')
            ?
            yield put(ACTIONS_SETTINGS.fetchAllSchedules())
            :
            null;
            yield put(ACTIONS_SETTINGS.fetchSiteHours());
            yield put(setViewAs(null));
            if (payload.token) {
                if (!payload.support) {
                    yield history.push('/app/home/all');
                }
                return;
            }
            const url = window.location.href;
            if (url.includes('info/create/pms')) {
                yield history.push(`/app/settings/info/all`);
            }
            if (url.includes('signin/confirm')){
                yield history.push(`/app/home/all`);
            }
            if (payload.userUUID) {
                yield history.push(`/app/users/display/${payload.userUUID}`);
            }
            if (payload.unitUUID) {
                yield history.push(`/app/units/display/${payload.unitUUID}`);
            }
            if (user.data.role && user.data.role.tier && user.data.role.tier !== 1) {
                yield put(ACTIONS_NOTIFICATIONS.fetchUserNotifications(1, 100));
            }
            yield put(ACTIONS_APP.setSwitcherLoader({loading: false, text: ''}));
            if (payload.redirectHome) {
                if (payload.redirectHome === true) {
                    yield history.push('/app/home');
                } else {
                    yield history.push(payload.redirectHome);
                }
                // let customVersion = newSite && newSite.nseRouterVersion && newSite.nseRouterVersion.name && newSite.nseRouterVersion.name !== "" ? newSite.nseRouterVersion.name : sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : null;
                // newSite && newSite.nseRouterVersion && newSite.nseRouterVersion.name && newSite.nseRouterVersion.name !== "" ? sessionStorage.setItem('nse_version', newSite.nseRouterVersion.name) : localStorage.removeItem('nse_version');
                // if(customVersion && window.location.href && !window.location.href.includes('localhost')){
                //     let referenceURL = window.location.origin;
                //     window.location = `${referenceURL}/?v=${customVersion}`
                // } else {
                    // yield history.push('/app/home');
                // } 
            }
            
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSyncSite({ payload }) {
    try {
        const sync = yield call(POST, payload.url, payload.data);
        if (!sync) {
            return;
        } else {
            if (payload.data.forceSync && payload.notify) {
                yield put(ACTIONS_APP.showMessage('notifications.success.forceSync', 'success'));
            } else if (payload.notify) {
                yield put(ACTIONS_APP.showMessage('notifications.success.sync', 'success'));
            }
            let nse_login_data = JSON.parse(localStorage.getItem('nse_login_data'));
            if (nse_login_data.site != payload.site.uuid) {
                yield put(setSwitcherLoader({loading: true, text: ''}));
                yield put(switchSite(payload.site.uuid));
            } else {
                let user_data = JSON.parse(sessionStorage.getItem('user_data'));
                let siteIndex = user_data.sites.findIndex((site) => site.uuid == payload.site.uuid);
                user_data.sites[siteIndex].lastSyncDate = moment().utc().format();
                const theme = yield select(themeState);
                let themeStorage = JSON.parse(localStorage.getItem('nse_theme'))
                user_data.sites[siteIndex].map = [
                    {
                        color: theme ? theme.color.themeColor : themeStorage ? themeStorage.color.themeColor : '#0070CE',
                        lat: user_data.sites[siteIndex].latitude
                            ? JSON.parse(user_data.sites[siteIndex].latitude)
                            : '0',
                        lng: user_data.sites[siteIndex].longitude
                            ? JSON.parse(user_data.sites[siteIndex].longitude)
                            : '0'
                    }
                ];
                user_data.sites[siteIndex].actualMap = [
                    {
                        color: theme ? theme.color.themeColor : themeStorage ? themeStorage.color.themeColor : '#0070CE',
                        lat:
                            user_data.sites[siteIndex].actualLatitude &&
                            user_data.sites[siteIndex].actualLatitude !== ''
                                ? JSON.parse(user_data.sites[siteIndex].actualLatitude)
                                : 0,
                        lng:
                            user_data.sites[siteIndex].actualLongitude &&
                            user_data.sites[siteIndex].actualLongitude !== ''
                                ? JSON.parse(user_data.sites[siteIndex].actualLongitude)
                                : 0
                    }
                ];
                sessionStorage.setItem('user_data', JSON.stringify(user_data));
                yield put(setSite(user_data.sites[siteIndex]));
            }
            yield put(setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSubmitSite({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        if (!payload.data.otherPMS) {
            payload.data.otherPMS = 'none';
        }
        if (!payload.data.facilityUUID) {
            payload.data.facilityUUID = 'none';
        }
        if (!payload.data.apiCreds) {
            payload.data.apiCreds = 'none';
        }
        window.location.href = `mailto:${CONSTANTS.SITE_CREATE_EMAIL}?subject=New Site Create Request for ${payload.data.companyName}&body=
        %0D%0A Site Type: ${payload.data.siteType}
        %0D%0A Open Date: ${payload.data.siteOpenDate}
        %0D%0A Install Date: ${payload.data.siteInstallDate}
        %0D%0A Company Name: ${payload.data.companyName}
        %0D%0A Facility Name: ${payload.data.facilityName}
        %0D%0A Street Address: ${payload.data.street}
        %0D%0A City: ${payload.data.city}
        %0D%0A Region/State: ${payload.data.region}
        %0D%0A Postal Code: ${payload.data.postalCode}
        %0D%0A Country: ${payload.data.country}
        %0D%0A Country Code: ${payload.data.countryCode.phone}
        %0D%0A Phone: ${payload.data.phone}
        %0D%0A Facility Email Address: ${payload.data.facilityEmail}
        %0D%0A Facility URL: ${payload.data.facilityUrl}
        %0D%0A Payment URL: ${payload.data.paymentUrl}
        %0D%0A Operating Hours: ${payload.data.operatingHours}
        %0D%0A Number of Smart Units: ${payload.data.smartUnits}
        %0D%0A Preferred App Color: ${payload.data.appColor}
        %0D%0A Payment Management Software: ${payload.data.paymentManagementSoftware}
        %0D%0A Other PMS: ${payload.data.otherPMS}
        %0D%0A API Credentials: ${payload.data.apiCreds}
        %0D%0A Facility ID: ${payload.data.facilityUUID}
        %0D%0A Facility/Company Logo URL: ${payload.data.companyLogo}
        %0D%0A Facility Image URL: ${payload.data.facilityImage}
    `;
    } catch (error) {
        yield put(showMessage('notifications.error.fail.siteCreate', 'warning'));
        console.warn(error);
    }
}

function* requestResetPinCall({ payload }) {
    try {
        const forgot = yield call(LOGIN, payload.url, payload.data);
        if (!forgot) {
            yield put(showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(showMessage('notifications.success.sentCall', 'success'));
            yield history.push('/signin/pin');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchAuthPin({ payload }) {
    try {
        const pin = yield call(POST, payload.url, payload.data);
        if (!pin) {
            yield put(showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            if (pin.data && pin.data.confirmPin) {
                let user = JSON.parse(sessionStorage.getItem('user_data'));
                let authDevice = JSON.parse(localStorage.getItem('auth_device'))
                    ? JSON.parse(localStorage.getItem('auth_device'))
                    : [];
                !authDevice.includes(user.uuid) && authDevice.push(user.uuid);
                localStorage.setItem('auth_device', JSON.stringify(authDevice));
                sessionStorage.setItem('user_data', JSON.stringify(user));
                yield put(showMessage('form.device2FAMatch', 'success'));
                yield put(setLoader(false));
                if(user.state == 'update_required'){
                    yield put(setAuthUser(user));
                    yield put(showTwoFactor(false));
                    yield history.push('/setup/password');
                } else {
                    yield put(setLoader(false));
                    yield put(hideAuthLoader());
                    yield put(setSwitcherLoader({loading: true, text: ''}));
                    yield put(switchSite(user.defaultSiteUUID));
                }
            } else {
                yield put(setLoader(false));
                yield put(showMessage('form.device2FAMismatch', 'warning'));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSendAuthPin({ payload }) {
    try {
        const pin = yield call(POST, payload.url, payload.data);
        if (!pin) {
            yield put(showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(showMessage('form.device2FAConfirm', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestValidateUser({ payload }) {
    try {
        yield put(showAuthLoader());
        const validate = yield call(LOGIN, payload.url, payload.data);
        if (!validate || !validate.data) {
            sessionStorage.setItem('login_confirm', JSON.stringify(payload.data));
            localStorage.setItem('login_confirm', JSON.stringify(payload.data));
            yield put(setLoginConfirm(payload.data));
            yield put(hideAuthLoader());
            return;
        } else if (validate.data.loginUrl && validate.data.loginUrl !== '') {
            yield put(showMessage('notifications.success.oktaRedirect', 'success'));
            sessionStorage.setItem('login_confirm', JSON.stringify(payload.data));
            localStorage.setItem('login_confirm', JSON.stringify(payload.data));
            yield put(setLoginConfirm(payload.data));
            yield put(hideAuthLoader());
            window.location = validate.data.loginUrl;
        } else {
            sessionStorage.setItem('login_confirm', JSON.stringify(payload.data));
            localStorage.setItem('login_confirm', JSON.stringify(payload.data));
            yield put(setLoginConfirm(payload.data));
            yield history.push('/signin/confirm');
            yield put(hideAuthLoader());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRefreshAuthToken({ payload }) {
    try {
        const refresh = yield call(POST, payload.url, payload.data);
    } catch (error) {
        console.warn(error);
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signIn);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* selectForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, requestForgotPassword);
}

export function* selectResetPassword() {
    yield takeEvery(RESET_PASSWORD, requestResetPassword);
}

export function* selectForgotUserPassword() {
    yield takeEvery(FORGOT_USER_PASSWORD, requestForgotUserPassword);
}

export function* selectResetUserPassword() {
    yield takeEvery(RESET_USER_PASSWORD, requestResetUserPassword);
}

export function* selectResetUserPin() {
    yield takeEvery(RESET_USER_PIN, requestResetUserPin);
}

export function* selectSwitchSite() {
    yield takeEvery(SWITCH_SITE, requestSwitchSite);
}

export function* selectSyncSite() {
    yield takeEvery(SYNC_SITE, requestSyncSite);
}

export function* selectResetPinCall() {
    yield takeEvery(RESET_PIN_CALL, requestResetPinCall);
}

export function* selectFetchAuthPin() {
    yield takeEvery(FETCH_AUTH_PIN, requestFetchAuthPin);
}

export function* selectSendAuthPin() {
    yield takeEvery(SEND_AUTH_PIN, requestSendAuthPin);
}

export function* selectValidateUser() {
    yield takeEvery(VALIDATE_USER, requestValidateUser);
}

export function* selectRefreshAuthToken() {
    yield takeEvery(REFRESH_AUTH_TOKEN, requestRefreshAuthToken);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(selectForgotPassword),
        fork(selectResetPassword),
        fork(selectForgotUserPassword),
        fork(selectResetUserPassword),
        fork(selectResetUserPin),
        fork(selectSwitchSite),
        fork(selectSyncSite),
        fork(signOutUser),
        fork(selectResetPinCall),
        fork(selectFetchAuthPin),
        fork(selectSendAuthPin),
        fork(selectValidateUser),
        fork(selectRefreshAuthToken)
    ]);
}
