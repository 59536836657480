//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY KEEPER LIST                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_KEEPER_LIST = '/gateway/stats/';
export const ENDPOINT_GATEWAY_KEEPER_LIST_OLD = '/stats/';
export const PURPOSE_GATEWAY_KEEPER_LIST = "Fetch a list of the site's gateways and stats from gateway keeper.";
export const PERMISSIONS_GATEWAY_KEEPER_LIST = [];
export const REQUIREMENTS_GATEWAY_KEEPER_LIST = ['API KEY'];
export const PARAMETER_GATEWAY_KEEPER_LIST = {
    siteID: 0
};
export const RETURN_GATEWAY_KEEPER_LIST = {
    gateways: [],
    stats: []
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY KEEPER DEVICE HISTORY      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_KEEPER_DEVICE_HISTORY = '/gateway/unit/history/';
export const PURPOSE_GATEWAY_KEEPER_DEVICE_HISTORY = "Fetch a device's history from gateway keeper.";
export const PERMISSIONS_GATEWAY_KEEPER_DEVICE_HISTORY = [];
export const REQUIREMENTS_GATEWAY_KEEPER_DEVICE_HISTORY = ['API KEY'];
export const PARAMETER_GATEWAY_KEEPER_DEVICE_HISTORY = {
    mac: ''
};
export const RETURN_GATEWAY_KEEPER_DEVICE_HISTORY = {
    data: {}
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY KEEPER DEVICE LIST         ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_KEEPER_DEVICE_LIST = '/gateway/details/';
export const ENDPOINT_GATEWAY_KEEPER_DEVICE_LIST_OLD = '/details/';
export const PURPOSE_GATEWAY_KEEPER_DEVICE_LIST = 'Fetch device list from gateway keeper.';
export const PERMISSIONS_GATEWAY_KEEPER_DEVICE_LIST = [];
export const REQUIREMENTS_GATEWAY_KEEPER_DEVICE_LIST = ['API KEY'];
export const PARAMETER_GATEWAY_KEEPER_DEVICE_LIST = {
    siteID: 0
};
export const RETURN_GATEWAY_KEEPER_DEVICE_LIST = {
    data: []
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY KEEPER GATEWAY HISTORY     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_KEEPER_GATEWAY_HISTORY = '/gateway/history/';
export const PURPOSE_GATEWAY_KEEPER_GATEWAY_HISTORY = "Fetch a gateways's history from gateway keeper.";
export const PERMISSIONS_GATEWAY_KEEPER_GATEWAY_HISTORY = [];
export const REQUIREMENTS_GATEWAY_KEEPER_GATEWAY_HISTORY = ['API KEY'];
export const PARAMETER_GATEWAY_KEEPER_GATEWAY_HISTORY = {
    mac: ''
};
export const RETURN_GATEWAY_KEEPER_GATEWAY_HISTORY = {
    data: {}
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY KEEPER REBOOT GATEWAYS     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REBOOT_GATEWAYS = '/gateway/history/';
export const PURPOSE_REBOOT_GATEWAYS = 'Force all gateways to reboot.';
export const PERMISSIONS_REBOOT_GATEWAYS = [];
export const REQUIREMENTS_REBOOT_GATEWAYS = ['API KEY'];
export const PARAMETER_REBOOT_GATEWAYS = {
    data: {}
};
export const RETURN_REBOOT_GATEWAYS = {
    data: {}
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY KEEPER REBOOT GATEWAY      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REBOOT_GATEWAY = '/gateway/history/';
export const PURPOSE_REBOOT_GATEWAY = 'Force all gateways to reboot.';
export const PERMISSIONS_REBOOT_GATEWAY = [];
export const REQUIREMENTS_REBOOT_GATEWAY = ['API KEY'];
export const PARAMETER_REBOOT_GATEWAY = {
    data: {}
};
export const RETURN_REBOOT_GATEWAY = {
    data: {}
};
