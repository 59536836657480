export const theme = {
    Id: 1,
    companyId: 0,
    title: 'Smart Entry',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#65A0CC',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const janus = {
    Id: 2,
    companyId: 1,
    title: 'Smart Entry',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#65A0CC',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const ess = {
    Id: 3,
    companyId: 2,
    title: 'Extra Space',
    logo: 'assets/images/Themes/ess/logo.png',
    menuLogo: 'assets/images/Themes/ess/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#86BF37',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const extraspace = {
    Id: 4,
    companyId: 2,
    title: 'Extra Space',
    logo: 'assets/images/Themes/ess/logo.png',
    menuLogo: 'assets/images/Themes/ess/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#86BF37',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const primestoragegroup = {
    Id: 5,
    companyId: 5,
    title: 'Prime Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#2C3076',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const nationalselfstorage = {
    Id: 6,
    companyId: 9,
    title: 'National Self Storage',
    logo: 'assets/images/Themes/national/logo.png',
    menuLogo: 'assets/images/Themes/national/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#000000',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const lakeviewselfstorage = {
    Id: 7,
    companyId: 10,
    title: 'Lakeview Self Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#007934',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const staxupstorage = {
    Id: 8,
    companyId: 12,
    title: 'Stax up Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#F9423A',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const keylockstorage = {
    Id: 9,
    companyId: 13,
    title: 'Key Lock Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#0062af',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const daffodilstorage = {
    Id: 10,
    companyId: 14,
    title: 'Daffodil Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#128443',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const morningstarstorage = {
    Id: 11,
    companyId: 15,
    title: 'Morningstar Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#0050a1',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const trivillageselfstorage = {
    Id: 12,
    companyId: 16,
    title: 'Tri-Village Self Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#274e67',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const allaboardstorage = {
    Id: 13,
    companyId: 17,
    title: 'All Aboard Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#44d62c',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const locktitestorage = {
    Id: 14,
    companyId: 18,
    title: 'LockTite Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#037b34',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const comfortstorage = {
    Id: 15,
    companyId: 19,
    title: 'Comfort Storage',
    logo: 'assets/images/Themes/comfort/logo.png',
    menuLogo: 'assets/images/Themes/comfort/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#FFC72C',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const safeguardselfstorage = {
    Id: 16,
    companyId: 20,
    title: 'Safeguard Self Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#FFD100',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const storage550 = {
    Id: 17,
    companyId: 21,
    title: '550 Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#f09763',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const greenstorage = {
    Id: 18,
    companyId: 22,
    title: 'Green Storage',
    logo: 'assets/images/Themes/greenstorage/logo.png',
    menuLogo: 'assets/images/Themes/greenstorage/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#44D62C',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const ultraselfstorage = {
    Id: 19,
    companyId: 23,
    title: 'Ultra Self Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#00B0B9',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const onguardstorage = {
    Id: 20,
    companyId: 24,
    title: 'On Guard Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#212322',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const expressstorage = {
    Id: 21,
    companyId: 25,
    title: 'Express Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#BE1E2D',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const carolinasecurestorage = {
    Id: 22,
    companyId: 26,
    title: 'Carolina Secure Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#0055F1',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const akikb = {
    Id: 23,
    companyId: 27,
    title: 'Aki KB Minibodegas',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#FF671F',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const lockawaystorage = {
    Id: 24,
    companyId: 28,
    title: 'Lockaway Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#13914D',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const blackmetalstorage = {
    Id: 25,
    companyId: 29,
    title: 'Black Metal Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#000000',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const sobrostorage = {
    Id: 26,
    companyId: 30,
    title: 'Sobro Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#236192',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const dymonstorage = {
    Id: 27,
    companyId: 31,
    title: 'Dymon Storage',
    logo: 'assets/images/Themes/dymon/logo.png',
    menuLogo: 'assets/images/Themes/dymon/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#014C8F',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const storage4u = {
    Id: 28,
    companyId: 32,
    title: 'Storage 4 U',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#833177',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const willstor = {
    Id: 29,
    companyId: 33,
    title: 'Will Stor',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#FB8B25',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const cubesmart = {
    Id: 30,
    companyId: 34,
    title: 'Self Storage Facility',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#26ABE2',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const preserveselfstorage = {
    Id: 31,
    companyId: 36,
    title: 'Preserve Self Storage',
    logo: 'assets/images/Themes/Demo/logo.png',
    menuLogo: 'assets/images/Themes/Demo/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#00664e',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
export const nationalstorage = {
    Id: 32,
    companyId: 96,
    title: 'National Storage',
    logo: 'assets/images/Themes/nationalau/logo.png',
    menuLogo: 'assets/images/Themes/nationalau/logo.png',
    supportLink: 'https://securguardsupport.zendesk.com/hc/en-us',
    country: 'US',
    color: {
        pageColor: '#f5f7f9',
        menuColor: '#f5f7f9',
        headerColor: '#ffffff',
        compColor: '#ffffff',
        themeColor: '#ffda00',
        fontColor: '#808080',
        buttonFontColor: '#ffffff',
        borderColor: '#d8dcdf',
        go: '#00B687'
    }
};
