import * as CONSTANTS from 'constants';
import * as THEMES from 'themes';
import * as COLORS from 'themes/colors';
import moment from 'moment-timezone';
import latest from 'moment-timezone/data/meta/latest.json';
import { countries } from 'countries-list';

const subdomain = window.location.host.split('.');
var site = subdomain[0];
if (subdomain[0] === 'www') {
    site = subdomain[1];
}
if (site === '550storage') {
    site = 'storage550';
}
if (site === 'lockaway-storage') {
    site = 'lockawaystorage';
}

const userCountry = moment.tz.zone(moment.tz.guess()).countries().length !== 0 && latest.countries[moment.tz.zone(moment.tz.guess()).countries()[0]].name;
const userCountryObject = userCountry &&
Object.values(countries).filter(c => c.name === userCountry).length && Object.values(countries).filter(c => c.name === userCountry)[0];

const initialSettings = {
    navCollapsed: false,
    drawerType: CONSTANTS.FIXED_DRAWER,
    theme: JSON.parse(localStorage.getItem('nse_theme')) || THEMES[site] || THEMES.theme,
    color: JSON.parse(localStorage.getItem('nse_color')) || COLORS.light,
    width: window.innerWidth,
    height: window.innerHeight,
    isDirectionRTL: false,
    navigationStyle: CONSTANTS.VERTICAL_NAVIGATION,
    horizontalNavPosition: CONSTANTS.ABOVE_THE_HEADER,
    contentMessage: '',
    titleMessage: '',
    typeMessage: 'error',
    showMessage: false,
    locale: localStorage.getItem('nse_local') || 'en',
    country:
        localStorage.getItem('nse_login_data') && JSON.parse(localStorage.getItem('nse_login_data')).country
            ?
            JSON.parse(localStorage.getItem('nse_login_data')).country
            :
            userCountryObject
            ?
            userCountryObject
            :
            Object.values(countries)[232],
    ipLocation: null,
    showLoader: false,
    userTimezone: moment.tz.guess(),
    errorDetails: null,
    errorDetailsDisplay: null,
    errorList: null,
    weatherInfo: null,
    weatherWeekInfo: null,
    switcherLoader: {
        loading: false,
        text: ''
    },
    facilityFeedback: null,
    airQualityIndex: null,
    saveLoader: false,
    showTestSuite: false,
    siteVersion: null,
    userSiteList: null,
    showPortalSearch: false,
    webhooksSiteDetails: null,
    webhooksClientDetails: null,
    filterCreds: {
        list: [],
        action: null
    },
    errorHeader: [
        {
            id: 0,
            title: 'comp.table.header.code',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'code',
            mobile: true,
            width: 103,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.subcode',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'subcode',
            width: 80,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'type',
            width: 80,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.description',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'description',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.troubleshoot',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'troubleshoot',
            style: { textAlign: 'left' }
        }
    ],
    dashboardWidgets: null,
    availableWidgets: null,
    feedbackHeaders: [
        {
            id: 0,
            title: 'form.user',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'userFirstName',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'pages.feedback.rating',
            width: 98,
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'rating',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.date',
            width: 80,
            active: true,
            sortable: true,
            order: false,
            mobile: true,
            sortTitle: 'dateSubmitted',
            style: { textAlign: 'right' }
        }
    ],
};

const app = (state = initialSettings, action) => {
    let tempTheme = { ...state.theme };
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false
            };
        case CONSTANTS.TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.isNavCollapsed
            };
        case CONSTANTS.WINDOW_WIDTH:
            return {
                ...state,
                width: action.width
            };
        case CONSTANTS.WINDOW_HEIGHT:
            return {
                ...state,
                height: action.height
            };
        case CONSTANTS.SET_THEME:
            localStorage.setItem('nse_theme', JSON.stringify(action.theme));
            return {
                ...state,
                theme: action.theme
            };
        case CONSTANTS.SET_THEME_COLORS:
            tempTheme.color = {
                name: action.colors.name,
                pageColor: action.colors.pageColor,
                menuColor: action.colors.menuColor,
                headerColor: action.colors.headerColor,
                compColor: action.colors.compColor,
                themeColor: tempTheme.color.themeColor,
                fontColor: action.colors.fontColor,
                subFontColor: action.colors.subFontColor,
                buttonFontColor: action.colors.buttonFontColor,
                borderColor: action.colors.borderColor,
                boxShadow: action.colors.boxShadow,
                boxShadowCorner: action.colors.boxShadowCorner,
                boxShadowCornerHover: action.colors.boxShadowCornerHover,
                boxShadowIntense: action.colors.boxShadowIntense,
                go: action.colors.go
            };
            localStorage.setItem('nse_theme', JSON.stringify(tempTheme));
            localStorage.setItem('nse_color', JSON.stringify(tempTheme.color));
            return {
                ...state,
                color: tempTheme.color,
                theme: tempTheme
            };
        case CONSTANTS.SET_LANGUAGE:
            localStorage.setItem('nse_local', action.payload);
            // window.location.reload();
            return {
                ...state,
                locale: action.payload
            };
        case CONSTANTS.SHOW_MESSAGE:
            return {
                ...state,
                contentMessage: action.payload.message,
                titleMessage: action.payload.title,
                typeMessage: action.payload.type,
                showMessage: true
            };
        case CONSTANTS.HIDE_MESSAGE:
            return {
                ...state,
                contentMessage: '',
                showMessage: false
            };
        case CONSTANTS.SET_FILTER:
            return {
                ...state,
                filterCreds: action.payload
            };
        case CONSTANTS.SET_LOADER:
            return {
                ...state,
                showLoader: action.payload
            };
        case CONSTANTS.SET_IP_LOCATION:
            return {
                ...state,
                ipLocation: action.payload
            };
        case CONSTANTS.SET_COUNTRY:
            return {
                ...state,
                country: action.payload
            };
        case CONSTANTS.SET_ERROR_DETAILS:
            return {
                ...state,
                errorDetails: action.payload
            };
        case CONSTANTS.SET_ERROR_DETAILS_DISPLAY:
            return {
                ...state,
                errorDetailsDisplay: action.payload
            };
        case CONSTANTS.SET_ALL_ERRORS:
            return {
                ...state,
                errorList: action.payload
            };
        case CONSTANTS.SET_ERROR_HEADERS:
            return {
                ...state,
                errorHeader: action.payload
            };
        case CONSTANTS.SET_WEATHER:
            return {
                ...state,
                weatherInfo: action.payload
            };
        case CONSTANTS.SET_WEATHER_WEEK:
            return {
                ...state,
                weatherWeekInfo: action.payload
            };
        case CONSTANTS.SET_SWITCHER_LOADER:
            return {
                ...state,
                switcherLoader: action.payload
            };
        case CONSTANTS.SET_DASHBOARD_WIDGETS:
            return {
                ...state,
                dashboardWidgets: action.payload
            };
        case CONSTANTS.SET_AVAILABLE_WIDGETS:
            return {
                ...state,
                availableWidgets: action.payload
            };
        case CONSTANTS.SET_FACILITY_FEEDBACK:
            return {
                ...state,
                facilityFeedback: action.payload
            };
        case CONSTANTS.SET_AIR_QUALITY:
            return {
                ...state,
                airQualityIndex: action.payload
            };
        case CONSTANTS.SET_SAVE_LOADER:
            return {
                ...state,
                saveLoader: action.payload
            };
        case CONSTANTS.SET_TEST_SUITE:
            return {
                ...state,
                showTestSuite: action.payload
            };
        case CONSTANTS.SET_FEEDBACK_HEADERS:
            return {
                ...state,
                feedbackHeaders: action.payload
            };
        case CONSTANTS.SET_SITE_VERSION:
            return {
                ...state,
                siteVersion: action.payload
            };
        case CONSTANTS.SET_USER_SITE_LIST: 
            return {
                ...state,
                userSiteList: action.payload
            };
        case CONSTANTS.SET_SHOW_PORTAL_SEARCH: 
            return {
                ...state,
                showPortalSearch: action.payload
            };
        case CONSTANTS.SET_WEBHOOKS_SITE_DETAILS: 
            return {
                ...state,
                webhooksSiteDetails: action.payload
            };
        case CONSTANTS.SET_WEBHOOKS_CLIENT_DETAILS: 
            return {
                ...state,
                webhooksClientDetails: action.payload
            };
        default:
            return state;
    }
};

export default app;
