import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { CircleSlider } from 'react-circle-slider';
import debounce from 'lodash.debounce';

import { EcobeeIcon, ThermostatIcon } from 'utilities/icons';
import * as DATABASE from 'utilities/database';

import { fetchThermostat, updateThermostatTemp } from 'actions/Settings';

import Button from 'components/Button';
import Header from 'components/Header/dashboard';
class DashboardEcobee extends React.Component {
    constructor(props) {
        super(props);
        this.updateTempEndpoint = debounce(this.updateTempEndpoint.bind(this), 1000);
    }
    componentDidMount() {
        const { fetchThermostat, authUser } = this.props;
        let siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).siteUUID;
        authUser &&
            authUser.featureflagAssignments &&
            authUser.featureflagAssignments.includes('ecobee_integration') &&
            fetchThermostat(siteUUID == DATABASE.FAKE_ECOBEE_SITE_UUID ? true : false);
    }
    updateTempEndpoint(temperature) {
        const { updateThermostatTemp } = this.props;
        let siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).siteUUID;
        updateThermostatTemp(temperature, siteUUID == DATABASE.FAKE_ECOBEE_SITE_UUID ? true : false);
    }
    updateTemp = (value) => {
        let temperature = value * 10;
        this.updateTempEndpoint(temperature);
    };
    render() {
        let { intl, theme, authUser, thermostatData } = this.props;
        this.styles = styles(theme);
        let weather = sessionStorage.getItem('weather') && JSON.parse(sessionStorage.getItem('weather'));
        return (
            <>
                <Header Icon={ThermostatIcon} iconSize={44} title={'comp.integration.request.climateControl'} />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col xs={12} style={{ padding: 0, overflow: 'hidden' }}>
                        <div></div>
                        {authUser &&
                        authUser.featureflagAssignments &&
                        authUser.featureflagAssignments.includes('ecobee_integration') ? (
                            thermostatData &&
                            thermostatData.thermostatList &&
                            thermostatData.thermostatList.length > 0 ? (
                                thermostatData.thermostatList.map((thermostat, i) => (
                                    <div className={css(this.styles.thermostatBox)} key={thermostat.identifier + i}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                                flexDirection: 'column',
                                                position: 'relative'
                                            }}>
                                            <div>
                                                <p className={css(this.styles.actualTempBoxTemp)}>{thermostat.name}</p>
                                            </div>
                                            {thermostat.runtime && thermostat.runtime.desiredHeat && (
                                                <CircleSlider
                                                    value={
                                                        thermostat.runtime &&
                                                        thermostat.runtime.desiredHeat &&
                                                        thermostat.runtime.desiredHeat / 10
                                                    }
                                                    min={65}
                                                    max={95}
                                                    size={200}
                                                    knobRadius={20}
                                                    progressWidth={20}
                                                    circleWidth={10}
                                                    onChange={this.updateTemp}
                                                    gradientColorFrom={
                                                        thermostat.runtime &&
                                                        (thermostat.runtime.desiredHeat / 10).toFixed(0) ==
                                                            (thermostat.runtime.actualTemperature / 10).toFixed(0)
                                                            ? '#E9EAEE'
                                                            : thermostat.runtime.desiredHeat >
                                                              thermostat.runtime.actualTemperature
                                                            ? '#FFA933'
                                                            : '#0070CE'
                                                    }
                                                    gradientColorTo={
                                                        thermostat.runtime &&
                                                        (thermostat.runtime.desiredHeat / 10).toFixed(0) ==
                                                            (thermostat.runtime.actualTemperature / 10).toFixed(0)
                                                            ? '#8B8C8E'
                                                            : thermostat.runtime.desiredHeat >
                                                              thermostat.runtime.actualTemperature
                                                            ? '#FF9400'
                                                            : '#00549B'
                                                    }
                                                    tooltipColor={
                                                        thermostat.runtime &&
                                                        (thermostat.runtime.desiredHeat / 10).toFixed(0) ==
                                                            (thermostat.runtime.actualTemperature / 10).toFixed(0)
                                                            ? '#8B8C8E'
                                                            : thermostat.runtime.desiredHeat >
                                                              thermostat.runtime.actualTemperature
                                                            ? '#FFA933'
                                                            : '#0070CE'
                                                    }
                                                    showTooltip={true}
                                                    tooltipSize={40}
                                                />
                                            )}
                                            <div
                                                className={css(this.styles.degrees)}
                                                style={
                                                    thermostat.runtime &&
                                                    (thermostat.runtime.desiredHeat / 10).toFixed(0) ==
                                                        (thermostat.runtime.actualTemperature / 10).toFixed(0)
                                                        ? { color: '#8B8C8E' }
                                                        : thermostat.runtime.desiredHeat >
                                                          thermostat.runtime.actualTemperature
                                                        ? { color: '#FFA933' }
                                                        : { color: '#0070CE' }
                                                }>
                                                °
                                            </div>
                                            <div
                                                className={css(this.styles.tempIcon)}
                                                style={
                                                    thermostat.runtime &&
                                                    (thermostat.runtime.desiredHeat / 10).toFixed(0) ==
                                                        (thermostat.runtime.actualTemperature / 10).toFixed(0)
                                                        ? { color: '#8B8C8E' }
                                                        : thermostat.runtime.desiredHeat >
                                                          thermostat.runtime.actualTemperature
                                                        ? { color: '#FFA933' }
                                                        : { color: '#0070CE' }
                                                }>
                                                <i className="material-icons">
                                                    {thermostat.runtime &&
                                                    (thermostat.runtime.desiredHeat / 10).toFixed(0) ==
                                                        (thermostat.runtime.actualTemperature / 10).toFixed(0)
                                                        ? 'thermostat'
                                                        : thermostat.runtime.desiredHeat >
                                                          thermostat.runtime.actualTemperature
                                                        ? 'local_fire_department'
                                                        : 'ac_unit'}
                                                </i>
                                            </div>
                                            <div className={css(this.styles.actualTemp)}>
                                                <div className={css(this.styles.actualTempBox)}>
                                                    <p className={css(this.styles.actualTempBoxTemp)}>
                                                        {intl.formatMessage({
                                                            id: 'comp.integration.request.currentInside'
                                                        })}
                                                    </p>
                                                    {thermostat.runtime &&
                                                        thermostat.runtime.actualTemperature &&
                                                        (thermostat.runtime.actualTemperature / 10).toFixed(0)}
                                                    °
                                                </div>
                                                <div className={css(this.styles.actualTempBox)}>
                                                    <p className={css(this.styles.actualTempBoxTemp)}>
                                                        {intl.formatMessage({
                                                            id: 'comp.integration.request.currentOutside'
                                                        })}
                                                    </p>
                                                    {weather && weather.main && weather.main.temp ? 
                                                        Math.round(weather.main.temp * (9 / 5) - 459.67) : 
                                                        intl.formatMessage({
                                                            id: 'form.na'
                                                    })}°
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingTop: 20
                                    }}>
                                    <EcobeeIcon color={theme.color.themeColor} />
                                    <p style={{ marginTop: 16 }}>
                                        {intl.formatMessage({
                                            id: 'comp.integration.request.thermostatsNone'
                                        })}
                                    </p>
                                    <Button
                                        text={'comp.integration.request.getStarted'}
                                        action={() => this.props.history.push('/app/settings/integrations/ecobee')}
                                        size={'lg'}
                                    />
                                </div>
                            )
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    paddingTop: 20
                                }}>
                                <EcobeeIcon color={theme.color.themeColor} />
                                <p style={{ marginTop: 16 }}>
                                    {intl.formatMessage({
                                        id: 'comp.integration.request.thermostatsNoneSite'
                                    })}
                                </p>
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, settings }) => {
    const { theme } = app;
    const { authUser } = auth;
    const { thermostatData } = settings;
    return { theme, authUser, thermostatData };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, {
            fetchThermostat,
            updateThermostatTemp
        })(DashboardEcobee)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 15px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        thermostatBox: {
            width: '100%'
        },
        actualTemp: {
            width: '100%',
            color: theme.color.themeColor,
            fontSize: 20,
            fontFamily: 'arial',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'space-around',
            justifyContent: 'space-around'
        },
        actualTempBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        actualTempBoxTemp: {
            color: theme.color.fontColor,
            fontFamily: 'roboto',
            fontSize: 16,
            marginBottom: 5
        },
        degrees: {
            fontSize: 36,
            fontFamily: 'arial',
            position: 'absolute',
            top: 100,
            marginLeft: 50
        },
        tempIcon: {
            position: 'absolute',
            top: 76
        }
    });
