//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY LIST                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_LIST = '/site/gateways/';
export const PURPOSE_GATEWAY_LIST = "Fetch a list of the site's gateways.";
export const PERMISSIONS_GATEWAY_LIST = [];
export const REQUIREMENTS_GATEWAY_LIST = ['Token Required'];
export const PARAMETER_GATEWAY_LIST = {
    companyId: 0
};
export const RETURN_GATEWAY_LIST = {
    gateways: [
        {
            comments: '',
            companyId: 0,
            forceFwUpdate: '',
            fwUpdateVersion: '',
            id: 0,
            ipAddress: '',
            macAddress: '',
            notifiedDelinquent: '',
            pagekitename: '',
            serial: '',
            siteId: 0,
            status: '',
            statusChangedTime: '',
            swVersion: '',
            sync: '',
            version: ''
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY EDIT                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_EDIT = '/gateway/edit/';
export const PURPOSE_GATEWAY_EDIT = 'Edit a site gateway.';
export const PERMISSIONS_GATEWAY_EDIT = [];
export const REQUIREMENTS_GATEWAY_EDIT = ['Token Required'];
export const PARAMETER_GATEWAY_EDIT = {
    companyId: 0,
    mac: '',
    comments: ''
};
export const RETURN_GATEWAY_EDIT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY IMAGE                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_IMAGE = '/gateway/image/read/';
export const PURPOSE_GATEWAY_IMAGE = 'Fetch gateway image.';
export const PERMISSIONS_GATEWAY_IMAGE = ['support_view_gateways'];
export const REQUIREMENTS_GATEWAY_IMAGE = ['Token Required'];
export const PARAMETER_GATEWAY_IMAGE = {
    gatewayID: 0
};
export const RETURN_GATEWAY_IMAGE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY DETAILS                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_DETAILS = '/support/gateways/details/';
export const PURPOSE_GATEWAY_DETAILS = 'Fetch a list of devices for a site.';
export const PERMISSIONS_GATEWAY_DETAILS = ['support_view_gateways'];
export const REQUIREMENTS_GATEWAY_DETAILS = ['Token Required'];
export const PARAMETER_GATEWAY_DETAILS = {
    SiteId: 0,
    VersionBle: '',
    VersionWp: '',
    VersionWpOtap: '',
    VersionWpStack: '',
    Online: '',
    GatewayMac: '',
    Missing: ''
};
export const RETURN_GATEWAY_DETAILS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY LOCK DETAILS                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GATEWAY_HISTORY = '/support/unit/history/';
export const PURPOSE_GATEWAY_HISTORY = 'Fetch device history for a site.';
export const PERMISSIONS_GATEWAY_HISTORY = ['support_view_gateways'];
export const REQUIREMENTS_GATEWAY_HISTORY = ['Token Required'];
export const PARAMETER_GATEWAY_HISTORY = {
    companyId: 0
};
export const RETURN_GATEWAY_HISTORY = {
    result: 'success'
};
