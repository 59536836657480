import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'aphrodite';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { fetchLoiteringUsers } from 'actions/Users';

import { SharedUserIcon } from 'utilities/icons';

import Table from 'components/Table';
import UsersCell from 'components/Table/cell/containers/users/loitering';
import Header from 'components/Header/dashboard';
class DashboardLoiteringUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usersHeaders: [
                {
                    id: 0,
                    title: 'comp.table.header.name',
                    active: false,
                    sortable: true,
                    order: true,
                    mobile: true,
                    sortTitle: 'firstName',
                    style: { textAlign: 'left' }
                },
                {
                    id: 1,
                    title: 'pages.dashboard.onsiteSince',
                    width: 145,
                    active: true,
                    sortable: true,
                    order: true,
                    mobile: true,
                    sortTitle: 'onSiteUpdated',
                    style: { textAlign: 'right' }
                }
            ],
            timeLimit: 180
        };
    }
    componentDidMount() {
        const { authUser, fetchLoiteringUsers, site } = this.props;
        const { timeLimit } = this.state;
        authUser &&
            authUser.permissions.includes('view_users') &&
            site &&
            site.loiteringAlert &&
            site.loiteringAlert == 'true' &&
            fetchLoiteringUsers(timeLimit);
    }
    updateTime = (e) => {
        const { fetchLoiteringUsers } = this.props;
        this.setState({ timeLimit: e.target.value });
        fetchLoiteringUsers(e.target.value);
    };
    handleTableAction = (data) => {
        this.props.history.push(`/app/users/display/${data.uuid}`);
    };
    render() {
        let { intl, theme, site, loiteringUsers, editWidgets } = this.props;
        const { usersHeaders, timeLimit } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <Header
                    Icon={SharedUserIcon}
                    timeLimit={timeLimit}
                    adjustTime={this.updateTime}
                    editWidgets={editWidgets}
                    iconSize={38}
                    title={'pages.dashboard.usersIdle'}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    {site && site.loiteringAlert && site.loiteringAlert == 'true' ? (
                        <Table
                            tableHeight={150}
                            data={loiteringUsers ? loiteringUsers : []}
                            action={(e) => this.handleTableAction(e)}
                            header={usersHeaders}
                            Cell={UsersCell}
                            itemSize={38}
                        />
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                paddingTop: 50,
                                minHeight: 150,
                                textAlign: 'center'
                            }}>
                            {intl.formatMessage({ id: 'form.loiteringAlertSite' })}
                        </div>
                    )}
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, users }) => {
    const { theme } = app;
    const { authUser, site } = auth;
    const { loiteringUsers } = users;
    return { theme, authUser, site, loiteringUsers };
};

export default withRouter(injectIntl(connect(mapStateToProps, { fetchLoiteringUsers })(DashboardLoiteringUsers)));

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 5,
            fontSize: 20
        },
        input: {
            border: 'none',
            boxShadow: 'none',
            padding: '5px 7px',
            borderRadius: 4,
            fontWeight: 400,
            fontSize: 13,
            width: 110,
            height: 28,
            marginBottom: 10,
            backgroundColor: theme.color.themeColor,
            color: theme.color.compColor
        }
    });
