import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { WeatherIcon } from 'utilities/icons';

import WeatherWeek from 'components/Weather/week';
import Header from 'components/Header/dashboard';
class DashboardWeather extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { theme, editWidgets } = this.props;
        this.styles = styles(theme);
        let weather = sessionStorage.getItem('weather') && JSON.parse(sessionStorage.getItem('weather'));
        return (
            <>
                <Header Icon={WeatherIcon} title={'comp.button.weeklyForecast'} subtitle={weather && weather.name} />
                <Row style={{ margin: '0 0 0 0', padding: '0 20px 20px 20px' }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <WeatherWeek dash={true} editWidgets={editWidgets} />
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(DashboardWeather)));

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 15px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        }
    });
