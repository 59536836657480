import React from 'react';
import { injectIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import { isTouchDevice } from 'utilities';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ToolTip extends React.Component {
    shouldShowTooltip = () => {
        // don't show tooltips on touchscreens; they don't really function properly on touchscreen
        return !isTouchDevice() || this.props.width > 600;
    }
    render() {
        const { intl, placement, delay, target, message, modifiers, theme } = this.props;
        return (
            this.shouldShowTooltip() && <UncontrolledTooltip
                placement={placement}
                target={target}
                delay={delay}
                style={{ 
                    color: theme.color.themeColor, 
                    background: theme.color.compColor,
                    border: '1px solid' + theme.color.themeColor,
                    padding: '5px 10px',
                    opacity: 1 + ' !important',
                 }}
                className="my-tooltip"
                hideArrow={false}
                modifiers={modifiers}>
                {intl.formatMessage({ id: message })}
            </UncontrolledTooltip>
        );
    }
}

ToolTip.defaultProps = {
    placement: 'bottom',
    delay: { show: 75, hide: 250 },
    target: 'id',
    message: 'blank'
};

const mapStateToProps = ({ app }) => {
    const { width, theme } = app;
    return { width, theme };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(ToolTip)));
