import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';

import { SupportUserIcon, CreatedByAutoIcon, UserIcon } from 'utilities/icons';
import ToolTips from 'components/ToolTips';
class CellText extends React.Component {
    constructor(props) {
        super(props);
    }
    handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let { link } = this.props;
        this.props.history.push(link)
    }
    render() {
        let {
            theme,
            Title,
            Description,
            Cellwidth,
            update,
            maxCharLength,
            maxDescLength,
            Level,
            Support,
            Automated,
            Tenant,
            MinCellHeight,
            SupportActivity,
            width,
            textAlign,
            TitleColor,
            DescriptionColor,
            TitleSize,
            textTransform,
            padding,
            margin, 
            uuid,
            overdue,
            link
        } = this.props;
        this.styles = styles(
            theme,
            width,
            textAlign,
            TitleColor,
            DescriptionColor,
            TitleSize,
            textTransform,
            padding,
            margin
        );
        return (
            <td width={Cellwidth} onClick={(e) => link && this.handleClick(e)}>
                <div
                    className={css(this.styles.wrapper, update && this.styles.hover)}
                    style={{ overflow: Tenant || Support || Automated ? 'initial' : 'hidden' }}>
                    {overdue && <div id={`overdue-${uuid}`} className={css(this.styles.overdue)}>
                        !
                    </div>}
                    <h5
                        title={maxCharLength && maxCharLength < Title.length ? Title : null}
                        className={
                            Tenant || Support || Automated || MinCellHeight
                                ? css(this.styles.titleIcon)
                                : css(this.styles.title)
                        }>
                        <span
                            className={
                                Tenant || Support || Automated || MinCellHeight
                                    ? css(this.styles.titleTextIcon)
                                    : css(this.styles.titleText)
                            }>
                            {maxCharLength && maxCharLength < Title.length
                                ? `${Title.substr(0, maxCharLength)}...`
                                : Title}
                            {SupportActivity && (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        background: theme.color.themeColor,
                                        borderRadius: '50%',
                                        height: 15,
                                        width: 16,
                                        padding: '1px 0 0 2px',
                                        marginLeft: 2
                                    }}>
                                    <SupportUserIcon height={12} width={12} color={theme.color.buttonFontColor} />
                                </div>
                            )}
                        </span>
                        {Tenant && (
                            <div
                                style={{
                                    marginLeft: 8,
                                    background: theme.color.themeColor,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    width: 31.5,
                                    height: 31
                                }}>
                                <UserIcon height={15} width={15} color={theme.color.buttonFontColor} />
                            </div>
                        )}
                        {Support && !Tenant && !Automated && (
                            <div
                                id={`created-support-${uuid}`}
                                style={{
                                    marginLeft: 8,
                                    background: theme.color.themeColor,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    width: 31.5,
                                    height: 31
                                }}>
                                <SupportUserIcon height={17} width={17} color={theme.color.buttonFontColor} />
                            </div>
                        )}
                        {Support && !Tenant && !Automated && (
                            <ToolTips 
                                target={`created-support-${uuid}`} 
                                modifiers={{ 
                                    preventOverflow: { boundariesElement: 'viewport' },
                                    hide: { enabled: false },
                                }} 
                                message={'pages.notes.Type.createdSupport'} />
                        )}
                        {Automated && (
                            <div
                                id={`created-automation-${uuid}`}
                                style={{
                                    marginLeft: 8,
                                    background: theme.color.themeColor,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    width: 31.5,
                                    height: 31
                                }}>
                                <CreatedByAutoIcon height={15} width={15} color={theme.color.buttonFontColor} />
                            </div>
                        )}
                        {Automated && (
                            <ToolTips 
                                target={`created-automation-${uuid}`} 
                                modifiers={{ 
                                    preventOverflow: { boundariesElement: 'viewport' },
                                    hide: { enabled: false },
                                }} 
                                message={'pages.notes.Type.createdAutomation'} />
                        )}
                    </h5>
                    <p
                        title={maxDescLength && maxDescLength < Description.length ? Description : null}
                        className={css(this.styles.description)}>
                        {maxDescLength && maxDescLength < Description.length
                            ? `${Description.substr(0, maxDescLength)}...`
                            : Description}
                    </p>
                    {Level && (
                        <div className={css(this.styles.authority)}>
                            <p className={css(this.styles.level)}>{Level}</p>
                            <div>
                                {Level === 'Basic' && (
                                    <div className={css(this.styles.authLevel)}>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#E02550' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                    </div>
                                )}
                                {Level === 'Low' && (
                                    <div className={css(this.styles.authLevel)}>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#EF7319' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#EF7319' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                    </div>
                                )}
                                {Level === 'Medium' && (
                                    <div className={css(this.styles.authLevel)}>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#EEB927' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#EEB927' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#EEB927' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                    </div>
                                )}
                                {Level === 'High' && (
                                    <div className={css(this.styles.authLevel)}>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#0EC55E' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#0EC55E' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#0EC55E' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#0EC55E' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: theme.color.borderColor }}></div>
                                    </div>
                                )}
                                {Level === 'Very High' && (
                                    <div className={css(this.styles.authLevel)}>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#2793e8' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#2793e8' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#2793e8' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#2793e8' }}></div>
                                        <div
                                            className={css(this.styles.authLine)}
                                            style={{ background: '#2793e8' }}></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </td>
        );
    }
}

CellText.defaultProps = {
    Title: 'Unknown',
    TitleColor: false,
    Description: '',
    DescriptionColor: false,
    maxDescLength: null,
    textAlign: 'center',
    TitleSize: 12,
    textTransform: 'capitalize',
    udpate: null,
    maxCharLength: null,
    Level: null,
    Tier: null,
    padding: '2px 10px',
    margin: 0,
    uuid: ''
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default withRouter(connect(mapStateToProps)(CellText));

const styles = (theme, width, textAlign, TitleColor, DescriptionColor, fontSize, textTransform, padding, margin) =>
    StyleSheet.create({
        wrapper: {
            padding,
            margin: margin,
            maxHeight: 55,
            position: 'relative',
            opacity: 1,
            transition: '.2s all ease-in-out',
            ":hover": {
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationColor: theme.color.themeColor,
                opacity: 0.8,
            }
        },
        overdue: {
            position: 'absolute',
            color: 'red',
            top: -2,
            left: 4,
            fontSize: 16,
            fontWeight: 600,
            minWidth: 20
        },
        title: {
            fontSize: width > 500 ? fontSize : width > 300 ? 12 : 10,
            fontWeight: 400,
            margin: 0,
            padding: 0,
            textAlign,
            color: TitleColor && theme.color.fontColor,
            textTransform
        },
        titleIcon: {
            fontSize: width > 500 ? fontSize : width > 300 ? 12 : 10,
            fontWeight: 400,
            margin: 0,
            padding: 0,
            textAlign,
            color: TitleColor && theme.color.fontColor,
            textTransform,
            minHeight: 35,
            maxHeight: 35,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 3,
            wordBreak: 'normal'
        },
        description: {
            fontSize: width > 500 ? 11 : width > 300 ? 9 : 8,
            margin: 0,
            padding: 0,
            color: DescriptionColor && theme.color.fontColor,
            textAlign,
            textTransform: 'capitalize'
        },
        level: {
            fontSize: width > 500 ? 11 : width > 300 ? 9 : 8,
            margin: 0,
            padding: 0,
            color: DescriptionColor && theme.color.themeColor,
            textAlign
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        authority: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '50%',
            '@media (max-width: 1380px)': {
                width: '75%'
            },
            '@media (max-width: 992px)': {
                width: '40%'
            },
            '@media (max-width: 500px)': {
                width: '100%'
            }
        },
        authLevel: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 5
        },
        authLine: {
            width: 5,
            height: 12,
            margin: '0 2px',
            opacity: 0.8
        },
        titleTextIcon: {
            width: 'calc(100% - 36px)'
        }
    });
