import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import { sortList } from 'utilities';
class TableHeaderIndex extends React.Component {
    sortList = (head) => {
        const { header, action, sort, data } = this.props;
        var list = [...data];
        if (head.sortTitle && typeof head.sortTitle === 'object') {
            list = sortList(head.order, list, head.sortTitle[0]);
            list = sortList(head.order, list, head.sortTitle[1]);
        } else {
            list = sortList(head.order, list, head.sortTitle);
        }
        header.map((item) => {
            item.id === head.id ? (item.active = true) : (item.active = false);
            item.id === head.id ? (item.order = !item.order) : (item.order = true);
        });
        action && action(header);
        sort(list);
    };
    checkMobile = (header) => {
        const { width } = this.props;
        if(width < 1700 && header.desktop){
            return false;
        } else if (width < 1550 && header.laptop) {
            return false;
        } else if (width < 1100 && header.tablet) {
            return false;
        } else if (width < 800 && header.mobile) {
            return true;
        } else if (width > 800) {
            return true;
        } else {
            return false;
        }
    };
    checkShow = (header) => {
        if (header.hide) {
            return false;
        } else {
            return true;
        }
    };
    render() {
        const { intl, header, theme, data, headerFontSize } = this.props;
        this.styles = styles(theme, headerFontSize);
        return (
            <tr className={css(this.styles.tr)}>
                {header.map(
                    (head, i) =>
                        this.checkMobile(head) &&
                        this.checkShow(head) && (
                            <td
                                key={'Header' + head.title + i}
                                width={head.width}
                                className={css(head.active ? this.styles.thActive : this.styles.th)}
                                style={head.style}
                                colSpan={head.col || '1'}
                                onClick={() => data && head.sortable && this.sortList(head)}>
                                <span className={css(head.active ? this.styles.activeText : this.styles.regText)}>
                                    {intl.formatMessage({ id: head.title })}
                                </span>{' '}
                                {head.sortable && (
                                    <i
                                        className={`material-icons ${css(
                                            head.active ? this.styles.iconActive : this.styles.icon
                                        )}`}>{`arrow_drop_${head.order ? 'down' : 'up'}`}</i>
                                )}
                            </td>
                        )
                )}
            </tr>
        );
    }
}

TableHeaderIndex.defaultprops = {
    headerFontSize: 12
};

const mapStateToProps = ({ app }) => {
    const { width, theme } = app;
    return { width, theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(TableHeaderIndex)));

const styles = (theme, headerFontSize) =>
    StyleSheet.create({
        tr: {
            borderTop: `solid 2px ${theme.color.compColor}`,
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            textAlign: 'center',
            color: theme.color.fontColor,
            width: '100%',
            userSelect: 'none'
        },
        thActive: {
            fontSize: headerFontSize,
            color: theme.color.fontColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        th: {
            fontSize: headerFontSize,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        iconActive: {
            color: theme.color.themeColor,
            fontSize: 20
        },
        icon: {
            color: theme.color.fontColor,
            fontSize: 20
        },
        activeText: {
            textDecoration: 'underline',
            textDecorationColor: theme.color.themeColor
        },
        regText: {
            textDecoration: 'underline',
            textDecorationColor: 'white'
        }
    });
