import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';

import { updateOutageStatus } from 'actions/App';

import Button from 'components/Button';
class PendingOutageDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            outageTime: null,
            now: null
        };
    }
    componentDidMount() {
        const { authUser, site, userTimezone } = this.props;
        this.setState({
            outageTime: sessionStorage.getItem('outage_time'),
            now: authUser && authUser.useSiteTime && site ? moment().tz(site.timeZone) : moment().tz(userTimezone)
        });
        this.interval = setInterval(() => {
            this.setState({
                outageTime: sessionStorage.getItem('outage_time'),
                now: authUser && authUser.useSiteTime && site ? moment().tz(site.timeZone) : moment().tz(userTimezone)
            });
        }, 30000);
    }
    dismissOutage = () => {
        const { updateOutageStatus } = this.props;
        updateOutageStatus(false, false);
    };
    reportNow = () => {
        const { updateOutageStatus } = this.props;
        updateOutageStatus(true, true);
    };
    render() {
        const { intl, app, authUser } = this.props;
        let { outageTime, now } = this.state;
        this.styles = styles();
        return (
            app &&
            authUser &&
            authUser.permissions &&
            authUser.permissions.includes('support_view_all_companies') && (
                <>
                    <div className={css(this.styles.outageApp)}>
                        <div style={{ opacity: 1 }}>
                            <i className="material-icons" style={{ color: 'white', marginRight: 5 }}>
                                error
                            </i>
                            {intl.formatMessage({ id: 'comp.button.outagePendingBanner' })}{' '}
                            {now && outageTime && moment.duration(now.diff(outageTime)).humanize()}
                            {authUser &&
                                authUser.permissions &&
                                authUser.permissions.includes('support_high_level') && (
                                    <Button
                                        action={this.reportNow}
                                        text={`comp.button.outagePostNow`}
                                        margin={'0 0 0 10px'}
                                        padding={'0px 5px'}
                                        backgroundColor={'white'}
                                        color={'#FF1919'}
                                        outline={true}
                                        style={{ float: 'right', display: 'inline-block' }}
                                    />
                                )}
                            {authUser &&
                                authUser.permissions &&
                                authUser.permissions.includes('support_high_level') && (
                                    <Button
                                        action={this.dismissOutage}
                                        text={`comp.button.outageRemove`}
                                        margin={'0 0 0 10px'}
                                        padding={'0px 5px'}
                                        backgroundColor={'white'}
                                        color={'#FF1919'}
                                        outline={true}
                                        style={{ float: 'right', display: 'inline-block' }}
                                    />
                                )}
                        </div>
                    </div>
                </>
            )
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { userTimezone } = app;
    const { authUser, site } = auth;
    return { authUser, site, userTimezone };
};

export default withRouter(injectIntl(connect(mapStateToProps, { updateOutageStatus })(PendingOutageDisplay)));

const styles = () =>
    StyleSheet.create({
        outageApp: {
            position: 'absolute',
            bottom: 0,
            width: '100vw',
            minHeight: 10,
            background: '#FF1919',
            opacity: 0.8,
            padding: '5px 5vw',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 14,
            zIndex: 999
        }
    });
