import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { fadeIn } from 'react-animations';
import SkeletonContainer from 'components/Skeleton/skeletonWrapper';
class Cells extends React.Component {
    constructor(props) {
        super(props);
    }
    checkMobile = (header) => {
        const { width } = this.props;
        if (width < 1700 && header.desktop){
            return false;
        } else if (width < 1550 && header.laptop) {
            return false;
        } else if (width < 1100 && header.tablet){
            return false;
        } else if (width < 800 && header.mobile) {
            return true;
        } else if (width > 800) {
            return true;
        } else {
            return false;
        }
    };
    checkShow = (header) => {
        if (header.hide) {
            return false;
        } else {
            return true;
        }
    };
    render() {
        const { itemSize, theme, rowWidth, header } = this.props;
        this.styles = styles(theme);
        let numItems = Math.ceil((rowWidth - itemSize) / 150);
        let icon = header && header.filter((head) => head.icon).length > 0 ? true : false;
        return (
            <div className={css(this.styles.tableWrapper)} style={{ height: itemSize }}>
                <table className={css(this.styles.table)} style={{ height: itemSize }}>
                    <SkeletonContainer>
                        <tbody className={css(this.styles.tbody)}>
                            {header ? (
                                <tr style={{ height: itemSize - 2, marginBottom: 2 }} className={css(this.styles.trow)}>
                                    {icon && (
                                        <td
                                            style={{
                                                width: 55,
                                                maxWidth: 55,
                                                padding: '0 0 0 10px'
                                            }}>
                                            <Skeleton
                                                inline={true}
                                                count={1}
                                                width={itemSize ? itemSize - 15 : 41}
                                                height={itemSize ? itemSize - 15 : 41}
                                                circle={true}
                                            />
                                        </td>
                                    )}
                                    {header.map(
                                        (header, i) =>
                                            this.checkMobile(header) &&
                                            this.checkShow(header) && (
                                                <td
                                                    style={{
                                                        width:
                                                            header.width && header.icon
                                                                ? header.width - 55
                                                                : header.width
                                                                ? header.width
                                                                : null,
                                                        textAlign: header.style ? header.style.textAlign : 'center',
                                                        verticalAlign: 'center'
                                                    }}
                                                    key={i}>
                                                    {header.title && header.title !== 'blank' && (
                                                        <Skeleton
                                                            inline={true}
                                                            width={
                                                                header.title &&
                                                                header.title == 'comp.table.header.online'
                                                                    ? 20
                                                                    : header.width && header.width < 81
                                                                    ? header.width - 10
                                                                    : 80
                                                            }
                                                            height={
                                                                header.title &&
                                                                header.title == 'comp.table.header.online'
                                                                    ? 20
                                                                    : 13
                                                            }
                                                            circle={
                                                                header.title &&
                                                                header.title == 'comp.table.header.online'
                                                                    ? true
                                                                    : false
                                                            }
                                                            count={1}
                                                        />
                                                    )}
                                                </td>
                                            )
                                    )}
                                </tr>
                            ) : (
                                <tr style={{ height: itemSize - 2, marginBottom: 2 }} className={css(this.styles.trow)}>
                                    {itemSize && itemSize > 36 && (
                                        <td style={{ verticalAlign: 'middle' }} width={itemSize}>
                                            <Skeleton
                                                inline={true}
                                                count={1}
                                                width={itemSize - 14}
                                                height={itemSize - 14}
                                                circle={true}
                                            />
                                        </td>
                                    )}
                                    {[...Array(numItems)].map((e, i) => (
                                        <td style={{ verticalAlign: 'middle' }} key={i}>
                                            <Skeleton inline={true} width={80} count={1} />
                                        </td>
                                    ))}
                                </tr>
                            )}
                        </tbody>
                    </SkeletonContainer>
                </table>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Cells)));

const styles = (theme) =>
    StyleSheet.create({
        tableWrapper: {
            display: 'table',
            position: 'relative',
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            zIndex: 3,
            cursor: 'pointer',
            width: '100%',
            transition: 'all .1s ease-in-out',
            ':hover': {
                background:
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                        ? 'rgba(255,255,255,0.1)'
                        : theme && theme.color && theme.color.name && theme.color.name.includes('light1')
                        ? '#fcfcfc'
                        : '#f9f9f9',
                textDecoration: 'none',
                filter: 'drop-shadow(0 2px 10px 2px #ddd)',
                zIndex: 2
            }
        },
        table: {
            width: '100%'
        },
        tbody: {
            display: 'block',
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'relative',
            animationName: fadeIn,
            animationDuration: '1.6s'
        },
        trow: {
            display: 'table',
            tableLayout: 'fixed',
            verticalAlign: 'middle',
            width: '100%',
            maxHeight: 55
        }
    });
