//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY REDIRECT LIST             ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACTIVITY_REDIRECTS_FETCH = '/activity/get/redirects/site/';
export const PURPOSE_ACTIVITY_REDIRECTS_FETCH = 'Fetch a list of the site activity redirects';
export const PERMISSIONS_ACTIVITY_REDIRECTS_FETCH = [];
export const REQUIREMENTS_ACTIVITY_REDIRECTS_FETCH = ['Token Required'];
export const PARAMETER_ACTIVITY_REDIRECTS_FETCH = {};
export const RETURN_ACTIVITY_REDIRECTS_FETCH = {
    redirects: [
        {
            id: '',
            organization: '',
            externalName: '',
            externalId: '',
            url: '',
            apiKey: '',
            created: '',
            modified: ''
        },
        {
            id: '',
            organization: '',
            externalName: '',
            externalId: '',
            url: '',
            apiKey: '',
            created: '',
            modified: ''
        }
    ],
    page: 0,
    pageCount: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY REMOVE                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REMOVE_ACTIVITY_REDIRECT = '/activity/remove/redirects/';
export const PURPOSE_REMOVE_ACTIVITY_REDIRECT = 'Remove activity redirects';
export const PERMISSIONS_REMOVE_ACTIVITY_REDIRECT = [];
export const REQUIREMENTS_REMOVE_ACTIVITY_REDIRECT = ['Token Required'];
export const PARAMETER_REMOVE_ACTIVITY_REDIRECT = {};
export const RETURN_REMOVE_ACTIVITY_REDIRECT = {};

//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY ADD                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ADD_ACTIVITY_REDIRECT = '/activity/register/redirect/site/';
export const PURPOSE_ADD_ACTIVITY_REDIRECT = 'Add activity redirect';
export const PERMISSIONS_ADD_ACTIVITY_REDIRECT = [];
export const REQUIREMENTS_ADD_ACTIVITY_REDIRECT = ['Token Required'];
export const PARAMETER_ADD_ACTIVITY_REDIRECT = {};
export const RETURN_ADD_ACTIVITY_REDIRECT = {};

//||||||||||||||||||||||||||||||||||||||||
//|| GET EVENT PREFERENCES              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_GET_SITE_EVENT_PREFERENCES = '/activity/get/event/preferences/site/';
export const PURPOSE_GET_SITE_EVENT_PREFERENCES = 'Add activity redirect';
export const PERMISSIONS_GET_SITE_EVENT_PREFERENCES = [];
export const REQUIREMENTS_GET_SITE_EVENT_PREFERENCES = ['Token Required'];
export const PARAMETER_GET_SITE_EVENT_PREFERENCES = {};
export const RETURN_GET_SITE_EVENT_PREFERENCES = {};

//||||||||||||||||||||||||||||||||||||||||
//|| EDIT EVENT PREFERENCES             ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_EDIT_SITE_EVENT_PREFERENCES = '/activity/set/event/preferences/site/';
export const PURPOSE_EDIT_SITE_EVENT_PREFERENCES = 'Add activity redirect';
export const PERMISSIONS_EDIT_SITE_EVENT_PREFERENCES = [];
export const REQUIREMENTS_EDIT_SITE_EVENT_PREFERENCES = ['Token Required'];
export const PARAMETER_EDIT_SITE_EVENT_PREFERENCES = {};
export const RETURN_EDIT_SITE_EVENT_PREFERENCES = {};
