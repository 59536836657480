import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
class SkeletonContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { theme, children } = this.props;
        return (
            <SkeletonTheme
                baseColor={
                    theme && theme.color && theme.color.name && theme.color.name == 'dark'
                        ? '#2B3E55'
                        : theme && theme.color && theme.color.name && theme.color.name == 'dark3'
                        ? '#534e6e'
                        : theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                        ? '#626262'
                        : theme && theme.color && theme.color.name && theme.color.name.includes('light2')
                        ? '#cccdd6'
                        : '#EBEBEB'
                }
                highlightColor={
                    theme && theme.color && theme.color.name && theme.color.name == 'dark'
                        ? '#707f8d'
                        : theme && theme.color && theme.color.name && theme.color.name == 'dark3'
                        ? '#666087'
                        : theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                        ? '#7f7f7f'
                        : theme && theme.color && theme.color.name && theme.color.name.includes('light2')
                        ? '#ededf0'
                        : '#f5f5f5'
                }>
                {children}
            </SkeletonTheme>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(connect(mapStateToProps)(SkeletonContainer));
