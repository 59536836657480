import * as CONSTANTS from 'constants';

const INIT_STATE = {
    gatewayKeeperList: null,
    gatewayKeeperListStats: null,
    gatewayKeeperDeviceStats: null,
    gatewayKeeperDeviceList: [],
    gatewayKeeperDeviceDetails: null,
    gatewayKeeperDetails: null,
    gatewayKeeperUnitHistory: null,
    gatewayKeeperGatewayHistory: null,
    gatewayKeeperStatsShow: false,
    hardwareTypes: null,
    firmwareSettings: null,
    firmwareStatuses: null,
    gatewayKeeperLockHeader: [
        {
            id: 0,
            title: 'comp.table.header.online',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'online',
            mobile: true,
            width: 100,
            style: { textAlign: 'center' }
        },
        {
            id: 1,
            title: 'comp.table.header.name',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'name',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.date.create',
            active: false,
            sortable: true,
            order: false,
            mobile: false,
            sortTitle: 'lockCreatedDate',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.updated',
            active: true,
            sortable: true,
            order: false,
            mobile: false,
            sortTitle: 'updated',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'mac',
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.gateway',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'gatewayMac',
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'comp.table.header.ble',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'ble',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 7,
            title: 'form.meshStrength',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'linkQuality',
            width: 120,
            style: { textAlign: 'left' }
        },
        {
            id: 8,
            title: 'comp.table.header.updatedMesh',
            active: false,
            sortable: true,
            order: false,
            mobile: false,
            sortTitle: 'rssiUpdated',
            width: 120,
            style: { textAlign: 'left' }
        },
        {
            id: 9,
            title: 'comp.table.header.unitsSinglePlural',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'units',
            width: 110,
            style: { textAlign: 'left' }
        },
        {
            id: 10,
            title: 'comp.table.header.wp',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wp',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 11,
            title: 'form.bleHwVersion',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'BleHwVersion',
            width: 150,
            style: { textAlign: 'left' }
        },
        /* Hiding Temperature until we get accurate temps */
        // {
        //     id: 12,
        //     title: 'comp.table.header.temp',
        //     active: false,
        //     sortable: true,
        //     order: true,
        //     mobile: false,
        //     sortTitle: 'temperature',
        //     width: 100,
        //     style: { textAlign: 'left' }
        // },
        {
            id: 13,
            title: 'comp.table.header.battery',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'voltageBat',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 14,
            title: 'comp.table.header.wired',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'voltageWired',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 15,
            title: 'comp.table.header.nextHop',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'nextHop',
            width: 110,
            style: { textAlign: 'left' }
        },
        {
            id: 16,
            title: 'comp.table.header.travelTime',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'travelTime',
            width: 90,
            style: { textAlign: 'left' }
        },
        {
            id: 17,
            title: 'form.state',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 18,
            title: 'pages.dashboard.rentalStatus',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'rentalStatus',
            width: 130,
            style: { textAlign: 'left' }
        }
    ],
    gatewayKeeperLockHeaderOld: [
        {
            id: 0,
            title: 'comp.table.header.online',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'online',
            mobile: true,
            width: 70,
            style: { textAlign: 'center' }
        },
        {
            id: 1,
            title: 'comp.table.header.name',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'name',
            width: 120,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.updated',
            active: true,
            sortable: true,
            order: false,
            mobile: false,
            sortTitle: 'updated',
            width: 110,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 120,
            sortTitle: 'mac',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.gateway',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 120,
            sortTitle: 'gatewayMac',
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.ble',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'ble',
            width: 65
        },
        {
            id: 6,
            title: 'comp.table.header.wp',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wp',
            width: 65
        },
        {
            id: 7,
            title: 'comp.table.header.wpOtap',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wpOTap',
            width: 100
        },
        {
            id: 8,
            title: 'comp.table.header.wpStack',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wpStack',
            width: 100
        },
        {
            id: 9,
            title: 'comp.table.header.missing',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'missing',
            width: 100
        }
    ],
    gatewayKeeperLockDetailHeader: [
        {
            id: 0,
            title: 'comp.table.header.online',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'delinquent',
            width: 75,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.updated',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'updated',
            width: 95,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.ble',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'ble',
            mobile: false,
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.gateway',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'gatewayMac',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.kind',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'kind',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.hopMac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'hop_mac',
            width: 110,
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'comp.table.header.hops',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'hops',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 7,
            title: 'comp.table.header.travelTime',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'travelTime',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 8,
            title: 'comp.table.header.wp',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wp',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 9,
            title: 'comp.table.header.wpOtap',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wpOtap',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 10,
            title: 'comp.table.header.wpStack',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wpStack',
            width: 100,
            style: { textAlign: 'left' }
        },
        /* Hiding Temperature until we get accurate temps */
        // {
        //     id: 11,
        //     title: 'comp.table.header.temp',
        //     active: false,
        //     sortable: true,
        //     order: true,
        //     mobile: false,
        //     sortTitle: 'temperature_avg',
        //     width: 100
        // },
        {
            id: 12,
            title: 'comp.table.header.motionActive',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'motion_active',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 14,
            title: 'form.state',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 15,
            title: 'comp.table.header.lastMotion',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'last_motion',
            width: 100,
            style: { textAlign: 'right' }
        }
    ],
    gatewayKeeperLockDetailHeaderOld: [
        {
            id: 0,
            title: 'comp.table.header.missing',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'missing',
            width: 75,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.ble',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'ble',
            mobile: true
        },
        {
            id: 2,
            title: 'comp.table.header.gateway',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'gatewayMac'
        },
        {
            id: 3,
            title: 'comp.table.header.kind',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'kind'
        },
        {
            id: 4,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'mac'
        },
        {
            id: 5,
            title: 'comp.table.header.nextHop',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'nextHop'
        },
        {
            id: 6,
            title: 'comp.table.header.travelTime',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'travelTime'
        },
        {
            id: 7,
            title: 'comp.table.header.wp',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wp'
        },
        {
            id: 8,
            title: 'comp.table.header.wpOtap',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wpOtap'
        },
        {
            id: 9,
            title: 'comp.table.header.wpStack',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wpStack'
        },
        {
            id: 10,
            title: 'comp.table.header.updated',
            active: true,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'updated'
        }
    ],
    gatewayKeeperGatewayDetailHeader: [
        {
            id: 0,
            title: 'comp.table.header.online',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'delinquent',
            width: 75,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.updated',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'updated',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.version',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'version',
            width: 100
        },
        {
            id: 3,
            title: 'comp.table.header.host',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'host',
            width: 200
        },
        {
            id: 4,
            title: 'comp.table.header.offlineAt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'offline_at',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.onlineAt',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'online_at',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'comp.table.header.ip',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'ip',
            width: 100
        },
        {
            id: 7,
            title: 'form.state',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state',
            width: 100
        },
        {
            id: 8,
            title: 'form.build',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'build',
            width: 100
        },
        {
            id: 9,
            title: 'comp.table.header.cloudUrl',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'cloud_url',
            width: 100
        },
        {
            id: 10,
            title: 'comp.table.header.wpLibrary',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'wp_library',
            width: 100
        }
    ],
    gatewayKeeperGatewaysHeader: [
        {
            id: 0,
            title: 'comp.table.header.online',
            active: true,
            sortable: true,
            order: true,
            width: 70,
            sortTitle: 'online',
            mobile: true,
            style: { textAlign: 'center' }
        },
        {
            id: 1,
            title: 'comp.table.header.name',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 200,
            sortTitle: 'name',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.serial',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'serial',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'macAddress',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.locks',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            width: 225,
            tablet: true,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'form.comments',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            tablet: true,
            sortTitle: 'comment',
            style: { textAlign: 'left' }
        }
    ],
    firmwareHardwareTypesHeader: [
        {
            id: 0,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 50
        },
        {
            id: 1,
            title: 'comp.table.cell.hardwareTypes',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'hardwareType',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.bleVersion',
            active: false,
            sortable: false,
            order: true,
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 130
        },
        {
            id: 4,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 130
        }
    ],
    firmwareUpdatesHeader: [
        {
            id: 0,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 50
        },
        {
            id: 1,
            title: 'comp.table.cell.hardwareTypes',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'hardwareType',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.bleVersion',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'bleVersion',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.cell.updateStarted',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'startTime',
            width: 180,
            style: { textAlign: 'right' }
        }
    ],
    gatewayKeeperFilters: [
        {
            title: 'filters.all',
            value: [true, false],
            active: true
        },
        {
            title: 'filters.gateway.online',
            value: [true],
            active: false
        },
        {
            title: 'filters.gateway.offline',
            value: [false],
            active: false
        }
    ],
    gatewayKeeperDeviceFilters: {
        versionHWBle: '',
        versionBle: '',
        versionWp: '',
        versionWpOtap: '',
        versionWpStack: '',
        gatewayMac: '',
        online: '',
        missing: '',
        kinds: ['unit', 'gate', 'repeater', 'door', 'slidingdoor', 'sample', 'elevator', 'padlock', 'alarm', 'exitgate']
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_GATEWAY_KEEPER_LIST: {
            return {
                ...state,
                gatewayKeeperList: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_LIST_STATS: {
            return {
                ...state,
                gatewayKeeperListStats: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_STATS: {
            return {
                ...state,
                gatewayKeeperDeviceStats: action.payload
            }
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_LIST: {
            return {
                ...state,
                gatewayKeeperDeviceList: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_DETAILS: {
            return {
                ...state,
                gatewayKeeperDetails: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_DETAILS: {
            return {
                ...state,
                gatewayKeeperDeviceDetails: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_HISTORY: {
            return {
                ...state,
                gatewayKeeperUnitHistory: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_LOCK_DETAIL_HEADERS: {
            return {
                ...state,
                gatewayKeeperLockDetailHeader: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_FILTERS: {
            return {
                ...state,
                gatewayKeeperFilters: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_FILTERS: {
            return {
                ...state,
                gatewayKeeperDeviceFilters: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_STATS_TOGGLE: {
            return {
                ...state,
                gatewayKeeperStatsShow: action.payload
            };
        }
        case CONSTANTS.SET_GATEWAY_KEEPER_GATEWAY_HISTORY: {
            return {
                ...state,
                gatewayKeeperGatewayHistory: action.payload
            };
        }
        case CONSTANTS.SET_HARDWARE_TYPES: {
            return {
                ...state,
                hardwareTypes: action.payload
            };
        }
        case CONSTANTS.SET_SITE_FIRMWARE_SETTINGS: {
            return {
                ...state,
                firmwareSettings: action.payload
            };
        }
        case CONSTANTS.SET_FIRMWARE_SITE_STATUSES: {
            return {
                ...state,
                firmwareStatuses: action.payload
            };
        }
        default:
            return state;
    }
};
