import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

class Cells extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleAction = () => {
        const { action, data } = this.props;
        action && action(data);
    };
    render() {
        const { Cell, theme, itemSize, action } = this.props;
        this.styles = styles(theme, itemSize, action);
        return (
            <div className={this.props.active && this.props.highlightActive ? css(this.styles.tableWrapperActive) : css(this.styles.tableWrapper)}>
                <table className={css(this.styles.table)}>
                    <tbody className={css(this.styles.tbody)}>
                        <tr
                            className={css(this.styles.trow)}
                            // used to be onMouseUp, however there were problems with closing modals in rows with actions
                            onClick={(e) => this.handleAction(e)}>
                            <Cell {...this.props} />
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Cells)));

const styles = (theme, itemSize, action) =>
    StyleSheet.create({
        tableWrapper: {
            display: 'table',
            position: 'relative',
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            zIndex: 3,
            cursor: action ? 'pointer' : 'auto',
            padding: '2px 0 1px 0',
            width: '100%',
            transition: 'all .1s ease-in-out',
            contentVisibility: 'auto',
            ':hover': action
                ? {
                      background:
                          theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                              ? 'rgba(255,255,255,0.15)'
                              : theme && theme.color && theme.color.name && theme.color.name.includes('light1')
                              ? '#fcfcfc'
                              : theme && theme.color && theme.color.name && theme.color.name.includes('light2')
                              ? '#e7e7ec'
                              : '#f9f9f9',
                      textDecoration: 'none',
                      filter: 'drop-shadow(0 2px 10px 2px #ddd)',
                      zIndex: 2
                  }
                : {
                      textDecoration: 'none',
                      zIndex: 2
                  }
        },
        tableWrapperActive: {
            display: 'table',
            position: 'relative',
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            zIndex: 3,
            cursor: action ? 'pointer' : 'auto',
            padding: '2px 0 1px 0',
            width: '100%',
            backgroundColor: theme.color.menuColor,
            transition: 'all .1s ease-in-out',
            contentVisibility: 'auto',
            ':hover': action
                ? {
                      background:
                          theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                              ? 'rgba(255,255,255,0.15)'
                              : theme && theme.color && theme.color.name && theme.color.name.includes('light1')
                              ? '#fcfcfc'
                              : theme && theme.color && theme.color.name && theme.color.name.includes('light2')
                              ? '#e7e7ec'
                              : '#f9f9f9',
                      textDecoration: 'none',
                      filter: 'drop-shadow(0 2px 10px 2px #ddd)',
                      zIndex: 2
                  }
                : {
                      textDecoration: 'none',
                      zIndex: 2
                  }
        },
        table: {
            width: '100%'
        },
        tbody: {
            display: 'block',
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'relative'
        },
        trow: {
            contentVisibility: 'auto',
            containIntrinsicSize: itemSize ? itemSize : 55,
            display: 'table',
            width: '100%',
            tableLayout: 'fixed',
            maxHeight: 55
        }
    });
