import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
class CellButton extends React.Component {
    constructor(props) {
        super(props);
    }
    parentAction = (e) => {
        e.stopPropagation();
        const { action } = this.props;
        action();
    };
    render() {
        const { Description, Cellwidth, theme, textAlign } = this.props;
        this.styles = styles(theme, textAlign);
        return (
            <td width={Cellwidth}>
                <div className={css(this.styles.wrapper, this.styles.hover)}>
                    <div onClick={(e) => this.parentAction(e)} className={css(this.styles.button)}>
                        <p className={css(this.styles.description)}>{Description}</p>
                    </div>
                </div>
            </td>
        );
    }
}

CellButton.defaultProps = {
    Description: '',
    textAlign: 'center'
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(connect(mapStateToProps)(CellButton));

const styles = (theme, textAlign) =>
    StyleSheet.create({
        wrapper: {
            padding: '2px 10px',
            margin: 0,
            maxHeight: 55,
            overflow: 'hidden'
        },
        description: {
            fontSize: 11,
            margin: 0,
            padding: 0,
            color: 'white',
            textAlign
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        button: {
            backgroundColor: theme.color.themeColor,
            padding: 5,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .2s ease-in-out'
        }
    });
