import * as CONSTANTS from 'constants';

const INIT_STATE = {
    userNotifications: null,
    userNotificationsCompany: null,
    userNotificationsEnterprise: null,
    userNotificationsViewed: null,
    userNotificationsNotViewed: null,
    notificationsSettings: null,
    notificationsSuite: null,
    supportNotificationsSuite: null,
    userNotificationsPush: null,
    notificationTypes: null,
    notificationsHeader: [
        {
            id: 0,
            title: 'comp.table.header.action',
            active: true,
            sortable: true,
            order: false,
            sortTitle: 'createdDate',
            mobile: true,
            width: 300,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.viewed',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 50,
            sortTitle: 'viewed'
        },
        {
            id: 3,
            title: 'comp.table.header.close',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            width: 50,
            sortTitle: ''
        }
    ],
    userNotificationsHeader: [
        {
            id: 0,
            title: 'pages.notifications.Type',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'body',
            mobile: true,
            width: 145,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.date.create',
            active: true,
            sortable: true,
            order: false,
            mobile: false,
            sortTitle: 'createdDate',
            style: { textAlign: 'right' }
        }
    ],
    managerNotificationFilters: {
        siteUUIDs: [],
        notificationTypes: [],
        limit: 1000
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_USER_NOTIFICATIONS_SETTINGS: {
            return {
                ...state,
                notificationsSettings: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS: {
            return {
                ...state,
                userNotifications: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_COMPANY: {
            return {
                ...state,
                userNotificationsCompany: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_ENTERPRISE: {
            return {
                ...state,
                userNotificationsEnterprise: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_VIEWED: {
            return {
                ...state,
                userNotificationsViewed: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_NOT_VIEWED: {
            return {
                ...state,
                userNotificationsNotViewed: action.payload
            };
        }
        case CONSTANTS.SET_NOTIFICATIONS_HEADERS: {
            return {
                ...state,
                notificationsHeader: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_SUITE: {
            return {
                ...state,
                notificationsSuite: action.payload
            };
        }
        case CONSTANTS.SUPPORT_SET_USER_NOTIFICATIONS_SUITE: {
            return {
                ...state,
                supportNotificationsSuite: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_PUSH: {
            return {
                ...state,
                userNotificationsPush: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_EMAIL: {
            return {
                ...state,
                userNotificationsEmail: action.payload
            };
        }
        case CONSTANTS.SET_USER_NOTIFICATIONS_SMS: {
            return {
                ...state,
                userNotificationsSms: action.payload
            };
        }
        case CONSTANTS.SET_NOTIFICATIONS_TYPES: {
            return {
                ...state,
                notificationTypes: action.payload
            };
        }
        case CONSTANTS.SET_MANAGER_NOTIFICATION_FILTERS: {
            return {
                ...state,
                managerNotificationFilters: action.payload
            };
        }
        default:
            return state;
    }
};
