import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_GATEWAY_KEEPER from 'actions/GatewayKeeper';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_UNITS from 'actions/Units';

const getGatewayKeeperState = (state) => state.gatewayKeeper;
const getAuthUser = (state) => state.auth.authUser;

function* requestGatewayKeeperList({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperList(null));
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperListStats(null));
        let gatewayList = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        !gatewayList.data && !gatewayList.data.gateways ? (gatewayList.data.gateways = []) : '';
        if (!gatewayList) {
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperList([]));
        } else {
            gatewayList.data.gateways =
                gatewayList.data.gateways && gatewayList.data.gateways.length > 0
                    ? UTILS.filterArray(gatewayList.data.gateways, 'online', payload.filter)
                    : [];
            const gatewayKeeperState = yield select(getGatewayKeeperState);
            let activeSiteHeader = gatewayKeeperState.gatewayKeeperGatewaysHeader.find((item) => item.active);
            gatewayList.data.gateways = UTILS.sortList(
                !activeSiteHeader.order,
                gatewayList.data.gateways,
                activeSiteHeader.sortTitle
            );
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperList(gatewayList.data.gateways));
            let stats = {
                totalGateways: gatewayList.data.gateways.length,
                onlineGateways: gatewayList.data.onlineGateways,
                offlineGateways: gatewayList.data.offlineGateways,
            };
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperListStats(stats));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperList([]));
    }
}

function* requestGatewayKeeperDeviceList({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDeviceList(null));
        let locks = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!locks || !locks.data) {
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDeviceList([]));
        } else {
            const gatewayKeeperState = yield select(getGatewayKeeperState);
            let activeLockHeader = gatewayKeeperState.gatewayKeeperLockHeader.find((item) => item.active);
            locks =
                locks && locks.data && UTILS.sortList(!activeLockHeader.order, locks.data, activeLockHeader.sortTitle);
            if (payload.data.fetchStats) {
                let stats = {
                    totalLocks: locks && locks.length ? locks.length : 0,
                    onlineLocks: 0,
                    offlineLocks: 0,
                    versionBle: {},
                    versionWp: {},
                    versionWpOtap: {},
                    versionWpStack: {}
                }
                locks.forEach(l => {
                    if (l.online) {
                        stats.onlineLocks += 1;
                    } else {
                        stats.offlineLocks += 1;
                    }
                    if (stats.versionBle[l.ble]) {
                        stats.versionBle[l.ble] += 1;
                    } else {
                        stats.versionBle[l.ble] = 1;
                    }
                    if (stats.versionWp[l.wp]) {
                        stats.versionWp[l.wp] += 1;
                    } else {
                        stats.versionWp[l.wp] = 1;
                    }
                    if (stats.versionWpOtap[l.wpOTap]) {
                        stats.versionWpOtap[l.wpOTap] += 1;
                    } else {
                        stats.versionWpOtap[l.wpOTap] = 1
                    }
                    if (stats.versionWpStack[l.wpStack]) {
                        stats.versionWpStack[l.wpStack] += 1;
                    } else {
                        stats.versionWpStack[l.wpStack] = 1
                    }
                })
                stats.versionBle = Object.keys(stats.versionBle).map(s => ({version: s, total: stats.versionBle[s]}));
                stats.versionWp = Object.keys(stats.versionWp).map(s => ({version: s, total: stats.versionWp[s]}));
                stats.versionWpOtap = Object.keys(stats.versionWpOtap).map(s => ({version: s, total: stats.versionWpOtap[s]}));
                stats.versionWpStack = Object.keys(stats.versionWpStack).map(s => ({version: s, total: stats.versionWpStack[s]}));
                yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDeviceStats(stats));
            }
            locks &&
                locks.map(
                    (lock) =>
                        (lock.temperature =
                            lock.BleHwVersion == '1A' && Number(lock.ble) <= 1.23
                                ? lock.temperature / 4
                                : lock.BleHwVersion == '3K' && Number(lock.ble) <= 3.11
                                ? lock.temperature / 4
                                : lock.temperature)
                );
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDeviceList(locks));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDeviceList([]));
    }
}

function* requestGatewayKeeperDeviceDetails({ payload }) {
    let authUser = yield select(getAuthUser);
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDeviceDetails(null));
        let locks = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!locks) {
            yield history.goBack();
        } else {
            const device = locks.data.find((item) => item.mac === payload.mac);
            if (device) {
                if (
                    device &&
                    device.mac &&
                    device.BleHwVersion &&
                    authUser &&
                    authUser.permissions.includes('support_view_all_companies')
                ) {
                    yield put(ACTIONS_UNITS.fetchLockManufacturingData(device.mac, device.BleHwVersion));
                }
                yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDeviceDetails(device));
            } else {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestGatewayKeeperDetails({ payload }) {
    let authUser = yield select(getAuthUser);
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDetails(null));
        const gatewayList = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!gatewayList) {
            yield history.goBack();
        } else {
            const gatewayDetails = gatewayList.data.gateways.find((item) => item.uuid === payload.gatewayUUID);
            if (gatewayDetails) {
                let Stats = Object.entries(gatewayDetails.stats);
                gatewayDetails.versionBle =
                    Stats.length > 0 &&
                    Object.entries(gatewayDetails.stats.version_ble).map((item) => {
                        return { version: item[0], total: item[1] };
                    });
                gatewayDetails.versionWp =
                    Stats.length > 0 &&
                    Object.entries(gatewayDetails.stats.version_wp).map((item) => {
                        return { version: item[0], total: item[1] };
                    });
                gatewayDetails.versionWpOtap =
                    Stats.length > 0 &&
                    Object.entries(gatewayDetails.stats.version_wp_otap).map((item) => {
                        return { version: item[0], total: item[1] };
                    });
                gatewayDetails.versionWpStack =
                    Stats.length > 0 &&
                    Object.entries(gatewayDetails.stats.version_wp_stack).map((item) => {
                        return { version: item[0], total: item[1] };
                    });
                gatewayDetails.totalLocks = gatewayDetails.locks;
                if (
                    gatewayDetails &&
                    gatewayDetails.mac &&
                    authUser &&
                    authUser.permissions.includes('support_view_all_companies')
                ) {
                    yield put(ACTIONS_UNITS.fetchLockManufacturingData(gatewayDetails.mac, '100'));
                }
                yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperDetails(gatewayDetails));
            } else {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestGatewayKeeperUnitHistory({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperUnitHistory(null));
        const deviceHistory = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!deviceHistory.data) {
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperUnitHistory([]));
        } else {
            deviceHistory.data &&
                deviceHistory.data.map(
                    (lock) =>
                        (lock.temperature_avg =
                            lock.version_ble_hw == '1A' && Number(lock.version_ble) <= 1.23
                                ? lock.temperature_avg / 4
                                : lock.version_ble_hw == '3K' && Number(lock.version_ble) <= 3.11
                                ? lock.temperature_avg / 4
                                : lock.temperature_avg)
                );
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperUnitHistory(deviceHistory.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperUnitHistory([]));
    }
}

function* requestGatewayKeeperGatewayHistory({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperGatewayHistory(null));
        const gatewayHistory = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!gatewayHistory || !gatewayHistory.data) {
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperGatewayHistory([]));
        } else {
            gatewayHistory.data = UTILS.sortList(true, gatewayHistory.data, 'updated');
            yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperGatewayHistory(gatewayHistory.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_GATEWAY_KEEPER.setGatewayKeeperGatewayHistory([]));
    }
}

function* requestRebootGateways({ payload }) {
    try {
        const reboot = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!reboot) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.gateway.reboot`, 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.gatewayReboot`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRebootGateway({ payload }) {
    try {
        const reboot = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!reboot) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.gateway.reboot`, 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.gatewayReboot`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}
function* requestEnterDiagMode({ payload }) {
    try {
        const diag = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!diag) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.gateway.diagMode`, 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.diagMode`, 'success'));
            yield put(ACTIONS_GATEWAY_KEEPER.fetchGatewayKeeperList());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteSiteGateway({ payload }) {
    try {
        const deleteGateway = yield call(API.GATEWAY_KEEPER_POST, payload.url, payload.data);
        if (!deleteGateway) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noDelete`, 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield history.push('/app/network/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestHardwareTypes({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setHardwareTypes(null));
        let hardware = yield call(API.POST, payload.url, payload.data);
        if (!hardware || !hardware.data || !hardware.data.versions) {
            yield put(ACTIONS_GATEWAY_KEEPER.setHardwareTypes([]));
        } else {
            let final = Object.entries(hardware.data.versions).map((e) => ({
                hardwareType: e[0],
                firmwareVersions: e[1]
            }));
            yield put(ACTIONS_GATEWAY_KEEPER.setHardwareTypes(final));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_GATEWAY_KEEPER.setHardwareTypes([]));
    }
}

function* requestFirmwareSettings({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setSiteFirmwareSettings(null));
        let hardware = yield call(API.POST, payload.url, payload.data);
        if (!hardware || !hardware.data || !hardware.data.settings) {
            yield put(ACTIONS_GATEWAY_KEEPER.setSiteFirmwareSettings([]));
        } else {
            yield put(ACTIONS_GATEWAY_KEEPER.setSiteFirmwareSettings(Object.values(hardware.data.settings)));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_GATEWAY_KEEPER.setSiteFirmwareSettings([]));
    }
}

function* requestUpdateFirmwareSettings({ payload }) {
    try {
        let update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_APP.showMessage(`errorCodes.description.2`, 'warning'));
        } else {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTriggerFirmwareUpdate({ payload }) {
    try {
        let trigger = yield call(API.POST, payload.url, payload.data);
        if (!trigger) {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_APP.showMessage(`errorCodes.description.2`, 'warning'));
        } else {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_APP.showMessage(`notifications.success.request`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTriggerIndividualFirmwareUpdate({ payload }) {
    try {
        let trigger = yield call(API.POST, payload.url, payload.data);
        if (!trigger) {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_APP.showMessage(`errorCodes.description.2`, 'warning'));
        } else {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_APP.showMessage(`notifications.success.request`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFirmwareUpdateStatuses({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY_KEEPER.setFirmwareSiteStatuses(null));
        let statuses = yield call(API.POST, payload.url, payload.data);
        if (!statuses || !statuses.data || !statuses.data.statuses) {
            yield put(ACTIONS_GATEWAY_KEEPER.setFirmwareSiteStatuses([]));
        } else {
            yield put(ACTIONS_GATEWAY_KEEPER.setFirmwareSiteStatuses(statuses.data.statuses));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_GATEWAY_KEEPER.setFirmwareSiteStatuses([]));
    }
}

export function* selectGatewayKeeperList() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_KEEPER_LIST, requestGatewayKeeperList);
}
export function* selectGatewayKeeperDeviceList() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_KEEPER_DEVICE_LIST, requestGatewayKeeperDeviceList);
}
export function* selectGatewayKeeperDeviceDetails() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_KEEPER_DEVICE_DETAILS, requestGatewayKeeperDeviceDetails);
}
export function* selectGatewayKeeperDetails() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_KEEPER_DETAILS, requestGatewayKeeperDetails);
}
export function* selectGatewayKeeperUnitHistory() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_KEEPER_DEVICE_HISTORY, requestGatewayKeeperUnitHistory);
}
export function* selectGatewayKeeperGatewayHistory() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_KEEPER_GATEWAY_HISTORY, requestGatewayKeeperGatewayHistory);
}
export function* selectRebootGateways() {
    yield takeEvery(CONSTANTS.REBOOT_SITE_GATEWAYS, requestRebootGateways);
}
export function* selectRebootGateway() {
    yield takeEvery(CONSTANTS.REBOOT_GATEWAY, requestRebootGateway);
}
export function* selectDeleteSiteGateway() {
    yield takeEvery(CONSTANTS.DELETE_SITE_GATEWAY, requestDeleteSiteGateway);
}
export function* selectEnterDiagMode() {
    yield takeEvery(CONSTANTS.ENTER_DIAGNOSTIC_MODE, requestEnterDiagMode);
}
export function* selectHardwareTypes() {
    yield takeEvery(CONSTANTS.FETCH_HARDWARE_TYPES, requestHardwareTypes);
}
export function* selectFirmwareSettings() {
    yield takeEvery(CONSTANTS.FETCH_SITE_FIRMWARE_SETTINGS, requestFirmwareSettings);
}
export function* selectUpdateFirmwareSettings() {
    yield takeEvery(CONSTANTS.UPDATE_SITE_FIRMWARE_SETTINGS, requestUpdateFirmwareSettings);
}
export function* selectTriggerFirmwareUpdate() {
    yield takeEvery(CONSTANTS.TRIGGER_SITE_FIRMWARE_UPDATE, requestTriggerFirmwareUpdate);
}
export function* selectTriggerIndividualFirmwareUpdate() {
    yield takeEvery(CONSTANTS.TRIGGER_INDIVIDUAL_FIRMWARE_UPDATE, requestTriggerIndividualFirmwareUpdate);
}
export function* selectFirmwareUpdateStatuses() {
    yield takeEvery(CONSTANTS.FETCH_FIRMWARE_SITE_STATUSES, requestFirmwareUpdateStatuses);
}

export default function* rootSaga() {
    yield all([
        fork(selectGatewayKeeperList),
        fork(selectGatewayKeeperDeviceList),
        fork(selectGatewayKeeperDeviceDetails),
        fork(selectGatewayKeeperDetails),
        fork(selectGatewayKeeperUnitHistory),
        fork(selectGatewayKeeperGatewayHistory),
        fork(selectRebootGateways),
        fork(selectRebootGateway),
        fork(selectDeleteSiteGateway),
        fork(selectEnterDiagMode),
        fork(selectHardwareTypes),
        fork(selectFirmwareSettings),
        fork(selectUpdateFirmwareSettings),
        fork(selectTriggerFirmwareUpdate),
        fork(selectTriggerIndividualFirmwareUpdate),
        fork(selectFirmwareUpdateStatuses)
    ]);
}
