import * as CONSTANTS from 'constants';

const INIT_STATE = {
    companyList: [],
    companySettings: null,
    companyStats: null,
    companyHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'phone'
        },
        {
            id: 2,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'email'
        },
        {
            id: 3,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state'
        }
    ],
    companyFilters: [
        {
            title: 'filters.all',
            value: ['client', 'employee', 'technician', 'site_manager', 'company_manager'],
            active: true
        },
        {
            title: 'filters.users.client',
            value: ['client'],
            active: false
        },
        {
            title: 'filters.users.employee',
            value: ['employee'],
            active: false
        },
        {
            title: 'filters.users.technician',
            value: ['technician'],
            active: false
        },
        {
            title: 'filters.users.site_manager',
            value: ['site_manager'],
            active: false
        },
        {
            title: 'filters.users.company_manager',
            value: ['company_manager'],
            active: false
        }
    ],
    companyHeaderShort: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_COMPANY_LIST: {
            return {
                ...state,
                companyList: action.payload
            };
        }
        case CONSTANTS.SET_COMPANY_SETTINGS: {
            return {
                ...state,
                companySettings: action.payload
            };
        }
        case CONSTANTS.SET_COMPANY_STATS: {
            return {
                ...state,
                companyStats: action.payload
            };
        }
        default:
            return state;
    }
};
