import * as CONSTANTS from 'constants';

export const fetchCameras = () => {
    return {
        type: CONSTANTS.FETCH_CAMERAS,
        payload: {
            url: '/site/cameras/',
            data: {}
        }
    };
};

export const setCameras = (data) => {
    return {
        type: CONSTANTS.SET_CAMERAS,
        payload: data
    };
};

export const fetchCameraToken = () => {
    return {
        type: CONSTANTS.FETCH_CAMERA_TOKEN,
        payload: {
            url: '/site/camera/live/',
            data: {}
        }
    };
};

export const setCameraToken = (data) => {
    return {
        type: CONSTANTS.SET_CAMERA_TOKEN,
        payload: data
    };
};
