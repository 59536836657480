import QuickOpen from 'components/Dashboard/quickOpen';
import ActivityOverview from 'components/Dashboard/activityOverview';
import ActivityRecent from 'components/Dashboard/activityRecent';
import Notifications from 'components/Dashboard/notifications';
import OpenUnits from 'components/Dashboard/openUnits';
import RentalStatus from 'components/Dashboard/rentalStatus';
import RecentUsers from 'components/Dashboard/recentUsers';
import RecentUnits from 'components/Dashboard/recentUnits';
import LowBattery from 'components/Dashboard/lowBattery';
import DailyCodes from 'components/Dashboard/dailyCodes';
import SharedUnits from 'components/Dashboard/sharedUnits';
import RecentUpdates from 'components/Dashboard/recentUpdates';
import FobList from 'components/Dashboard/fobList';
import HubspotTickets from 'components/Dashboard/hubspotTickets';
import Weather from 'components/Dashboard/weather';
import Ecobee from 'components/Dashboard/ecobee';
import UnitNotes from 'components/Dashboard/unitNotes';
import SiteNotes from 'components/Dashboard/siteNotes';
import LoiteringUsers from 'components/Dashboard/loiteringUsers';
import KnowledgeBase from 'components/Dashboard/knowledgeBase';
import Feedback from 'components/Dashboard/feedback';
import OutOfTempLocks from 'components/Dashboard/outOfTempLocks';
import TransferUsers from 'components/Dashboard/transferUsers';

export const latestNews = [
    {
        id: 'fakeNews-0',
        image: 'https://secureservercdn.net/166.62.112.199/m5n.19f.myftpupload.com/wp-content/uploads/2019/05/janus.jpg',
        title: 'Noke Inc., creator of high-tech smart locking solutions, is acquired by the self-storage building and facility automation experts at Janus International',
        url: 'https://blog.noke.com/noke-inc-creator-of-high-tech-smart-locking-solutions-is-acquired-by-the-self-storage-building-and-facility-automation-experts-at-janus-international/'
    },
    {
        id: 'fakeNews-1',
        image: 'https://secureservercdn.net/166.62.112.199/m5n.19f.myftpupload.com/wp-content/uploads/2019/05/door-storage.jpeg',
        title: 'Nokē partners with Janus International Group to release all-new mobile, wireless SecurGuard™',
        url: 'https://blog.noke.com/noke-partners-with-janus-international-group-to-release-all-new-mobile-wireless-securguard/'
    }
];

export const unitsWithoutActivity = [
    {
        id: 'unitsWithoutActivity-0',
        name: 'Today',
        value: 33
    },
    {
        id: 'unitsWithoutActivity-1',
        name: 'Past Week',
        value: 47
    },
    {
        id: 'unitsWithoutActivity-2',
        name: 'Past Month',
        value: 235
    },
    {
        id: 'unitsWithoutActivity-3',
        name: 'Past Year',
        value: 479
    }
];

export const averageOpenUnitTime = [
    {
        id: 'averageOpenUnitTime-0',
        name: 'Today',
        value: null,
        hour: 3,
        min: 22
    },
    {
        id: 'averageOpenUnitTime-1',
        name: 'Past Week',
        value: null,
        hour: 4,
        min: 49
    },
    {
        id: 'averageOpenUnitTime-2',
        name: 'Past Month',
        value: null,
        hour: 4,
        min: 31
    },
    {
        id: 'averageOpenUnitTime-3',
        name: 'Past Year',
        value: null,
        hour: 5,
        min: 17
    }
];

export const activityOverview = [
    {
        name: '12:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '1:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '2:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '3:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '4:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '5:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '6:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '7:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '8:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '9:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '10:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '11:00 AM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '12:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '1:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '2:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '3:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '4:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '5:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '6:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '7:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '8:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '9:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '10:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: '11:00 PM',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    }
];

export const activityOverviewDay = [
    {
        name: 'Aug 27',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 28',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 29',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 30',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Sept 1',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Sept 2',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Sept 3',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    }
];

export const activityOverviewMonth = [
    {
        name: 'Aug 3',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 4',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 5',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 6',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 7',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 8',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 9',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 10',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 11',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 12',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 13',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 14',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 15',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 16',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 17',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 18',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 19',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 20',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 21',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 22',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 23',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 24',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 25',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 26',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 27',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 28',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 29',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Aug 30',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Sep 1',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Sep 2',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    },
    {
        name: 'Sep 3',
        'Avg.': Math.floor(Math.random() * Math.floor(120)),
        Activity: Math.floor(Math.random() * Math.floor(120))
    }
];

export const urgentItems = [
    {
        id: 1,
        total: 2,
        type: 'attention',
        event: {
            name: 'db.urgent.motion',
            description: 'db.urgent.motion.description',
            warning: 'db.urgent.motion.warning',
            action: 'db.urgent.motion.action'
        },
        buttons: [
            {
                title: 'db.urgent.motion.found',
                description: 'db.urgent.motion.found.description',
                color: 'themeColor'
            },
            {
                title: 'db.urgent.motion.verify.description',
                description: 'db.urgent.motion.verify.description',
                color: 'go'
            }
        ]
    },
    {
        id: 2,
        total: 2,
        type: 'attention',
        event: {
            name: 'db.urgent.open',
            description: 'db.urgent.open.description',
            warning: 'db.urgent.open.warning',
            action: 'db.urgent.open.action'
        },
        buttons: [
            {
                title: 'db.urgent.open.secured',
                description: 'db.urgent.open.secured.description',
                color: 'themeColor'
            }
        ]
    },
    {
        id: 3,
        total: 2,
        type: 'warning',
        event: {
            name: 'db.urgent.motion',
            description: 'db.urgent.motion.description',
            warning: 'db.urgent.motion.warning',
            action: 'db.urgent.motion.action'
        },
        buttons: [
            {
                title: 'db.urgent.motion.found',
                description: 'db.urgent.motion.found.description',
                color: 'themeColor'
            },
            {
                title: 'db.urgent.motion.verify',
                description: 'db.urgent.motion.verify.description',
                color: 'go'
            }
        ]
    },
    {
        id: 4,
        total: 2,
        type: 'warning',
        event: {
            name: 'db.urgent.open',
            description: 'db.urgent.open.description',
            warning: 'db.urgent.open.warning',
            action: 'db.urgent.open.action'
        },
        buttons: [
            {
                title: 'db.urgent.open.secured',
                description: 'db.urgent.open.secured.description',
                color: 'themeColor'
            }
        ]
    },
    {
        id: 5,
        total: 2,
        type: 'warning',
        event: {
            name: 'db.urgent.checkout',
            description: 'db.urgent.checkout.description',
            warning: 'db.urgent.checkout.warning',
            action: 'db.urgent.checkout.action'
        },
        buttons: [
            {
                title: 'db.urgent.checkout.complete',
                description: 'db.urgent.checkout.complete.description',
                color: 'themeColor'
            }
        ]
    }
];

export const openUnits = [
    {
        id: 1,
        time: '4 hours, 8 minutes',
        warning: true
    },
    {
        id: 2,
        time: '1 hour, 12 minutes',
        warning: false
    },
    {
        id: 3,
        time: '45 minutes',
        warning: false
    },
    {
        id: 4,
        time: '9 minutes',
        warning: false
    }
];

export const dashboardWidgetsList = [
    {
        id: '0',
        name: ActivityOverview,
        stringName: 'Activity Overview/Active Tenants',
        customHeight: 284,
        permissions: ['view_user_activity'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '1',
        name: ActivityRecent,
        stringName: 'Recent Activity/On-Site Now',
        customHeight: 316,
        permissions: ['view_user_activity'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '2',
        name: RecentUsers,
        stringName: 'Most Active Users',
        customHeight: 259,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '3',
        name: RecentUnits,
        stringName: 'Most Active Units',
        customHeight: 259,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '4',
        name: QuickOpen,
        stringName: 'Remote Open',
        customHeight: 254,
        permissions: ['remote_open'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '5',
        name: OpenUnits,
        stringName: 'Unlocked Units',
        customHeight: 223,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '6',
        name: DailyCodes,
        stringName: 'Temporary Code',
        customHeight: 229,
        permissions: ['manage_access_codes'],
        featureFlags: [],
        checkHas3K: true,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '7',
        name: LowBattery,
        stringName: 'Low Battery Unit Controllers',
        customHeight: 229,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '8',
        name: Notifications,
        stringName: 'Notifications',
        customHeight: 225,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '9',
        name: RentalStatus,
        stringName: 'Unit Status/Lock Type/Unit Controller Status',
        customHeight: 374,
        permissions: ['view_rental_chart'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '10',
        name: SharedUnits,
        stringName: 'Shared Units',
        customHeight: 328,
        permissions: ['view_rental_chart'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
    {
        id: '11',
        name: FobList,
        stringName: 'Fobs',
        customHeight: 223,
        permissions: ['user_fobs'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '12',
        name: Weather,
        stringName: 'Weather Outlook',
        customHeight: 269,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '13',
        name: Ecobee,
        stringName: 'Climate Control',
        customHeight: 300,
        permissions: ['manage_thermostat'],
        featureFlags: ['ecobee_integration'],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '14',
        name: HubspotTickets,
        stringName: 'Hubspot Tickets',
        customHeight: 314,
        permissions: ['permission_view_hubspot_tickets'],
        featureFlags: ['view_hubspot_tickets'],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '15',
        name: UnitNotes,
        stringName: 'Unit Tasks',
        customHeight: 293,
        permissions: ['view_tasks'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '16',
        name: LoiteringUsers,
        stringName: 'Loitering Users',
        customHeight: 295,
        permissions: ['view_users'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: true,
        default: false
    },
    {
        id: '17',
        name: SiteNotes,
        stringName: 'Support Site Notes',
        customHeight: 293,
        permissions: ['support_view_site_notes'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '18',
        name: KnowledgeBase,
        stringName: 'Trending Nokē Support Questions',
        customHeight: 344,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '19',
        name: Feedback,
        stringName: 'Customer Feedback',
        customHeight: 293,
        permissions: [],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '20',
        name: OutOfTempLocks,
        stringName: 'Abnormal Temperature Locks',
        customHeight: 295,
        permissions: ['view_site_locks'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '21',
        name: TransferUsers,
        stringName: 'Transfer Users',
        customHeight: 340,
        permissions: ['manage_auction'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: false
    },
    {
        id: '22',
        name: RecentUpdates,
        stringName: 'Recent Updates',
        customHeight: 328,
        permissions: ['view_rental_chart'],
        featureFlags: [],
        checkHas3K: false,
        checkLoiteringAlert: false,
        default: true
    },
];
