import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import App from './App';
import Settings from './Settings';
import Auth from './Auth';
import Units from './Units';
import Users from './Users';
import Help from './Help';
import Reports from './Reports';
import Support from './Support';
import Entry from './Entry';
import Gateways from './Gateways';
import GatewayKeeper from './GatewayKeeper';
import Zones from './Zones';
import Activity from './Activity';
import Roles from './Roles';
import Company from './Company';
import Notifications from './Notifications';
import Security from './Security';
import AccessCodes from './AccessCodes';
import Maps from './Maps';
import Releases from './Releases';
import ActivityForwarding from './ActivityForwarding';
import Cameras from './Cameras';
import HelpWizard from './HelpWizard';
import Wifi from './Wifi';
import { history } from '../store/history';

const reducers = combineReducers({
    app: App,
    settings: Settings,
    auth: Auth,
    units: Units,
    users: Users,
    reports: Reports,
    help: Help,
    support: Support,
    entry: Entry,
    gateways: Gateways,
    gatewayKeeper: GatewayKeeper,
    zones: Zones,
    activity: Activity,
    company: Company,
    notifications: Notifications,
    security: Security,
    roles: Roles,
    accessCodes: AccessCodes,
    maps: Maps,
    releases: Releases,
    activityForwarding: ActivityForwarding,
    cameras: Cameras,
    wizard: HelpWizard,
    wifi: Wifi,
    router: connectRouter(history)
});

export default reducers;
