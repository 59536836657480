import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
class MenuAdd extends React.Component {
    constructor(props) {
        super(props);
    }
    checkPermissions = (permission, menuPMS) => {
        const { authUser, pms } = this.props;
        if (!permission || authUser.permissions.includes(permission)) {
            return true;
        }
        if (menuPMS && !pms) {
            return true;
        }
        return false;
    };
    render() {
        const { intl, toggle, theme } = this.props;
        this.styles = styles(theme);
        const menu = [
            {
                id: 'pages.dashboard.viewRemoved',
                link: '/app/users/removed',
                permission: 'manage_users'
            },
            {
                id: 'pages.dashboard.onsitePushSend',
                link: '/app/notifications/onsite/create',
                permission: 'manage_site_notifications'
            }
        ];
        return (
            <ul className="list-unstyled">
                {menu.map((item) => {
                    return (
                        this.checkPermissions(item.permission, item.pms) && (
                            <Link to={item.link} key={item.id} onClick={toggle} style={{ textDecoration: 'none' }}>
                                <li className={css(this.styles.addList)}>
                                    <p className={css(this.styles.addTitle)}>
                                        {intl.formatMessage({ id: `${item.id}` })}
                                    </p>
                                    <div className={css(this.styles.addArrow)}>
                                        <i style={{ fontSize: 17 }} className="material-icons">
                                            arrow_forward_ios
                                        </i>
                                    </div>
                                </li>
                            </Link>
                        )
                    );
                })}
            </ul>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { authUser, pms } = auth;
    const { theme } = app;
    return { theme, authUser, pms };
};

export default injectIntl(withRouter(connect(mapStateToProps)(MenuAdd)));

const styles = (theme) =>
    StyleSheet.create({
        addList: {
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            width: '100%',
            color: theme.color.themeColor,
            paddingTop: 10,
            position: 'relative',
            ':hover': {
                textDecoration: 'none',
                opacity: '.8',
                cursor: 'pointer'
            }
        },
        addTitle: {
            margin: 0,
            padding: '8px 20px 8px 0',
            fontWeight: 400,
            fontFamily: 'Roboto',
            fontSize: 12
        },
        addArrow: {
            position: 'absolute',
            right: 8,
            top: 20
        }
    });
