import * as CONSTANTS from 'constants';

const INIT_STATE = {
    siteWifi: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_WIFI: {
            return {
                ...state,
                siteWifi: action.payload
            };
        }
        default:
            return state;
    }
};
