import * as CONSTANTS from 'constants';

export const setActiveHelp = (data) => {
    return {
        type: CONSTANTS.SET_HELP_ACTIVE,
        payload: data
    };
};

export const fetchApiDocumentation = () => {
    return {
        type: CONSTANTS.FETCH_API_DOCUMENTATION,
        payload: {
            url: '/api/documentation/read/',
            data: {}
        }
    };
};

export const setApiDocumentation = (data) => {
    return {
        type: CONSTANTS.SET_API_DOCUMENTATION,
        payload: data
    };
};

export const createApiDocumentation = ({ title = '', markdown = '', siteUUIDs = [] }) => {
    return {
        type: CONSTANTS.CREATE_API_DOCUMENTATION,
        payload: {
            url: '/api/documentation/create/',
            required: {
                title,
                markdown
            },
            data: {
                title,
                markdown,
                siteUUIDs
            }
        }
    };
};

export const editApiDocumentation = ({ uuid = '', title = '', markdown = '', siteUUIDs }) => {
    return {
        type: CONSTANTS.EDIT_API_DOCUMENTATION,
        payload: {
            url: '/api/documentation/update/',
            data: {
                uuid,
                title,
                markdown,
                siteUUIDs
            }
        }
    };
};

export const deleteApiDocumentation = (uuid = '') => {
    return {
        type: CONSTANTS.DELETE_API_DOCUMENTATION,
        payload: {
            url: '/api/documentation/delete/',
            data: {
                apiDocumentationUUID: uuid
            }
        }
    };
};
