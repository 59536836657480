import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { FormGroup } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'components/Modal/new';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';

import { switchSite } from 'actions/Auth';
import { fetchManagerSites } from 'actions/Users';
import { setSwitcherLoader } from 'actions/App';

import { SiteIcon } from 'utilities/icons';
import { filterList } from 'utilities';

import TextInput from 'components/Inputs/text';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
class DashboardMyStores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            currentSite: null,
            filterSitesList: null,
            siteSearch: ''
        };
    }
    componentDidMount() {
        const { fetchManagerSites } = this.props;
        fetchManagerSites();
    }
    toggleModal = (site) => {
        site
            ? this.setState({ showModal: true, currentSite: site })
            : this.setState({ showModal: false, currentSite: null });
    };
    switchSite = (data) => {
        const { switchSite, setSwitcherLoader, intl } = this.props;
        setSwitcherLoader({loading: true, text: intl.formatMessage({ id: 'comp.button.switchingSite' })});
        switchSite(data.uuid, false, false, false, false, true);
        setTimeout(() => {
            sessionStorage.removeItem('manager_dash');
        }, 750);
        setTimeout(() => {
            this.props.history.push('/app/home');
        }, 1000);
    };
    handleSearch = (value) => {
        const typeSet = new Set(['name']);
        let data = filterList(this.props.managerSites, typeSet, value);
        this.setState({ siteSearch: value, filterSitesList: data });
    };
    render() {
        const { intl, theme, managerSites } = this.props;
        const { showModal, currentSite, filterSitesList, siteSearch } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <Modal isOpen={showModal} toggle={() => this.toggleModal(null)}>
                    <ModalHeader
                        style={{
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            borderBottom: '1px solid' + theme.color.borderColor,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4
                        }}
                        toggle={() => this.toggleModal(null)}>
                        <SiteIcon color={theme.color.themeColor} />
                    </ModalHeader>
                    <ModalBody
                        style={{
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '80px 0',
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4
                        }}>
                        <div>
                            <h1 className={css(this.styles.header)}>
                                {intl.formatMessage({ id: 'comp.button.viewStore' })} {currentSite && currentSite.name}?
                            </h1>
                        </div>
                        <div style={{ marginTop: 50 }}>
                            {currentSite && (
                                <Button
                                    width={100}
                                    text={'comp.form.yes'}
                                    action={() => this.switchSite(currentSite)}
                                />
                            )}
                            <Button width={100} text={'comp.button.goBack'} action={() => this.toggleModal(null)} />
                        </div>
                    </ModalBody>
                </Modal>
                <div className={css(this.styles.titleBox)}>
                    <SiteIcon color={theme.color.themeColor} />
                    <h1 className={css(this.styles.header)}>{intl.formatMessage({ id: 'pages.dashboard.yourStores' })}</h1>
                </div>
                <div>
                    <FormGroup
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-between',
                            marginBottom: 12
                        }}>
                        <TextInput
                            placeholder={intl.formatMessage({ id: 'comp.search.myFacilities' })}
                            fontSize={12}
                            type="text"
                            name="siteSearch"
                            value={siteSearch}
                            width={'100%'}
                            margin={'0px 0px 0px 0px'}
                            noMargin={true}
                            onChange={(e) => this.handleSearch(e.target.value)}
                        />
                        <div className={css(this.styles.searchIcon)}>
                            <i
                                className="material-icons"
                                style={{
                                    fontSize: 28,
                                    fontWeight: '400',
                                    color: theme.color.themeColor
                                }}>
                                search
                            </i>
                        </div>
                    </FormGroup>
                </div>
                <div style={{ overflowY: 'auto' }}>
                    {filterSitesList ? (
                        <div className={css(this.styles.table)}>
                            {filterSitesList && filterSitesList.length > 0 ? (
                                <div className={css(this.styles.siteList)} style={{ maxHeight: 407 }}>
                                    {filterSitesList.map((site) => (
                                        <div
                                            key={'SITELIST' + site.uuid}
                                            className={css(this.styles.siteBox)}
                                            onClick={() => this.toggleModal(site)}>
                                            <div className={css(this.styles.siteBoxTop)}>
                                                <SiteIcon color={'white'} />
                                            </div>
                                            <div className={css(this.styles.siteBoxBottom)}>
                                                <p
                                                    style={{
                                                        fontWeight: 600,
                                                        fontSize: 14,
                                                        margin: 0
                                                    }}>
                                                    {site.name}
                                                </p>
                                                <p style={{ margin: 0 }}>
                                                    {intl.formatMessage({
                                                        id: 'comp.table.header.lowBatteryUnit'
                                                    })}
                                                    : {site.lowBatteryCount}
                                                </p>
                                                <p style={{ margin: 0 }}>
                                                    {intl.formatMessage({
                                                        id: 'comp.table.header.openUnits'
                                                    })}
                                                    : {site.openUnitsCount}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        minHeight: 102,
                                        fontSize: 12,
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        animationName: fadeIn,
                                        animationDuration: '1.2s'
                                    }}>
                                    <div>
                                        <span>
                                            {intl.formatMessage({
                                                id: 'comp.table.cell.empty'
                                            })}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                    :
                    managerSites ? (
                        <div className={css(this.styles.table)}>
                            {managerSites && managerSites.length > 0 ? (
                                <div className={css(this.styles.siteList)} style={{ maxHeight: 407 }}>
                                    {managerSites.map((site) => (
                                        <div
                                            key={'SITELIST' + site.uuid}
                                            className={css(this.styles.siteBox)}
                                            onClick={() => this.toggleModal(site)}>
                                            <div className={css(this.styles.siteBoxTop)}>
                                                <SiteIcon color={'white'} />
                                            </div>
                                            <div className={css(this.styles.siteBoxBottom)}>
                                                <p
                                                    style={{
                                                        fontWeight: 600,
                                                        fontSize: 14,
                                                        margin: 0
                                                    }}>
                                                    {site.name}
                                                </p>
                                                <p style={{ margin: 0 }}>
                                                    {intl.formatMessage({
                                                        id: 'comp.table.header.lowBatteryUnit'
                                                    })}
                                                    : {site.lowBatteryCount}
                                                </p>
                                                <p style={{ margin: 0 }}>
                                                    {intl.formatMessage({
                                                        id: 'comp.table.header.openUnits'
                                                    })}
                                                    : {site.openUnitsCount}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        minHeight: 102,
                                        fontSize: 12,
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        animationName: fadeIn,
                                        animationDuration: '1.2s'
                                    }}>
                                    <div>
                                        <span>
                                            {intl.formatMessage({
                                                id: 'comp.table.cell.empty'
                                            })}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ app, users }) => {
    const { theme, height } = app;
    const { managerSites } = users;
    return { theme, height, managerSites };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, { fetchManagerSites, switchSite, setSwitcherLoader })(DashboardMyStores)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.fontColor,
            marginBottom: 15,
            marginLeft: 10
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
        },
        table: {
            width: '100%',
            height: '100%',
            fontSize: 11
        },
        siteList: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            flexWrap: 'wrap',
            height: '100%',
            width: '100%',
            overflowY: 'auto'
        },
        input: {
            border: 'none',
            boxShadow: 'none',
            borderRadius: 0,
            padding: '10px 0 5px 0',
            fontWeight: 400,
            fontSize: 14,
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor,
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 14
            }
        },
        searchIcon: {
            paddingTop: 8,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        siteBox: {
            color: theme.color.fontColor,
            margin: '5px 1%',
            minHeight: 125,
            minWidth: 150,
            maxWidth: 200,
            textAlign: 'center',
            width: '31%',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:500px)': {
                width: '100%',
                marginRight: 10
            }
        },
        siteBoxTop: {
            background: theme.color.themeColor,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px 0',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            position: 'relative'
        },
        siteBoxBottom: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '10px 10px'
        },
        priorityIcon: {
            color: 'white',
            position: 'absolute',
            top: 2,
            right: 2,
            transition: 'all .2s ease-in-out',
            opacity: 1,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        }
    });
