import * as CONSTANTS from 'constants';

export const fetchWifi = () => {
    return {
        type: CONSTANTS.FETCH_WIFI,
        payload: {
            url: '/site/keypads/wifi/ssids/get/',
            data: {
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
                companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
            }
        }
    };
};

export const setWifi = (data) => {
    return {
        type: CONSTANTS.SET_WIFI,
        payload: data
    };
};

export const createWifi = ({ ssid = '', password = '' }) => {
    return {
        type: CONSTANTS.CREATE_WIFI,
        payload: {
            url: '/site/keypads/wifi/add/',
            data: {
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
                companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                ssid,
                password
            }
        }
    };
};

export const editWifi = ({ ssid = '', password = '' }) => {
    return {
        type: CONSTANTS.EDIT_WIFI,
        payload: {
            url: '/site/keypads/wifi/password/update/',
            data: {
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
                companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                ssid,
                password
            }
        }
    };
};

export const deleteWifi = (ssid) => {
    return {
        type: CONSTANTS.DELETE_WIFI,
        payload: {
            url: '/site/keypads/wifi/delete/',
            data: {
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
                companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                ssid
            }
        }
    };
};
