import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Button from 'components/Button';

import { setLoader } from 'actions/App';
class Cookies extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, theme } = this.props;
        this.styles = styles(theme);
        return (
            <>
                <div className={css(this.styles.cookies)}>
                    <div style={{ opacity: 1 }}>
                        <i className="material-icons" style={{ color: 'white', marginRight: 5 }}>
                            privacy_tip
                        </i>
                        {intl.formatMessage({ id: 'comp.button.acceptCookiesDesc' })}{' '}
                        <a
                            title="Link to Janus International Privacy Policy"
                            href="https://www.janusintl.com/privacypolicy"
                            target="_blank"
                            style={{ fontWeight: 600, color: 'white', outline: 'none' }}
                            rel="noreferrer">
                            {intl.formatMessage({ id: 'comp.button.privacyPolicy' })}
                        </a>
                        .
                    </div>
                    <Button
                        action={() => {
                            this.props.setLoader(false);
                            this.props.disableCookies();
                        }}
                        text={`comp.button.acceptCookies`}
                        style={{ fontSize: 14 }}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(injectIntl(connect(mapStateToProps, { setLoader })(Cookies)));

const styles = (theme) =>
    StyleSheet.create({
        cookies: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            minHeight: '15vh',
            background: theme.color.themeColor,
            opacity: 0.8,
            padding: '20px 5vw',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 14
        }
    });
