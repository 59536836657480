const domain = window.location.href;
import firebase from 'firebase/app';
import 'firebase/firestore';
import '@firebase/messaging';

//||||||||||||||||||||||||||||||||||||||||
//|| APP                                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENV = domain.includes('smartentry.noke.dev') || domain.includes('localhost') ? '-dev' : '';
export const WEATHER = 'be36a336b410202c1590a24335116347';
export const WEATHER_API = '3728a3435dad44d3b02221029212012';
export const USER_DATA = JSON.parse(sessionStorage.getItem('user_data'));
export const SUPPORT_EMAIL = 'smartentrysupport@janusintl.com';
export const SITE_CREATE_EMAIL = 'ideas-SE-noke@mailer.aha.io';
export const SUPPORT_PHONE = '+1 (833) 257-0240';
export const SUPPORT_PHONE_AU = '1300 173 731';
export const SUPPORT_PHONE_UK = '+44 (0)208 092 9441';
export const SUPPORT_PHONE_ESS = '+1 (888) 675-0187';
export const SUPPORT_PHONE_ECC = '+1 (833) 976-3232';
export const SUPPORT_URL = 'https://support.noke.com/faqs/pg/q9r35uC5';
export const SUPPORT_HELPDESK = 'https://www.janusintl.com/knowledge';
export const TOKEN = USER_DATA && USER_DATA.token;
export const FETCH_STARTUP = 'fetchStartup';
export const FETCH_THEME = 'fetchTheme';
export const SET_THEME_COLORS = 'setThemeColors';
export const SET_THEME = 'setTheme';
export const SET_FILTER = 'setFilter';
export const SET_LOADER = 'setLoader';
export const FETCH_IP_LOCATION = 'fetchIpLocation';
export const SET_COUNTRY = 'setCountry';
export const SET_IP_LOCATION = 'setIpLocation';
export const PMS_BYPASS = [''];
export const FETCH_ERROR_DETAILS = 'fetchErrorDetails';
export const FETCH_ERROR_DETAILS_DISPLAY = 'fetchErrorDetailsDisplay';
export const SET_ERROR_DETAILS = 'setErrorDetails';
export const SET_ERROR_DETAILS_DISPLAY = 'setErrorDetailsDisplay';
export const SUBMIT_ERROR_DETAILS = 'submitErrorDetails';
export const FETCH_ALL_ERRORS = 'fetchAllErrors';
export const SET_ALL_ERRORS = 'setAllErrors';
export const SET_ERROR_HEADERS = 'setErrorHeaders';
export const UPDATE_ERROR = 'updateError';
export const DELETE_ERROR = 'deleteError';
export const FETCH_WEATHER = 'fetchWeather';
export const SET_WEATHER = 'setWeather';
export const FETCH_WEATHER_WEEK = 'fetchWeatherWeek';
export const SET_WEATHER_WEEK = 'setWeatherWeek';
export const SET_SWITCHER_LOADER = 'setSwitcherLoader';
export const FETCH_DASHBOARD_WIDGETS = 'fetchDashboardWidgets';
export const UPDATE_DASHBOARD_WIDGETS = 'updateDashboardWidgets';
export const SET_DASHBOARD_WIDGETS = 'setDashboardWidgets';
export const SET_AVAILABLE_WIDGETS = 'setAvailableWidgets';
export const FETCH_OUTAGE_STATUS = 'fetchOutageStatus';
export const UPDATE_OUTAGE_STATUS = 'updateOutageStatus';
export const FETCH_FACILITY_FEEDBACK = 'fetchFacilityFeedback';
export const SET_FACILITY_FEEDBACK = 'setFacilityFeedback';
export const FETCH_AIR_QUALITY = 'fetchAirQuality';
export const SET_AIR_QUALITY = 'setAirQuality';
export const SET_SAVE_LOADER = 'setSaveLoader';
export const SET_TEST_SUITE = 'setTestSuite';
export const SET_FEEDBACK_HEADERS = 'setFeedbackHeaders';
export const FETCH_SITE_VERSION = 'fetchSiteVersion';
export const SET_SITE_VERSION = 'setSiteVersion';
export const FETCH_USER_SITE_LIST = 'fetchUserSiteList';
export const SET_USER_SITE_LIST = 'setUserSiteList';
export const SET_SHOW_PORTAL_SEARCH = 'setShowPortalSearch';
export const FETCH_WEBHOOKS_SITE_DETAILS = 'fetchWebhooksSiteDetails';
export const FETCH_WEBHOOKS_CLIENT_DETAILS = 'fetchWebhooksClientDetails';
export const SET_WEBHOOKS_SITE_DETAILS = 'setWebhooksSiteDetails';
export const SET_WEBHOOKS_CLIENT_DETAILS = 'setWebhooksClientDetails';

//||||||||||||||||||||||||||||||||||||||||
//|| API                                ||
//||||||||||||||||||||||||||||||||||||||||
export const API_LOGIN = 'postLogin';
export const API_RESET = 'postReset';
export const API_COUNTRY = 'postCountry';
export const API_POST = 'postApi';
export const API_GET = 'getApi';
export const API_POST_REPORTS = 'postReports';
export const API_GET_REPORTS = 'getReports';
export const API_ADAM = 'getAdam';

var goodToGo = true;
var ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
        goodToGo = true;
    } else {
        goodToGo = false;
    }
}
//||||||||||||||||||||||||||||||||||||||||
//|| FIREBASE                           ||
//||||||||||||||||||||||||||||||||||||||||
export const FIREBASE_DEV = domain.includes('smartentry.noke.dev') || domain.includes('localhost');
export const FIREBASE_RUN = domain.includes('smartentry.noke.run');
// IF UPDATE CONFIGS, MAKE SURE TO UPDATE IN public/firebase FILES************************
export const FIREBASE_DEV_CONFIG = {
    apiKey: 'AIzaSyBxDCpEMjIqlcxDjqTmCR5CQI0O-5zELvo',
    authDomain: 'noke-smart-entry-dev.firebaseapp.com',
    databaseURL: 'https://noke-smart-entry-dev.firebaseio.com',
    projectId: 'noke-smart-entry-dev',
    storageBucket: 'noke-smart-entry-dev.appspot.com',
    messagingSenderId: '306210374648',
    appId: '1:306210374648:web:7db8dc759c87ecd87d0c0d',
    measurementId: 'G-ZXGCFZZ3RH'
};
export const FIREBASE_RUN_CONFIG = {
    apiKey: 'AIzaSyAOl7qh2hsU4qXaIx5_U8lhFlwPS0VrMVI',
    authDomain: 'noke-smart-entry-run.firebaseapp.com',
    databaseURL: 'https://noke-smart-entry-run.firebaseio.com',
    projectId: 'noke-smart-entry-run',
    storageBucket: 'noke-smart-entry-run.appspot.com',
    messagingSenderId: '1011498023638',
    appId: '1:1011498023638:web:5cfbea965cc851d4256777',
    measurementId: 'G-JH4WQJGS1Y'
};
export const FIREBASE_PROD_CONFIG = {
    apiKey: 'AIzaSyAuASihsDG1GWYO-p3ko87E7oBtOR2LspI',
    authDomain: 'extraspace-go.firebaseapp.com',
    databaseURL: 'https://extraspace-go.firebaseio.com',
    projectId: 'extraspace-go',
    storageBucket: 'extraspace-go.appspot.com',
    messagingSenderId: '1092784223122',
    appId: '1:1092784223122:web:1e855963c090ec1d6a474b',
    measurementId: 'G-G0P1JS09YW'
};

const FIREBASE_CONFIG =
    goodToGo &&
    firebase.initializeApp(
        FIREBASE_DEV ? FIREBASE_DEV_CONFIG : FIREBASE_RUN ? FIREBASE_RUN_CONFIG : FIREBASE_PROD_CONFIG
    );
const FIREBASE_DATABASE = goodToGo && FIREBASE_CONFIG.firestore();
const FIREBASE_MESSAGING = goodToGo && FIREBASE_CONFIG.messaging();
export const FIREBASE = goodToGo && FIREBASE_DATABASE;
export const FIREBASE_NOTIFICATIONS_MESSAGING = goodToGo && FIREBASE_MESSAGING;
export const FIREBASE_NOTIFICATIONS =
    goodToGo && FIREBASE_DATABASE.collection('notifications').doc('demoSite').collection('notifications');
export const FIREBASE_NOTIFICATIONS_VIEWED =
    goodToGo &&
    FIREBASE_DATABASE.collection('notifications')
        .doc('demoSite')
        .collection('notifications')
        .where('viewed', '==', true);
export const FIREBASE_NOTIFICATIONS_NOT_VIEWED =
    goodToGo &&
    FIREBASE_DATABASE.collection('notifications')
        .doc('demoSite')
        .collection('notifications')
        .where('viewed', '==', false);

//||||||||||||||||||||||||||||||||||||||||
//|| AUTH                               ||
//||||||||||||||||||||||||||||||||||||||||
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const FORGOT_PASSWORD = 'forgotPassword';
export const RESET_PASSWORD = 'resetPassword';
export const FORGOT_USER_PASSWORD = 'forgotUserPassword';
export const RESET_USER_PASSWORD = 'resetUserPassword';
export const RESET_USER_PIN = 'resetUserPin';
export const SWITCH_SITE = 'switchSite';
export const SET_SITE = 'setSite';
export const SET_CREATE_SITE = 'setCreateSite';
export const SYNC_SITE = 'syncSite';
export const RESET_PIN_CALL = 'resetPinCall';
export const FETCH_AUTH_PIN = 'fetchAuthPin';
export const SEND_AUTH_PIN = 'sendAuthPin';
export const SHOW_TWO_FACTOR = 'showTwoFactor';
export const VALIDATE_USER = 'validateUser';
export const SET_LOGIN_CONFIRM = 'setLoginConfirm';
export const SET_AUTH_USER = 'setAuthUser';
export const SET_VIEW_AS = 'setViewAs';
export const SET_LOGIN_ATTEMPT_MODAL = 'setLoginAttemptModal';
export const REFRESH_AUTH_TOKEN = 'refreshAuthToken';

//||||||||||||||||||||||||||||||||||||||||
//|| UNITS                              ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_UNITS_LIST = 'fetchUnitList';
export const FETCH_UNITS_STATS = 'fetchUnitsStats';
export const FETCH_UNITS_LIST_BY_ZONE = 'fetchUnitListByZone';
export const FETCH_UNIT_DETAILS = 'fetchUnitDetails';
export const FETCH_LAST_SYNC_INFO = 'fetchLastSyncInfo';
export const FETCH_UNIT_LOCK_DETAILS = 'fetchUnitLockDetails';
export const EDIT_UNIT_EXTERNAL_ID = 'editUnitExternalId';
export const EDIT_UNIT = 'editUnit';
export const EDIT_LOCK = 'editLock';
export const EDIT_LOCK_SILENTLY = 'editLockSilently';
export const EDIT_LOCK_SETTINGS = 'editLockSettings';
export const EDIT_LOCK_MOTION_SETTINGS = 'editLockMotionSettings'
export const EDIT_SITE_MOTION_SETTINGS = 'editSiteMotionSettings'
export const EDIT_LOCK_MAC = 'editLockMac';
export const REKEY_UNIT = 'rekeyUnit';
export const CHECKOUT_UNIT = 'checkoutUnit';
export const ASSIGN_UNIT = 'assignUnit';
export const UNASSIGN_UNIT = 'unassignUnit';
export const AUCTION_UNIT = 'auctionUnit';
export const REASSIGN_AUCTION = 'reassignAuction';
export const UNAUCTION_UNIT = 'unauctionUnit';
export const TRANSFER_UNIT = 'transferUnit';
export const UNTRANSFER_UNIT = 'untransferUnit';
export const SET_UNIT_STATE = 'setUnitState';
export const SET_UNITS_LIST = 'setUnitList';
export const SET_UNITS_LIST_STATS = 'setUnitListStats';
export const SET_UNITS_LIST_LOCK_STATS = 'setUnitListLockStats';
export const SET_UNITS_LIST_LOCK_STATUS_STATS = 'setUnitListLockStatusStats';
export const SET_UNIT = 'setUnit';
export const SET_LAST_SYNC_INFO = 'setLastSyncInfo';
export const SET_UNIT_LOCK_DETAILS = 'setUnitLockDetails';
export const SET_UNIT_HEADERS = 'setUnitHeaders';
export const SET_UNIT_HEADERS_WEBHOOKS = 'setUnitHeadersWebhooks';
export const SET_LOCK_HEADERS = 'setLockHeaders';
export const SET_ASSIGN_UNIT_HEADERS = 'setAssignUnitHeaders';
export const SET_ASSIGN_USER_HEADERS = 'setAssignUserHeaders';
export const SET_UNIT_FILTERS = 'setUnitFilters';
export const SET_UNIT_ACTIVE = 'setActiveUnit';
export const SET_LOCK_ACTIVE = 'setActiveLock';
export const SET_UNIT_EXTERNAL_ID = 'setUnitExternalId';
export const SET_UNIT_ZONE_SELECTED = 'setUnitZoneSelected';
export const REMOTE_UNLOCK = 'remoteUnlock';
export const UNLOCK_REQUEST_PIN = 'unlockRequestPin';
export const CREATE_REPEATING_LOCK_SCHEDULE = 'createRepeatingLockSchedule';
export const UPDATE_REPEATING_LOCK_SCHEDULE = 'updateRepeatingLockSchedule';
export const DELETE_REPEATING_LOCK_SCHEDULE = 'deleteRepeatingLockSchedule';
export const HOLD_OPEN_GATE_TIME = 'holdOpenGateTime';
export const HOLD_OPEN_GATE_SCHEDULE = 'holdOpenGateSchedule';
export const FETCH_LOCK_HOLDS = 'fetchLockHolds';
export const SET_LOCK_HOLDS = 'setLockHolds';
export const REMOVE_LOCK_HOLD = 'removeLockHold';
export const READ_LOCK_SCHEDULE = 'readLockSchedule';
export const READ_SITE_LOCK_SCHEDULES = 'readSiteLockSchedules';
export const SET_CURRENT_LOCK_SCHEDULES = 'setCurrentLockSchedules';
export const SET_CURRENT_SITE_LOCK_SCHEDULES = 'setCurrentSiteLockSchedules';
export const SET_OPEN_UNITS = 'setOpenUnits';
export const SET_UNLOCK_STATUS = 'setUnlockStatus';
export const FETCH_LOW_BATTERY_UNITS = 'fetchLowBatteryUnits';
export const SET_LOW_BATTERY_UNITS = 'setLowBatteryUnits';
export const FETCH_BATTERY_ISSUES = 'fetchBatteryIssues';
export const SET_LOW_BATTERY_UNITS_REPLACED = 'setLowBatteryUnitsReplaced';
export const SET_NEW_BATTERY_ENDPOINT_NOT_EXIST = 'setNewBatteryEndpointNotExist';
export const MARK_LOW_BATTERY_UNITS_REPLACED = 'markLowBatteryUnitsReplaced';
export const MARK_LOW_BATTERY_UNITS_NOT_REPLACED = 'markLowBatteryUnitsNotReplaced';
export const SET_LOCK_FILTER_UNIT = 'setLockFilterUnit';
export const CREATE_LOCK = 'createLock';
export const DELETE_LOCK = 'deleteLock';
export const DELETE_UNIT = 'deleteUnit';
export const REBOOT_SITE_UNITS = 'rebootSiteUnits';
export const SET_UNIT_FILTER = 'setUnitFilter';
export const SET_LOCK_TYPE_FILTER = 'setLockTypeFilter';
export const SET_LOCK_STATUS_FILTER = 'setLockStatusFilter';
export const FETCH_LOCK_SETTINGS = 'fetchLockSettings';
export const SET_LOCK_SETTINGS = 'setLockSettings';
export const FETCH_LOCK_MOTION_SETTINGS = 'fetchLockMotionSettings';
export const SET_LOCK_MOTION_SETTINGS = 'setLockMotionSettings';
export const FETCH_SITE_MOTION_SETTINGS = 'fetchSiteMotionSettings';
export const SET_SITE_MOTION_SETTINGS = 'setSiteMotionSettings';
export const FETCH_SHARED_UNITS = 'fetchSharedUnits';
export const SET_SHARED_UNITS = 'setSharedUnits';
export const SETUP_UNIT = 'setupUnit';
export const RESEND_KEYS = 'resendKeys';
export const FETCH_SYNC_UNITS = 'fetchSyncUnits';
export const FETCH_SYNC_UNITS_DASH = 'fetchSyncUnitsDash';
export const FETCH_PMS_SYNC = 'fetchPmsSync';
export const SET_SYNC_UNITS = 'setSyncUnits';
export const SET_PMS_SYNC_UNITS = 'setPmsSyncUnits';
export const SET_PMS_SYNC_CHANGES = 'setPmsSyncChanges';
export const FETCH_SITE_SHARES = 'fetchSiteShares';
export const SET_SITE_SHARES = 'setSiteShares';
export const FETCH_SITE_FOBS = 'fetchSiteFobs';
export const SET_SITE_FOBS = 'setSiteFobs';
export const MANAGER_SHARE_REQUEST = 'managerShareRequest';
export const FETCH_ALL_UNIT_NOTES = 'fetchAllUnitNotes';
export const SET_ALL_UNIT_NOTES = 'setAllUnitNotes';
export const FETCH_UNIT_NOTES = 'fetchUnitNotes';
export const SET_UNIT_NOTES = 'setUnitNotes';
export const FETCH_UNIT_NOTE = 'fetchUnitNote';
export const SET_UNIT_NOTE = 'setUnitNote';
export const CREATE_UNIT_NOTE = 'createUnitNote';
export const EDIT_UNIT_NOTE = 'editUnitNote';
export const DELETE_UNIT_NOTE = 'deleteUnitNote';
export const FETCH_LOCK_MANUFACTURING_DATA = 'fetchLockManufacturingData';
export const SET_LOCK_MANUFACTURING_DATA = 'setLockManufacturingData';
export const UPLOAD_UNIT_TASK_IMAGE = 'uploadUnitTaskImage';
export const DELETE_UNIT_TASK_IMAGE = 'deleteUnitTaskImage';
export const SET_TEMPERATURE_UNITS = 'setTemperatureUnits';
export const FETCH_UNIT_TASK_COMMENTS = 'fetchUnitTaskComments';
export const SET_UNIT_TASK_COMMENTS = 'setUnitTaskComments';
export const CREATE_UNIT_TASK_COMMENT = 'createUnitTaskComment';
export const EDIT_UNIT_TASK_COMMENT = 'editUnitTaskComment';
export const DELETE_UNIT_TASK_COMMENT = 'deleteUnitTaskComment';
export const SET_SYNC_UNITS_HEADERS = 'setSyncUnitsHeaders';
export const SET_SHARED_UNITS_HEADERS = 'setSharedUnitsHeaders';
export const SET_UNITS_TASKS_HEADERS = 'setUnitsTasksHeaders';
export const SET_UNITS_TASKS_COMMENTS_HEADERS = 'setUnitsTasksCommentsHeaders';
export const FETCH_REPEATER_LIST = 'fetchRepeaterList';
export const SET_REPEATER_LIST = 'setRepeaterList';
export const SET_REPEATER_HEADER = 'setRepeaterHeader';

//||||||||||||||||||||||||||||||||||||||||
//|| USERS                              ||
//||||||||||||||||||||||||||||||||||||||||
export const SEND_USER_ACTIVATION = 'sendUserActivation';
export const SEND_USER_ACTIVATION_ALL = 'sendUserActivationAll';
export const SET_NOTIFY_WARNING = 'setNotifyWarning';
export const UNFREEZE_USER_ACCOUNT = 'unfreezeUserAcount';
export const REFRESH_USER_PIN = 'refreshUserPin';
export const FETCH_USER_PIN = 'fetchUserPin';
export const FETCH_USER_LOGIN_ATTEMPTS = 'fetchUserLoginAttempts';
export const FETCH_USER_FOBS = 'fetchUserFobs';
export const FETCH_USER_SHARES = 'fetchUserShares';
export const SET_USER_SHARED_UNITS_HEADERS = 'setUserSharedUnitsHeaders';
export const SAVE_USER_SITES = 'saveUserSites';
export const CREATE_USER = 'createUser';
export const CREATE_SERVICE_USER = 'createServiceUser';
export const DELETE_USER = 'deleteUser';
export const DELETE_SERVICE_USER = 'deleteServiceUser';
export const REMOVE_USER_FOB = 'removeUserFob';
export const EDIT_USER = 'editUser';
export const EDIT_USER_ROLE = 'editUserRole';
export const EDIT_USER_PHONE = 'editUserPhone';
export const EDIT_USER_EMAIL = 'editUserEmail';
export const EDIT_USER_KEY_USE = 'editUserKeyUse';
export const EDIT_USER_VIEW = 'editUserView';
export const EDIT_USER_PERMISSION = 'editUserPermission';
export const EDIT_USER_EXPIRATION = 'editUserExpiration';
export const SAVE_USER_GATE_PERMISSIONS = 'saveUserGatePermissions';
export const FETCH_SITE_USER_LIST = 'fetchSiteUserList';
export const FETCH_REMOVED_USERS = 'fetchRemovedUsers';
export const FETCH_USER_DETAILS = 'fetchUserDetails';
export const FETCH_USER_PHONE_DETAILS = 'fetchUserPhoneDetails';
export const CONFIRM_USER_ACCOUNT = 'confirmUserAccount';
export const SET_SITE_USER_LIST = 'setSiteUserList';
export const SET_REMOVED_USERS = 'setRemovedUsers';
export const SET_COMPANY_USER_LIST_STATS = 'setCompanyUserListStats';
export const SET_SITE_USER_LIST_STATS = 'setSiteUserListStats';
export const SET_SITE_USER_LIST_STATUS_STATS = 'setSiteUserListStatusStats';
export const SET_SITE_USER_LIST_SERVICE_STATS = 'setSiteUserListServiceStats';
export const SET_USER_DETAILS = 'setUserDetails';
export const SET_USER_PHONE_DETAILS = 'setUserPhoneDetails';
export const SET_USER_HEADERS = 'setUserHeaders';
export const SET_USER_HEADERS_OLD = 'setUserHeadersOld';
export const SET_USER_SITE_HEADERS = 'setUserSiteHeaders';
export const SET_USER_PERMISSION_HEADERS = 'setUserPermissionHeaders';
export const SET_USER_SHARED_HEADERS = 'setUserSharedHeaders';
export const SET_USER_FOB_HEADERS = 'setUserFobHeaders';
export const SET_USER_FILTERS = 'setUserFilters';
export const SET_USER_FILTERS_OLD = 'setUserFiltersOld';
export const SET_USER_PIN = 'setUserPin';
export const SET_USER_LOGIN_ATTEMPTS = 'setUserLoginAttempts';
export const SET_USER_FOBS = 'setUserFobs';
export const SET_USER_SHARES = 'setUserShares';
export const SET_USER_ACTIVE = 'setActiveUser';
export const SAVE_USER_DAILY_HOURS = 'saveUserDailyHours';
export const DELETE_USER_DAILY_HOURS = 'deleteUserDailyHours';
export const REMOVE_ONSITE_USER = 'removeOnsiteUser';
export const USER_ACKNOWLEDGE_TRAINING = 'userAcknowledgeTraining';
export const FETCH_USER_UNITS = 'fetchUserUnits';
export const SET_USER_UNITS = 'setUserUnits';
export const SEND_USER_ACCESSCODE = 'sendUserAccesscode';
export const SET_USER_KEYPAD_STATUS = 'setUserKeypadStatus';
export const EDIT_USER_SETTINGS = 'editUserSettings';
export const SET_USER_FILTER_STATUS = 'setUserFilterStatus';
export const SET_USER_FILTER_SERVICE = 'setUserFilterService';
export const SET_USER_FILTER_SCHEDULE = 'setUserFilterSchedule';
export const SET_USER_EDITED_SCHEDULE = 'setUserEditedSchedule';
export const EDIT_SELF_2FACTOR = 'editSelf2Factor';
export const EDIT_2FACTOR = 'edit2Factor';
export const FETCH_SUPPORT_USERS = 'fetchSupportUsers';
export const FETCH_SUPPORT_USER_DETAILS = 'fetchSupportUserDetails';
export const SET_SUPPORT_USERS = 'setSupportUsers';
export const EXPORT_USERS = 'exportUsers';
export const EXPORT_REMOVED_USERS = 'exportRemovedUsers';
export const REMOVE_MANAGER_SHARE = 'removeManagerShare';
export const FETCH_ONSITE_USERS = 'fetchOnsiteUsers';
export const SET_ONSITE_USERS = 'setOnsiteUsers';
export const FETCH_ONSITE_USERS_ALL = 'fetchOnsiteUsersAll';
export const SET_ONSITE_USERS_ALL = 'setOnsiteUsersAll';
export const FETCH_MANAGER_SITES = 'fetchManagerSites';
export const SET_MANAGER_SITES = 'setManagerSites';
export const FETCH_EMPLOYEES_ONLINE = 'fetchEmployeesOnline';
export const SET_EMPLOYEES_ONLINE = 'setEmployeesOnline';
export const SET_REMOVED_USERS_DATES = 'setRemovedUsersDates';
export const FETCH_LOITERING_USERS = 'fetchLoiteringUsers';
export const SET_LOITERING_USERS = 'setLoiteringUsers';
export const FETCH_TRANSFERRING_USERS = 'fetchTransferringUsers';
export const SET_TRANSFERRING_USERS = 'setTransferringUsers';
export const EXTEND_USER_TRANSFER = 'extendUserTransfer';
export const SET_USER_ROLE_ACTIVE_FILTER = 'setUserRoleActiveFilter';
export const FETCH_TENANT_UNITS = 'fetchTenantUnits';
export const SET_TENANT_UNITS = 'setTenantUnits';
export const FETCH_TENANT_SHARES = 'fetchTenantShares';
export const SET_TENANT_SHARES = 'setTenantShares';
export const SET_CURRENT_USER_FILTER = 'setCurrentUserFilter';
export const SET_TRANSFER_HEADERS = 'setTransferHeaders';

//||||||||||||||||||||||||||||||||||||||||
//|| ENTRY                              ||
//||||||||||||||||||||||||||||||||||||||||
export const CREATE_ENTRY = 'createEntry';
export const DELETE_ENTRY = 'deleteEntry';
export const EDIT_ENTRY = 'editEntry';
export const FETCH_ENTRY_LIST = 'fetchEntryList';
export const FETCH_ENTRY_DETAILS = 'fetchEntryDetails';
export const SET_ENTRY_LIST = 'setEntryList';
export const SET_ENTRY_LIST_MAIN = 'setEntryListMain';
export const SET_ENTRY_DETAILS = 'setEntryDetails';
export const SET_ENTRY_LIST_STATS = 'setEntryListStats';
export const SET_ENTRY_LIST_LOCK_STATS = 'setEntryListLockStats';
export const SET_ENTRY_LOCK_TYPE_FILTER = 'setEntryLockTypeFilter';
export const SET_ENTRY_HEADERS = 'setEntryHeaders';
export const SET_ENTRY_VIEW_HEADERS = 'setEntryViewHeaders';
export const SET_ENTRY_FILTERS = 'setEntryFilters';
export const SET_ACTIVE_ENTRY = 'setActiveEntry';
export const RESEND_ENTRY_CODE = 'resendEntryCode';

//||||||||||||||||||||||||||||||||||||||||
//|| ACCESS CODES                       ||
//||||||||||||||||||||||||||||||||||||||||
export const EDIT_ACCESS_CODES = 'editAccessCode';
export const FETCH_ACCESS_CODES_LIST = 'fetchAccessCodeList';
export const FETCH_ACCESS_CODES_DETAILS = 'fetchAccessCodeDetails';
export const SET_ACCESS_CODES_LIST = 'setAccessCodeList';
export const SET_ACCESS_CODES_DETAILS = 'setAccessCodeDetails';
export const SET_ACCESS_CODES_HEADERS = 'setAccessCodeHeaders';
export const SET_ACCESS_CODES_FILTERS = 'setAccessCodeFilters';
export const SET_KEYPADS_HEADERS = 'setKeypadsHeaders';
export const FETCH_DAILY_CODES = 'fetchDailyCodes';
export const SET_DAILY_CODES = 'setDailyCodes';
export const SEND_SITE_CODES = 'sendSiteCodes';
export const SEND_PENDING_SITE_CODES = 'sendPendingSiteCodes';
export const SET_ACCESS_CODES_STATS = 'setAccessCodesStats';

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAYS                           ||
//||||||||||||||||||||||||||||||||||||||||
export const EDIT_GATEWAY = 'editGateway';
export const FETCH_GATEWAY_DETAILS = 'fetchGatewayDetails';
export const FETCH_GATEWAY_IMAGE = 'fetchGatewayImage';
export const SET_GATEWAY_LIST = 'setGatewayList';
export const SET_GATEWAY_IMAGE = 'setGatewayImage';
export const SET_GATEWAY_DETAILS = 'setGatewayDetails';

//||||||||||||||||||||||||||||||||||||||||
//|| GATEWAY KEEPER                     ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_GATEWAY_KEEPER_LIST = 'fetchGatewayKeeperList';
export const FETCH_GATEWAY_KEEPER_DEVICE_LIST = 'fetchGatewayKeeperDeviceList';
export const FETCH_GATEWAY_KEEPER_DETAILS = 'fetchGatewayKeeperDetails';
export const FETCH_GATEWAY_KEEPER_DEVICE_DETAILS = 'fetchGatewayKeeperDeviceDetails';
export const FETCH_GATEWAY_KEEPER_DEVICE_HISTORY = 'fetchGatewayKeeperUnitHistory';
export const SET_GATEWAY_KEEPER_LIST = 'setGatewayKeeperList';
export const SET_GATEWAY_KEEPER_LIST_STATS = 'setGatewayKeeperListStats';
export const SET_GATEWAY_KEEPER_DEVICE_STATS = 'setGatewayKeeperDeviceStats';
export const SET_GATEWAY_KEEPER_DEVICE_LIST = 'setGatewayKeeperDeviceList';
export const SET_GATEWAY_KEEPER_DETAILS = 'setGatewayKeeperDetails';
export const SET_GATEWAY_KEEPER_DEVICE_DETAILS = 'setGatewayKeeperDeviceDetails';
export const SET_GATEWAY_KEEPER_DEVICE_HISTORY = 'setGatewayKeeperUnitHistory';
export const SET_GATEWAY_KEEPER_LOCK_DETAIL_HEADERS = 'setGatewayKeeperLockDetailHeaders';
export const SET_GATEWAY_KEEPER_FILTERS = 'setGatewayKeeperFilters';
export const SET_GATEWAY_KEEPER_DEVICE_FILTERS = 'setGatewayKeeperDeviceFilters';
export const SET_GATEWAY_KEEPER_STATS_TOGGLE = 'setGatewayKeeperStatsToggle';
export const FETCH_GATEWAY_KEEPER_GATEWAY_HISTORY = 'fetchGatewayKeeperGatewayHistory';
export const SET_GATEWAY_KEEPER_GATEWAY_HISTORY = 'setGatewayKeeperGatewayHistory';
export const REBOOT_SITE_GATEWAYS = 'rebootSiteGateways';
export const REBOOT_GATEWAY = 'rebootGateway';
export const DELETE_SITE_GATEWAY = 'deleteSiteGateway';
export const ENTER_DIAGNOSTIC_MODE = 'enterDiagnosticMode';
export const FETCH_HARDWARE_TYPES = 'fetchHardwareTypes';
export const SET_HARDWARE_TYPES = 'setHardwareTypes';
export const FETCH_SITE_FIRMWARE_SETTINGS = 'fetchSiteFirmwareSettings';
export const SET_SITE_FIRMWARE_SETTINGS = 'setSiteFirmwareSettings';
export const UPDATE_SITE_FIRMWARE_SETTINGS = 'updateSiteFirmwareSettings';
export const TRIGGER_SITE_FIRMWARE_UPDATE = 'triggerSiteFirmwareUpdate';
export const TRIGGER_INDIVIDUAL_FIRMWARE_UPDATE = 'triggerIndividualFirmwareUpdate';
export const FETCH_FIRMWARE_SITE_STATUSES = 'fetchFirmwareSiteStatuses';
export const SET_FIRMWARE_SITE_STATUSES = 'setFirmwareSiteStatuses';

//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY                           ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_ACTIVITY_LIST = 'fetchActivityList';
export const FETCH_ACTIVITY_CSV_LIST = 'fetchActivityCSVList';
export const SET_ACTIVITY_LIST = 'setActivityList';
export const SET_USER_ACTIVITY_LIST = 'setUserActivityList';
export const SET_UNIT_ACTIVITY_LIST = 'setUnitActivityList';
export const SET_ENTRY_ACTIVITY_LIST = 'setEntryActivityList';
export const SET_ACTIVITY_CSV_LIST = 'setActivityCSVList';
export const SET_ACTIVITY_HEADERS = 'setActivityHeaders';
export const SET_ACTIVITY_FILTERS = 'setActivityFilters';
export const SET_SUPPORT_ACTIVITY_FILTERS = 'setSupportActivityFilters';
export const SET_MAP_ACTIVITY_FILTERS = 'setMapActivityFilters';
export const SET_MOTION_ACTIVITY_FILTERS = 'setMotionActivityFilters';
export const SET_ACTIVITY_DASHBOARD_FILTERS = 'setActivityDashboardFilters';
export const SET_ACTIVITY_PAGE_COUNT = 'setActivityPageCount';
export const SET_ACTIVITY_CURRENT_INDEX = 'setActivityCurrentIndex';
export const SET_RECENT_USERS = 'setRecentUsers';
export const SET_RECENT_UNITS = 'setRecentUnits';
export const SET_ACTIVITY_DATES = 'setActivityDates';
export const FETCH_TENANT_ACTIVITY = 'fetchTenantActivity';
export const SET_TENANT_ACTIVITY = 'setTenantActivity';
export const FETCH_USER_ACTIVITY_FILTERS = 'fetchUserActivityFilters';
export const SET_USER_ACTIVITY_FILTERS = 'setUserActivityFilters';
export const SET_USER_ACTIVITY_FILTER = 'setUserActivityFilter';
export const CREATE_USER_ACTIVITY_FILTER = 'createUserActivityFilter';
export const UPDATE_USER_ACTIVITY_FILTER = 'updateUserActivityFilter';
export const DELETE_USER_ACTIVITY_FILTER = 'deleteUserActivityFilter';
export const SET_ACTIVITY_VIEW = 'setActivityView';
export const SET_ACTIVITY_DEFAULT_VIEW = 'setActivityDefaultView';

//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY REDIRECTS                 ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_ACTIVITY_REDIRECTS = 'fetchActivityRedirects';
export const SET_ACTIVITY_REDIRECTS = 'setActivityRedirects';
export const REMOVE_ACTIVITY_REDIRECT = 'removeActivityRedirect';
export const ADD_ACTIVITY_REDIRECT = 'addActivityRedirect';
export const GET_SITE_EVENT_PREFERENCES = 'getSiteEventPreferences';
export const SET_SITE_EVENT_PREFERENCES = 'setSiteEventPreferences';
export const EDIT_SITE_EVENT_PREFERENCES = 'editSiteEventPreferences';
export const SET_ACTIVITY_REDIRECT_HEADERS = 'setActivityRedirectHeaders';

//||||||||||||||||||||||||||||||||||||||||
//|| ZONES                              ||
//||||||||||||||||||||||||||||||||||||||||
export const SAVE_ZONE = 'saveZone';
export const DELETE_ZONE = 'deleteZone';
export const FETCH_ZONE_LIST = 'fetchZoneList';
export const FETCH_ZONE_DETAILS = 'fetchZoneDetails';
export const SET_ZONE_LIST = 'setZoneList';
export const SET_ZONE_KEYPAD_LIST = 'setZoneKeypadList';
export const SET_ZONE_DETAILS = 'setZoneDetails';
export const SET_ZONE_HEADERS = 'setZoneHeaders';
export const SET_ZONE_UNIT_HEADERS = 'setZoneUnitHeaders';
export const SET_ZONE_ENTRY_HEADERS = 'setZoneEntryHeaders';
export const RESEND_ZONE_CODE = 'resendZoneCode';

//||||||||||||||||||||||||||||||||||||||||
//|| MAPS                               ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_SITE_MAP = 'fetchSiteMap';
export const SET_SITE_MAP = 'setSiteMap';
export const UPLOAD_SITE_MAP = 'uploadSiteMap';
export const EDIT_SITE_MAP = 'editSiteMap';
export const DELETE_SITE_MAP = 'deleteSiteMap';
export const SET_MAP = 'setMap';
export const EDIT_SITE_MAP_DATA = 'editSiteMapData';
export const CREATE_SHAPE_SITE_MAP = 'createShapeSiteMap';
export const UPLOAD_SITE_MAP_SVG = 'uploadSiteMapSvg';
export const DOWNLOAD_SITE_MAP_SVG = 'downloadSiteMapSvg';

//||||||||||||||||||||||||||||||||||||||||
//|| REPORTS                            ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_REPORT_LIST = 'fetchReportList';
export const FETCH_REPORT_DETAILS = 'fetchReportDetails';
export const FETCH_FACILITIES = 'fetchFacilities';
export const FETCH_REPORT_GATEWAY_DATA = 'fetchReportGatewayData';
export const FETCH_REPORT_LOCK_DATA = 'fetchReportLockData';
export const SET_REPORT_LIST = 'setReportList';
export const SET_REPORT_DETAILS = 'setReportDetails';
export const SET_REPORT_GATEWAY_DATA = 'setReportGatewayData';
export const SET_REPORT_LOCK_DATA = 'setReportLockData';
export const SET_REPORT_SITE_HEADERS = 'setReportSiteHeaders';
export const SET_REPORT_SITE_ADVANCED_HEADERS = 'setReportSiteAdvancedHeaders';
export const SET_REPORT_LOCK_HEADERS = 'setReportLockHeaders';
export const SET_REPORT_LOCK_DETAIL_HEADERS = 'setReportLockDetailHeaders';
export const SET_REPORT_GATEWAY_HEADERS = 'setReportGatewayHeaders';
export const SET_REPORT_VERSION_HEADERS = 'setReportVersionHeaders';
export const SET_REPORT_FILTERS = 'setReportFilters';
export const SET_SITE_LOCK_TOTALS = 'setSiteLockTotals';
export const SET_SITE_GATEWAY_TOTALS = 'setSiteGatewayTotals';
export const SET_SITE_UNIT_FILTER = 'setSiteUnitFilter';
export const SET_SITE_GATEWAY_FILTER = 'setSiteGatewayFilter';
export const FETCH_CROSS_SITE_SEARCH_RESULTS = 'fetchCrossSiteSeachResults';
export const SET_CROSS_SITE_SEARCH_RESULTS = 'setCrossSiteSearchResults';
export const FETCH_SUSPICIOUS_REPORT = 'fetchSuspiciousReport';
export const SET_SUSPICIOUS_REPORT = 'setSuspiciousReport';
export const FETCH_ONSITE_TIME_REPORT = 'fetchOnSiteTimeReport';
export const SET_ONSITE_TIME_REPORT = 'setOnSiteTimeReport';
export const FETCH_SUSPICIOUS_REPORT_ALL = 'fetchSuspiciousReportAll';
export const SET_SUSPICIOUS_REPORT_ALL = 'setSuspiciousReportAll';
export const FETCH_SUSPICIOUS_REPORT_TOP = 'fetchSuspiciousReportTop';
export const SET_SUSPICIOUS_REPORT_TOP = 'setSuspiciousReportTop';
export const FETCH_SUSPICIOUS_REPORT_USER = 'fetchSuspiciousReportUser';
export const SET_SUSPICIOUS_REPORT_USER = 'setSuspiciousReportUser';
export const EXPORT_SUSPICIOUS_REPORT = 'exportSuspiciousReport';
export const EXPORT_ONSITE_TIME_REPORT = 'exportOnSiteTimeReport';
export const FETCH_SUSPICIOUS_MANAGER_REPORT = 'fetchSuspiciousManagerReport';
export const SET_SUSPICIOUS_MANAGER_REPORT = 'setSuspiciousManagerReport';
export const FETCH_SUSPICIOUS_MANAGER_REPORT_TOP = 'fetchSuspiciousManagerReportTop';
export const SET_SUSPICIOUS_MANAGER_REPORT_TOP = 'setSuspiciousManagerReportTop';
export const FETCH_SUSPICIOUS_MANAGER_REPORT_USER = 'fetchSuspiciousManagerReportUser';
export const SET_SUSPICIOUS_MANAGER_REPORT_USER = 'setSuspiciousManagerReportUser';
export const EXPORT_SUSPICIOUS_MANAGER_REPORT = 'exportSuspiciousManagerReport';
export const EXPORT_SUSPICIOUS_REPORT_TOP = 'exportSuspiciousReportTop';
export const EXPORT_SUSPICIOUS_MANAGER_REPORT_TOP = 'exportSuspiciousManagerReportTop';

//||||||||||||||||||||||||||||||||||||||||
//|| SETTINGS                           ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_SETTINGS = 'fetchSettings';
export const FETCH_PMS_DETAILS = 'fetchPmsDetails';
export const EDIT_SETTINGS = 'editSettings';
export const SET_SETTINGS = 'setSettings';
export const SET_PMS_DETAILS = 'setPmsDetails';
export const SAVE_SITE_HOURS = 'saveSiteHours';
export const SAVE_SITE_DAILY_HOURS = 'saveSiteDailyHours';
export const SET_SETTINGS_ACTIVE = 'setActiveSetting';
export const UPLOAD_IMAGE = 'uploadImage';
export const UPLOAD_SITE_IMAGE = 'uploadSiteImage';
export const CREATE_SITE = 'createSite';
export const CREATE_BULK_SITE = 'createBulkSite';
export const EDIT_SITE = 'editSite';
export const EDIT_SITE_SILENTLY = 'editSiteSilently';
export const EDIT_SITE_LOCATION = 'editSiteLocation';
export const FETCH_FEATURE_FLAGS = 'fetchFeatureFlags';
export const SET_FEATURE_FLAGS = 'setFeatureFlags';
export const FETCH_FEATURE_FLAG_ASSIGNMENTS = 'fetchFeatureFlagAssignments';
export const SET_FEATURE_FLAG_ASSIGNMENTS = 'setFeatureFlagAssignments';
export const EDIT_FEATURE_FLAGS = 'editFeatureFlags';
export const CREATE_FEATURE_FLAG = 'createFeatureFlag';
export const SET_FEATURE_FLAG = 'setFeatureFlag';
export const EDIT_FEATURE_FLAG = 'editFeatureFlag';
export const DELETE_FEATURE_FLAG = 'deleteFeatureFlag';
export const FETCH_SITE_HOURS = 'fetchSiteHours';
export const SET_SITE_HOURS = 'setSiteHours';
export const SET_SITE_CREATE_HOURS = 'setCreateSiteHours';
export const CREATE_SCHEDULE = 'createSchedule';
export const EDIT_SCHEDULE = 'editSchedule';
export const DELETE_SCHEDULE = 'deleteSchedule';
export const FETCH_SCHEDULE = 'fetchSchedule';
export const SET_SCHEDULE = 'setSchedule';
export const FETCH_SCHEDULE_USERS = 'fetchScheduleUsers';
export const SET_SCHEDULE_USERS = 'setScheduleUsers';
export const FETCH_ALL_SCHEDULES = 'fetchAllSchedules';
export const SET_ALL_SCHEDULES = 'setAllSchedules';
export const FETCH_HOLIDAY_HOURS = 'fetchHolidayHours';
export const SET_HOLIDAY_HOURS = 'setHolidayHours';
export const ADD_HOLIDAY = 'addHoliday';
export const DELETE_HOLIDAY = 'deleteHoliday';
export const UPDATE_USER_SCHEDULE = 'updateUserSchedule';
export const DELETE_USER_SCHEDULE = 'deleteUserSchedule';
export const SEND_SITE_HOURS = 'sendSiteHours';
export const FETCH_MESSAGES = 'fetchMessages';
export const SET_MESSAGES = 'setMessages';
export const FETCH_SITE_MESSAGES = 'fetchSiteMessages';
export const FETCH_SITE_SMS_MESSAGES = 'fetchSiteSMSMessages';
export const FETCH_SITE_EMAIL_MESSAGES = 'fetchSiteEmailMessages';
export const FETCH_SITE_PUSH_MESSAGES = 'fetchSitePushMessages';
export const FETCH_SITE_NOTIFICATION_MESSAGES = 'fetchSiteNotificationMessages';
export const SET_SITE_MESSAGES = 'setSiteMessages';
export const SET_SITE_VARIABLES = 'setSiteVariables';
export const FETCH_NOTIFICATION_MESSAGES = 'fetchNotificationMessages';
export const SET_NOTIFICATION_MESSAGES = 'setNotificationMessages';
export const SET_SITE_SPECIFIC_SMS_MESSAGES = 'setSiteSpecificSMSMessages';
export const SET_SITE_SPECIFIC_EMAIL_MESSAGES = 'setSiteSpecificEmailMessages';
export const SET_SITE_SPECIFIC_PUSH_MESSAGES = 'setSiteSpecificPushMessages';
export const SET_SITE_SPECIFIC_NOTIFICATION_MESSAGES = 'setSiteSpecificNotificationMessages';
export const FETCH_LANGUAGES = 'fetchLanguages';
export const SET_LANGUAGES = 'setLanguages';
export const EDIT_MESSAGES = 'editMessages';
export const EDIT_SITE_MESSAGES = 'editSiteMessages';
export const EDIT_SITE_SMS_MESSAGES = 'editSiteSMSMessages';
export const EDIT_SITE_EMAIL_MESSAGES = 'editSiteEmailMessages';
export const EDIT_SITE_NOTIFICATION_MESSAGES = 'editSiteNotificationMessages';
export const EDIT_SITE_PUSH_MESSAGES = 'editSitePushMessages';
export const EDIT_NOTIFICATION_MESSAGES = 'editNotificationMessages';
export const GET_SITE_MESSAGE_VARIABLES = 'getSiteMessageVariables';
export const SEARCH_FOB = 'searchFob';
export const SET_FOB_DETAILS = 'setFobDetails';
export const FETCH_TRAINING_LIST = 'fetchTrainingList';
export const SET_TRAINING_LIST = 'setTrainingList';
export const EMAIL_DAILY_ACTIVITY = 'emailDailyActivity';
export const EMAIL_SITES_LIST = 'emailSitesList';
export const FETCH_LOGIN_TYPES = 'fetchLoginTypes';
export const SET_LOGIN_TYPES = 'setLoginTypes';
export const FETCH_ENTRY_MESSAGES = 'fetchEntryMessages';
export const SET_ENTRY_MESSAGES = 'setEntryMessages';
export const CREATE_ENTRY_MESSAGE = 'createEntryMessage';
export const EDIT_ENTRY_MESSAGE = 'editEntryMessage';
export const DELETE_ENTRY_MESSAGE = 'deleteEntryMessage';
export const REQUEST_AUDIT = 'requestAudit';
export const SET_AUDIT = 'setAudit';
export const REQUEST_HISTORICAL_AUDITS = 'requestHistoricalAudits';
export const SET_HISTORICAL_AUDITS = 'setHistoricalAudits';
export const REQUEST_INDIVIDUAL_AUDIT = 'requestIndividualAudit';
export const SET_INDIVIDUAL_AUDIT = 'setIndividualAudit';
export const FETCH_DIGITAL_AUDITS_LIST = 'fetchDigitalAuditsList';
export const SET_DIGITAL_AUDITS_LIST = 'setDigitalAuditsList';
export const FETCH_DIGITAL_AUDITS_FACILITIES = 'fetchDigitalAuditsFacilities';
export const SET_DIGITAL_AUDITS_FACILITIES = 'setDigitalAuditsFacilities';
export const SET_DIGITAL_AUDITS_FACILITIES_STATS = 'setDigitalAuditsFacilitiesStats';
export const SET_DIGITAL_AUDITS_FACILITIES_COMPANIES = 'setDigitalAuditsFacilitiesCompanies';
export const FETCH_DIGITAL_AUDIT_SUMMARY = 'fetchDigitalAuditSummary';
export const SET_DIGITAL_AUDIT_SUMMARY = 'setDigitalAuditSummary';
export const FETCH_DIGITAL_AUDIT_HISTORY = 'fetchDigitalAuditHistory';
export const SET_DIGITAL_AUDIT_HISTORY = 'setDigitalAuditHistory';
export const FETCH_DIGITAL_AUDIT_DETAIL = 'fetchDigitalAuditDetail';
export const SET_DIGITAL_AUDIT_DETAIL = 'setDigitalAuditDetail';
export const REQUEST_NEW_DIGITAL_AUDIT = 'requestNewDigitalAudit';
export const REQUEST_BULK_DIGITAL_AUDIT = 'requestBulkDigitalAudit';
export const REQUEST_DIGITAL_AUDIT_SUMMARY_EXPORT = 'requestDigitalAuditSummaryExport';
export const UPDATE_AUDIT_LIFECYCLE = 'updateAuditLifecycle';
export const FETCH_HUBSPOT_TICKETS = 'fetchHubspotTickets';
export const SET_HUBSPOT_TICKETS = 'setHubspotTickets';
export const FETCH_HUBSPOT_TICKET = 'fetchHubspotTicket';
export const SET_HUBSPOT_TICKET = 'setHubspotTicket';
export const FETCH_HUBSPOT_COMPANIES = 'fetchHubspotCompanies';
export const SUBMIT_HUBSPOT_FEEDBACK = 'submitHubspotFeedback';
export const SET_HUBSPOT_COMPANIES = 'setHubspotCompanies';
export const REQUEST_ECOBEE_PIN = 'requestEcobeePin';
export const FETCH_ECOBEE_PIN = 'fetchEcobeePin';
export const SET_ECOBEE_PIN = 'setEcobeePin';
export const FETCH_THERMOSTAT = 'fetchThermostat';
export const SET_THERMOSTAT = 'setThermostat';
export const REQUEST_ECOBEE_TOKEN = 'requestEcobeeToken';
export const UPDATE_THERMOSTAT_TEMP = 'updateThermostatTemp';
export const FETCH_SITE_STATS = 'fetchSiteStats';
export const SET_SITE_STATS = 'setSiteStats';
export const FETCH_HUBSPOT_FIELDS = 'fetchHubspotFields';
export const SET_HUBSPOT_FIELDS = 'setHubspotFields';
export const SUBMIT_CUSTOM_HUBSPOT_FORM = 'submitCustomHubspotForm';
export const ADD_COMPANY_LANGUAGE = 'addCompanyLanguage';
export const SET_TRAINING_HEADERS = 'setTrainingHeaders';
export const SET_SITE_CREATE_DATA = 'setSiteCreateData';
export const SET_COMPANY_CREATE_DATA = 'setCompanyCreateData';
export const FETCH_SCREEN_IMAGES = 'fetchScreenImages';
export const UPLOAD_SCREEN_IMAGE = 'uploadScreenImage';
export const SET_SCREEN_IMAGES = 'setScreenImages';
export const SET_FEATURE_FLAG_HEADERS = 'fetchFeatureFlagHeaders';
export const BLACKLIST_ALL_FOBS = 'blacklistAllFobs';

//||||||||||||||||||||||||||||||||||||||||
//|| NOTIFICATIONS                      ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_USER_NOTIFICATIONS = 'fetchUserNotifications';
export const FETCH_USER_NOTIFICATIONS_COMPANY = 'fetchUserNotificationsCompany';
export const UPDATE_USER_NOTIFICATIONS = 'updateUserNotifications';
export const REMOVE_USER_NOTIFICATIONS = 'removeUserNotifications';
export const REMOVE_USER_NOTIFICATIONS_ALL = 'removeUserNotificationsAll';
export const REGISTER_NOTIFICATIONS_DEVICE = 'registerNotificationsDevice';
export const TEST_NOTIFICATIONS_DEVICE = 'testNotificationsDevice';
export const SET_USER_NOTIFICATIONS_SETTINGS = 'setUserNotificationsSetting';
export const SET_USER_NOTIFICATIONS = 'setUserNotifications';
export const SET_USER_NOTIFICATIONS_COMPANY = 'setUserNotificationsCompany';
export const SET_USER_NOTIFICATIONS_ENTERPRISE = 'setUserNotificationsEnterprise';
export const SET_USER_NOTIFICATIONS_VIEWED = 'setUserNotificationsViewed';
export const SET_USER_NOTIFICATIONS_NOT_VIEWED = 'setUserNotificationsNotViewed';
export const SET_NOTIFICATIONS_HEADERS = 'setNotificationsHeaders';
export const CREATE_INTERNAL_NOTIFICATION = 'createInternalNotification';
export const FETCH_USER_NOTIFICATIONS_SUITE = 'fetchUserNotificationsSuite';
export const SET_USER_NOTIFICATIONS_SUITE = 'setUserNotificationsSuite';
export const SUPPORT_FETCH_USER_NOTIFICATIONS_SUITE = 'supportFetchUserNotificationsSuite';
export const SUPPORT_SET_USER_NOTIFICATIONS_SUITE = 'supportSetUserNotificationsSuite';
export const UPDATE_USER_NOTIFICATIONS_SUITE = 'updateUserNotificationsSuite';
export const UPDATE_USER_NOTIFICATIONS_SUITE_SILENTLY = 'updateUserNotificationsSuiteSilently';
export const SUPPORT_UPDATE_USER_NOTIFICATIONS_SUITE = 'supportUpdateUserNotificationsSuite';
export const SUPPORT_UPDATE_USER_NOTIFICATIONS_SUITE_SILENTLY = 'supportUpdateUserNotificationsSuiteSilently';
export const FETCH_USER_NOTIFICATIONS_PUSH = 'fetchUserNotificationsPush';
export const SET_USER_NOTIFICATIONS_PUSH = 'setUserNotificationsPush';
export const FETCH_USER_NOTIFICATIONS_EMAIL = 'fetchUserNotificationsEmail';
export const SET_USER_NOTIFICATIONS_EMAIL = 'setUserNotificationsEmail';
export const FETCH_USER_NOTIFICATIONS_SMS = 'fetchUserNotificationsSms';
export const SET_USER_NOTIFICATIONS_SMS = 'setUserNotificationsSms';
export const FETCH_NOTIFICATIONS_TYPES = 'fetchNotificationsTypes';
export const SET_NOTIFICATIONS_TYPES = 'setNotificationsTypes';
export const SET_MANAGER_NOTIFICATION_FILTERS = 'setManagerNotificationFilters';
export const CREATE_ONSITE_NOTIFICATION = 'createOnsiteNotification';

//||||||||||||||||||||||||||||||||||||||||
//|| ROLES                              ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_ROLE_LIST = 'fetchRoleList';
export const FETCH_ROLE_PERMISSIONS_LIST = 'fetchRolePermissionsList';
export const CREATE_ROLE = 'createRole';
export const EDIT_ROLE = 'editRole';
export const DELETE_ROLE = 'deleteRole';
export const SET_ROLE_LIST = 'setRoleList';
export const SET_ROLE_DETAILS = 'setRoleDetails';
export const SET_ROLE_PERMISSIONS_LIST = 'setRolePermissionsList';
export const SET_ROLE_ACTIVE = 'setRoleActive';
export const SET_PERMISSION_GROUPS = 'setPermissionGroups';

//||||||||||||||||||||||||||||||||||||||||
//|| HELP                               ||
//||||||||||||||||||||||||||||||||||||||||
export const SET_HELP_ACTIVE = 'setActiveHelp';
export const FETCH_API_DOCUMENTATION = 'fetchApiDocumentation';
export const SET_API_DOCUMENTATION = 'setApiDocumentation';
export const CREATE_API_DOCUMENTATION = 'createApiDocumentation';
export const EDIT_API_DOCUMENTATION = 'editApiDocumentation';
export const DELETE_API_DOCUMENTATION = 'deleteApiDocumentation';

//||||||||||||||||||||||||||||||||||||||||
//|| COMPANY                            ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_COMPANY_LIST = 'fetchCompanyList';
export const SET_COMPANY_LIST = 'setCompanyList';
export const CREATE_COMPANY = 'createCompany';
export const EDIT_COMPANY = 'editCompany';
export const EDIT_COMPANY_APP_COLOR = 'editCompanyAppColor';
export const SET_COMPANY_SETTINGS = 'setCompanySettings';
export const FETCH_COMPANY_STATS = 'fetchCompanyStats';
export const SET_COMPANY_STATS = 'setCompanyStats';

//||||||||||||||||||||||||||||||||||||||||
//|| SUPPORT                            ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_SUPPORT_LOGS = 'fetchSupportLogs';
export const FETCH_SITE_SYNC_STATUS = 'fetchSiteSyncStatus';
export const FETCH_SITE_SYNC_STATUS_LOGS = 'fetchSiteSyncStatusLogs'
export const FETCH_SITE_SYNC_LOGS = 'fetchSiteSyncLogs';
export const FETCH_TROUBLESHOOT_LIST = 'fetchTroubleshootList';
export const SYNC_SITE_UNITS = 'syncSiteUnits';
export const FETCH_SUPPORT_ACTIVITY = 'fetchSupportActivity';
export const SET_SITE_SYNC_STATUS = 'setSiteSyncStatus';
export const SET_SITE_SYNC_STATUS_LOGS = 'setSiteSyncStatusLogs';
export const SET_SITE_SYNC_LOGS = 'setSiteSyncLogs';
export const SET_SITE_SYNC_UNITS = 'setSiteSyncUnits';
export const SET_SUPPORT_ACTIVITY = 'setSupportActivity';
export const SET_SUPPORT_ACTIVITY_HEADERS = 'setSupportActivityHeaders';
export const SET_SITE_SYNC_HEADERS = 'setSiteSyncHeaders';
export const SET_SITE_SYNC_LOGS_HEADERS = "setSiteSyncLogsHeaders"
export const SET_TROUBLESHOOT_LIST = 'setTroubleshootList';
export const SET_SUPPORT_LOGS = 'setSupportLogs';
export const SET_SUPPORT_ACTIVE = 'setActiveSupport';
export const SET_SUPPORT_LOGS_HEADERS = 'setSupportLogsHeaders';
export const SEND_TRAINING_ACKNOWLEDGEMENT = 'sendTrainingAcknowledgement';
export const SEND_SUPPORT_TICKET = 'sendSupportTicket';
export const CREATE_SITE_NOTE = 'createSiteNote';
export const EDIT_SITE_NOTE = 'editSiteNote';
export const DELETE_SITE_NOTE = 'deleteSiteNote';
export const FETCH_SITE_NOTES = 'fetchSiteNotes';
export const SET_SITE_NOTES = 'setSiteNotes';
export const FETCH_SITE_NOTES_ALL = 'fetchSiteNotesAll';
export const SET_SITE_NOTES_ALL = 'setSiteNotesAll';
export const FETCH_SITE_NOTE = 'fetchSiteNote';
export const SET_SITE_NOTE = 'setSiteNote';
export const FETCH_SITE_NOTE_COMMENTS = 'fetchSiteNoteComments';
export const SET_SITE_NOTE_COMMENTS = 'setSiteNoteComments';
export const CREATE_SITE_NOTE_COMMENT = 'createSiteNoteComment';
export const EDIT_SITE_NOTE_COMMENT = 'editSiteNoteComment';
export const DELETE_SITE_NOTE_COMMENT = 'deleteSiteNoteComment';
export const SET_SUPPORT_FACILITY_HEADERS = 'setSupportFacilityHeaders';
export const SET_SUPPORT_FACILITY_HEADERS_ALL = 'setSupportFacilityHeadersAll';
export const SET_SUPPORT_FACILITY_COMMENTS_HEADERS = 'setSupportFacilityCommentsHeaders';

//||||||||||||||||||||||||||||||||||||||||
//|| SECURITY                           ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_SECURITY_LIST_STATS = 'fetchSecurityListStats';
export const FETCH_SECURITY_LIST = 'fetchSecurityList';
export const FETCH_SECURITY_DETAILS = 'fetchSecurityDetails';
export const EDIT_SECURITY = 'editSecurity';
export const SET_SECURITY_LIST = 'setSecurityList';
export const SET_SECURITY_LIST_STATS = 'setSecurityListStats';
export const SET_SECURITY = 'setSecurity';
export const SET_SECURITY_HEADERS = 'setSecurityHeaders';
export const SET_SECURITY_FILTERS = 'setSecurityFilters';
export const SET_SECURITY_ACTIVE = 'setActiveSecurity';

//||||||||||||||||||||||||||||||||||||||||
//|| LAYOUT                             ||
//||||||||||||||||||||||||||||||||||||||||
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const WINDOW_WIDTH = 'window-width';
export const WINDOW_HEIGHT = 'window-height';
export const SET_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//||||||||||||||||||||||||||||||||||||||||
//|| TO CLEAN UP                        ||
//||||||||||||||||||||||||||||||||||||||||
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';
export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';
export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';
export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

//||||||||||||||||||||||||||||||||||||||||
//|| RELEASES                           ||
//||||||||||||||||||||||||||||||||||||||||

export const CREATE_RELEASE = 'createRelease';
export const FETCH_RELEASES = 'fetchReleases';
export const SET_RELEASES = 'setReleases';
export const UPDATE_RELEASE = 'updateRelease';
export const DELETE_RELEASE = 'deleteRelease';
export const SEARCH_RELEASES = 'searchReleases';
export const SET_SEARCHED_RELEASES = 'setSearchedReleases';
export const SET_RELEASE_FILTERS = 'setReleaseFilters';


//||||||||||||||||||||||||||||||||||||||||
//|| CAMERAS                            ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_CAMERAS = 'fetchCameras';
export const SET_CAMERAS = 'setCameras';
export const FETCH_CAMERA_TOKEN = 'fetchCameraToken';
export const SET_CAMERA_TOKEN = 'setCameraToken';

//||||||||||||||||||||||||||||||||||||||||
//|| WIFI                               ||
//||||||||||||||||||||||||||||||||||||||||
export const FETCH_WIFI = 'fetchWifi';
export const SET_WIFI = 'setWifi';
export const CREATE_WIFI = 'createWifi';
export const EDIT_WIFI = 'editWifi';
export const DELETE_WIFI = 'deleteWifi';

//||||||||||||||||||||||||||||||||||||||||
//|| HELP WIZARD                        ||
//||||||||||||||||||||||||||||||||||||||||

export const SET_USER_WIZARD_RESPONSES = 'setUserWizardResponses';
export const WIZARD_CONTACT_SUPPORT = 'wizardContactSupport';
export const GET_USER_WIZARD_RESPONSES = 'getUserWizardResponses';
