import * as CONSTANTS from 'constants';

const INIT_STATE = {
    siteMaps: null,
    currentMap: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_SITE_MAP: {
            return {
                ...state,
                siteMaps: action.payload
            };
        }
        case CONSTANTS.SET_MAP: {
            return {
                ...state,
                currentMap: action.payload
            };
        }
        default:
            return state;
    }
};
