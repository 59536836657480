import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import ToolTips from 'components/ToolTips';
class CellIcon extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            Icon,
            theme,
            height,
            width,
            borderRadius,
            backgroundColor,
            Cellwidth,
            fill,
            color,
            stroke,
            update,
            checker,
            icon,
            action,
            data,
            tooltip,
            tooltipText,
            tooltipId
        } = this.props;
        this.styles = styles(theme, width, height, borderRadius, backgroundColor);
        return (
            <td width={Cellwidth}>
                <div
                    id={tooltip && tooltipId}
                    onMouseDown={(e) => action && action(e, data)}
                    className={css(this.styles.wrapper, checker && this.styles.hover)}
                    onClick={() => checker && update()}>
                    {icon ? (
                        <i
                            className={`material-icons ${css(this.styles.center)}`}
                            style={{
                                width: width,
                                height: height,
                                fontSize: height - 8,
                                color: fill
                            }}>
                            {icon}
                        </i>
                    ) : (
                        Icon && (
                            <Icon
                                fill={fill || theme.color.buttonFontColor}
                                color={color || theme.color.themeColor}
                                width={width / 2}
                                height={height / 2}
                                stroke={stroke}
                            />
                        )
                    )}
                </div>
                {tooltip && (
                    <ToolTips
                        target={tooltipId}
                        message={tooltipText}
                        modifiers={{ 
                            preventOverflow: { boundariesElement: 'viewport' },
                            hide: { enabled: false },
                        }}
                    />
                )}
            </td>
        );
    }
}

CellIcon.defaultProps = {
    Icon: null,
    width: 40,
    height: 40,
    borderRadius: '50%',
    fill: null,
    color: null,
    backgroundColor: null,
    stroke: null,
    update: null,
    checker: null
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(CellIcon)));

const styles = (theme, width, height, borderRadius, backgroundColor, icon) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: backgroundColor ? backgroundColor : theme && theme.color ? theme.color.themeColor : '#0070CE',
            borderRadius,
            width,
            height,
            display: 'block',
            margin: '5px auto',
            padding: icon ? '1.5px 0 0 .5px' : `${height / 4}px ${width / 4}px`
        },
        hover: {
            ':hover': {
                cursor: 'pointer'
            }
        },
        center: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0
        }
    });
