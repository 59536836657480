import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { FobIcon } from 'utilities/icons';
import { fetchSiteFobs } from 'actions/Units';
import { fadeIn } from 'react-animations';
import Spinner from 'components/Spinner';
import Header from 'components/Header/dashboard';
class DashboardSiteFobs extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { fetchSiteFobs, editWidgets, visible } = this.props;
        (editWidgets || visible) && fetchSiteFobs();
    }
    render() {
        let { intl, history, theme, width, authUser, site, editWidgets, siteFobs } = this.props;
        this.styles = styles(theme);
        const permissions = authUser.permissions;
        return (
            <>
                <Header
                    Icon={FobIcon}
                    iconSize={34}
                    title={'pages.fobs.Types'}
                    subtitle={siteFobs && siteFobs.length}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col
                        className={
                            permissions.includes('manage_access_codes') && site && site.has3K
                                ? css(this.styles.shortWrapper)
                                : permissions.includes('remote_open')
                                ? css(this.styles.wrapper)
                                : css(this.styles.tallWrapper)
                        }
                        style={{ padding: '0 0', overflowY: 'auto' }}
                        xs={12}>
                        {siteFobs ? (
                            <table className={css(this.styles.table)}>
                                {siteFobs && siteFobs.length > 0 ? (
                                    <tbody>
                                        {siteFobs.map((fob, i) => (
                                            <tr
                                                key={'FOBLIST' + fob.uuid + i}
                                                className={css(this.styles.row)}
                                                onClick={() => history.push(`/app/users/display/${fob.userUUID}`)}>
                                                <td>
                                                    <FobIcon color={theme.color.themeColor} />
                                                </td>
                                                <td
                                                    style={{ textTransform: 'capitalize' }}
                                                    className={
                                                        width > 400 ? css(this.styles.bold) : css(this.styles.text)
                                                    }>
                                                    {fob.name && fob.name}
                                                </td>
                                                {width > 400 && <td className={css(this.styles.text)}>{fob.mac}</td>}
                                                {width > 400 && !editWidgets && (
                                                    <td
                                                        style={{ textTransform: 'capitalize' }}
                                                        className={css(this.styles.text)}>
                                                        {fob.userName}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody
                                        style={{
                                            minHeight: 102,
                                            fontSize: 12,
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            animationName: fadeIn,
                                            animationDuration: '1.2s'
                                        }}>
                                        <tr>
                                            <td>
                                                {intl.formatMessage({
                                                    id: 'comp.table.cell.empty'
                                                })}
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        ) : (
                            <Spinner />
                        )}
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, units }) => {
    const { theme, width } = app;
    const { authUser, site } = auth;
    const { siteFobs } = units;
    return { theme, authUser, site, width, siteFobs };
};

export default withRouter(injectIntl(connect(mapStateToProps, { fetchSiteFobs })(DashboardSiteFobs)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            maxHeight: 182,
            height: 182
        },
        tallWrapper: {
            maxHeight: 290,
            height: 290
        },
        shortWrapper: {
            maxHeight: 109,
            height: 109
        },
        header: {
            color: theme.color.themeColor,
            margin: '0 0 15px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        table: {
            width: '100%',
            fontSize: 11
        },
        row: {
            borderBottom: '1px solid' + theme.color.borderColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        bold: {
            fontWeight: 600,
            marginLeft: 5
        },
        text: {
            textTransform: 'capitalize'
        }
    });
