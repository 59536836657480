import * as CONSTANTS from 'constants';

const INIT_STATE = {
    rolesList: null,
    roleDetails: null,
    permissionsList: null,
    activeRole: 0,
    permissionGroups: null,
    roleColors: {
        veryhigh: '#43A1CA',
        high: '#43CA82',
        medium: '#D2D200',
        low: '#DBAD51',
        basic: '#D06F6F'
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_ROLE_LIST: {
            return {
                ...state,
                rolesList: action.payload
            };
        }
        case CONSTANTS.SET_ROLE_DETAILS: {
            return {
                ...state,
                roleDetails: action.payload
            };
        }
        case CONSTANTS.SET_ROLE_PERMISSIONS_LIST: {
            return {
                ...state,
                permissionsList: action.payload
            };
        }
        case CONSTANTS.SET_ROLE_ACTIVE: {
            return {
                ...state,
                activeRole: action.payload
            };
        }
        case CONSTANTS.SET_PERMISSION_GROUPS: {
            return {
                ...state,
                permissionGroups: action.payload
            };
        }
        default:
            return state;
    }
};
