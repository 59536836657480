import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_SUPPORT from 'actions/Support';

const getSupportState = (state) => state.support;
const getTroubleshootHeaders = (state) => state.support.troubleshootHeaders;
const getNotesHeaders = (state) => state.support.supportNotesHeaders;
const getNotesHeadersAll = (state) => state.support.notesHeadersAll;
const getCommentsHeaders = (state) => state.support.supportCommentsHeaders;

function* requestSupportLogs({ payload }) {
    try {
        yield put(ACTIONS_SUPPORT.setSupportLogs(null));
        let logs = yield call(API.POST, payload.url, payload.data);
        if (!logs) {
            yield put(ACTIONS_SUPPORT.setSupportLogs([]));
        } else {
            let supportState = yield select(getSupportState);
            let activeHeader = supportState.supportLogsHeaders.find((item) => item.active);
            logs.data.logs = UTILS.sortList(!activeHeader.order, logs.data.logs, activeHeader.sortTitle);
            yield put(ACTIONS_SUPPORT.setSupportLogs(logs.data.logs));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setSupportLogs([]));
        console.warn(error);
    }
}

function* requestTroubleshootList({ payload }) {
    try {
        yield put(ACTIONS_SUPPORT.setTroubleshootList([]));
        let troubleshoot = yield call(API.POST, payload.url, payload.data);
        if (!troubleshoot) {
            yield put(ACTIONS_SUPPORT.setTroubleshootList([]));
        } else {
            let troubleshootHeaders = yield select(getTroubleshootHeaders);
            let activeHeader = troubleshootHeaders.find((item) => item.active);
            troubleshoot.data.errors = UTILS.sortList(
                !activeHeader.order,
                troubleshoot.data.errors,
                activeHeader.sortTitle
            );
            yield put(ACTIONS_SUPPORT.setTroubleshootList(troubleshoot.data.errors));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setTroubleshootList([]));
        console.warn(error);
    }
}

function* requestSiteSyncStatus({ payload }) {
    try {
        yield put(ACTIONS_SUPPORT.setSiteSyncStatus(null));
        let status = yield call(API.POST, payload.url, payload.data);
        if (!status) {
            yield put(ACTIONS_SUPPORT.setSiteSyncStatus([]));
        } else {
            status.data.UnitUsers.map((user) => (user.Name = `${user.FirstName} ${user.LastName}`));
            let supportState = yield select(getSupportState);
            let activeHeader = supportState.siteSyncHeaders.find((item) => item.active);
            status.data.UnitUsers = UTILS.sortList(!activeHeader.order, status.data.UnitUsers, activeHeader.sortTitle);
            yield put(ACTIONS_SUPPORT.setSiteSyncStatus(status.data.UnitUsers));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setSiteSyncStatus([]));
        console.warn(error);
    }
}
function* requestSiteSyncStatusLogs({ payload }) {
    
    try {
        yield put(ACTIONS_SUPPORT.setSiteSyncStatusLogs(null));
        let statusLogs = yield call(API.POST, payload.url, payload.data);
        if (!statusLogs) {
            yield put(ACTIONS_SUPPORT.setSiteSyncStatusLogs([]));
        } else {
            yield put(ACTIONS_SUPPORT.setSiteSyncStatusLogs(statusLogs.data));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setSiteSyncStatusLogs([]));
        console.warn(error);
    }
}

function* requestSiteSyncUnits({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.syncUnits', 'success'));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSupportActivity({ payload }) {
    try {
        yield put(ACTIONS_SUPPORT.setSupportActivity(null));
        let activity = yield call(API.POST, payload.url, payload.data);
        if (!activity) {
            yield put(ACTIONS_SUPPORT.setSupportActivity([]));
        } else {
            let supportState = yield select(getSupportState);
            let activeHeader = supportState.supportActivityHeaders.find((item) => item.active);
            activity.data.logs = UTILS.sortList(!activeHeader.order, activity.data.logs, activeHeader.sortTitle);
            yield put(ACTIONS_SUPPORT.setSupportActivity(activity.data.logs));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setSupportActivity([]));
        console.warn(error);
    }
}

function* requestSendTrainingAcknowledgement({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sentTraining', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSendSupportTicket({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.sentEmail`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sentTicket', 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateSiteNote({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteNote({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield put(ACTIONS_SUPPORT.fetchSiteNotes());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteSiteNote({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(ACTIONS_SUPPORT.fetchSiteNotes());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteNotes({ payload }) {
    try {
        let siteNotes = yield call(API.POST, payload.url, payload.data);
        if (!siteNotes || !siteNotes.data || !siteNotes.data.siteNotes) {
            yield put(ACTIONS_SUPPORT.setSiteNotes([]));
        } else {
            const notesHeaders = yield select(getNotesHeaders);
            const activeHeader = notesHeaders.find((item) => item.active);
            siteNotes.data.siteNotes = UTILS.sortList(!activeHeader.order, siteNotes.data.siteNotes, activeHeader.sortTitle);
            siteNotes.data.siteNotes = UTILS.sortList(true, siteNotes.data.siteNotes, 'priority');
            yield put(ACTIONS_SUPPORT.setSiteNotes(siteNotes.data.siteNotes));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setSiteNotes([]));
        console.warn(error);
    }
}

function* requestFetchSiteNotesAll({ payload }) {
    try {
        let siteNotes = yield call(API.POST, payload.url, payload.data);
        if (!siteNotes || !siteNotes.data || !siteNotes.data.siteNotes) {
            yield put(ACTIONS_SUPPORT.setSiteNotesAll([]));
        } else {
            const notesHeaders = yield select(getNotesHeadersAll);
            const activeHeader = notesHeaders.find((item) => item.active);
            siteNotes.data.siteNotes = UTILS.sortList(!activeHeader.order, siteNotes.data.siteNotes, activeHeader.sortTitle);
            siteNotes.data.siteNotes = UTILS.sortList(true, siteNotes.data.siteNotes, 'priority');
            yield put(ACTIONS_SUPPORT.setSiteNotesAll(siteNotes.data.siteNotes));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setSiteNotesAll([]));
        console.warn(error);
    }
}

function* requestFetchSiteNote({ payload }) {
    try {
        yield put(ACTIONS_SUPPORT.setSiteNote([]));
        let siteNotes = yield call(API.POST, payload.url, payload.data);
        if (!siteNotes || !siteNotes.data || !siteNotes.data.siteNotes) {
            yield put(ACTIONS_SUPPORT.setSiteNote([]));
        } else {
            let note = siteNotes.data.siteNotes.filter((note) => note.uuid == payload.uuid)[0]
                ? siteNotes.data.siteNotes.filter((note) => note.uuid == payload.uuid)[0]
                : null;
            yield put(ACTIONS_SUPPORT.setSiteNote(note));
        }
    } catch (error) {
        yield put(ACTIONS_SUPPORT.setSiteNote([]));
        console.warn(error);
    }
}

function* requestFetchSiteNoteComments({ payload }) {
    try {
        yield put(ACTIONS_SUPPORT.setSiteNoteComments(null));
        const comments = yield call(API.POST, payload.url, payload.data);
        if (!comments || !comments.data || !comments.data.comments) {
            yield put(ACTIONS_SUPPORT.setSiteNoteComments([]));
            return;
        } else {
            const commentsHeaders = yield select(getCommentsHeaders);
            const activeHeader = commentsHeaders.find((item) => item.active);
            comments.data.comments = UTILS.sortList(!activeHeader.order, comments.data.comments, activeHeader.sortTitle);
            yield put(ACTIONS_SUPPORT.setSiteNoteComments(comments.data.comments));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateSiteNoteComment({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield put(ACTIONS_SUPPORT.fetchSiteNoteComments(payload.data.noteUUID));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteNoteComment({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield put(ACTIONS_SUPPORT.fetchSiteNoteComments(payload.data.noteUUID));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteSiteNoteComment({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(ACTIONS_SUPPORT.fetchSiteNoteComments(payload.data.noteUUID));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectSupportLogs() {
    yield takeEvery(CONSTANTS.FETCH_SUPPORT_LOGS, requestSupportLogs);
}
export function* selectTroubleshootList() {
    yield takeEvery(CONSTANTS.FETCH_TROUBLESHOOT_LIST, requestTroubleshootList);
}
export function* selectSiteSyncStatus() {
    yield takeEvery(CONSTANTS.FETCH_SITE_SYNC_STATUS, requestSiteSyncStatus);
}
export function* selectSiteSyncStatusLogs() {
    yield takeEvery(CONSTANTS.FETCH_SITE_SYNC_STATUS_LOGS, requestSiteSyncStatusLogs);
}
export function* selectSiteSyncUnits() {
    yield takeEvery(CONSTANTS.SYNC_SITE_UNITS, requestSiteSyncUnits);
}
export function* selectSupportActivity() {
    yield takeEvery(CONSTANTS.FETCH_SUPPORT_ACTIVITY, requestSupportActivity);
}
export function* selectSendTrainingAcknowledgement() {
    yield takeEvery(CONSTANTS.SEND_TRAINING_ACKNOWLEDGEMENT, requestSendTrainingAcknowledgement);
}
export function* selectSendSupportTicket() {
    yield takeEvery(CONSTANTS.SEND_SUPPORT_TICKET, requestSendSupportTicket);
}
export function* selectCreateSiteNote() {
    yield takeEvery(CONSTANTS.CREATE_SITE_NOTE, requestCreateSiteNote);
}
export function* selectEditSiteNote() {
    yield takeEvery(CONSTANTS.EDIT_SITE_NOTE, requestEditSiteNote);
}
export function* selectDeleteSiteNote() {
    yield takeEvery(CONSTANTS.DELETE_SITE_NOTE, requestDeleteSiteNote);
}
export function* selectFetchSiteNotes() {
    yield takeEvery(CONSTANTS.FETCH_SITE_NOTES, requestFetchSiteNotes);
}
export function* selectFetchSiteNotesAll() {
    yield takeEvery(CONSTANTS.FETCH_SITE_NOTES_ALL, requestFetchSiteNotesAll);
}
export function* selectFetchSiteNote() {
    yield takeEvery(CONSTANTS.FETCH_SITE_NOTE, requestFetchSiteNote);
}

export function* selectFetchSiteNoteComments() {
    yield takeEvery(CONSTANTS.FETCH_SITE_NOTE_COMMENTS, requestFetchSiteNoteComments);
}

export function* selectCreateSiteNoteComment() {
    yield takeEvery(CONSTANTS.CREATE_SITE_NOTE_COMMENT, requestCreateSiteNoteComment);
}

export function* selectEditSiteNoteComment() {
    yield takeEvery(CONSTANTS.EDIT_SITE_NOTE_COMMENT, requestEditSiteNoteComment);
}

export function* selectDeleteSiteNoteComment() {
    yield takeEvery(CONSTANTS.DELETE_SITE_NOTE_COMMENT, requestDeleteSiteNoteComment);
}

export default function* rootSaga() {
    yield all([
        fork(selectSupportLogs),
        fork(selectSiteSyncStatus),
        fork(selectSiteSyncStatusLogs),
        fork(selectSiteSyncUnits),
        fork(selectSupportActivity),
        fork(selectSendTrainingAcknowledgement),
        fork(selectSendSupportTicket),
        fork(selectTroubleshootList),
        fork(selectCreateSiteNote),
        fork(selectEditSiteNote),
        fork(selectDeleteSiteNote),
        fork(selectFetchSiteNotes),
        fork(selectFetchSiteNotesAll),
        fork(selectFetchSiteNote),
        fork(selectFetchSiteNoteComments),
        fork(selectCreateSiteNoteComment),
        fork(selectEditSiteNoteComment),
        fork(selectDeleteSiteNoteComment)
    ]);
}
