import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import { filterList } from 'utilities';
import { filterListStart } from 'utilities';
import { subscribe, unsubscribe } from "utilities/events";
import TextInput from 'components/Inputs/text';
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            show: props.disableSearchHide || props.width > 800
        };
    }
    componentDidMount() {
        subscribe('stuffWasFiltered', () => this.setState({searchValue: ''}));
    }
    componentWillUnmount() {
        unsubscribe('stuffWasFiltered');
    }
    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            let { show, width } = this.props;
            this.setState({ show: show ? show : width > 800 });
        }
    }
    handleSearch = (value, temp) => {
        let { data, searchType, startsWith } = this.props;
        const typeSet = new Set(searchType);
        data = startsWith
            ? filterListStart(temp ? temp : data, typeSet, value)
            : filterList(temp ? temp : data, typeSet, value);
        this.setState({ searchValue: value });
        this.props.update(data);
    };
    render() {
        const { intl, autoFocus, theme, data, disableSearchHide, color, iconSize, noPadding, inputWidth, placeholder, invertIcon } = this.props;
        const { show, searchValue } = this.state;
        this.styles = styles(theme, color, disableSearchHide);
        return (
            <div className={color ? css(this.styles.searchWrapperColor) : css(this.styles.searchWrapper)}>
                {!invertIcon && <i
                    className={`material-icons ${css(this.styles.searchIcon)}`}
                    style={{ fontSize: iconSize }}
                    onClick={() => !disableSearchHide && this.setState({ show: !show })}>
                    search
                </i>}
                {show ? (
                    <TextInput
                        placeholder={placeholder ? placeholder : intl.formatMessage({ id: `comp.search.default` })}
                        fontSize={12}
                        type="text"
                        name="searchValue"
                        value={searchValue}
                        noPadding={noPadding}
                        width={inputWidth ? inputWidth : null}
                        margin={searchValue === '' ? '0px 16px 0px 3px' : '0px 0px 0px 3px'}
                        noBorder={true}
                        onChange={(e) => data && this.handleSearch(e.target.value)}
                        autoFocus={autoFocus}
                    />
                ) : (
                    <div className={css(this.styles.placeholder)}></div>
                )}
                {show && searchValue != '' && (
                    <i
                        className={`material-icons ${css(this.styles.closeIcon)}`}
                        style={{ fontSize: iconSize }}
                        onClick={() => this.handleSearch('')}>
                        close
                    </i>
                )}
                {invertIcon && <i
                    className={`material-icons ${css(this.styles.searchIcon)}`}
                    style={{ fontSize: iconSize }}
                    onClick={() => !disableSearchHide && this.setState({ show: !show })}>
                    search
                </i>}
            </div>
        );
    }
}

Search.defaultProps = {
    searchTitle: 'blank',
    searchType: ['name'],
    data: [],
    autoFocus: true,
    color: false,
    iconSize: 24,
    noPadding: false
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(connect(mapStateToProps)(Search));

const styles = (theme, disableSearchHide) =>
    StyleSheet.create({
        input: {
            border: 'none',
            boxShadow: 'none',
            borderRadius: 0,
            fontWeight: 400,
            fontSize: 12,
            marginBottom: 10,
            padding: '6px 25px 6px 35px',
            width: 150,
            color: theme.color.fontColor,
            backgroundColor: theme.color.compColor,
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 12
            }
        },
        placeholder: {
            width: 35
        },
        searchWrapper: {
            position: 'relative',
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center',
        },
        searchWrapperColor: {
            position: 'relative',
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center',
            background: theme.color.compColor,
            borderRadius: 15,
            padding: '0 5px'
        },
        searchIcon: {
            color: theme.color.themeColor,
            padding: 0,
            ':hover': {
                cursor: disableSearchHide ? 'auto' : 'pointer',
                opacity: disableSearchHide ? 1 : '.8'
            }
        },
        closeIcon: {
            color: theme.color.fontColor,
            padding: 0,
            fontSize: 16,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        }
    });
