import * as CONSTANTS from 'constants';

const INIT_STATE = {
    entryList: [],
    entryListMain: [],
    entryListStats: null,
    entryListLockStats: null,
    entryObject: null,
    entryLockTypeFilter: 'all',
    activeEntry: 'info',
    entryHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 100,
            sortTitle: 'hwType',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 150,
            sortTitle: 'mac',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.batteryVolts',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 125,
            tablet: true,
            sortTitle: 'voltageBattery',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.wiredVolts',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 125,
            tablet: true,
            sortTitle: 'voltageWired',
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'pages.zones.Types',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'zoneIDs',
            style: { textAlign: 'left' }
        }
    ],
    entryViewHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 80,
            sortTitle: 'hwType'
        },
        {
            id: 2,
            title: 'comp.table.header.batteryVolts',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 115,
            sortTitle: 'voltageBattery'
        },
        {
            id: 3,
            title: 'comp.table.header.wiredVolts',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 115,
            sortTitle: 'voltageWired'
        },
        {
            id: 4,
            title: 'pages.zones.Types',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'zoneIDs',
            laptop: true,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 90
        },
        {
            id: 6,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 90
        }
    ],
    entryUnitHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            icon: true,
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'accessType'
        }
    ],
    entryFilters: [
        {
            title: 'filters.all',
            value: ['gate', 'door', 'elevator', 'sample', 'repeater', 'slidingdoor', 'exitgate'],
            active: true
        },
        {
            title: 'filters.entry.gate',
            value: ['gate'],
            active: false
        },
        {
            title: 'filters.entry.door',
            value: ['door'],
            active: false
        },
        {
            title: 'filters.entry.elevator',
            value: ['elevator'],
            active: false
        },
        {
            title: 'filters.entry.sample',
            value: ['sample'],
            active: false
        },
        {
            title: 'filters.entry.repeater',
            value: ['repeater'],
            active: false
        },
        {
            title: 'filters.entry.slidingdoor',
            value: ['slidingdoor'],
            active: false
        },
        {
            title: 'db.exitgate',
            value: ['exitgate'],
            active: false
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_ENTRY_LIST: {
            return {
                ...state,
                entryList: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_LIST_MAIN: {
            return {
                ...state,
                entryListMain: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_LIST_STATS: {
            return {
                ...state,
                entryListStats: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_LIST_LOCK_STATS: {
            return {
                ...state,
                entryListLockStats: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_LOCK_TYPE_FILTER: {
            return {
                ...state,
                entryLockTypeFilter: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_DETAILS: {
            return {
                ...state,
                entryObject: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_HEADERS: {
            return {
                ...state,
                entryHeader: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_VIEW_HEADERS: {
            return {
                ...state,
                entryViewHeader: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_FILTERS: {
            return {
                ...state,
                entryFilters: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVE_ENTRY: {
            return {
                ...state,
                activeEntry: action.payload
            };
        }
        default:
            return state;
    }
};
