import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { fadeIn } from 'react-animations';
class Box extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            children,
            route,
            theme,
            boxShadow,
            customHeight,
            padding,
            width,
            margin,
            borderRadius,
            backgroundColor,
            height
        } = this.props;
        this.styles = styles(
            boxShadow,
            customHeight,
            padding,
            width,
            margin,
            borderRadius,
            theme,
            backgroundColor,
            route,
            height
        );
        return (
            <div
                className={
                    theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                        ? css(this.styles.boxWrapperDark, this.styles.fadeIn)
                        : css(this.styles.boxWrapper, this.styles.fadeIn)
                }
                onClick={() => route && this.props.history.push(route)}>
                {children}
            </div>
        );
    }
}

Box.defaultProps = {
    boxShadow: null,
    customHeight: null,
    padding: 20,
    width: '100%',
    margin: '17px 0px 0px 0px',
    borderRadius: 5,
    backgroundColor: null,
    route: null
};

const mapStateToProps = ({ app }) => {
    const { theme, height } = app;
    return { theme, height };
};

export default withRouter(connect(mapStateToProps)(Box));

const styles = (boxShadow, customHeight, padding, width, margin, borderRadius, theme, backgroundColor, route, height) =>
    StyleSheet.create({
        boxWrapper: {
            position: 'relative',
            boxShadow: boxShadow ? boxShadow : theme.color.boxShadowCorner,
            minHeight: customHeight ? customHeight : height - 94,
            padding,
            width,
            margin,
            borderRadius,
            backgroundColor: backgroundColor ? backgroundColor : theme.color.compColor,
            // overflowY: 'auto',
            // overflowX: 'hidden',
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: route ? 'pointer' : 'default',
                opacity: route ? '.8' : '1',
                boxShadow: route ? 'none' : boxShadow ? boxShadow : theme.color.boxShadowCornerHover
            }
        },
        boxWrapperDark: {
            position: 'relative',
            boxShadow: 'none',
            minHeight: customHeight ? customHeight : height - 94,
            padding,
            width,
            margin,
            borderRadius,
            backgroundColor: backgroundColor ? backgroundColor : theme.color.compColor,
            // overflowY: 'auto',
            // overflowX: 'hidden',
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: route ? 'pointer' : 'default',
                opacity: route ? '.8' : '1',
                boxShadow: 'none'
            }
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: '1.2s'
        }
    });
