import { testPost, returnTest, createResult, createWarnings, } from 'components/TestSuite/tests/functions';

const usersTests = () => {
    const userData = JSON.parse(sessionStorage.getItem('user_data'));

    const languagesEndpoint = '/company/messages/languages/'; 
    const siteSMSMessagesEndpoint = '/site/messages/SMS/read/';
    const siteEmailMessagesEndpoint = '/site/messages/email/read/';
    const sitePushMessagesEndpoint = '/site/messages/push/read/';
    const siteInternalMessagesEndpoint = '/site/messages/internal/read/';
    const companySMSMessagesEndpoint = '/company/messages/SMS/read/';
    const companyNotificationMessagesEndpoint = '/company/messages/notifications/read/';
    const siteMessagesEndpoint = '/site/messages/read/';

    const guide = [
        {
            name: 'Languages Endpoint',
            endpoint: languagesEndpoint,
            action: async () => {
                let res = await testPost(languagesEndpoint, {});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success', 
                        },
                        {
                            condition: res.data && res.data.length > 0,
                            errorMessage: 'No languages were returned from the endpoint'
                        },
                    ]),
                });
            },
        },
        {
            name: 'Site SMS Messages Endpoint',
            endpoint: siteSMSMessagesEndpoint,
            action: async () => {
                let res = await testPost(siteSMSMessagesEndpoint, {language: 'English'});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                        },
                        {
                            condition: res.data && res.data.length > 0,
                            errorMessage: 'No site SMS messages returned'
                        },
                    ]),
                })
            },
        },
        {
            name: 'Site Email Messages Endpoint',
            endpoint: siteEmailMessagesEndpoint,
            action: async () => {
                let res = await testPost(siteEmailMessagesEndpoint, {language: 'English'});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                        },
                        {
                            condition: res.data && res.data.length > 0,
                            errorMessage: 'No site Email messages returned'
                        },
                    ]),
                })
            },
        },
        {
            name: 'Site Push Messages Endpoint',
            endpoint: sitePushMessagesEndpoint,
            action: async () => {
                let res = await testPost(sitePushMessagesEndpoint, {language: 'English'});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                        },
                        {
                            condition: res.data && res.data.length > 0,
                            errorMessage: 'No site Push messages returned'
                        },
                    ]),
                })
            },
        },
        {
            name: 'Site Internal Messages Endpoint',
            endpoint: siteInternalMessagesEndpoint,
            action: async () => {
                let res = await testPost(siteInternalMessagesEndpoint, {language: 'English'});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                        },
                        {
                            condition: res.data && res.data.length > 0,
                            errorMessage: 'No site Internal messages returned'
                        },
                    ]),
                })
            },
        },
        {
            name: 'Company SMS Messages Endpoint',
            endpoint: companySMSMessagesEndpoint,
            action: async () => {
                let res = await testPost(companySMSMessagesEndpoint, {
                    language: 'English',
                    companyUUID: userData && userData.companyUUID,
                });
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                        },
                        {
                            condition: res.data && res.data.length > 0,
                            errorMessage: 'No company SMS messages returned'
                        },
                    ]),
                })
            },
        },
        {
            name: 'Company Notification Messages Endpoint',
            endpoint: companyNotificationMessagesEndpoint,
            action: async () => {
                let res = await testPost(companyNotificationMessagesEndpoint, {
                    language: 'English',
                    companyUUID: userData && userData.companyUUID,
                });
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                        },
                        {
                            condition: res.data && res.data.length > 0,
                            errorMessage: 'No company notification messages returned'
                        },
                    ]),
                })
            },
        },
        {
            name: 'Site Messages',
            endpoint: siteMessagesEndpoint,
            action: async () => {
                let res = await testPost(siteMessagesEndpoint, {});
                return returnTest(res, {
                    result: res && createResult([
                        {
                            condition: res.result === 'success',
                            errorMessage: `${res.errorCode}-${res.subcode}: ${res.message} - ${res.data}`
                        },
                    ]),
                })
            },
        },
    ]

    return guide;
}

export default usersTests;
