import React from 'react';
import { connect } from 'react-redux';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
class CommentsCell extends React.Component {
    render() {
        const { data, width, authUser, userTimezone, site } = this.props;
        const { createdDate, comment, firstName, lastName } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    maxCharLength={width > 991 ? 72 : width > 900 ? 45 : width > 300 ? 35 : 20}
                    Title={comment}
                    textAlign={'left'}
                    TitleColor={true}
                />
                {width > 800 && (
                    <CellText
                        maxCharLength={width > 300 ? 80 : 20}
                        Title={`${firstName} ${lastName}`}
                        textAlign={'center'}
                        TitleColor={true}
                    />
                )}
                {width > 800 && (
                    <CellText
                        padding={'2px 0 2px 10px'}
                        Cellwidth={'90px'}
                        Title={UTILS.dateTimeFormatter(createdDate, authUser, site, userTimezone)}
                        textAlign={'right'}
                        TitleColor={true}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, authUser, site, userTimezone };
};

export default connect(mapStateToProps)(CommentsCell);
