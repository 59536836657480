import * as CONSTANTS from 'constants';

const INIT_STATE = {
    activityRedirectsList: null,
    activityEventPreferencesList: null,
    activityRedirectsHeader: [
        {
            id: 0,
            title: 'db.blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 50
        },
        {
            id: 1,
            title: 'form.organization',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'organization',
            mobile: false,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.url',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'url',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'form.externalName',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'externalName',
            tablet: true,
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'form.apiKey',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'apiKey',
            tablet: true,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.date.update',
            active: true,
            sortable: true,
            order: false,
            mobile: false,
            laptop: true,
            sortTitle: 'modified',
            width: 100,
            style: { textAlign: 'right' }
        },
        {
            id: 6,
            title: 'db.blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 120
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_ACTIVITY_REDIRECTS: {
            return {
                ...state,
                activityRedirectsList: action.payload
            };
        }
        case CONSTANTS.SET_SITE_EVENT_PREFERENCES: {
            return {
                ...state,
                activityEventPreferencesList: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_REDIRECT_HEADERS: {
            return {
                ...state,
                activityRedirectsHeader: action.payload
            };
        }
        default:
            return state;
    }
};
