import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import * as UTILS from 'utilities';
import { UserIcon, SharedUserIcon } from 'utilities/icons';

import { fetchTransferringUsers, extendUserTransfer, setTransferHeaders } from 'actions/Users';
import { untransferUnit } from 'actions/Units';

import Table from 'components/Table';
import TransferUsersCell from 'components/Table/cell/containers/dashboard/transferringUsers';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Header from 'components/Header/dashboard';
class TransferUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showUserModal: false,
            currentUser: null,
        };
    }
    componentDidMount() {
        const { editWidgets, visible, fetchTransferringUsers } = this.props;
        (editWidgets || visible) && fetchTransferringUsers();
    }
    handleUserAction = (data) => {
        this.setState({ showUserModal: true, currentUser: data });
    };
    extendTransfer = () => {
        const { currentUser } = this.state;
        const { extendUserTransfer, intl } = this.props;
        extendUserTransfer(currentUser.userUUID, intl);
        this.setState({ showUserModal: false, currentUser: null });
    };
    endTransfer = () => {
        const { currentUser } = this.state;
        const { untransferUnit } = this.props;
        untransferUnit({ userUUID: currentUser.userUUID });
        this.setState({ showUserModal: false, currentUser: null });
    };
    render() {
        const { intl, theme, authUser, transferringUsers, site, userTimezone, transferHeaders } = this.props;
        const { showUserModal, currentUser } = this.state;
        this.styles = styles(theme);
        let minutesRemaining = currentUser && currentUser.transferExpirationTime ? moment().diff(currentUser.transferExpirationTime, 'minutes', true) : null;
        return (
            <>
                <Modal
                    show={showUserModal}
                    title={
                        <div className={css(this.styles.iconWrapperModalNote)}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                <UserIcon height={28} width={28} color={theme.color.themeColor} />
                                <p className={css(this.styles.titleModalNote)}>
                                    {currentUser && currentUser.userFirstName && currentUser.userFirstName}{' '}
                                    {currentUser && currentUser.userLastName && currentUser.userLastName}
                                </p>
                            </div>
                            <div></div>
                        </div>
                    }
                    toggle={() => this.setState({ showUserModal: !showUserModal, currentUser: null })}>
                    <div className={css(this.styles.buttonWrapperNote)} style={{ color: theme.color.fontColor }}>
                        <div className={css(this.styles.noteModalWrapper)}>
                            <div className={css(this.styles.descriptionModalNoteRow)}>
                                <div style={{ width: '50%' }}>
                                    <p className={css(this.styles.descriptionModalLabel)}>
                                        {intl.formatMessage({
                                            id: 'comp.button.transfer.multipleUnits'
                                        })}
                                    </p>
                                    <p className={css(this.styles.descriptionModalNote)}>
                                        {currentUser &&
                                            currentUser.units &&
                                            currentUser.units.map((unit) => unit.unitName).join(', ')}
                                    </p>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <p className={css(this.styles.descriptionModalLabel)}>
                                        {intl.formatMessage({
                                            id: 'comp.button.transfer.expiration'
                                        })}
                                    </p>
                                    {currentUser && currentUser.transferExpirationTime && <p className={css(this.styles.descriptionModalNote)}>
                                        {UTILS.dateTimeFormatter(currentUser.transferExpirationTime, authUser, site, userTimezone)}
                                    </p>}
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: 40
                            }}>
                            <Button
                                width={140}
                                load={false}
                                action={() =>
                                    this.setState({
                                        showUserModal: !showUserModal,
                                        currentUser: null
                                    })
                                }
                                text={'comp.button.transfer.end'}
                                margin={'0'}
                                padding={'10px 20px'}
                            />
                            <Button
                                width={140}
                                load={false}
                                action={() => this.extendTransfer()}
                                disabled={minutesRemaining && minutesRemaining <= -1440}
                                text={'comp.button.transfer.extend'}
                                margin={'0'}
                                padding={'10px 20px'}
                            />
                            <Button
                                width={140}
                                load={false}
                                action={() =>
                                    this.setState({
                                        showUserModal: !showUserModal,
                                        currentUser: null
                                    })
                                }
                                text={'pages.users.dismissTraining'}
                                margin={'0'}
                                padding={'10px 20px'}
                            />
                        </div>
                    </div>
                </Modal>
                <Header Icon={SharedUserIcon} iconSize={38} title={'comp.button.transfer.users'} />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <div>
                            <Table
                                tableHeight={194}
                                maxHeight={194}
                                border={false}
                                header={transferHeaders}
                                itemSize={41}
                                data={transferringUsers}
                                action={this.handleUserAction}
                                Cell={TransferUsersCell}
                            />
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, users, auth }) => {
    const { theme, width, userTimezone } = app;
    const { transferringUsers, transferHeaders } = users;
    const { authUser, site } = auth;
    return { theme, width, userTimezone, transferringUsers, authUser, site, transferHeaders };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { 
        fetchTransferringUsers, 
        extendUserTransfer, 
        untransferUnit, 
        setTransferHeaders 
    })(TransferUsers))
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 10px 0'
        },
        iconWrapperModalNote: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        buttonWrapperNote: {
            display: 'flex',
            flexDirection: 'column',
            padding: 25,
        },
        noteModalWrapper: {
            textAlign: 'left'
        },
        descriptionModalLabel: {
            color: theme.color.themeColor,
            fontSize: 16,
            marginBottom: 0
        },
        titleModalNote: {
            color: theme.color.fontColor,
            textAlign: 'left',
            fontSize: 22,
            margin: '0 0 0 15px'
        },
        descriptionModalNoteRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    });
