import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import CellText from 'components/Table/cell/components/text';
class ActivityCell extends React.Component {
    render() {
        const { intl, data, width } = this.props;
        const { unitName, unitOwnerName, sharedName } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    Title={unitName ? unitName : intl.formatMessage({ id: `db.blank` })}
                    textAlign={'left'}
                    TitleColor={true}
                />
                {width > 800 && (
                    <CellText
                        padding={'2px 10px 2px 0'}
                        maxCharLength={25}
                        Title={unitOwnerName ? unitOwnerName : intl.formatMessage({ id: `db.blank` })}
                        textAlign={'left'}
                        TitleColor={true}
                        textTransform={'capitalize'}
                    />
                )}
                {width > 800 && (
                    <CellText
                        padding={'2px 10px 2px 0'}
                        maxCharLength={25}
                        Title={sharedName ? sharedName : intl.formatMessage({ id: `db.blank` })}
                        textAlign={'left'}
                        TitleColor={true}
                        textTransform={'capitalize'}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { width } = app;
    return { width };
};

export default injectIntl(connect(mapStateToProps)(ActivityCell));
