import * as CONSTANTS from 'constants';
import * as ICONS from 'utilities/icons';

const INIT_STATE = {
    activeHelp: 'faq',
    apiDocumentation: null,
    helpMenuList: [
        {
            title: 'pages.faq.TYPES',
            url: 'faq/all',
            icon: ICONS.HelpIcon,
            tooltip: 'tooltip.help.faqs'
        },
        {
            title: 'pages.troubleshoot.TYPE',
            url: 'troubleshoot/all',
            icon: ICONS.ErrorIcon,
            permission: 'view_errors',
            tooltip: 'tooltip.help.troubleshoot'
        },
        {
            title: 'pages.contact.header',
            url: 'contact/all',
            icon: ICONS.UserIcon,
            tooltip: 'tooltip.help.contact'
        },
        {
            title: 'pages.appInstructions.Types',
            url: 'instructions',
            icon: ICONS.HelpIcon,
            tooltip: 'tooltip.help.app'
        },
        {
            title: 'comp.button.apiDocumentation',
            url: 'documentation/all',
            icon: ICONS.ApiDocumentationIcon,
            permission: 'view_api_documentation',
            tooltip: 'tooltip.help.api'
        },
        {
            title: 'comp.button.outageReport',
            url: 'outage/all',
            icon: ICONS.OutageIcon,
            permission: 'support_high_level',
            tooltip: 'tooltip.help.outage'
        },
        {
            title: 'pages.suggestImprovement.header',
            url: 'suggest',
            icon: ICONS.LightBulb,
            tooltip: 'tooltip.help.improvement'
        },
        {
            title: 'pages.orderFobs.header',
            url: 'order/fobs/all',
            icon: ICONS.FobIcon,
            tooltip: 'tooltip.help.fobs'
        },
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_HELP_ACTIVE: {
            return {
                ...state,
                activeHelp: action.payload
            };
        }
        case CONSTANTS.SET_API_DOCUMENTATION: {
            return {
                ...state,
                apiDocumentation: action.payload
            };
        }
        default:
            return state;
    }
};
