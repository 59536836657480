import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment-timezone';

import { NotifyIcon, NewsIcon } from 'utilities/icons';
import * as UTILS from 'utilities';

import { fetchUserNotifications, removeUserNotifications } from 'actions/Notifications';
import { fetchReleases } from 'actions/Releases';

import Table from 'components/Table';
import NotificationHeaderCell from 'components/Table/cell/containers/users/notificationsHeader';
import Header from 'components/Header/table';
import FooterLink from 'components/Footer/link';
import Spinner from 'components/Spinner';
import ToolTips from 'components/ToolTips';

class NotificationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewReleases: props.authUser && props.authUser.role && props.authUser.role.tier === 1,
            limit: 100,
            page: 1
        };
    }
    componentDidMount() {
        const { authUser, fetchReleases } = this.props;
        authUser.permissions.includes('view_releases') && fetchReleases();
    }
    editRelease = (i) => {
        this.props.toggle();
        this.props.history.push(`/app/releases/update/${i}`);
    };
    fetchMore = () => {
        const { fetchUserNotifications } = this.props;
        const { page, limit } = this.state;
        fetchUserNotifications(page + 1, limit);
        this.setState({ page: page + 1 });
    };
    clearAll = (e) => {
        const { removeUserNotifications, userNotifications } = this.props;
        if (userNotifications) {
            removeUserNotifications(userNotifications.map(n => n.id));
        } else {
            console.error('Facility or notifications are undefined');
        }
    }
    render() {
        const { intl, theme, userNotifications, toggle, authUser, releaseList, width, site } = this.props;
        let { viewReleases } = this.state;

        this.styles = styles(theme, authUser);
        let parsedReleaseList = releaseList;
        // convert the string tags to objects; this is to be consistent with other components
        parsedReleaseList &&
            parsedReleaseList.forEach((release) => {
                release &&
                    release.items &&
                    release.items.forEach((item, i) => {
                        if (typeof item.tag === 'string') {
                            release.items[i].tag = {
                                backend: item.tag.includes('b'),
                                web: item.tag.includes('w'),
                                app: item.tag.includes('a')
                            };
                        }
                    });
            });
            
        return authUser.permissions.includes('view_releases') ? (
            <>
                {authUser && authUser.role && authUser.role.tier !== 1 && (
                    <div className={css(this.styles.dropdownHeader)}>
                        <div
                            onClick={() => this.setState({ viewReleases: false })}
                            className={
                                viewReleases ? css(this.styles.dropdownTitle) : css(this.styles.dropdownTitleActive)
                            }>
                            <NotifyIcon
                                height={width > 800 ? 25 : 18}
                                width={width > 800 ? 25 : 18}
                                color={!viewReleases ? theme.color.themeColor : theme.color.fontColor}
                            />
                            <h2 className={css(this.styles.title)}>
                                {intl.formatMessage({ id: 'pages.home.notifications' })}
                            </h2>
                        </div>
                        <div
                            onClick={() => this.setState({ viewReleases: true })}
                            className={
                                viewReleases ? css(this.styles.dropdownTitleActive) : css(this.styles.dropdownTitle)
                            }>
                            <NewsIcon
                                height={width > 800 ? 25 : 18}
                                width={width > 800 ? 25 : 18}
                                color={viewReleases ? theme.color.themeColor : theme.color.fontColor}
                            />
                            <h2 className={css(this.styles.title)}>
                                {intl.formatMessage({ id: 'pages.releases.head' })}
                            </h2>
                        </div>
                    </div>
                )}
                {!viewReleases ? (
                    <div>
                        <Table
                            tableHeight={290}
                            itemSize={40}
                            data={userNotifications}
                            toggle={toggle}
                            Cell={NotificationHeaderCell}
                            moreItemsLoading={false}
                            loadMore={this.fetchMore}
                            hasNextPage={true}
                            force={true}
                        />
                        <div className={css(this.styles.notificationsFooter)}>
                        <FooterLink
                            title={'pages.dashboard.allNotifications'}
                            route={'/app/notifications'}
                            toggle={this.props.toggle}
                        />
                       <div
                                className={css(this.styles.title)}
                                id={`notificationsTableClear`}
                                onClick={(e) => this.clearAll(e)}
                                >{intl.formatMessage({ id: 'pages.dashboard.clearAll' })}</div>
                            <ToolTips placement='left' target={`notificationsTableClear`} message={'pages.dashboard.clearOneTooltip'} />
            </div>                    </div>
                ) : (
                    <div>
                        <div className={css(this.styles.releaseTable)}>
                            {parsedReleaseList && parsedReleaseList.length > 0 ? (
                                parsedReleaseList.slice(0, 5).map((release, i) => (
                                    <div className={css(this.styles.featureWrapper)} key={i}>
                                        <div className={css(this.styles.releaseItemHeader)}>
                                            <div className={css(this.styles.releaseItemHeaderLeft)}>
                                                <div className={css(this.styles.releaseItemIcon)}>
                                                    <i
                                                        className="material-icons"
                                                        style={{ color: 'white', fontSize: 23, fontWeight: '400' }}>
                                                        emoji_flags
                                                    </i>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {release && release.releaseDate && (
                                                        <h3 style={{ margin: 0, fontSize: 15, fontWeight: 500 }}>
                                                            {UTILS.dateFormatter(release.releaseDate, authUser, site)}
                                                        </h3>
                                                    )}
                                                    <h3
                                                        style={{
                                                            margin: 0,
                                                            fontSize: 13,
                                                            fontWeight: 500,
                                                            color: theme.color.themeColor
                                                        }}>
                                                        {release.versionID}
                                                    </h3>
                                                </div>
                                            </div>
                                            {authUser.permissions.includes('support_manage_releases') && (
                                                <div
                                                    onClick={() => this.editRelease(i)}
                                                    className={css(this.styles.releaseItemIconEdit)}>
                                                    <i
                                                        className="material-icons"
                                                        style={{ fontSize: 23, fontWeight: '400' }}>
                                                        create
                                                    </i>
                                                </div>
                                            )}
                                        </div>
                                        {release && release.tags && release.tags.length > 0 && (
                                            <div className={css(this.styles.releaseItemTagList)}>
                                                {release &&
                                                    release.tags &&
                                                    release.tags.map(
                                                        (tag, i) =>
                                                            tag !== '' && (
                                                                <div
                                                                    className={css(this.styles.releaseItemTagItem)}
                                                                    key={tag + i}>
                                                                    {tag}
                                                                </div>
                                                            )
                                                    )}
                                            </div>
                                        )}
                                        <h2 style={{ fontWeight: 600 }}>
                                            {release.name
                                                ? release.name
                                                : release.featureTitle
                                                ? release.featureTitle
                                                : intl.formatMessage({ id: 'comp.table.header.name' })}
                                        </h2>
                                        <p>{release.featureDescription ? release.featureDescription : null}</p>
                                        {release.items &&
                                            release.items
                                                .filter((item) => !item.tag.web && !item.tag.backend && !item.tag.app)
                                                .filter((item) => item.description.replace(/\s/g, '') !== '').length >
                                                0 && (
                                                <div className={css(this.styles.previewType)}>
                                                    <ul className={css(this.styles.previewTypeList)}>
                                                        {release.items &&
                                                            release.items.map(
                                                                (item, i) =>
                                                                    !item.tag.web &&
                                                                    !item.tag.backend &&
                                                                    !item.tag.app &&
                                                                    item.description.replace(/\s/g, '') !== '' && (
                                                                        <li
                                                                            className={css(
                                                                                this.styles.previewTypeListItem
                                                                            )}
                                                                            key={'misc' + i}>
                                                                            {item.description}
                                                                        </li>
                                                                    )
                                                            )}
                                                    </ul>
                                                </div>
                                            )}
                                        {release.items &&
                                            release.items
                                                .filter((item) => item.tag.web)
                                                .filter((item) => item.description.replace(/\s/g, '') !== '').length >
                                                0 && (
                                                <div className={css(this.styles.previewType)}>
                                                    <h3 className={css(this.styles.previewTypeHeader)}>
                                                        {intl.formatMessage({ id: 'form.releases.frontend' })}
                                                    </h3>
                                                    <ul className={css(this.styles.previewTypeList)}>
                                                        {release.items.map(
                                                            (item, i) =>
                                                                item.tag.web && (
                                                                    <li
                                                                        className={css(this.styles.previewTypeListItem)}
                                                                        key={'web' + i}>
                                                                        {item.description}
                                                                    </li>
                                                                )
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        {release.items &&
                                            release.items
                                                .filter((item) => item.tag.backend)
                                                .filter((item) => item.description.replace(/\s/g, '') !== '').length >
                                                0 && (
                                                <div className={css(this.styles.previewType)}>
                                                    <h3 className={css(this.styles.previewTypeHeader)}>
                                                        {intl.formatMessage({ id: 'form.releases.backend' })}
                                                    </h3>
                                                    <ul className={css(this.styles.previewTypeList)}>
                                                        {release.items.map(
                                                            (item, i) =>
                                                                item.tag.backend && (
                                                                    <li
                                                                        className={css(this.styles.previewTypeListItem)}
                                                                        key={'backend' + i}>
                                                                        {item.description}
                                                                    </li>
                                                                )
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        {release.items &&
                                            release.items
                                                .filter((item) => item.tag.app)
                                                .filter((item) => item.description.replace(/\s/g, '') !== '').length >
                                                0 && (
                                                <div className={css(this.styles.previewType)}>
                                                    <h3 className={css(this.styles.previewTypeHeader)}>
                                                        {intl.formatMessage({ id: 'form.releases.mobile' })}
                                                    </h3>
                                                    <ul className={css(this.styles.previewTypeList)}>
                                                        {release.items.map(
                                                            (item, i) =>
                                                                item.tag.app && (
                                                                    <li
                                                                        className={css(this.styles.previewTypeListItem)}
                                                                        key={'app' + i}>
                                                                        {item.description}
                                                                    </li>
                                                                )
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        {release && release.imageLink && (
                                            <a href={release.imageLink} target="_blank" rel="noreferrer">
                                                <img
                                                    style={{
                                                        boxShadow: theme.color.boxShadow,
                                                        maxWidth: '100%',
                                                        maxHeight: 700
                                                    }}
                                                    src={release.imageLink}
                                                    alt={release.featureTitle}
                                                />
                                            </a>
                                        )}

                                        {release && release.externalLink && release.externalLink !== '' && (
                                            <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
                                                <a
                                                    className={css(this.styles.releaseItemLink)}
                                                    target="_blank"
                                                    href={release.externalLink}
                                                    rel="noreferrer">
                                                    {intl.formatMessage({ id: 'pages.releases.readMore' })}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : parsedReleaseList && parsedReleaseList.length === 0 ? (
                                <div style={{ 
                                            padding: 50, 
                                            width: '100%', 
                                            textAlign: 'center',                                                         
                                            animationName: fadeIn,
                                            animationDuration: '1.2s' 
                                            }}>
                                    {intl.formatMessage({ id: 'comp.table.cell.empty' })}
                                </div>
                            ) : (
                                <Spinner />
                            )}
                        </div>
                        <FooterLink
                            title={'pages.releases.seeAll'}
                            route={'/app/releases'}
                            toggle={this.props.toggle}
                        />
                    </div>
                )}
            </>
        ) : (
            <>
                <Header title="pages.home.notifications" Icon={<NotifyIcon color={theme.color.fontColor} />} />
                <Table
                    tableHeight={290}
                    itemSize={40}
                    data={userNotifications}
                    toggle={toggle}
                    Cell={NotificationHeaderCell}
                    moreItemsLoading={false}
                    loadMore={this.fetchMore}
                    hasNextPage={true}
                    force={true}
                />
                <FooterLink
                    title={'pages.dashboard.allNotifications'}
                    route={'/app/notifications'}
                    toggle={this.props.toggle}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, notifications, releases }) => {
    const { authUser, site } = auth;
    const { theme, width } = app;
    const { userNotifications } = notifications;
    const { releaseList } = releases;
    return { authUser, site, theme, width, userNotifications, releaseList };
};

export default injectIntl(
    withRouter(connect(mapStateToProps, { fetchUserNotifications, fetchReleases, removeUserNotifications })(NotificationList))
);

const translateKeyframes = {
    '0%': {
        transform: 'scale(0.1, 0.1)',
        opacity: 0
    },
    '50%': {
        opacity: 0.8
    },
    '100%': {
        transform: 'scale(1.6, 1.6)',
        opacity: 0
    }
};

const styles = (theme, authUser) =>
    StyleSheet.create({
        dropdownHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid' + theme.color.borderColor
        },
        title: {
            marginTop: 3,
            marginLeft: 8,
            fontSize: 16,
            display: 'block',
            textTransform: 'uppercase',
            color: theme.color.fontColor,
            opacity: 1,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            },
            '@media(max-width:800px)': {
                fontSize: 10
            }
        },
        dropdownTitle: {
            display: 'flex',
            flexDirection: 'row',
            margin: '0 10px 0 10px',
            padding: '8px 12px 0 12px',
            borderTop: '1px solid' + theme.color.borderColor,
            borderRight: '1px solid' + theme.color.borderColor,
            borderLeft: '1px solid' + theme.color.borderColor,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            background: theme.color.pageColor,
            ':hover': {
                cursor: 'pointer'
            }
        },
        dropdownTitleActive: {
            display: 'flex',
            flexDirection: 'row',
            margin: '0 10px 0 10px',
            padding: '8px 12px 0 12px',
            borderTop: '1px solid' + theme.color.borderColor,
            borderRight: '1px solid' + theme.color.borderColor,
            borderLeft: '1px solid' + theme.color.borderColor,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            ':hover': {
                cursor: 'pointer'
            }
        },
        notificationsFooter:{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        releaseTable: {
            height: authUser && authUser.role && authUser.role.tier !== 1 ? 293 : 350,
            maxHeight: authUser && authUser.role && authUser.role.tier !== 1 ? 293 : 350,
            overflowY: 'auto',
            borderBottom: '1px solid' + theme.color.borderColor,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        featureWrapper: {
            width: '100%',
            borderBottom: '1px solid' + theme.color.borderColor,
            borderRadius: 5,
            padding: authUser && authUser.role && authUser.role.tier !== 1 ? 30 : '20px 30px 30px 30px',
            marginBottom: '7px',
            color: theme.color.fontColor
        },
        releaseItemHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative'
        },
        releaseItemHeaderLeft: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        releaseItemIcon: {
            background: theme.color.themeColor,
            height: 40,
            width: 40,
            borderRadius: '50%',
            marginRight: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        releaseItemTagList: {
            maxWidth: '100%',
            overflowX: 'scroll',
            marginTop: 15,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        releaseItemTagItem: {
            padding: '5px 10px',
            background: theme.color.pageColor,
            margin: '0px 5px 0px 0px',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            textTransform: 'capitalize'
        },
        releaseItemLink: {
            color: theme.color.fontColor,
            transition: '.2s all ease-in-out',
            textDecoration: 'underline' + theme.color.themeColor,
            opacity: 1,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        releaseItemIconEdit: {
            color: theme.color.themeColor,
            transition: '.2s all ease-in-out',
            opacity: 1,
            position: 'absolute',
            right: 0,
            top: 5,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        previewTypeHeader: {
            display: 'block',
            fontFamily: 'Poppins, sans-serif',
            marginBottom: 5,
            fontSize: 15
        },
        previewTypeList: {
            paddingInlineStart: 20,
            marginBottom: 16
        },
        previewTypeListItem: {
            display: 'list-item',
            listStyle: 'disc'
        },
        title: {
            fontSize: 12,
            fontWeight: 400,
            margin: 0,
            paddingRight: 100,
            paddingTop:23,
            textDecoration: 'underline',
            textDecorationColor: theme.color.themeColor,
            color: theme.color.fontColor,
            textAlign: 'center',
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        }
    });
