import React from 'react';
import { StyleSheet, css } from 'aphrodite';

class ToggleSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked,
            displayText: true,
            dragging: false
        };
    }

    componentDidUpdate(prevProps) {
        // Sync internal state with props if checked prop changes externally
        if (prevProps.checked !== this.props.checked) {
            this.setState({ checked: this.props.checked  });
        }
    }

    handleChange = () => {
        const { onChange } = this.props;
        this.setState(prevState => ({
            checked: !prevState.checked,
            displayText: false  // Set displayText to false to hide the text temporarily
        }), () => {
            // Call the onChange callback if provided
            if (onChange) {
                onChange(this.state.checked);  // Pass the updated state value
                setTimeout(() => {
                    this.setState({ displayText: true });
                }, 2000); // Adjust the delay as needed (2000ms = 2 seconds)
            }
        });
    };

    handleMouseDown = (event) => {
        event.preventDefault();
        this.setState({ dragging: true });
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    };

    handleMouseMove = (event) => {
        if (!this.state.dragging) return;

        const toggleRect = this.toggleRef.getBoundingClientRect();
        const clickX = event.clientX - toggleRect.left;
        const isChecked = clickX >= toggleRect.width / 2;

        if (isChecked !== this.state.checked) {
            this.setState({ checked: isChecked });
        }
    };

    handleMouseUp = () => {
        this.setState({ dragging: false }, () => {
            document.removeEventListener('mousemove', this.handleMouseMove);
            document.removeEventListener('mouseup', this.handleMouseUp);
            if (this.props.onChange) {
                this.props.onChange(this.state.checked);
            }
        });
    };

    render() {
        const { themeColor, buttonText } = this.props;
        const { displayText, checked } = this.state;
        const isDarkMode = themeColor && themeColor.color && themeColor.color.name && themeColor.color.name.includes('dark');
        const bgmColour = themeColor && themeColor.color && themeColor.color.themeColor;
        const backColor = themeColor && themeColor.color && themeColor.color.headerColor;
        const fillPosition = checked ? 'calc(100% - 22px)' : '2px';
        const buttonTextToShow = displayText ? buttonText : ''; // Show empty text if displayText is false

        this.styles = styles(isDarkMode, bgmColour, backColor, fillPosition);

        return (
            <div
                className={css(this.styles.wrapper)}
                ref={(ref) => (this.toggleRef = ref)}
                onMouseDown={this.handleMouseDown}
            >
                <label style={{ display: 'flex' }}>
                    <input
                        type="checkbox"
                        className={css(this.styles.input)}
                        checked={checked}
                        
                    />
                    <span className={css(this.styles.fill)}></span>
                    <span
                        className={css(this.styles.text)}
                        style={{
                            marginTop: '1px',
                            marginLeft: checked ? '18px' : '25px',
                            color: bgmColour,
                            userSelect: 'none'
                        }}
                    >
                        {buttonTextToShow}
                    </span>
                </label>
            </div>
        );
    }
}

ToggleSwitch.defaultProps = {
    fontSize: 13,
    themeColor: { color: { name: '' } },
    buttonText: 'Simple'
};

export default ToggleSwitch;

const styles = (isDarkMode, bgmColour, backColor, fillPosition) =>
    StyleSheet.create({
        wrapper: {
            position: 'relative',
            width: '100px',
            height: '24px',
            background: isDarkMode ? backColor : '#fff',
            display: 'inline-block',
            borderRadius: '100px',
            boxShadow: '0px 2px 6px -2px rgba(0, 0, 0, 0.4)',
            border: `1px solid ${bgmColour}`,
            verticalAlign: 'middle'
        },
        fill: {
            cursor: 'pointer',
            position: 'absolute',
            left: fillPosition,
            top: '1px',
            borderRadius: '50%',
            background: bgmColour,
            width: '20px',
            height: '20px',
            transition: 'left 0.4s'
        },
        input: {
            width: '0',
            visibility: 'hidden'
        },
        text: {
            fontSize: 12,
            fontFamily: 'Poppins'
        },
        textWrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            textAlign: 'center',
            '-webkit-user-select': 'none'
        }
    });
