import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import { fetchRoleList } from 'actions/Roles';
import { setAuthUser, setViewAs } from 'actions/Auth';

import ToolTips from 'components/ToolTips';
class MenuViewAs extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { fetchRoleList, rolesList } = this.props;
        !rolesList && fetchRoleList();
    }
    changePerms = (role) => {
        const { toggle, authUser, setAuthUser, setViewAs } = this.props;
        toggle();
        let newUser = JSON.parse(JSON.stringify(authUser));
        newUser.permissions = role.permissions;
        setAuthUser(newUser);
        setViewAs(role.name);
    };
    resetUser = () => {
        const { setViewAs, setAuthUser, toggle } = this.props;
        let newUser = JSON.parse(sessionStorage.getItem('user_data'))
        setAuthUser(newUser);
        setViewAs(null);
        toggle();
    }
    render() {
        const { intl, rolesList, theme, viewedRole } = this.props;
        this.styles = styles(theme);
        let filteredRoles = rolesList && rolesList.filter((role) => role.tier !== 0);
        return (
            filteredRoles && (
                <ul className="list-unstyled">
                    <div className={css(this.styles.title)}>
                        {intl.formatMessage({ id: `comp.table.header.viewAs` })}:
                    </div>
                    <div className={css(this.styles.rolesList)}>
                        {viewedRole && <li className={css(this.styles.addList)} id="viewedRoleMenu" onClick={() => this.resetUser()}>
                            <p className={css(this.styles.addTitleSelected)}>{viewedRole}
                                <span>                                    
                                    <i className="material-icons" id="add_circle_outline" style={{ fontSize: 17, marginLeft: 2 }}>
                                        person_search
                                    </i>
                                </span>
                            </p>
                            <div className={css(this.styles.addArrow)}>
                                <i style={{ fontSize: 17 }} className="material-icons">
                                    arrow_forward_ios
                                </i>
                            </div>
                        </li>}
                        {viewedRole && <ToolTips target="viewedRoleMenu" message="comp.table.header.viewAsTooltip" />}
                        {filteredRoles.filter(filteredRole => filteredRole.name !== viewedRole).map((role) => {
                            return (
                                <div key={role.id} onClick={() => this.changePerms(role)}>
                                    <li className={css(this.styles.addList)}>
                                        <p className={role.name == viewedRole ? css(this.styles.addTitleSelected) : css(this.styles.addTitle)}>{role.name}
                                            {role.name == viewedRole && <span>                                    
                                                <i className="material-icons" id="add_circle_outline" style={{ fontSize: 17, marginLeft: 2 }}>
                                                    person_search
                                                </i>
                                            </span>}
                                        </p>
                                        <div className={css(this.styles.addArrow)}>
                                            <i style={{ fontSize: 17 }} className="material-icons">
                                                arrow_forward_ios
                                            </i>
                                        </div>
                                    </li>
                                </div>
                            );
                        })}
                    </div>
                </ul>
            )
        );
    }
}

const mapStateToProps = ({ app, auth, roles }) => {
    const { authUser, viewedRole } = auth;
    const { theme } = app;
    const { rolesList } = roles;
    return { theme, authUser, viewedRole, rolesList };
};

export default injectIntl(withRouter(connect(mapStateToProps, { fetchRoleList, setAuthUser, setViewAs })(MenuViewAs)));

const styles = (theme) =>
    StyleSheet.create({
        addList: {
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            width: '100%',
            color: theme.color.themeColor,
            paddingTop: 2,
            position: 'relative',
            userSelect: 'none',
            ':hover': {
                textDecoration: 'none',
                opacity: '.8',
                cursor: 'pointer'
            }
        },
        addTitle: {
            float: 'left',
            margin: 0,
            padding: '8px 20px 8px 0',
            fontWeight: 400,
            fontFamily: 'Roboto',
            fontSize: 16,
        },
        addTitleSelected: {
            float: 'left',
            margin: 0,
            marginLeft: -6,
            marginBottom: 2,
            padding: '7px 6px 7px 6px',
            fontWeight: 400,
            fontFamily: 'Roboto',
            fontSize: 16,
            background: theme.color.themeColor,
            borderRadius: 4,
            color: theme.color.buttonFontColor
        },
        addArrow: {
            position: 'absolute',
            right: 8,
            top: 12
        },
        title: {
            fontSize: 16,
            paddingBottom: 5,
        },
        rolesList: {
            maxHeight: 265,
            overflowY: 'auto',
            borderTop: '1px solid' + theme.color.borderColor,
            borderBottom: '1px solid' + theme.color.borderColor,
        }
    });
