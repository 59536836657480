import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { fadeIn } from 'react-animations';

import { fetchUserNotificationsCompany } from 'actions/Notifications';

import { NotifyIcon } from 'utilities/icons';

import ExpandableTable from './expandableTable';
import Spinner from 'components/Spinner';
class DashboardCompanyNotifications extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { fetchUserNotificationsCompany, managerNotificationFilters } = this.props;
        fetchUserNotificationsCompany({...managerNotificationFilters, enterpriseNotifications: true});
    }
    render() {
        const { intl, theme, userNotificationsEnterprise } = this.props;
        this.styles = styles(theme);
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <div className={css(this.styles.titleBox)}>
                            <NotifyIcon color={theme.color.themeColor} />
                            <h1 className={css(this.styles.header)}>{intl.formatMessage({ id: 'pages.home.notifications' })}</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div style={{maxHeight: 407, overflow: 'auto'}}>
                            <Row style={{borderBottom: `solid 1px ${theme.color.borderColor}`, padding: '5px 0px', margin: '0px'}}>
                                <Col xs={4}>
                                    {intl.formatMessage({ id: 'form.facilityName' })}
                                </Col>
                                <Col xs={4}>
                                    {intl.formatMessage({ id: 'form.numberOfNotifications' })}
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Row>
                            {userNotificationsEnterprise
                            ?
                            userNotificationsEnterprise.length > 0
                            ?
                            <div>
                                {userNotificationsEnterprise.map((n,i) => (
                                    <ExpandableTable key={i} facility={n} />
                                ))}
                            </div>
                            :
                            <div
                                style={{
                                    minHeight: 110,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    animationName: fadeIn,
                                    animationDuration: '1.2s'
                                }}>
                                <p>
                                    {intl.formatMessage({
                                        id: 'comp.table.cell.empty'
                                    })}
                                </p>
                            </div>
                            :
                            <Spinner />}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, notifications }) => {
    const { theme } = app;
    const { userNotificationsEnterprise, managerNotificationFilters } = notifications;
    return { theme, userNotificationsEnterprise, managerNotificationFilters };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, { fetchUserNotificationsCompany })(
            DashboardCompanyNotifications
        )
    )
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.fontColor,
            margin: '0px 0px 0px 10px'
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            paddingBottom: 5
        }
    });
