import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
class FiltersZones extends React.Component {
    render() {
        const { intl, theme, filterList, data, entry, site } = this.props;
        this.styles = styles(theme);
        return (
            <div className={css(this.styles.wrapper)}>
                <ul className={css(this.styles.list)}>
                    {entry ? (
                        <li
                            className={css(this.styles.listItem)}
                            onClick={() => filterList(data.map((zone) => zone.entryUUID))}>
                            {intl.formatMessage({ id: 'form.select.all' })}
                        </li>
                    ) : (
                        <li
                            className={css(this.styles.listItem)}
                            onClick={() => filterList(data.map((zone) => zone.uuid))}>
                            {intl.formatMessage({ id: 'form.select.all' })}
                        </li>
                    )}
                    <li className={css(this.styles.listItem)} onClick={() => filterList([site.uuid])}>
                        {intl.formatMessage({ id: 'form.select.onlyCurrentSite' })}
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme } = app;
    const { site } = auth;
    return { theme, site };
};

export default injectIntl(connect(mapStateToProps)(FiltersZones));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            padding: 5,
            marginBottom: 0,
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            textAlign: 'left'
        },
        list: {
            padding: 0,
            margin: 0,
            display: 'table',
            width: '100%',
            color: theme.color.themeColor
        },
        listItem: {
            display: 'table-cell',
            textAlign: 'center',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        }
    });
