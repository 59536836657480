//||||||||||||||||||||||||||||||||||||||||
//|| REPORT LIST                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REPORT_LIST = '/support/site/stats/';
export const PURPOSE_REPORT_LIST = 'Fetch a list of all sites by Janus.';
export const PERMISSIONS_REPORT_LIST = [];
export const REQUIREMENTS_REPORT_LIST = ['Token Required'];
export const PARAMETER_REPORT_LIST = {
    companyId: 0
};
export const RETURN_REPORT_LIST = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| REPORT DETAILS                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REPORT_DETAILS = '/support/site/details/';
export const PURPOSE_REPORT_DETAILS = 'Fetch a list of devices for a site.';
export const PERMISSIONS_REPORT_DETAILS = [];
export const REQUIREMENTS_REPORT_DETAILS = ['Token Required'];
export const PARAMETER_REPORT_DETAILS = {
    SiteId: 0,
    VersionBle: '',
    VersionWp: '',
    VersionWpOtap: '',
    VersionWpStack: '',
    Online: '',
    GatewayMac: '',
    Missing: ''
};
export const RETURN_REPORT_DETAILS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| FACILITIES LIST                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FACILITIES_LIST = '/support/sites/';
export const PURPOSE_FACILITIES_LIST = 'Fetch a list of all sites by Janus.';
export const PERMISSIONS_FACILITIES_LIST = [];
export const REQUIREMENTS_FACILITIES_LIST = ['Token Required'];
export const PARAMETER_FACILITIES_LIST = {
    companyId: 0
};
export const RETURN_FACILITIES_LIST = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| REPORT LOCK DETAILS                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REPORT_HISTORY = '/history';
export const PURPOSE_REPORT_HISTORY = 'Fetch device history for a site.';
export const PERMISSIONS_REPORT_HISTORY = [];
export const REQUIREMENTS_REPORT_HISTORY = ['Token Required'];
export const PARAMETER_REPORT_HISTORY = {
    companyId: 0
};
export const RETURN_REPORT_HISTORY = {
    result: 'success'
};
