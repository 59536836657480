import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as UTILS from 'utilities';

import { removeUserNotifications } from 'actions/Notifications';

import CellText from 'components/Table/cell/components/text';
import CellClose from 'components/Table/cell/components/close';
class UserNotificationsCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
    }
    removeNotification = (id) => {
        this.setState({ show: false });
        this.props.removeUserNotifications([id]);
    };
    render() {
        const { data, width, history, toggle, authUser, site } = this.props;
        const { id, createdDate, eventsId, title, type } = data;
        const { show } = this.state;
        return (
            show && (
                <>
                    <CellText
                        Title={type && type == 'feature' ? `${type}: ${title}` : title}
                        Description={UTILS.dateFormatter(createdDate, authUser, site)}
                        textAlign={'left'}
                        maxCharLength={width > 800 ? 70 : width > 400 ? 40 : 27}
                        TitleColor={true}
                        DescriptionColor={true}
                        update={
                            eventsId && eventsId !== ''
                                ? () => {
                                      toggle();
                                      history.push(`/app/activity/notification/${eventsId}`);
                                  }
                                : null
                        }
                    />
                    <CellClose Cellwidth={'50px'} update={() => this.removeNotification(id)} />
                </>
            )
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width } = app;
    const { authUser, site } = auth;
    return { width, authUser, site };
};

export default withRouter(connect(mapStateToProps, { removeUserNotifications })(UserNotificationsCell));
