import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
class DashboardRecentActivityCell extends React.Component {
    render() {
        const { intl, data, width, authUser, site, userTimezone } = this.props;
        const { user, device, action, recordedDate, event } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    maxCharLength={
                        width > 1450 ? 
                        width / 85 : 
                        width > 1200 ? 
                        width / 100 : 
                        width > 991 ? 
                        width / 70 : 
                        width / 18
                    }
                    maxDescLength={
                        width > 1600 ? 
                        width / 70 : 
                        width > 1500 ?
                        width / 68 :
                        width > 1450 ? 
                        width / 70 : 
                        width > 1250 ? 
                        width / 80 : 
                        width > 991 ? 
                        width / 73 : 
                        width / 15
                    }
                    SupportActivity={event && event.by && event.by.isSupportUser}
                    Title={user && user !== ' ' ? user : device ? device : intl.formatMessage({ id: `form.na` })}
                    Description={`${intl.formatMessage({
                        id: `db.${action}`,
                        defaultMessage: ' '
                    })}${
                        data && data.event && data.event.accessCode && data.event.accessCode !== '0'
                            ? `: ${data.event.accessCode}`
                            : ''
                    }`}
                    textAlign={'left'}
                    TitleColor={true}
                />
                {width > 800 && !(1125 > width && width > 991) && !(width >= 1200 && width < 1300) && (
                    <CellText 
                    TitleSize={11} 
                    Title={device && device !== "" ? device : intl.formatMessage({ id: `form.na` })} 
                    textAlign={'left'}
                    />
                )}
                <CellText
                    textAlign={'right'}
                    padding={'2px 0 2px 10px'}
                    TitleSize={width > 300 ? 11 : 8}
                    Cellwidth={'80px'}
                    Title={recordedDate && recordedDate !== "" ? UTILS.dateTimeFormatter(recordedDate, authUser, site, userTimezone) : intl.formatMessage({ id: `form.na` })}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, userTimezone, authUser, site };
};

export default injectIntl(connect(mapStateToProps)(DashboardRecentActivityCell));
