import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import { TailSpin } from 'react-loader-spinner';
import { fadeIn } from 'react-animations';

class Saving extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, theme, show, saveLoader, textColor, logoColor } = this.props;
        this.styles = styles(theme, textColor);

        return (
            (show || saveLoader) && (
                <div className={css(this.styles.saving)}>
                    <TailSpin color={logoColor || theme.color.themeColor} width={18} height={18} />
                    <span style={{ marginLeft: 10 }}>{intl.formatMessage({ id: 'form.globalnotifs.saving' })}...</span>
                </div>
            )
        );
    }
}

Saving.defaultProps = {
    show: false
};

const mapStateToProps = ({ app }) => {
    const { theme, saveLoader } = app;
    return { theme, saveLoader };
};

export default withRouter(injectIntl(connect(mapStateToProps)(Saving)));

const styles = (theme, textColor) =>
    StyleSheet.create({
        saving: {
            display: 'flex',
            flexDirection: 'row',
            float: 'right',
            animationName: fadeIn,
            animationDuration: '1.2s',
            marginRight: 5,
            color: textColor || theme && theme.color.fontColor
        }
    });
