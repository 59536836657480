import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { UncontrolledTooltip } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'components/Modal/new';
import { injectIntl } from 'react-intl';

import WeatherWeek from 'components/Weather/week';
import {
    SunIcon,
    MoonIcon,
    SunCloudsIcon,
    MoonCloudsIcon,
    CloudIcon,
    CloudsIcon,
    RainIcon,
    RainSunIcon,
    RainMoonIcon,
    ThunderstormIcon,
    SnowIcon,
    MistIcon
} from 'utilities/icons';
class WeatherWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWeek: false,
            weather: sessionStorage.getItem('weather')
                ? JSON.parse(sessionStorage.getItem('weather'))
                : props.weatherInfo
                ? props.weatherInfo
                : null,
            airQuality: sessionStorage.getItem('air_quality')
                ? JSON.parse(sessionStorage.getItem('air_quality'))
                : props.airQualityIndex
                ? props.airQualityIndex
                : null
        };
    }
    componentDidUpdate(prevProps) {
        if (sessionStorage.getItem('weather') && !this.state.weather) {
            this.setState({ weather: JSON.parse(sessionStorage.getItem('weather')) });
        } else if (prevProps.weatherInfo !== this.props.weatherInfo) {
            this.setState({ weather: this.props.weatherInfo });
        }
        if (sessionStorage.getItem('air_quality') && !this.state.airQuality) {
            this.setState({ airQuality: JSON.parse(sessionStorage.getItem('air_quality')) });
        } else if (prevProps.airQualityIndex !== this.props.airQualityIndex) {
            this.setState({ airQuality: this.props.airQualityIndex });
        }
    }
    renderIcon = (param) => {
        switch (param) {
            case '01n':
                return <MoonIcon />;
            case '02d':
                return <SunCloudsIcon />;
            case '02n':
                return <MoonCloudsIcon />;
            case '03d':
                return <CloudIcon />;
            case '03n':
                return <CloudIcon />;
            case '04d':
                return <CloudsIcon />;
            case '04n':
                return <CloudsIcon />;
            case '09d':
                return <RainIcon />;
            case '09n':
                return <RainIcon />;
            case '10d':
                return <RainSunIcon />;
            case '10n':
                return <RainMoonIcon />;
            case '11d':
                return <ThunderstormIcon />;
            case '11n':
                return <ThunderstormIcon />;
            case '13d':
                return <SnowIcon />;
            case '13n':
                return <SnowIcon />;
            case '50d':
                return <MistIcon />;
            case '50n':
                return <MistIcon />;
            default:
                return <SunIcon />;
        }
    };
    toggleWeek = () => {
        const { showWeek } = this.state;
        this.setState({ showWeek: !showWeek });
    };
    render() {
        const { authUser, theme, intl } = this.props;
        const { showWeek, weather, airQuality } = this.state;
        this.styles = styles(theme);
        //let weather = sessionStorage.getItem('weather') && JSON.parse(sessionStorage.getItem('weather'));
        //let airQuality = sessionStorage.getItem('air_quality') && JSON.parse(sessionStorage.getItem('air_quality'));
        return weather ? (
            <div
                className={css(this.styles.boxWrapper)}
                style={{ backgroundColor: theme.color.compColor }}
                onClick={() => this.toggleWeek()}>
                <div className={css(this.styles.wrapper)} style={{ color: theme.color.fontColor }}>
                    <Modal isOpen={showWeek} toggle={this.toggleWeek}>
                        <ModalHeader
                            style={{
                                background: theme.color.compColor,
                                color: theme.color.fontColor,
                                border: 'none',
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4
                            }}
                            toggle={this.toggleWeek}>
                            <span>
                                {intl.formatMessage({ id: 'comp.button.weeklyForecast' })}: {weather && weather.name}
                            </span>
                        </ModalHeader>
                        <ModalBody
                            style={{
                                background: theme.color.compColor,
                                color: theme.color.fontColor,
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4
                            }}>
                            <WeatherWeek />
                        </ModalBody>
                    </Modal>
                    <div>
                        <div>
                            {authUser && authUser.role && authUser.role.tier === 1 ? (
                                <div className={css(this.styles.tempSupport)} style={{ color: theme.color.fontColor }}>
                                    {weather &&
                                        weather.main &&
                                        weather.main.temp &&
                                        `${Math.round(weather.main.temp * (9 / 5) - 459.67)}F°/${Math.round(
                                            weather.main.temp - 273.15
                                        )}C°`}
                                </div>
                            ) : (
                                <div className={css(this.styles.temp)} style={{ color: theme.color.fontColor }}>
                                    {weather &&
                                    weather.main &&
                                    weather.main.temp &&
                                    authUser &&
                                    authUser.temperatureScale &&
                                    authUser.temperatureScale === 'fahrenheit'
                                        ? `${Math.round(weather.main.temp * (9 / 5) - 459.67)}°`
                                        : `${Math.round(weather.main.temp - 273.15)}°`}
                                </div>
                            )}
                        </div>
                        <div>
                            <div className={css(this.styles.conditions)} style={{ color: theme.color.subFontColor }}>
                                {weather && weather.weather && weather.weather[0] && weather.weather[0].main}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                        {weather.weather && weather.weather[0] && this.renderIcon(weather.weather[0].icon)}
                        {airQuality &&
                            airQuality.current &&
                            airQuality.current.air_quality &&
                            airQuality.current.air_quality['us-epa-index'] && (
                                <div
                                    id="airQualityIndex"
                                    className={css(this.styles.airQuality)}
                                    style={{
                                        color: theme.color.buttonFontColor,
                                        background:
                                            airQuality.current.air_quality['us-epa-index'] == 1
                                                ? '#6AA133'
                                                : airQuality.current.air_quality['us-epa-index'] == 2
                                                ? '#FABD2C'
                                                : airQuality.current.air_quality['us-epa-index'] == 3
                                                ? '#F57E00'
                                                : airQuality.current.air_quality['us-epa-index'] == 4
                                                ? '#C63A29'
                                                : airQuality.current.air_quality['us-epa-index'] == 5
                                                ? '#AE1358'
                                                : '#8C0C51'
                                    }}>
                                    AQI
                                </div>
                            )}
                        {airQuality &&
                            airQuality.current &&
                            airQuality.current.air_quality &&
                            airQuality.current.air_quality['us-epa-index'] && (
                                <UncontrolledTooltip
                                    placement="top"
                                    target="airQualityIndex"
                                    delay={{ show: 75, hide: 250 }}
                                    className="my-tooltip"
                                    style={{
                                        background: theme.color.compColor,
                                        boxShadow: theme.color.boxShadow,
                                        opacity: 1
                                    }}>
                                    <div className={css(this.styles.airQualityTooltip)}>
                                        <div className={css(this.styles.airQualityTitle)}>
                                            <h3
                                                style={{
                                                    margin: '10px 0 0 0',
                                                    color: theme.color.fontColor
                                                }}>
                                                {intl.formatMessage({
                                                    id: 'comp.button.airQuality'
                                                })}
                                            </h3>
                                            <h2
                                                style={{
                                                    fontSize: 14,
                                                    textAlign: 'left',
                                                    color:
                                                        airQuality.current.air_quality['us-epa-index'] == 1
                                                            ? '#6AA133'
                                                            : airQuality.current.air_quality['us-epa-index'] == 2
                                                            ? '#FABD2C'
                                                            : airQuality.current.air_quality['us-epa-index'] == 3
                                                            ? '#F57E00'
                                                            : airQuality.current.air_quality['us-epa-index'] == 4
                                                            ? '#C63A29'
                                                            : airQuality.current.air_quality['us-epa-index'] == 5
                                                            ? '#AE1358'
                                                            : '#8C0C51'
                                                }}>
                                                {intl.formatMessage({
                                                    id: `comp.button.airQuality${airQuality.current.air_quality['us-epa-index']}`
                                                })}
                                            </h2>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div className={css(this.styles.airQualityBar)}>
                                                <div
                                                    className={css(this.styles.airQualityArrow)}
                                                    style={{ height: 15 }}>
                                                    {airQuality.current.air_quality['us-epa-index'] == 1 && (
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: 22,
                                                                marginTop: 2,
                                                                color: '#6AA133',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                            arrow_right
                                                        </i>
                                                    )}
                                                </div>
                                                <div
                                                    className={css(this.styles.airQualityArrow)}
                                                    style={{ height: 15 }}>
                                                    {airQuality.current.air_quality['us-epa-index'] == 2 && (
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: 22,
                                                                marginTop: 2,
                                                                color: '#FABD2C',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                            arrow_right
                                                        </i>
                                                    )}
                                                </div>
                                                <div
                                                    className={css(this.styles.airQualityArrow)}
                                                    style={{ height: 15 }}>
                                                    {airQuality.current.air_quality['us-epa-index'] == 3 && (
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: 22,
                                                                marginTop: 2,
                                                                color: '#F57E00',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                            arrow_right
                                                        </i>
                                                    )}
                                                </div>
                                                <div
                                                    className={css(this.styles.airQualityArrow)}
                                                    style={{ height: 15 }}>
                                                    {airQuality.current.air_quality['us-epa-index'] == 4 && (
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: 22,
                                                                marginTop: 2,
                                                                color: '#C63A29',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                            arrow_right
                                                        </i>
                                                    )}
                                                </div>
                                                <div
                                                    className={css(this.styles.airQualityArrow)}
                                                    style={{ height: 15 }}>
                                                    {airQuality.current.air_quality['us-epa-index'] == 5 && (
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: 22,
                                                                marginTop: 2,
                                                                color: '#AE1358',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                            arrow_right
                                                        </i>
                                                    )}
                                                </div>
                                                <div
                                                    className={css(this.styles.airQualityArrow)}
                                                    style={{ height: 15 }}>
                                                    {airQuality.current.air_quality['us-epa-index'] == 6 && (
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                fontSize: 22,
                                                                marginTop: 2,
                                                                color: '#8C0C51',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                            arrow_right
                                                        </i>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={css(this.styles.airQualityBar)}>
                                                <div
                                                    style={{
                                                        height: 15,
                                                        background: '#6AA133',
                                                        borderTopLeftRadius: 10,
                                                        borderTopRightRadius: 10
                                                    }}></div>
                                                <div style={{ height: 15, background: '#FABD2C' }}></div>
                                                <div style={{ height: 15, background: '#F57E00' }}></div>
                                                <div style={{ height: 15, background: '#C63A29' }}></div>
                                                <div style={{ height: 15, background: '#AE1358' }}></div>
                                                <div
                                                    style={{
                                                        height: 15,
                                                        background: '#8C0C51',
                                                        borderBottomLeftRadius: 10,
                                                        borderBottomRightRadius: 10
                                                    }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </UncontrolledTooltip>
                            )}
                    </div>
                </div>
            </div>
        ) : (
            <div style={{ minHeight: 58, margin: '10px 0' }}></div>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme, weatherInfo, airQualityIndex } = app;
    const { authUser } = auth;
    return { theme, weatherInfo, airQualityIndex, authUser };
};

export default injectIntl(connect(mapStateToProps)(WeatherWidget));

const styles = (theme) =>
    StyleSheet.create({
        // currently the background and font colors are being set through inline styles because for some reason the theme object doesn't change when switching themes.
        boxWrapper: {
            width: '100%',
            margin: '10px 0',
            transition: 'all .2s ease-in-out',
            padding: '0 10px',
            borderRadius: 5,
            boxShadow: theme.color.boxShadowCorner,
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-height:800px)': {
                margin: '5px 0',
            }
        },
        wrapper: {
            margin: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 58,
            '@media(max-height:800px)': {
                minHeight: 50,
            }
        },
        temp: {
            fontSize: 22,
            fontFamily: 'Poppins',
            '@media(max-height:800px)': {
                fontSize: 16,
            }
        },
        tempSupport: {
            fontSize: 14,
            fontFamily: 'Poppins',
            '@media(max-height:800px)': {
                fontSize: 11,
            }
        },
        conditions: {
            marginTop: 3,
            fontSize: 11
        },
        airQuality: {
            marginTop: 4,
            borderRadius: 10,
            minHeight: 10,
            minWidth: 25,
            fontSize: 9,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        airQualityTooltip: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px 8px',
            opacity: 1,
            minWidth: 165
        },
        airQualityTitle: {
            fontSize: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            textAlign: 'left'
        },
        airQualityBar: {
            minHeight: 90,
            minWidth: 13,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            margin: '10px 0'
        },
        airQualityArrow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    });
