//||||||||||||||||||||||||||||||||||||||||
//|| ENTRY LIST                         ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ENTRY_LIST = '/site/entries/';
export const PURPOSE_ENTRY_LIST = 'Fetch a list of entry devices.';
export const PERMISSIONS_ENTRY_LIST = [];
export const REQUIREMENTS_ENTRY_LIST = ['Token Required'];
export const PARAMETER_ENTRY_LIST = {
    companyId: 0
};
export const RETURN_ENTRY_LIST = {
    units: [
        {
            User: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            },
            accessType: '',
            companyId: 0,
            details: '',
            detailsDepth: 0,
            detailsPrice: 0,
            detailsWidth: 0,
            externalId: '',
            id: 0,
            jsonType: '',
            lastUpdated: '',
            locks: [
                {
                    bleAppVersion: '',
                    bleHwVersion: '',
                    companyId: 0,
                    gatewayId: 0,
                    gwDelinquent: 0,
                    hwState: '',
                    hwType: '',
                    id: 0,
                    mac: '',
                    name: '',
                    offlineKey: '',
                    siteId: 0,
                    syncFlag: '',
                    time: '',
                    unlockCmd: '',
                    voltageBattery: '',
                    voltageWired: '',
                    wpAppVersion: '',
                    wpOtapVersion: '',
                    wpStackVersion: ''
                }
            ],
            name: '',
            rentalState: '',
            siteId: 0
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| ENTRY EDIT                         ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ENTRY_EDIT = '/entries/edit/';
export const PURPOSE_ENTRY_EDIT = 'Edit entry.';
export const PERMISSIONS_ENTRY_EDIT = [];
export const REQUIREMENTS_ENTRY_EDIT = ['Token Required'];
export const PARAMETER_ENTRY_EDIT = {
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};
export const RETURN_ENTRY_EDIT = {
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};
