import React from 'react'
import { connect } from 'react-redux';
import {StyleSheet, css} from 'aphrodite';
import { CloseIcon } from 'utilities/icons';
import { ModalHeader } from 'components/Modal/new';
import Portal from 'components/Modal/portal';

import { setLoader } from 'actions/App';

class ModalNew extends React.Component {
  constructor (props) {
    super (props); 
    this.state = {
      // enableAnimations is used to prevent opacity transition from starting at 1. weird bug
      enableAnimations: false,
      // open var exists so that both isOpen and show can be used as props
      open: null,
      includesHeader: false,
      includesBody: false,
      includesFooter: false,
      useHeaderToggle: false,
      useTitleProp: false,
    };
  }
  componentDidUpdate () {
    const { isOpen, show, children, toggle, title, setLoader } = this.props;
    const { enableAnimations, open, includesHeader, includesBody, includesFooter, useHeaderToggle, useTitleProp } = this.state;

    if (!enableAnimations && !isOpen) {
      this.setState({enableAnimations: true});
    }
    // set open as either isOpen or show, depending on whatever is used
    const chosenVar = isOpen !== undefined ? isOpen : show !== undefined ? show : null;
    if(chosenVar !== open){
      this.setState({open: chosenVar});
      setLoader(false);
    }
    for (let child in children) {
      const currChild = children[child];
      const displayName = currChild && currChild.type && currChild.type.displayName;
      if (!displayName) continue;

      // children need to know if ModalHeader, ModalBody and ModalFooter are being used
      if (displayName.includes('ModalHeader') && !includesHeader) this.setState({includesHeader: true});
      if (displayName.includes('ModalBody') && !includesBody) this.setState({includesBody: true});
      if (displayName.includes('ModalFooter') && !includesFooter) this.setState({includesFooter: true});

      // if there is a toggle being used and it's not in the header, 
      // remove the toggle prop for the main parent and add it to the ModalHeader
      const headerToggle = currChild && currChild.props && currChild.props.toggle;
      if (toggle && headerToggle && !useHeaderToggle) this.setState({useHeaderToggle: true});

      // if there is a title prop being supplied, replace a potential ModalHeader component with it
      if (!this.modalComponentDetected() && title && !useHeaderToggle && !useTitleProp) this.setState({useHeaderToggle: true, useTitleProp: true});
    }
  }

  modalComponentDetected = () => {
    const { includesHeader, includesBody, includesFooter } = this.state;
    return includesHeader || includesBody || includesFooter;
  }

  toggleClickParentOnly = (e) => {
    const { toggle } = this.props;
    e && e.preventDefault();
    e && e.stopPropagation();
    if (e.target === e.currentTarget) {
      toggle && toggle();
    }
  }

  checkChildren = (child) => {
    const { useTitleProp } = this.state;
    if (!child) return false;
    const displayName = child && child.type && child.type.displayName;

    // dont pass down ModalHeader child if useTitleProp is true
    if (useTitleProp && displayName.includes('ModalHeader') ) return false;
    return true;
  }

  render() {
    const { enableAnimations, open, useHeaderToggle, useTitleProp } = this.state;
    const { toggle, children, title, theme, style, customWidth, customHeight, headerType, clickableBackground, hideClose, needScroll, zindex } = this.props;
    this.styles = styles(theme, open, customWidth, customHeight, enableAnimations, this.modalComponentDetected, useTitleProp, needScroll, zindex)

    return (
      <Portal node={this._element}> {/* Portal transports modal outside of all elements */}
        {/* dont delete data-id attribute, important for some logic in table Row component */}
        <div className={css(this.styles.background)} tabIndex={-1} data-id={'modal'} onClick={(e) => clickableBackground && this.toggleClickParentOnly(e)}>
          <div className={css(this.styles.card)} tabIndex={-1} style={style}>
            {toggle && !useHeaderToggle && !useTitleProp && !hideClose && <div className={css(this.styles.closeIcon)} onClick={toggle}>
              <CloseIcon fill={theme && headerType ? theme.color.buttonFontColor : theme.color.fontColor} width={15} height={15} />
            </div>}
            {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
            {children && children instanceof Array ? 
              children.filter(this.checkChildren)
              .map((child, i) => typeof child === 'object' && React.cloneElement(child, headerType ? {headerType, key: i} : {key: i})) : 
              typeof children === 'object' ? React.cloneElement(children, headerType && {headerType}) : children}
          </div>
        </div>
      </Portal>
    )
  }
}

ModalNew.defaultProps = {
  clickableBackground: true,
  hideClose: false,
  needScroll: false,
  zindex: 1050
};

const mapStateToProps = ({ app }) => {
  const { theme, width } = app;
  return { theme, width };
};

export default connect(mapStateToProps, {setLoader})(ModalNew);

const cardEnter = {
  // was having problem adding animationDelay so i had to do simulate it here
  '0%': { opacity: 0, transform: 'translateY(-20px)' },
  '20%': { opacity: 0, transform: 'translateY(-20px)' },
  '100%': { opacity: 1, transform: 'translateY(0px)' }
};

const styles = (theme,open,customWidth,customHeight,enableAnimations,modalComponentDetected,useTitleProp,needScroll, zindex) => StyleSheet.create({
  background: {
    background: 'rgba(0,0,0,0.5)',
    display: 'grid',
    placeItems: 'center', 
    width: '100vw', 
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: zindex || '1050',
    opacity: open ? 1 : 0,
    pointerEvents: open ? 'auto' : 'none',
    transition: enableAnimations && '.2s opacity ease-in-out',
    '@media(max-width:575px)': {
      placeItems: 'start',
      justifyContent: 'center',
    },
  },
  card: {
    position: 'relative', 
    borderRadius: 13,
    width: customWidth || 750,
    height: customHeight,
    backgroundColor: theme && theme.color.headerColor,
		boxShadow: `3px 3px 8px 1px rgba(0,0,0,0.17)`,
    animationName: open ? [cardEnter] : [],
    animationDuration: '0.6s',
    animationFillMode: 'forwards', 
    padding: (useTitleProp || modalComponentDetected()) ? 0 : '1rem',
    pointerEvents: open ? 'auto' : 'none',

    overflowY: 'auto',
    overflowX: 'hidden',

    // flex styling is the same as reactstrap, this fixes some padding problems
    display: 'flex',
    flexDirection: 'column',

    '@media(max-width:990px)': {
      width: 500,
    },
    '@media(max-width:575px)': {
      width: '96vw',
      marginTop: 10,
    },
  },
  // this close icon differs from the icon on the ModalBody component
  closeIcon: {
    position: 'absolute',
    top: 24,
    right: 24,
    cursor: 'pointer',
    display: 'grid',
    placeItems: 'center',
  }
});
