import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { SupportUserIcon, ActivityIcon, UserIcon } from 'utilities/icons';
import { fetchOnsiteUsers } from 'actions/Users';

import Table from 'components/Table';
import DashboardRecentActivityCell from 'components/Table/cell/containers/dashboard/recentActivity';
import DashboardRecentActivityWidgetCell from 'components/Table/cell/containers/dashboard/recentActivityEditWidgets';
import UsersOnsiteCell from 'components/Table/cell/containers/dashboard/onsiteUsers';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Header from 'components/Header/dashboard';
class DashboardActivityRecent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            view: 'recentActivity'
        };
    }
    componentDidMount() {
        const { editWidgets, fetchOnsiteUsers, visible } = this.props;
        !editWidgets && visible && fetchOnsiteUsers();
    }
    handleActivityAction = (data) => {
        const { showModal } = this.state;
        if (data.event.by && data.event.by.isSupportUser) {
            this.setState({ showModal: !showModal });
        } else if (data.userUUID && data.userUUID != '') {
            this.props.history.push(`/app/users/display/${data.userUUID}`);
        } else if (data.userId && data.userId !== 0) {
            this.props.history.push(`/app/users/display/${data.userId}`);
        } else if (data.event && data.event.unit && data.event.unit.uuid && data.event.unit.uuid !== '') {
            this.props.history.push(`/app/units/display/${data.event.unit.uuid}`);
        } else if (data.event && data.event.unit) {
            this.props.history.push(`/app/units/display/${data.event.unit.id}`);
        } else if (data.event && data.event.lock && data.event.lock.uuid && data.event.lock.uuid !== '') {
            this.props.history.push(`/app/units/lock/${data.event.lock.uuid}`);
        } else if (data.event && data.event.lock && data.event.lock.id) {
            this.props.history.push(`/app/units/lock/${data.event.lock.id}`);
        }
    };
    // change cell type depending on if editWidgets is true or not
    getCellType = () => {
        return this.props.editWidgets ? DashboardRecentActivityWidgetCell : DashboardRecentActivityCell;
    };
    updateView = (view) => {
        this.setState({ view });
    };
    render() {
        const {
            intl,
            theme,
            width,
            activityList,
            onsiteUsers,
            onsiteUsersHeader,
            authUser,
            editWidgets,
            handleDateSelection,
            active
        } = this.props;
        this.styles = styles(theme, width);
        !authUser.permissions.includes('manage_users') && onsiteUsersHeader.pop();
        const { showModal, view } = this.state;
        return (
            <>
                <Modal show={showModal} toggle={() => this.setState({ showModal: !showModal })}>
                    <div className={css(this.styles.iconWrapperModal)}>
                        <SupportUserIcon height={100} width={100} color={theme.color.themeColor} />
                    </div>
                    <div className={css(this.styles.buttonWrapper)}>
                        <p className={css(this.styles.titleModal)}>
                            {intl.formatMessage({ id: 'pages.support.Type' })}{' '}
                            {intl.formatMessage({ id: 'pages.users.Type' })}{' '}
                            {intl.formatMessage({ id: 'pages.activity.Type' })}
                        </p>
                        <p className={css(this.styles.descriptionModal)}>
                            {intl.formatMessage({ id: 'pages.activity.support' })}
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <Button
                                width={140}
                                load={false}
                                action={() => this.setState({ showModal: !showModal })}
                                text={'pages.users.dismissTraining'}
                                margin={'0 0'}
                                padding={'10px 20px'}
                            />
                        </div>
                    </div>
                </Modal>
                <Header
                    handleDateSelection={handleDateSelection}
                    widgetId={2}
                    active={active}
                    activityView={view}
                    editWidgets={editWidgets}
                    updateActivityView={this.updateView}
                    Icon={view === 'usersOnsite' ? UserIcon : ActivityIcon}
                    iconSize={34}
                    title={view === 'usersOnsite' ? 'pages.dashboard.onsiteNow' : 'pages.dashboard.activityRecent'}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <div>
                            {view === 'usersOnsite' ? (
                                <Table
                                    tableHeight={168}
                                    //maxHeight={168}
                                    //removing maxHeight as it adds a scrollbar when the table is empty
                                    border={false}
                                    itemSize={editWidgets ? 70 : 55}
                                    data={onsiteUsers}
                                    action={() => {}}
                                    header={onsiteUsersHeader}
                                    Cell={UsersOnsiteCell}
                                />
                            ) : (
                                <Table
                                    tableHeight={198}
                                    //maxHeight={194}
                                    //removing maxHeight as it adds a scrollbar when the table is empty
                                    border={false}
                                    itemSize={41} // 41 seems to be the sweet spot? even padding
                                    data={activityList && activityList.slice(0, 10)}
                                    action={this.handleActivityAction}
                                    Cell={this.getCellType()}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, activity, users, auth }) => {
    const { theme, width } = app;
    const { activityList } = activity;
    const { onsiteUsers, onsiteUsersHeader } = users;
    const { authUser } = auth;
    return { theme, activityList, width, onsiteUsers, onsiteUsersHeader, authUser };
};

export default withRouter(injectIntl(connect(mapStateToProps, { fetchOnsiteUsers })(DashboardActivityRecent)));

const styles = (theme, width) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 10px 0'
        },
        titleHeader: {
            whiteSpace: 'nowrap',
            fontSize: 13,
            margin: '5px 0 0 0',
            color: theme.color.themeColor,
            opacity: 1,
            transition: 'all .1s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            },
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        iconWrapperModal: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 300,
            margin: '0 auto'
        },
        titleModal: {
            color: theme.color.fontColor,
            textAlign: 'center',
            fontSize: 30,
            margin: '30px 0 20px 0'
        },
        descriptionModal: {
            color: theme.color.fontColor,
            textAlign: 'center',
            fontSize: 16
        },
        buttonWrapper: {
            display: 'block',
            width: 300,
            margin: '0 auto'
        },
        footerRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 5,
            fontSize: 12,
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerRowShort: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 5,
            fontSize: 12,
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerRowEdit: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: 10,
            '@media(max-width:1384px)': {
                fontSize: 9
            }
        },
        footerItemActive: {
            color: 'white',
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.themeColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        footerItem: {
            color: theme.color.fontColor,
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.pageColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        icon: {
            fontSize: width > 1300 ? 24 : 20,
            margin: '0 5px 0 0',
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        }
    });
