import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as ICONS from 'utilities/icons';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_GATEWAY from 'actions/Gateways';

function* requestGatewayDetails({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY.setGatewayDetails(null));
        let gateways = yield call(API.POST, payload.url, payload.data);
        if (!gateways.data) {
            yield history.goBack();
        } else {
            let gateway = gateways.data.find((grab) => grab.uuid === payload.gatewayUUID);
            if (!gateway) {
                yield put(ACTIONS_APP.showMessage(`notifications.error.fail.notSiteGateway`));
                yield history.goBack();
            } else {
                gateway.Icon = ICONS.GatewayIcon;
                yield put(ACTIONS_GATEWAY.setGatewayDetails(gateway));
            }
        }
    } catch (error) {
        yield put(ACTIONS_GATEWAY.setGatewayDetails(null));
        console.warn(error);
    }
}

function* requestGatewayImage({ payload }) {
    try {
        yield put(ACTIONS_GATEWAY.setGatewayImage(null));
        let gateway = yield call(API.POST, payload.url, payload.data);
        if (!gateway.data) {
            return;
        } else {
            if (!gateway) {
                return;
            } else {
                let frontendVersion = sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : 'default';
                let gatewayImage = gateway.data.imageURL
                        ? gateway.data.imageURL !== ''
                            ? `${gateway.data.imageURL}?t=${new Date().getTime()}&v=${frontendVersion}`
                            : gateway.data.imageURL
                        : ''
                yield put(ACTIONS_GATEWAY.setGatewayImage(gatewayImage));
            }
        }
    } catch (error) {
        yield put(ACTIONS_GATEWAY.setGatewayImage(null));
        console.warn(error);
    }
}

function* requestGatewayEdit({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectEditGateway() {
    yield takeEvery(CONSTANTS.EDIT_GATEWAY, requestGatewayEdit);
}
export function* selectFetchGatewayDetails() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_DETAILS, requestGatewayDetails);
}
export function* selectFetchGatewayImage() {
    yield takeEvery(CONSTANTS.FETCH_GATEWAY_IMAGE, requestGatewayImage);
}

export default function* rootSaga() {
    yield all([fork(selectEditGateway), fork(selectFetchGatewayDetails), fork(selectFetchGatewayImage)]);
}
