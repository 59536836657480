import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { removeUserNotificationsAll, fetchUserNotifications } from 'actions/Notifications';

import { NotifyIcon } from 'utilities/icons';
import Table from 'components/Table';
import NotificationCell from 'components/Table/cell/containers/users/notifications';
import NotificationEditWidgetCell from 'components/Table/cell/containers/users/notificationsEditWidget';
import Header from 'components/Header/dashboard';
class DashboardNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1
        };
    }
    componentDidMount() {
        const { editWidgets, visible, fetchUserNotifications } = this.props;
        (editWidgets || visible) && fetchUserNotifications();
    }
    linkNotifications = () => {
        this.props.history.push('/app/notifications');
    };
    clearNotifications = () => {
        const { removeUserNotificationsAll } = this.props;
        removeUserNotificationsAll();
    };
    fetchMore = () => {
        const { fetchUserNotifications } = this.props;
        const { page, limit } = this.state;
        fetchUserNotifications(page + 1, limit);
        this.setState({ page: page + 1 });
    };
    // change cell type depending on if editWidgets is true or not
    getCellType = () => {
        return this.props.editWidgets ? NotificationEditWidgetCell : NotificationCell;
    };
    getItemSize = () => {
        return this.props.editWidgets ? 54 : 40;
    };
    render() {
        const { theme, userNotifications } = this.props;
        this.styles = styles(theme);
        return (
            <>
                <Header
                    Icon={NotifyIcon}
                    iconSize={30}
                    title={'comp.button.notifications'}
                    manage={[
                        {
                            icon: 'clear',
                            color: theme.color.buttonFontColor,
                            text: 'pages.dashboard.clearAll',
                            action: () => this.clearNotifications(),
                            id: 'notifications-clear'
                        },
                        {
                            icon: 'view_list',
                            color: theme.color.buttonFontColor,
                            text: 'pages.dashboard.all',
                            action: () => this.linkNotifications(),
                            id: 'notifications-view_list'
                        }
                    ]}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <Table
                            tableHeight={108}
                            border={false}
                            data={userNotifications}
                            Cell={this.getCellType()}
                            itemSize={this.getItemSize()}
                            moreItemsLoading={false}
                            loadMore={this.fetchMore}
                            hasNextPage={true}
                            force={true}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, notifications }) => {
    const { theme, width } = app;
    const { userNotifications } = notifications;
    return { theme, width, userNotifications };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { removeUserNotificationsAll, fetchUserNotifications })(DashboardNotifications))
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            fontSize: 20,
            margin: '0 0 5px 0'
        },
        titleHeader: {
            fontSize: 12,
            margin: '0 8px 5px 0',
            color: theme.color.themeColor,
            opacity: 1,
            transition: 'all .1s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            },
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        }
    });
