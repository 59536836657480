import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const createRelease = (data) => {
    return {
        type: CONSTANTS.CREATE_RELEASE,
        payload: {
            url: ENDPOINTS.ENDPOINT_CREATE_RELEASE,
            data: data
        }
    };
};

export const fetchReleases = () => {
    return {
        type: CONSTANTS.FETCH_RELEASES,
        payload: {
            url: ENDPOINTS.ENDPOINT_RELEASE_LIST,
            data: {}
        }
    };
};

export const setReleases = (data) => {
    return {
        type: CONSTANTS.SET_RELEASES,
        payload: data
    };
};

export const updateRelease = (data) => {
    return {
        type: CONSTANTS.UPDATE_RELEASE,
        payload: {
            url: ENDPOINTS.ENDPOINT_UPDATE_RELEASE,
            data: { data }
        }
    };
};

export const deleteRelease = (id = 0) => {
    return {
        type: CONSTANTS.DELETE_RELEASE,
        payload: {
            url: ENDPOINTS.ENDPOINT_DELETE_RELEASE,
            data: {
                id
            }
        }
    };
};

export const searchReleases = () => {
    return {
        type: CONSTANTS.SEARCH_RELEASES,
        payload: {
            url: ENDPOINTS.ENDPOINT_RELEASE_SEARCHED_LIST,
            data: {}
        }
    };
};

export const setSearchedReleases = (data) => {
    return {
        type: CONSTANTS.SET_SEARCHED_RELEASES,
        payload: data
    };
};

export const setReleaseFilters = (data) => {
    return {
        type: CONSTANTS.SET_RELEASE_FILTERS,
        payload: data
    };
};
