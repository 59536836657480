//||||||||||||||||||||||||||||||||||||||||
//|| SITE SWITCH                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SITE_SWITCH = '/site/switch/';
export const PURPOSE_SITE_SWITCH = 'Switch from one site to another.';
export const PERMISSIONS_SITE_SWITCH = [];
export const REQUIREMENTS_SITE_SWITCH = ['Token Required'];
export const PARAMETER_SITE_SWITCH = {
    companyId: 0,
    siteId: 0
};
export const RETURN_SITE_SWITCH = {
    companyId: 0,
    defaultSiteId: 0,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    permissions: [],
    phone: '',
    resetPassFlag: false,
    sites: [
        {
            CompanyId: 0,
            assignedUserId: 0,
            city: '',
            closeTime: '',
            country: '',
            id: 0,
            last_sync_date: '',
            latitude: '',
            longitude: '',
            mainUrl: '',
            name: '',
            openTime: '',
            paymentUrl: '',
            phone: '',
            pms: '',
            postalCode: '',
            state: '',
            streetAddress: '',
            suite: '',
            syncId: '',
            timeZone: ''
        }
    ],
    state: '',
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| SITE SYNC                          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SITE_SYNC = '/site/sync/';
export const PURPOSE_SITE_SYNC = 'Sync site with rentable managment system.';
export const PERMISSIONS_SITE_SYNC = [];
export const REQUIREMENTS_SITE_SYNC = ['Token Required'];
export const PARAMETER_SITE_SYNC = {
    companyId: 0
};
export const RETURN_SITE_SYNC = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| SITE SYNC LOGS                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNKNOWN_SITE_SYNC_LOGS = '/site/sync/logs/';
export const PURPOSE_UNKNOWN_SITE_SYNC_LOGS = 'Sync site logs and fetch new logs.';
export const PERMISSIONS_UNKNOWN_SITE_SYNC_LOGS = [];
export const REQUIREMENTS_UNKNOWN_SITE_SYNC_LOGS = ['Token Required'];
export const PARAMETER_UNKNOWN_SITE_SYNC_LOGS = {
    companyId: 0
};
export const RETURN_UNKNOWN_SITE_SYNC_LOGS = {
    data: [],
    pageCount: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| SITE HOURS                         ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SITE_HOURS = '/site/hours/';
export const PURPOSE_SITE_HOURS = 'Edit site hours.';
export const PERMISSIONS_SITE_HOURS = [];
export const REQUIREMENTS_SITE_HOURS = ['Token Required'];
export const PARAMETER_SITE_HOURS = {
    companyId: 0,
    open: '',
    close: ''
};
export const RETURN_SITE_HOURS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| IMAGE UPLOAD                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_IMAGE_UPLOAD = '/company/image/';
export const PURPOSE_IMAGE_UPLOAD = 'Upload site image.';
export const PERMISSIONS_IMAGE_UPLOAD = [];
export const REQUIREMENTS_IMAGE_UPLOAD = ['Token Required'];
export const PARAMETER_IMAGE_UPLOAD = {
    site: '',
    logo: ''
};
export const RETURN_IMAGE_UPLOAD = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| SITE EDIT                          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SITE_EDIT = '/site/setup/';
export const PURPOSE_SITE_EDIT = 'Edit site settings.';
export const PERMISSIONS_SITE_EDIT = [];
export const REQUIREMENTS_SITE_EDIT = ['Token Required'];
export const PARAMETER_SITE_EDIT = {
    CompanyId: 0,
    assignedUserId: 0,
    city: '',
    closeTime: '',
    country: '',
    id: 0,
    last_sync_date: '',
    latitude: '',
    longitude: '',
    mainUrl: '',
    name: '',
    openTime: '',
    paymentUrl: '',
    phone: '',
    pms: '',
    postalCode: '',
    state: '',
    streetAddress: '',
    suite: '',
    syncId: '',
    timeZone: '',
    preferredAppColor: ''
};
export const RETURN_SITE_EDIT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| FETCH SITE HOURS                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FETCH_SITE_HOURS = '/site/hours/suite/read/';
export const PURPOSE_FETCH_SITE_HOURS = 'Fetch Site Hours.';
export const PERMISSIONS_FETCH_SITE_HOURS = [];
export const REQUIREMENTS_FETCH_SITE_HOURS = ['Token Required'];
export const PARAMETER_FETCH_SITE_HOURS = {};
export const RETURN_FETCH_SITE_HOURS = {
    result: 'success'
};
