import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import {
    fetchSharedUnits,
    setSharedUnitsHeaders 
} from 'actions/Units';

import { UnitIcon } from 'utilities/icons';

import Table from 'components/Table';
import SharedUnitsCell from 'components/Table/cell/containers/dashboard/sharedUnits';
import Header from 'components/Header/dashboard';
class DashboardSharedUnits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sharedFilterList: null
        };
    }
    componentDidMount() {
        const { fetchSharedUnits, editWidgets, visible } = this.props;
        (editWidgets || visible) && fetchSharedUnits();
    }
    sharedUnitsSearch = (sharedFilterList) => {
        this.setState({ sharedFilterList });
    };
    render() {
        const { sharedUnits, editWidgets, syncUnitsHeaders, sharedUnitsHeaders, setSharedUnitsHeaders  } = this.props;
        const { sharedFilterList } = this.state;
        return (
            <>
                <Header
                    sharedView={'sharedUnits'}
                    Icon={UnitIcon}
                    iconSize={26}
                    title={'pages.units.shared'}
                    subtitle={sharedUnits && sharedUnits.length}
                    editWidgets={editWidgets}
                    syncUnitsHeaders={syncUnitsHeaders}
                    sharedUnits={sharedUnits}
                    sharedUnitsHeaders={sharedUnitsHeaders}
                    sharedUnitsSearch={this.sharedUnitsSearch}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <Table
                            tableHeight={185}
                            data={sharedFilterList ? sharedFilterList : sharedUnits}
                            header={sharedUnitsHeaders}
                            headerAction={setSharedUnitsHeaders}
                            Cell={SharedUnitsCell}
                            moreItemsLoading={false}
                            itemSize={editWidgets ? 50 : 24}
                            hasNextPage={false}
                            force={true}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, units }) => {
    const { theme, width } = app;
    const { sharedUnits, sharedUnitsHeaders } = units;
    return { theme, width, sharedUnits, sharedUnitsHeaders };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, {
        fetchSharedUnits,
        setSharedUnitsHeaders 
    })(DashboardSharedUnits))
);
