import { ENV } from 'constants';

const domain = window.location.href;

//||||||||||||||||||||||||||||||||||||||||
//|| URLS                               ||
//||||||||||||||||||||||||||||||||||||||||
export const URL = domain.includes('canary')
    ? `https://router.smartentry.noke.com`
    : `https://extraspace${ENV}-go.appspot.com`;
export const URL_TEMP = 'https://manual-codes-dot-extraspace-dev-go.appspot.com';
export const URL_DEV = `https://router.smartentry.noke.dev`;
export const URL_BACKEND = `https://router.smartentry.noke.com`;
export const URL_BACKEND_DEV = `https://router.smartentry.noke.dev`;
export const URL_CANARY = `https://canary-dot-extraspace-go.appspot.com`;
export const URL_RUN = `https://router.smartentry.noke.run`;
export const URL_LIVE = `https://router.smartentry.noke.com`;
export const URL_REPORTS = 'http://nate.noke/securguard';
export const URL_IP_LOCATION = 'http://ip-api.com/json';
export const URL_ADAM = 'https://adam-support-companies-dot-extraspace-dev-go.appspot.com';
export const URL_GATEWAY_KEEPER = `https://gateway-keeper-dot-extraspace${ENV}-go.appspot.com`;
export const URL_GATEWAY_KEEPER_DEV = `https://gateway-keeper-dot-extraspace-dev-go.appspot.com`;
export const URL_GATEWAY_KEEPER_PROD = `https://gateway-keeper-dot-extraspace-go.appspot.com`;

//||||||||||||||||||||||||||||||||||||||||
//|| API KEYS                           ||
//||||||||||||||||||||||||||||||||||||||||
export const API_GATEWAY_KEEPER =
    ENV === '-dev' ? '5cd072cf-fc6a-4692-ae7c-09e3dcf7ab9d' : '01e0410e-7380-4adc-a17a-d6d60c56cdf3';
export const API_GATEWAY_KEEPER_DEV = '5cd072cf-fc6a-4692-ae7c-09e3dcf7ab9d';
export const API_GATEWAY_KEEPER_PROD = '01e0410e-7380-4adc-a17a-d6d60c56cdf3';

//||||||||||||||||||||||||||||||||||||||||
//|| WEBSITES                           ||
//||||||||||||||||||||||||||||||||||||||||
export const WEBSITE_CANARY = 'https://canary.smartentry.noke.com/';
export const WEBSITE_DEV = 'https://dev.ess.nokepro.com/';
export const WEBSITE_LIVE = 'https://janus.nokepro.com';

//||||||||||||||||||||||||||||||||||||||||
//|| THEME                              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_THEME = '/company/skin/';
export const PURPOSE_THEME = 'Fetch a theme object (logos, colors, urls) for selected company.';
export const PERMISSIONS_THEME = [];
export const REQUIREMENTS_THEME = ['Token Required'];
export const PARAMETER_THEME = {
    companyId: 0
};
export const RETURN_THEME = {
    AppName: '',
    BackgroundColor: '',
    BgImage: '',
    ButtonTextColor: '',
    CompanyId: 0,
    FavoriteCountry: '',
    Id: 0,
    InactiveColor: '',
    MainColor: '',
    MainLogoImage: '',
    ManualColor: '',
    PlaceholderTextColor: '',
    RentedColor: '',
    ShortAppName: '',
    SiteImage: '',
    SupportUrl: '',
    TextColor: ''
};
