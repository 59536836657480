import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import Confirm from './routes/Login';

const AuthTemplate = React.lazy(() => import('components/Auth'));
const Password = React.lazy(() => import('app/routes/Auth/routes/Password'));
const Forgot = React.lazy(() => import('app/routes/Auth/routes/Forgot'));
const Pin = React.lazy(() => import('app/routes/Auth/routes/Pin'));
const InitialLogin = React.lazy(() => import('app/routes/Auth/routes/InitialLogin'));
class Auth extends React.Component {
    render() {
        const { match, authUser, theme } = this.props;
        return (
            <Suspense fallback={<Spinner fallback={true} />}>
                <AuthTemplate theme={theme}>
                    <Switch>
                        {authUser && authUser.state === 'update_required' && (
                            <Route path={`${match.url}/password`} component={Password} />
                        )}
                        <Route path={`${match.url}/forgot`} component={Forgot} />
                        <Route path={`${match.url}/confirm`} component={Confirm} />
                        <Route path={`${match.url}/pin`} component={Pin} />
                        <Route path={`${match.url}/:id`} component={InitialLogin} />
                        <Route component={InitialLogin} />
                    </Switch>
                </AuthTemplate>
            </Suspense>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { authUser } = auth;
    const { theme } = app;
    return { authUser, theme };
};

export default connect(mapStateToProps)(Auth);
