import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import {
    fetchAllUnitNotes,
    deleteUnitNote,
    uploadUnitTaskImage,
    deleteUnitTaskImage,
    fetchUnitTaskComments,
    createUnitTaskComment,
    editUnitTaskComment,
    deleteUnitTaskComment,
    setUnitsTasksHeaders,
    setUnitsTasksCommentsHeaders
} from 'actions/Units';
import { showMessage } from 'actions/App';

import * as UTILS from 'utilities';
import { FileIcon, UnitIcon } from 'utilities/icons';
import Table from 'components/Table';
import NotesCell from 'components/Table/cell/containers/units/notes';
import CommentsCell from 'components/Table/cell/containers/units/noteCommentsCell';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import ToolTips from 'components/ToolTips';
import Header from 'components/Header/dashboard';
import TextAreaInput from 'components/Inputs/textarea';
import FileUpload from 'components/FileUpload';

class DashboardUnitNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNoteModal: false,
            currentNote: null,
            uploadImage: false,
            removeImage: false,
            previewImg: '',
            imageFile: '',
            safeCheckImage: true,
            showAddDropdown: false,
            newComment: '',
            currentComment: null,
            changesMadeComment: false,
        };
    }
    componentDidMount() {
        const { fetchAllUnitNotes, authUser, editWidgets, visible } = this.props;
        (editWidgets || visible) && authUser && authUser.permissions.includes('view_tasks') && fetchAllUnitNotes();
    }
    handleNoteAction = (data) => {
        const { fetchUnitTaskComments } = this.props;
        fetchUnitTaskComments(data.noteUUID);
        this.setState({ showNoteModal: true, currentNote: data });
    };
    handleCommentAction = (data) => {
        this.setState({ currentComment: data });
    };
    handleDelete = () => {
        const { currentNote } = this.state;
        const { deleteUnitNote } = this.props;
        deleteUnitNote(currentNote.noteUUID);
        this.setState({ showNoteModal: false, currentNote: null, uploadImage: false });
    };
    handleImageFileUpload = (e) => {
        let reader = new FileReader();
        var file = e.target.files[0];
        if (!file) {
            this.props.showMessage(`errorCodes.description.2`, 'warning');
        } else {
            if (file.size > 500000) {
                this.props.showMessage(`notifications.error.fail.fileSize`, 'warning');
            } else {
                reader.onloadend = () => {
                    this.setState({ previewImg: reader.result });
                };
                reader.readAsDataURL(file);
                this.setState({ imageFile: file });
            }
        }
    };
    uploadImage = (files) => {
        const { uploadUnitTaskImage, site } = this.props;
        const { currentNote } = this.state;
        if (!files || !files[0]) {
            this.props.showMessage(`notifications.error.fail.noImage`, 'warning');
        } else {
            const img = files && files[0];
            uploadUnitTaskImage({
                Image: img,
                UnitNoteUUID: currentNote.noteUUID,
                SiteUUID: site.uuid
            });
            this.setState({
                showNoteModal: false,
                currentNote: null,
                uploadImage: false,
                previewImg: '',
                imageFile: '',
                safeCheckImage: true
            });
        }
    };
    deleteImage = () => {
        const { deleteUnitTaskImage } = this.props;
        const { currentNote } = this.state;
        deleteUnitTaskImage({ unitNoteUUID: currentNote.noteUUID });
        this.setState({
            showNoteModal: false,
            currentNote: null,
            uploadImage: false,
            removeImage: false,
            previewImg: '',
            imageFile: '',
            safeCheckImage: true
        });
    };
    toggleAddComment = () => {
        const { showAddDropdown } = this.state;
        this.setState({ showAddDropdown: !showAddDropdown });
    };
    addComment = () => {
        const { newComment, currentNote } = this.state;
        const { createUnitTaskComment } = this.props;
        createUnitTaskComment({ noteUUID: currentNote.noteUUID, comment: newComment });
        this.toggleAddComment();
        this.setState({ newComment: '' });
    };
    editComment = (data) => {
        let { currentComment } = this.state;
        currentComment.comment = data;
        this.setState({ currentComment, changesMadeComment: true });
    };
    saveEditComment = () => {
        const { editUnitTaskComment } = this.props;
        const { currentComment } = this.state;
        editUnitTaskComment({
            id: currentComment.id,
            noteUUID: currentComment.noteUUID,
            comment: currentComment.comment
        });
        this.setState({ currentComment: null });
    };
    deleteComment = () => {
        const { deleteUnitTaskComment } = this.props;
        const { currentComment } = this.state;
        deleteUnitTaskComment({ id: currentComment.id, noteUUID: currentComment.noteUUID });
        this.setState({ currentComment: null });
    };
    render() {
        let now = moment().utc().format();
        let { 
            intl, 
            theme, 
            authUser, 
            site, 
            allUnitNotes, 
            unitTaskComments, 
            width, 
            notesHeaders, 
            commentsHeaders,
            setUnitsTasksHeaders,
            setUnitsTasksCommentsHeaders
        } = this.props;
        const {
            showNoteModal,
            currentNote,
            uploadImage,
            removeImage,
            showAddDropdown,
            currentComment,
            changesMadeComment,
            newComment
        } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <Modal
                    show={showNoteModal}
                    title={
                        <div className={css(this.styles.iconWrapperModalNote)}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                {currentNote && currentNote.imageURL ? (
                                    <Avatar
                                        image={currentNote.imageURL}
                                        name={currentNote.heading}
                                        height={60}
                                        width={60}
                                    />
                                ) : (
                                    <FileIcon height={28} width={28} color={theme.color.themeColor} />
                                )}
                                <p className={css(this.styles.titleModalNote)}>
                                    {currentNote && currentNote.heading}
                                </p>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                {authUser &&
                                    authUser.permissions.includes('manage_tasks') &&
                                    currentNote &&
                                    currentNote.noteUUID &&
                                    currentNote.imageURL &&
                                    currentNote.imageURL.length > 0 && (
                                        <div
                                            onClick={() => this.setState({ removeImage: true })}
                                            className={css(this.styles.noteEditIcon)}>
                                            <i
                                                id="removeTaskImage"
                                                className="material-icons"
                                                style={{
                                                    fontSize: 22,
                                                    marginTop: 2,
                                                    color: '#F60F0F'
                                                }}>
                                                image_not_supported
                                            </i>
                                            <ToolTips target={'removeTaskImage'} message={'form.removeImageUnitTask'} />
                                        </div>
                                    )}
                                {authUser &&
                                    authUser.permissions.includes('manage_tasks') &&
                                    currentNote &&
                                    !currentNote.imageURL && (
                                        <div
                                            onClick={() => this.setState({ uploadImage: true })}
                                            className={css(this.styles.noteEditIcon)}>
                                            <i id="addTaskImage" className="material-icons" style={{ fontSize: 25 }}>
                                                add_photo_alternate
                                            </i>
                                            <ToolTips target={'addTaskImage'} message={'form.addImageUnitTask'} />
                                        </div>
                                    )}
                                {authUser &&
                                    authUser.permissions.includes('manage_tasks') &&
                                    currentNote &&
                                    !currentNote.createdByTenant &&
                                    currentNote.unitUUID &&
                                    currentNote.unitUUID > 0 && (
                                        <div
                                            onClick={() =>
                                                this.props.history.push(
                                                    `/app/units/task/update/${currentNote.unitUUID}/${currentNote.noteUUID}`
                                                )
                                            }
                                            className={css(this.styles.noteEditIcon)}>
                                            <i id="editTaskIcon" className="material-icons" style={{ fontSize: 25 }}>
                                                edit
                                            </i>
                                            <ToolTips target={'editTaskIcon'} message={'form.editTask'} />
                                        </div>
                                    )}
                            </div>
                        </div>
                    }
                    toggle={() =>
                        this.setState({
                            showNoteModal: !showNoteModal,
                            currentNote: null,
                            uploadImage: false,
                            removeImage: false,
                            safeCheckImage: true
                        })
                    }>
                    {uploadImage ? (
                        <div className={css(this.styles.buttonWrapperNote)}>
                            <FileUpload
                                onSubmit={this.uploadImage} 
                                onCancel={() => {
                                    this.setState({
                                        uploadImage: false,
                                        previewImg: '',
                                        imageFile: '',
                                        safeCheckImage: true
                                    })
                                }}
                                showActions={true} />
                        </div>
                    ) : removeImage ? (
                        <div className={css(this.styles.buttonWrapperNote)}>
                            <div className={css(this.styles.noteImageModalWrapper)}>
                                <div>
                                    <h2 style={{ color: theme.color.fontColor, font: 'roboto' }}>
                                        {intl.formatMessage({ id: 'pages.photos.confirmDelete' })}
                                    </h2>
                                    {currentNote && currentNote.imageURL && (
                                        <img
                                            style={{ maxWidth: 500, maxHeight: 200 }}
                                            src={currentNote.imageURL}
                                            alt={currentNote.heading}
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: 40
                                }}>
                                <Button
                                    width={140}
                                    load={false}
                                    action={() =>
                                        this.setState({
                                            removeImage: false,
                                            previewImg: '',
                                            imageFile: '',
                                            safeCheckImage: true
                                        })
                                    }
                                    text={'comp.button.cancel'}
                                    margin={'0'}
                                    padding={'10px 20px'}
                                />
                                {authUser && authUser.permissions.includes('manage_tasks') && (
                                    <Button
                                        width={140}
                                        load={false}
                                        color={'#F60F0F'}
                                        action={() => this.deleteImage()}
                                        text={'db.delete'}
                                        margin={'0'}
                                        padding={'10px 20px'}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={css(this.styles.buttonWrapperNote)}>
                            <div className={css(this.styles.noteModalWrapper)}>
                                <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => this.props.history.push(`/app/units/display/${currentNote.unitUUID}`)}>
                                    <div className={css(this.styles.unitNameWrapper)}>
                                        <UnitIcon color={theme.color.buttonFontColor} height={22} width={22} />
                                        <p className={css(this.styles.descriptionModalUnit)}>
                                            {currentNote && currentNote.unitName}
                                        </p>
                                    </div>
                                </div>
                                <p className={css(this.styles.descriptionModalLabel)}>
                                    {intl.formatMessage({ id: 'comp.table.header.description' })}
                                </p>
                                <p className={css(this.styles.descriptionModalNote)}>
                                    {currentNote && currentNote.description}
                                </p>
                                <div className={css(this.styles.descriptionModalNoteRow)}>
                                    <div style={{ width: '50%' }}>
                                        <p className={css(this.styles.descriptionModalLabel)}>
                                            {intl.formatMessage({ id: 'pages.notes.assignedUser' })}
                                        </p>
                                        <p className={css(this.styles.descriptionModalNote)}>
                                            {currentNote && currentNote.assignedUser && currentNote.assignedUser !== ''
                                                ? currentNote.assignedUser
                                                : intl.formatMessage({ id: 'form.na' })}
                                        </p>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <p className={css(this.styles.descriptionModalLabel)}>
                                            {intl.formatMessage({ id: 'pages.notes.dueDate' })} {currentNote && currentNote.dueDate && moment(now).isAfter(currentNote.dueDate) && <span className={css(this.styles.overdueLabel)}>({intl.formatMessage({ id: 'pages.units.unitTasksOverdue' })})</span>}
                                        </p>
                                        {currentNote && currentNote.dueDate && <p className={css(this.styles.descriptionModalNote)}>
                                            {moment(now).isAfter(currentNote.dueDate) && <div className={css(this.styles.overdue)}>!</div>}{UTILS.dateFormatter(currentNote.dueDate, authUser, site)}
                                        </p>}
                                    </div>
                                </div>
                                <div className={css(this.styles.descriptionModalNoteRow)}>
                                    <div style={{ width: '50%' }}>
                                        <p className={css(this.styles.descriptionModalLabel)}>
                                            {intl.formatMessage({ id: 'form.createdBy' })}
                                        </p>
                                        <p className={css(this.styles.descriptionModalNote)}>
                                            {currentNote &&
                                            currentNote.createdByName &&
                                            currentNote.createdByName !== ''
                                                ? currentNote.createdByName
                                                : currentNote && currentNote.createdByAutomation
                                                ? intl.formatMessage({
                                                      id: 'pages.automation.head'
                                                  })
                                                : intl.formatMessage({ id: 'form.na' })}
                                        </p>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <p className={css(this.styles.descriptionModalLabel)}>
                                            {intl.formatMessage({ id: 'form.createdDate' })}
                                        </p>
                                        {currentNote && currentNote.createdDate && <p className={css(this.styles.descriptionModalNote)}>
                                            {UTILS.dateFormatter(currentNote.createdDate, authUser, site)}
                                        </p>}
                                    </div>
                                </div>
                                {authUser && authUser.permissions && authUser.permissions.includes('manage_tasks') && (
                                    <div className={css(this.styles.commentsRow)}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                height: 28,
                                                width: '100%'
                                            }}>
                                            <h1 className={css(this.styles.headerComments)}>
                                                {intl.formatMessage({
                                                    id: 'form.comments'
                                                }) || <Skeleton />}
                                            </h1>
                                            {width > 500 && (
                                                <Dropdown
                                                    direction={'down'}
                                                    isOpen={showAddDropdown}
                                                    toggle={() => this.toggleAddComment()}>
                                                    <div id="addCommentIcon" className={css(this.styles.commentsAdd)}>
                                                        {!currentComment ? (
                                                            <DropdownToggle
                                                                style={{
                                                                    backgroundColor: theme.color.compColor,
                                                                    border: 'none'
                                                                }}>
                                                                <i
                                                                    className="material-icons"
                                                                    style={{
                                                                        fontSize: 24,
                                                                        color: theme.color.themeColor
                                                                    }}>
                                                                    add_circle_outline
                                                                </i>
                                                            </DropdownToggle>
                                                        ) : null}
                                                    </div>
                                                    {!currentComment ? (
                                                        <ToolTips
                                                            target={'addCommentIcon'}
                                                            message={'form.commentsAdd'}
                                                        />
                                                    ) : null}
                                                    <DropdownMenu right className={css(this.styles.commentAddWrapper)}>
                                                        <TextAreaInput
                                                            title={intl.formatMessage({ id: 'form.commentsAdd' })}
                                                            name="newComment"
                                                            value={newComment}
                                                            placeholder={intl.formatMessage({ id: 'form.comment' })}
                                                            className={css(this.styles.input)}
                                                            onChange={(e) =>
                                                                this.setState({ newComment: e.target.value })
                                                            }
                                                        />
                                                        <Button
                                                            width={140}
                                                            load={false}
                                                            action={() => this.addComment()}
                                                            text={'comp.button.add'}
                                                            margin={'0'}
                                                            float={'right'}
                                                            padding={'10px 20px'}
                                                        />
                                                    </DropdownMenu>
                                                </Dropdown>
                                            )}
                                        </div>
                                        {currentComment ? (
                                            <div style={{ height: 179, paddingTop: 10, width: '100%' }}>
                                                {/* <Label className={css(this.styles.label)}>{ intl.formatMessage({ id:"form.commentsEdit" }) }</Label> */}
                                                <TextAreaInput
                                                    name="comment"
                                                    value={currentComment.comment}
                                                    placeholder={intl.formatMessage({ id: 'form.comment' })}
                                                    className={css(this.styles.inputEdit)}
                                                    onChange={(e) => this.editComment(e.target.value)}
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        marginTop: 40,
                                                        justifyContent: 'space-between'
                                                    }}>
                                                    <Button
                                                        width={width > 900 && 140}
                                                        load={false}
                                                        action={() =>
                                                            this.setState({
                                                                currentComment: null
                                                            })
                                                        }
                                                        text={'comp.button.cancel'}
                                                        margin={'0'}
                                                        padding={'10px 20px'}
                                                    />
                                                    <Button
                                                        width={width > 900 && 140}
                                                        load={false}
                                                        action={() => this.deleteComment()}
                                                        text={
                                                            width > 800 ? 'form.commentsDelete' : 'comp.button.delete'
                                                        }
                                                        color={'#F60F0F'}
                                                        margin={'0'}
                                                        padding={'10px 20px'}
                                                    />
                                                    <Button
                                                        width={width > 900 && 140}
                                                        load={false}
                                                        disabled={!changesMadeComment}
                                                        action={() => this.saveEditComment()}
                                                        text={
                                                            width > 800 ? 'comp.button.saveChanges' : 'comp.button.save'
                                                        }
                                                        margin={'0'}
                                                        padding={'10px 20px'}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <Table
                                                tableHeight={150}
                                                data={unitTaskComments}
                                                header={commentsHeaders}
                                                headerAction={setUnitsTasksCommentsHeaders}
                                                action={(e) =>
                                                    e.userID === authUser.id ? this.handleCommentAction(e) : null
                                                }
                                                Cell={CommentsCell}
                                                itemSize={width > 800 ? 38 : 20}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            {!currentComment && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 40
                                    }}>
                                    <Button
                                        width={width > 800 && 140}
                                        load={false}
                                        action={() =>
                                            this.setState({
                                                showNoteModal: !showNoteModal,
                                                currentNote: null,
                                                uploadImage: false
                                            })
                                        }
                                        text={'pages.users.dismissTraining'}
                                        margin={'0'}
                                        padding={'10px 20px'}
                                    />
                                    {authUser && authUser.permissions.includes('manage_tasks') && (
                                        <Button
                                            width={width > 800 && 140}
                                            load={false}
                                            action={() => this.handleDelete(currentNote)}
                                            text={'pages.notes.complete'}
                                            margin={'0'}
                                            padding={'10px 20px'}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </Modal>
                <Header Icon={FileIcon} iconSize={26} title={'pages.units.unitTasks'} />
                <Row style={{ margin: '0 0 0 0', padding: '0 20px 20px 20px' }}>
                    <Table
                        tableHeight={150}
                        data={allUnitNotes}
                        action={(e) => this.handleNoteAction(e)}
                        header={notesHeaders}
                        headerAction={setUnitsTasksHeaders}
                        Cell={NotesCell}
                        itemSize={47}
                    />
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, units }) => {
    const { theme, width, userTimezone } = app;
    const { authUser, site } = auth;
    const { allUnitNotes, unitTaskComments, notesHeaders, commentsHeaders } = units;
    return { theme, authUser, site, width, allUnitNotes, userTimezone, unitTaskComments, notesHeaders, commentsHeaders };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, {
            fetchAllUnitNotes,
            deleteUnitNote,
            uploadUnitTaskImage,
            deleteUnitTaskImage,
            showMessage,
            fetchUnitTaskComments,
            createUnitTaskComment,
            editUnitTaskComment,
            deleteUnitTaskComment,
            setUnitsTasksHeaders,
            setUnitsTasksCommentsHeaders
        })(DashboardUnitNotes)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 5,
            fontSize: 20
        },
        headerComments: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0',
            fontSize: 16,
            fontFamily: 'Roboto'
        },
        descriptionModalNote: {
            color: theme.color.fontColor,
            fontSize: 14,
            position: 'relative',
        },
        iconWrapperModalNote: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        buttonWrapperNote: {
            display: 'block',
            padding: 25,
            margin: '0 auto',
            minHeight: 482
        },
        noteModalWrapper: {
            textAlign: 'left',
            minHeight: 240
        },
        noteImageModalWrapper: {
            textAlign: 'center',
            minHeight: 240,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        descriptionModalLabel: {
            color: theme.color.themeColor,
            fontSize: 16,
            marginBottom: 0
        },
        titleModalNote: {
            color: theme.color.fontColor,
            textAlign: 'left',
            fontSize: 22,
            margin: '0 0 0 15px'
        },
        descriptionModalNoteRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        commentsRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            minHeight: 207
        },
        commentsAdd: {
            opacity: 1,
            transition: '0.2s ease-in-out all',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        noteEditIcon: {
            color: theme.color.themeColor,
            opacity: 1,
            margin: '0 5px',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        uploadWrapper: {
            marginTop: 25,
            height: 175,
            width: 300,
            overflow: 'hidden',
            position: 'relative',
            cursor: 'pointer',
            background: 'gray',
            border: '1px solid' + theme.color.borderColor,
            color: '#fff',
            borderRadius: 4,
            display: 'flex',
            padding: '0px 15px',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        uploadFile: {
            cursor: 'pointer',
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            marginTop: 0,
            fontSize: 0,
            opacity: 0,
            mozOpacity: 0,
            filter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)',
            ':hover': {
                cursor: 'pointer'
            }
        },
        labelFile: {
            marginTop: 15,
            marginBottom: 5,
            marginLeft: 12,
            fontWeight: 400,
            textAlign: 'left',
            fontSize: 16,
            color: theme.color.themeColor
        },
        previewImg: {
            margin: '0 auto',
            maxHeight: 200,
            maxWidth: 350,
            backgroundColor: theme.color.themeColor,
            '@media(max-width:800px)': {
                maxWidth: 75,
                maxHeight: 75
            }
        },
        previewImgCheck: {
            margin: '0 auto',
            maxHeight: 200,
            maxWidth: 350,
            backgroundColor: theme.color.themeColor,
            filter: 'blur(10px)',
            '@media(max-width:800px)': {
                maxWidth: 75,
                maxHeight: 75
            }
        },
        filePreview: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 365,
            overflow: 'hidden'
        },
        commentAddWrapper: {
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            padding: 20,
            width: 350
        },
        label: {
            color: theme.color.themeColor,
            fontWeight: 600,
            marginBottom: 10
        },
        input: {
            boxShadow: 'none',
            borderRadius: 5,
            padding: '10px 0 5px 10px',
            fontWeight: 400,
            fontSize: 14,
            minHeight: 60,
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor,
            marginBottom: 10,
            marginTop: 20,
            width: '100%',
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 14
            }
        },
        inputEdit: {
            boxShadow: 'none',
            borderRadius: 5,
            padding: '10px 0 5px 10px',
            fontWeight: 400,
            fontSize: 14,
            width: '100%',
            minHeight: 169,
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor,
            // marginBottom: 10,
            '::placeholder': {
                color: theme.color.subFontColor,
                fontSize: 14
            }
        },
        unitNameWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minWidth: 100,
            background: theme.color.themeColor,
            color: theme.color.buttonFontColor,
            borderRadius: '0.2rem',
            padding: '7px 10px',
            marginBottom: 16,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        descriptionModalUnit: {
            color: theme.color.buttonFontColor,
            margin: '0 0 0 8px',
        },
        overdue: {
            position: 'absolute',
            color: 'red',
            top: -1,
            left: -9,
            fontSize: 16,
            fontWeight: 600,
        },
        overdueLabel: {
            color: 'red',
            fontSize: 12,
            opacity: 0.7,
        }
    });
