import React from 'react';
import { connect } from 'react-redux';
import {StyleSheet, css} from 'aphrodite';
import { injectIntl } from 'react-intl';

class ErrorCritical extends React.Component {
  constructor (props) {
    super(props);
  }

  refresh = () => {
    window.location.reload();
  }
 
  statusPage = () => {
    window.open('https://status.smartentry.noke.app/', '_blank');
  }

  render() {
    const { intl, theme, errorInfo } = this.props;
    // "info" var is also property in errorInfo
    const { error } = errorInfo;
    this.styles = styles(theme);

    return (
      <div className={css(this.styles.wrapper)}>
        <div className={css(this.styles.contentWrapper)}>
          <div className={css(this.styles.header)}>{intl.formatMessage({id: `comp.errorCritical.uhOh`})}</div>
          <div className={css(this.styles.text)}>
          {intl.formatMessage({id: 'comp.errorCritical.error'})}
          </div>
          <div className={css(this.styles.text)}>{intl.formatMessage({id: `comp.table.header.errorMessage`})}: <code>{error && error.message}</code></div>
          <div className={css(this.styles.buttonContainer)}>
            <button onClick={this.refresh} className={css(this.styles.button)}>{intl.formatMessage({id: `comp.errorCritical.refresh`})}</button>
            <button onClick={this.statusPage} className={css(this.styles.button)}>{intl.formatMessage({id: `comp.errorCritical.status`})}</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app }) => {
  const { theme } = app;
  return { theme };
}

export default injectIntl(connect(mapStateToProps)(ErrorCritical));

const styles = (theme) => StyleSheet.create({
  wrapper: {
    width: '100vw',
    height: '100vh',
    padding: '15px 30px',
    background: theme && theme.color.headerColor,
  },
  contentWrapper: {
    margin: '0 auto',
    width: 900,
    height: '100vh',
    marginTop: 100,
    '@media(max-width: 1000px)': {
      width: '100%',
      marginTop: 10,
    }
  },
  header: {
    color: theme && theme.color.themeColor,
    paddingBottom: 5,
    fontFamily: 'Poppins',
    fontSize: 70,
    fontWeight: 800,
    '@media(max-width: 1000px)': {
      fontSize: 40,
    }
  },
  text: {
    color: theme && theme.color.fontColor,
    paddingBottom: 5,
    fontSize: 20,
    '@media(max-width: 1000px)': {
      fontSize: 14,
    }
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
    marginTop: 15,
    '@media(max-width: 1000px)': {
      marginTop: 5,
    }
  },
  button: {
    color: theme && theme.color.fontColor,
    background: theme && theme.color.menuColor,
    fontSize: 14,
    borderRadius: 10,
    display: 'grid',
    placeItems: 'center',
    height: 40,
    padding: '0 15px',
    transition: 'opacity 0.2s ease',
    ':hover': {
      opacity: 0.8,
    },
    '@media(max-width: 1000px)': {
      fontSize: 12,
      padding: '0 10px',
    }
  },
});
