import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';

import Modal from 'components/Modal';
class Avatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }
    toggleModal = () => {
        this.setState({ show: !this.state.show });
    };
    render() {
        const { image, name, theme, width, height } = this.props;
        const { show } = this.state;
        this.styles = styles(theme, width, height);
        return (
            <div className={css(this.styles.imageWrapper)}>
                <img className={css(this.styles.image)} src={image} alt={name} onClick={this.toggleModal} />
                <Modal show={show} toggle={this.toggleModal} title={name} padding={50}>
                    <img className={css(this.styles.modalImage)} src={image} alt={name} />
                </Modal>
            </div>
        );
    }
}

Avatar.defaultProps = {
    image: null,
    name: 'Unknown'
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default connect(mapStateToProps)(Avatar);

const styles = (theme, width, height) =>
    StyleSheet.create({
        imageWrapper: {
            marginRight: 10
        },
        image: {
            display: 'block',
            margin: '0 auto',
            width,
            height,
            borderRadius: '50%',
            backgroundColor: theme.color.themeColor,
            border: `solid 5px ${theme.color.compColor}`,
            '@media(max-width:800px)': {
                width: 75,
                height: 75
            },
            ':hover': {
                cursor: 'pointer',
                border: `solid 2px ${theme.color.compColor}`
            }
        },
        modalImage: {
            display: 'block',
            margin: '25px auto',
            maxWidth: '100%',
            backgroundColor: theme.color.themeColor
        }
    });
