import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { removeUserNotifications } from 'actions/Notifications';

import CellText from 'components/Table/cell/components/text';
import CellClose from 'components/Table/cell/components/close';
class UserNotificationsCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
    }
    removeNotification = (id) => {
        this.setState({ show: false });
        this.props.removeUserNotifications([id]);
    };
    render() {
        const { data, width, history, toggle } = this.props;
        const { id, createdDate, eventsId, title, type } = data;
        const { show } = this.state;
        return (
            show && (
                <>
                    <CellText
                        Title={type && type == 'feature' ? `${type}: ${title}` : title}
                        Description={moment(createdDate).local().calendar()}
                        textAlign={'left'}
                        maxCharLength={
                            width > 1450
                                ? width / 33
                                : width > 1200
                                ? width / 40
                                : width > 991
                                ? width / 30
                                : width / 12
                        }
                        TitleColor={true}
                        update={
                            eventsId && eventsId !== ''
                                ? () => {
                                      toggle && toggle();
                                      history.push(`/app/activity/notification/${eventsId}`);
                                  }
                                : null
                        }
                    />
                    <CellClose Cellwidth={'50px'} update={() => this.removeNotification(id)} />
                </>
            )
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { width } = app;
    return { width };
};

export default withRouter(connect(mapStateToProps, { removeUserNotifications })(UserNotificationsCell));
