export const FAKE_ECOBEE_SITE_UUID = '1028';

export const ECOBEE_DATA = {
    page: {
        page: 1,
        totalPages: 1,
        pageSize: 1,
        total: 1
    },
    thermostatList: [
        {
            identifier: '311082941566',
            name: 'Home',
            thermostatRev: '211213072014',
            isRegistered: true,
            modelNumber: 'athenaSmart',
            brand: 'ecobee',
            features: 'Home,HomeKit',
            lastModified: '2021-12-13 07:20:14',
            thermostatTime: '2021-12-13 13:06:18',
            utcTime: '2021-12-13 20:06:18',
            serialNumber: '',
            productCode: '',
            audio: {
                playbackVolume: 0,
                microphoneEnabled: false,
                soundAlertVolume: 0,
                soundTickVolume: 0,
                voiceEngines: null
            },
            alerts: null,
            settings: {
                hvacMode: 'heat',
                lastServiceDate: '2014-01-05',
                serviceRemindMe: false,
                monthsBetweenService: 6,
                remindMeDate: '2014-07-05',
                vent: 'off',
                ventilatorMinOnTime: 20,
                serviceRemindTechnician: false,
                eiLocation: '',
                coldTempAlert: 500,
                coldTempAlertEnabled: true,
                hotTempAlert: 920,
                hotTempAlertEnabled: true,
                coolStages: 1,
                heatStages: 2,
                maxSetBack: 100,
                maxSetForward: 80,
                quickSaveSetBack: 40,
                quickSaveSetForward: 40,
                hasHeatPump: false,
                hasForcedAir: true,
                hasBoiler: false,
                hasHumidifier: false,
                hasErv: false,
                hasHrv: false,
                condensationAvoid: false,
                useCelsius: false,
                useTimeFormat12: true,
                locale: 'en',
                humidity: '36',
                humidifierMode: 'off',
                backlightOnIntensity: 10,
                backlightSleepIntensity: 4,
                backlightOffTime: 60,
                soundTickVolume: 0,
                soundAlertVolume: 0,
                compressorProtectionMinTime: 300,
                compressorProtectionMinTemp: 350,
                stage1HeatingDifferentialTemp: 5,
                stage1CoolingDifferentialTemp: 5,
                stage1HeatingDissipationTime: 31,
                stage1CoolingDissipationTime: 31,
                heatPumpReversalOnCool: true,
                fanControlRequired: true,
                fanMinOnTime: 15,
                heatCoolMinDelta: 50,
                tempCorrection: 0,
                holdAction: 'indefinite',
                heatPumpGroundWater: false,
                hasElectric: false,
                hasDehumidifier: false,
                dehumidifierMode: 'off',
                dehumidifierLevel: 60,
                dehumidifyWithAC: false,
                dehumidifyOvercoolOffset: 0,
                autoHeatCoolFeatureEnabled: true,
                wifiOfflineAlert: false,
                heatMinTemp: 450,
                heatMaxTemp: 1200,
                coolMinTemp: -100,
                coolMaxTemp: 1200,
                heatRangeHigh: 790,
                heatRangeLow: 450,
                coolRangeHigh: 920,
                coolRangeLow: 650,
                userAccessCode: '',
                userAccessSetting: 0,
                auxRuntimeAlert: 10800,
                auxOutdoorTempAlert: 500,
                auxMaxOutdoorTemp: 700,
                auxRuntimeAlertNotify: false,
                auxOutdoorTempAlertNotify: false,
                auxRuntimeAlertNotifyTechnician: false,
                auxOutdoorTempAlertNotifyTechnician: false,
                disablePreHeating: false,
                disablePreCooling: false,
                installerCodeRequired: false,
                drAccept: 'always',
                isRentalProperty: false,
                useZoneController: false,
                randomStartDelayCool: 0,
                randomStartDelayHeat: 0,
                humidityHighAlert: -1,
                humidityLowAlert: -1,
                disableHeatPumpAlerts: false,
                disableAlertsOnIdt: false,
                humidityAlertNotify: false,
                humidityAlertNotifyTechnician: false,
                tempAlertNotify: true,
                tempAlertNotifyTechnician: false,
                monthlyElectricityBillLimit: 0,
                enableElectricityBillAlert: false,
                enableProjectedElectricityBillAlert: false,
                electricityBillingDayOfMonth: 1,
                electricityBillCycleMonths: 1,
                electricityBillStartMonth: 1,
                ventilatorMinOnTimeHome: 20,
                ventilatorMinOnTimeAway: 0,
                backlightOffDuringSleep: false,
                autoAway: true,
                smartCirculation: false,
                followMeComfort: false,
                ventilatorType: 'none',
                isVentilatorTimerOn: false,
                ventilatorOffDateTime: '',
                hasUVFilter: true,
                coolingLockout: false,
                ventilatorFreeCooling: true,
                dehumidifyWhenHeating: false,
                ventilatorDehumidify: true,
                groupRef: '',
                groupName: '',
                groupSetting: 0,
                fanSpeed: 'low'
            },
            runtime: {
                runtimeRev: '211213200602',
                connected: true,
                firstConnected: '2017-05-28 22:12:08',
                connectDateTime: '2021-12-03 20:31:35',
                disconnectDateTime: '2021-11-03 07:18:23',
                lastModified: '2021-12-13 20:06:02',
                lastStatusModified: '2021-12-13 20:06:02',
                runtimeDate: '2021-12-13',
                runtimeInterval: 239,
                actualTemperature: 717,
                actualHumidity: 34,
                desiredHeat: 720,
                desiredCool: 720,
                desiredHumidity: 36,
                desiredDehumidity: 60,
                desiredFanMode: 'auto',
                desiredHeatRange: [450, 790],
                desiredCoolRange: [650, 920]
            },
            extendedRuntime: {
                lastReadingTimestamp: '',
                runtimeDate: '',
                runtimeInterval: 0,
                actualTemperature: null,
                actualHumidity: null,
                desiredHeat: null,
                desiredCool: null,
                desiredHumidity: null,
                desiredDehumidity: null,
                dmOffset: null,
                hvacMode: null,
                heatPump1: null,
                heatPump2: null,
                auxHeat1: null,
                auxHeat2: null,
                auxHeat3: null,
                cool1: null,
                cool2: null,
                fan: null,
                humidifier: null,
                dehumidifier: null,
                economizer: null,
                ventilator: null,
                currentElectricityBill: 0,
                projectedElectricityBill: 0
            },
            electricity: {
                devices: null
            },
            devices: null,
            location: {
                timeZoneOffsetMinutes: 0,
                timeZone: '',
                isDaylightSaving: false,
                streetAddress: '',
                city: '',
                provinceState: '',
                country: '',
                postalCode: '',
                phoneNumber: '',
                mapCoordinates: ''
            },
            technician: {
                contractorRef: '',
                name: '',
                phone: '',
                streetAddress: '',
                city: '',
                provinceState: '',
                country: '',
                postalCode: '',
                email: '',
                web: ''
            },
            utility: {
                name: '',
                phone: '',
                email: '',
                web: ''
            },
            management: {
                administrativeContact: '',
                billingContact: '',
                name: '',
                phone: '',
                email: '',
                web: '',
                showAlertIdt: false,
                showAlertWeb: false
            },
            weather: {
                timestamp: '',
                weatherStation: '',
                forecasts: null
            },
            events: null,
            program: {
                schedule: null,
                climates: null,
                currentClimateRef: ''
            },
            houseDetails: {
                style: '',
                size: 0,
                numberOfFloors: 0,
                numberOfRooms: 0,
                numberOfOccupants: 0,
                age: 0,
                windowEfficiency: 0
            },
            equipmentStatus: '',
            notificationSettings: {
                emailAddresses: null,
                emailNotificationsEnabled: false,
                equipment: null,
                general: null,
                limit: null
            },
            version: {
                thermostatFirmwareVersion: ''
            },
            securitySettings: {
                userAccessCode: '',
                allUserAccess: false,
                programAccess: false,
                detailsAccess: false,
                quickSaveAccess: false,
                vacationAccess: false
            },
            remoteSensors: null
        }
    ],
    status: {
        code: 0,
        message: ''
    },
    error: '',
    error_description: '',
    error_uri: ''
};

export const ECOBEE_REQUEST_PIN = {
    ecobeePin: 'KRWF-KNNF',
    code: 'wCTeQF-HRESX_92j3y5LvET9',
    scope: 'openid,offline_access,smartWrite',
    expires_in: 900,
    interval: 5,
    error: '',
    error_description: '',
    error_uri: ''
};

export const ECOBEE_VIEW_PIN = {
    site_id: 1000000,
    pin: 'KRWF-KNNF',
    pin_code: 'wCTeQF-HRESX_92j3y5LvET9',
    pin_scope: 'openid,offline_access,smartWrite',
    pin_expires: 900,
    pin_interval: 5,
    access_token:
        'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJFWXhNVEpDT0Rnek9UaERRelJHTkRCRlFqZEdNVGxETnpaR1JUZzRNalEwTmtWR01UQkdPQSJ9.eyJpc3MiOiJodHRwczovL2F1dGguZWNvYmVlLmNvbS8iLCJzdWIiOiJhdXRoMHw5ZDliMDdiZS1lOTY4LTRjMDktYWM1Ny1hZGZhNzYwMDU0ZDEiLCJhdWQiOlsiaHR0cHM6Ly9kZXZlbG9wZXItYXBwcy5lY29iZWUuY29tL2FwaS92MSIsImh0dHBzOi8vZWNvYmVlLXByb2QuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYzOTQyNTk3OCwiZXhwIjoxNjM5NDI5NTc4LCJhenAiOiI0TUR4UngyV3l4aEtrOEZFazVIZk80bkMzRUMyb0pKTyIsInNjb3BlIjoib3BlbmlkIHNtYXJ0V3JpdGUgb2ZmbGluZV9hY2Nlc3MifQ.sutbrEOylEAigoOTYVqGL5dwbnRPXePdhfDCe8PiY96xKMjIz7KKJEbAKUybPlgbjaT8PUL5e3ltCKn75xeo6G4P6NWj80q-NPzxJeosVblaBaRfiZQIYQ4qHVrCTqswgDHb_1v-TAewKD9IoEGLsgkP7o1sbaOAj5Hh8y7jyHEu2kBeT9-9yCs1AiNhuaF3LZzI9FBr6YDS_X7addOn91E_h580xdOWgxP0Gb27PHHgFK942VDzygEvHyQ6bI9s_Bc0L99M_vtbKnqdSbPkXrilfAa2kpSZhSBr3xX7kpc4wG0lxX9fLJ63u4DZzr5SfnnBVdJhSsJKxFTlNhIrGA',
    token_type: 'Bearer',
    refresh_token: 'hFs2xW7nlilDVWe0PNDUeSFx6DVLah7wBA1Vcbb3Tfgbo',
    token_expires: 3600,
    token_scope: 'openid,smartWrite,offline_access',
    created_date: '2021-07-15T14:31:42Z',
    last_updated: '2021-12-13T20:25:16Z'
};

export const ECOBEE_REQUEST_TOKEN = 'Waiting for user to authorize application.';

export const ECOBEE_REFRESH_TOKEN = {
    access_token:
        'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJFWXhNVEpDT0Rnek9UaERRelJHTkRCRlFqZEdNVGxETnpaR1JUZzRNalEwTmtWR01UQkdPQSJ9.eyJpc3MiOiJodHRwczovL2F1dGguZWNvYmVlLmNvbS8iLCJzdWIiOiJhdXRoMHw5ZDliMDdiZS1lOTY4LTRjMDktYWM1Ny1hZGZhNzYwMDU0ZDEiLCJhdWQiOlsiaHR0cHM6Ly9kZXZlbG9wZXItYXBwcy5lY29iZWUuY29tL2FwaS92MSIsImh0dHBzOi8vZWNvYmVlLXByb2QuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYzOTQyNzE5MywiZXhwIjoxNjM5NDMwNzkzLCJhenAiOiI0TUR4UngyV3l4aEtrOEZFazVIZk80bkMzRUMyb0pKTyIsInNjb3BlIjoib3BlbmlkIHNtYXJ0V3JpdGUgb2ZmbGluZV9hY2Nlc3MifQ.RDx1eGljqDB1lpqCns9kPElya9O1T44rGUhwM62kx42WJs6dq6g4EyEIffbKZN-OHODFds1rNA78F-Ofcs662egK5JsB1ZtxRBPPDuaOBZEMUfrmHxrcGP4gR8clvEtRaZn2L6LoATK8_QcPyJwgeEug995ho4cbqFmSphLP2CQ8MSYQMk_xB7PqYZ6MvMxAsyQSLZPLQbynXYobpnRn3T5GsAy4L7z-JeXOyc436gABVVN69ARRNKgP35T3EWRl5qUvRgvvcoZvWHDYzyowwM8MMqC4az5nVDsEKbPhdxgX_cZI7ElQWFhxbk1pcPxVsBnrnCkGasPUscOoqLovbA',
    token_type: 'Bearer',
    expires_in: 3600,
    refresh_token: 'hFs2xW7nlilDVWe0PNDUeSFx6DVLah7wBA1Vcbb3Tfgbo',
    scope: 'openid,smartWrite,offline_access',
    error: '',
    error_description: '',
    error_uri: ''
};
