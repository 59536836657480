import React from 'react';
import { connect } from 'react-redux';

import LanguageList from 'components/LanguageSwitcher/list';
import Dropdown from 'components/Dropdowns';
class LanguageSwitcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }
    render() {
        const { locale, direction } = this.props;
        const { show } = this.state;
        return (
            <Dropdown
                menu={LanguageList}
                direction={direction}
                width={180}
                height={165}
                title={'comp.header.select.lang'}
                toggle={(show) => this.setState({ show })}>
                <div className="d-flex align-items-center pointer">
                    <i className="material-icons">public</i>(
                    <span style={{ textTransform: 'uppercase' }}>{locale}</span>)
                    <i className="material-icons">{`arrow_drop_${show ? direction : 'down'}`}</i>
                </div>
            </Dropdown>
        );
    }
}

LanguageSwitcher.defaultProps = {
    direction: 'up'
};

const mapStateToProps = ({ app }) => {
    const { locale } = app;
    return { locale };
};

export default connect(mapStateToProps)(LanguageSwitcher);
