import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import * as UTILS from 'utilities';

import { EmailIcon, HubspotIcon } from 'utilities/icons';
import { fetchHubspotTickets, fetchHubspotTicket } from 'actions/Settings';

import SkeletonContainer from 'components/Skeleton/skeletonWrapper';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Header from 'components/Header/dashboard';
class DashboardHubspotTickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }
    componentDidMount() {
        const { fetchHubspotTickets, authUser } = this.props;
        authUser && authUser.featureflagAssignments && authUser.featureflagAssignments.includes('view_hubspot_tickets') && fetchHubspotTickets();
    }
    fetchIndividualTicket = (id) => {
        const { fetchHubspotTicket } = this.props;
        this.setState({ showModal: true });
        fetchHubspotTicket(id);
    };
    pipelineCheck = (param) => {
        const { intl } = this.props;
        switch (param) {
            case '11598105':
                return intl.formatMessage({ id: 'pages.tickets.pipeline.support' });
            case '7766707':
                return intl.formatMessage({ id: 'pages.tickets.pipeline.elite' });
            case '1242528':
                return intl.formatMessage({ id: 'pages.tickets.pipeline.install' });
            case '3483928':
                return intl.formatMessage({ id: 'pages.tickets.pipeline.uk' });
            case '10064801':
                return intl.formatMessage({ id: 'pages.tickets.pipeline.t3' });
            default:
                return intl.formatMessage({ id: 'form.na' });
        }
    };
    pipelineStageCheck = (param) => {
        const { intl } = this.props;
        switch (param) {
            case '11598135':
            case '22756441':
            case '4357473':
            case '11793685':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.new' });
            case '11598136':
            case '22756442':
            case '4357474':
            case '11793686':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.waitingThem' });
            case '11598108':
            case '4357475':
            case '11793687':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.waitingUs' });
            case '7469589':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.t2' });
            case '11598137':
            case '11774389':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.t3' });
            case '22756443':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.progress' });
            case '22762124':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.escalation' });
            case '6462758':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.servicePending' });
            case '5600063':
            case '11774392':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.serviceRequested' });
            case '5601626':
            case '11774393':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.serviceConfirmed' });
            case '28969298':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.shipmentRequests' });
            case '28956748':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.supportEscalation' });
            case '28956749':
                return intl.formatMessage({ id: 'pages.tickets.pipelinestage.eccEscalation' });
            default:
                return intl.formatMessage({ id: 'form.na' });
        }
    };
    render() {
        let { intl, theme, authUser, site, hubspotTicket, hubspotTickets, userTimezone } = this.props;
        let { showModal } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <SkeletonContainer>

                    <Modal 
                        show={showModal} 
                        toggle={() => this.setState({ showModal: !showModal })}
                        title={
                            <div className={css(this.styles.iconWrapperModalNote)}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <HubspotIcon height={28} width={28} color={theme.color.themeColor} />
                                    <p className={css(this.styles.titleModalNote)}>
                                        {intl.formatMessage({ id: 'pages.tickets.Type' })}# {hubspotTicket ? `${hubspotTicket.objectId}` : <Skeleton width={105} height={27} />}
                                    </p>
                                </div>
                            </div>}
                        >
                        <div className={css(this.styles.modalWrapper)}>
                            <div className={css(this.styles.buttonWrapper)}>
                                <p className={css(this.styles.descriptionModalLabel)}>
                                    {intl.formatMessage({ id: 'comp.table.header.subject' })}
                                </p>
                                <p className={css(this.styles.titleModal)}>
                                    {hubspotTicket ?
                                        hubspotTicket.properties &&
                                        hubspotTicket.properties.subject &&
                                        hubspotTicket.properties.subject.value &&
                                        hubspotTicket.properties.subject.value : <Skeleton width={125} height={17} />}
                                </p>
                                <p className={css(this.styles.descriptionModalLabel)}>
                                    {intl.formatMessage({ id: 'form.body' })}
                                </p>
                                <p className={css(this.styles.descriptionModal)}>
                                    {hubspotTicket ?
                                        hubspotTicket.properties &&
                                        hubspotTicket.properties.content &&
                                        hubspotTicket.properties.content.value &&
                                        hubspotTicket.properties.content.value : <Skeleton width={125} height={17} />}
                                </p>
                                <p className={css(this.styles.descriptionModalLabel)}>
                                    {intl.formatMessage({ id: 'form.createdBy' })}
                                </p>
                                <p className={css(this.styles.descriptionModal)} style={{ textTransform: 'capitalize' }}>
                                    {hubspotTicket ?
                                        hubspotTicket.properties &&
                                        hubspotTicket.properties.source_type &&
                                        hubspotTicket.properties.source_type.value &&
                                        hubspotTicket.properties.source_type.value.toLowerCase() : <Skeleton width={125} height={17} />}
                                </p>
                                <p className={css(this.styles.descriptionModalLabel)}>
                                    {intl.formatMessage({ id: 'pages.filter.units.openedDate' })}
                                </p>
                                <p className={css(this.styles.descriptionModal)}>
                                    {hubspotTicket ?
                                        hubspotTicket.properties &&
                                        hubspotTicket.properties.createdate &&
                                        hubspotTicket.properties.createdate.timestamp &&
                                        UTILS.dateFormatter(moment.unix(hubspotTicket.properties.createdate.timestamp/1000), authUser, site) : <Skeleton width={125} height={17} />}
                                </p>
                                <p className={css(this.styles.descriptionModalLabel)}>
                                    {intl.formatMessage({ id: 'pages.tickets.pipeline' })}
                                </p>
                                <p className={css(this.styles.descriptionModal)}>
                                    {hubspotTicket ?
                                        hubspotTicket.properties &&
                                        hubspotTicket.properties.hs_pipeline &&
                                        hubspotTicket.properties.hs_pipeline.value &&
                                        this.pipelineCheck(hubspotTicket.properties.hs_pipeline.value) : <Skeleton width={125} height={17} />}
                                </p>
                                <p className={css(this.styles.descriptionModalLabel)}>
                                    {intl.formatMessage({ id: 'pages.tickets.pipelineStage' })}
                                </p>
                                <p className={css(this.styles.descriptionModal)}>
                                    {hubspotTicket ?
                                        hubspotTicket.properties &&
                                        hubspotTicket.properties.hs_pipeline_stage &&
                                        hubspotTicket.properties.hs_pipeline_stage.value &&
                                        this.pipelineStageCheck(hubspotTicket.properties.hs_pipeline_stage.value) : <Skeleton width={125} height={17} />}
                                </p>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end',
                                    marginTop: 30
                                }}>
                                <Button
                                    width={140}
                                    load={false}
                                    action={() => this.setState({ showModal: !showModal })}
                                    text={'pages.users.dismissTraining'}
                                    margin={'0 0'}
                                    padding={'10px 20px'}
                                />
                            </div>
                        </div>
                    </Modal>
                    <Header
                        Icon={HubspotIcon}
                        iconSize={32}
                        title={'pages.tickets.Types'}
                        subtitle={hubspotTickets ? hubspotTickets.length : null}
                    />
                    <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                        <Col className={css(this.styles.wrapper)} style={{ padding: '0 0', overflowY: 'auto' }} xs={12}>
                            {authUser &&
                            authUser.featureflagAssignments &&
                            authUser.featureflagAssignments.includes('view_hubspot_tickets') ? (
                                hubspotTickets ? (
                                    <table className={css(this.styles.table)}>
                                        {hubspotTickets && hubspotTickets.length > 0 ? (
                                            <tbody>
                                                {hubspotTickets.map((ticket) => (
                                                    <tr
                                                        key={'TICKETLIST' + ticket.objectId}
                                                        className={css(this.styles.row)}
                                                        onClick={() => this.fetchIndividualTicket(ticket.objectId)}>
                                                        <td  className={css(this.styles.iconWrapper)}>
                                                            <EmailIcon height={18} width={18} color={theme.color.buttonFontColor} />
                                                        </td>
                                                        <td
                                                            style={{ textTransform: 'capitalize' }}
                                                            className={css(this.styles.text)}>
                                                            {ticket &&
                                                            ticket.properties &&
                                                            ticket.properties.subject &&
                                                            ticket.properties.subject.value &&
                                                            ticket.properties.subject.value.length < 65
                                                                ? ticket.properties.subject.value
                                                                : `${ticket.properties.subject.value
                                                                    .split('')
                                                                    .splice(0, 64)
                                                                    .join('')}..`}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        ) : (
                                            <tbody
                                                style={{
                                                    minHeight: 102,
                                                    fontSize: 12,
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    animationName: fadeIn,
                                                    animationDuration: '1.2s'
                                                }}>
                                                <tr>
                                                    <td>
                                                        {intl.formatMessage({
                                                            id: 'comp.table.cell.emptyTickets'
                                                        })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                ) : (
                                    <Spinner />
                                )
                            ) : (
                                <div
                                    style={{
                                        minHeight: 102,
                                        fontSize: 12,
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        animationName: fadeIn,
                                        animationDuration: '1.2s'
                                    }}>
                                    {intl.formatMessage({ id: 'comp.table.cell.emptyTicketsSite' })}
                                </div>
                            )}
                        </Col>
                    </Row>
                </SkeletonContainer>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, settings }) => {
    const { theme, userTimezone } = app;
    const { authUser, site } = auth;
    const { hubspotTickets, hubspotTicket } = settings;
    return { theme, authUser, site, hubspotTickets, hubspotTicket, userTimezone };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { fetchHubspotTickets, fetchHubspotTicket })(DashboardHubspotTickets))
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            maxHeight: 200,
            height: 200,
            borderTop: '1px solid' + theme.color.borderColor,
            borderBottom: '1px solid' + theme.color.borderColor,
        },
        header: {
            color: theme.color.themeColor,
            margin: '0 0 15px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        table: {
            width: '100%',
            fontSize: 11
        },
        row: {
            borderBottom: '1px solid' + theme.color.borderColor,
            padding: '5px 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        text: {
            textTransform: 'capitalize',
            //minHeight: 51,
        },
        iconWrapperModal: {
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom: 10
        },
        modalWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between'
        },
        buttonWrapper: {
            minHeight: 200
        },
        iconWrapperModalNote: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        descriptionModal: {
            color: theme.color.fontColor,
            fontSize: 14
        },
        descriptionModalLabel: {
            color: theme.color.themeColor,
            fontSize: 16,
            marginBottom: 0
        },
        titleModalNote: {
            margin: '0 0 0 10px'
        },
        iconWrapper: {
            height: 31, 
            width: 31, 
            borderRadius: '50%', 
            margin: '5px 5px 5px 0', 
            background: theme.color.themeColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    });
