import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';

import { filterList } from 'utilities';

import DropdownHeader from 'components/Dropdowns/header';
class DropdownTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: props.data,
            updateData: null,
            active: 100
        };
    }
    filterMenuData = (value) => {
        let { data, updateData } = this.state;
        updateData = data && filterList(data, new Set(['name', 'native']), value);
        this.setState({ updateData, active: 0 });
    };
    updateCountry = (country) => {
        const { show } = this.state;
        this.filterMenuData('');
        this.setState({ show: !show });
        this.props.update(country);
    };
    updateActive = (keycode) => {
        let { active, data, updateData } = this.state;
        if (keycode === 38 && active != 0) {
            this.setState({ active: --active });
        }
        if (keycode === 40 && (updateData ? updateData.length - 1 > active : data.length - 1 > active)) {
            this.setState({ active: ++active });
        }
        if (keycode === 13) {
            this.updateCountry(updateData ? updateData[active] : data[active]);
        }
    };
    toggle = (show) => {
        this.props.toggle && this.props.toggle(show);
        this.setState({ show });
    };
    render() {
        const { children, menu, direction, title, input, theme, width, height, padding } = this.props;
        const { show, data, updateData, active } = this.state;
        this.styles = styles(theme, width, height, padding);
        const Menu = menu;
        return (
            <Dropdown direction={direction} isOpen={show} toggle={() => this.toggle(!show)}>
                <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                    {children}
                </DropdownToggle>
                <DropdownMenu right className={css(this.styles.wrapper)}>
                    <DropdownHeader
                        title={title}
                        input={input}
                        filter={this.filterMenuData}
                        width={width}
                        update={this.updateActive}
                    />
                    <div className={css(this.styles.menu)}>
                        <Menu
                            toggle={() => this.toggle(!show)}
                            data={updateData ? updateData : data}
                            update={this.updateCountry}
                            active={active}
                        />
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

DropdownTemplate.defaultProps = {
    direction: 'down',
    width: 250,
    padding: 20,
    data: [],
    height: 300
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(connect(mapStateToProps)(DropdownTemplate));

const styles = (theme, width, height, padding) =>
    StyleSheet.create({
        wrapper: {
            border: `solid 1px ${theme.color.borderColor}`,
            backgroundColor: theme.color.compColor,
            padding,
            width
        },
        menu: {
            height,
            overflow: 'auto'
        }
    });
