import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

class FooterLink extends React.Component {
    constructor(props) {
        super(props);
    }
    handleClick = () => {
        const { toggle, history, route } = this.props;
        toggle && toggle();
        history.push(route);
    };
    render() {
        const { intl, title, route, link, theme, textAlign } = this.props;
        this.styles = styles(theme, textAlign);
        return (
            <div className={css(this.styles.wrapper)}>
                {route && (
                    <p className={css(this.styles.title)} onClick={this.handleClick}>
                        {intl.formatMessage({ id: title })}
                    </p>
                )}
                {link && (
                    <a
                        title="Footer Link"
                        className={css(this.styles.title)}
                        href={link}
                        target="_blank"
                        rel="noreferrer">
                        {intl.formatMessage({ id: title })}
                    </a>
                )}
            </div>
        );
    }
}

FooterLink.defaultProps = {
    title: 'Go To',
    textAlign: 'center',
    toggle: false
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(FooterLink)));

const styles = (theme, textAlign) =>
    StyleSheet.create({
        wrapper: {
            display: 'block',
            margin: '20px auto 0 auto',
            width: 200,
            textAlign
        },
        title: {
            fontSize: 12,
            fontWeight: 400,
            margin: 0,
            padding: 0,
            textDecoration: 'underline',
            textDecorationColor: theme.color.themeColor,
            color: theme.color.fontColor,
            textAlign,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        }
    });
