//||||||||||||||||||||||||||||||||||||||||
//|| LOGIN                              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_LOGIN = '/login/';
export const PURPOSE_LOGIN = 'Authenticate user and fetch user credentials.';
export const PERMISSIONS_LOGIN = [];
export const REQUIREMENTS_LOGIN = ['Token Required'];
export const PARAMETER_LOGIN = {
    email: '',
    phone: '',
    password: '',
    companyId: 0
};
export const RETURN_LOGIN = {
    companyId: 0,
    defaultSiteId: 0,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    permissions: [],
    phone: '',
    resetPassFlag: false,
    sites: [
        {
            CompanyId: 0,
            assignedUserId: 0,
            city: '',
            closeTime: '',
            country: '',
            id: 0,
            last_sync_date: '',
            latitude: '',
            longitude: '',
            mainUrl: '',
            name: '',
            openTime: '',
            paymentUrl: '',
            phone: '',
            pms: '',
            postalCode: '',
            state: '',
            streetAddress: '',
            suite: '',
            syncId: '',
            timeZone: ''
        }
    ],
    state: '',
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| FORGOT PASSWORD                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FORGOT_PASSWORD = '/user/password/requestreset/';
export const PURPOSE_FORGOT_PASSWORD = 'Send email/text to user to reset their password.';
export const PERMISSIONS_FORGOT_PASSWORD = [];
export const REQUIREMENTS_FORGOT_PASSWORD = ['Token Required'];
export const PARAMETER_FORGOT_PASSWORD = {
    companyId: 0,
    email: '',
    phone: ''
};
export const RETURN_FORGOT_PASSWORD = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| CONFIRM PASSWORD                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CONFIRM_PASSWORD = '/user/password/confirm/';
export const PURPOSE_CONFIRM_PASSWORD = 'Confirm user password';
export const PERMISSIONS_CONFIRM_PASSWORD = [];
export const REQUIREMENTS_CONFIRM_PASSWORD = ['Token Required'];
export const PARAMETER_CONFIRM_PASSWORD = {
    companyId: 0,
    email: '',
    pin: '',
    phone: '',
    newPassword: ''
};
export const RETURN_CONFIRM_PASSWORD = {
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| RESET PASSWORD                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_RESET_PASSWORD = '/user/update/';
export const PURPOSE_RESET_PASSWORD = "Update user's first name, last name and password.";
export const PERMISSIONS_RESET_PASSWORD = [];
export const REQUIREMENTS_RESET_PASSWORD = ['Token Required'];
export const PARAMETER_RESET_PASSWORD = {
    firstName: '',
    lastName: '',
    password: ''
};
export const RETURN_RESET_PASSWORD = {
    companyId: 0,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    permissions: [],
    phone: '',
    resetPassFlag: false,
    state: '',
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| REFRESH AUTH TOKEN                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REFRESH_AUTH_TOKEN = '/auth/token/refresh/';
export const PURPOSE_REFRESH_AUTH_TOKEN = 'Send email/text to user to reset their password.';
export const PERMISSIONS_REFRESH_AUTH_TOKEN = [];
export const REQUIREMENTS_REFRESH_AUTH_TOKEN = ['Token Required'];
export const PARAMETER_REFRESH_AUTH_TOKEN = {
    companyId: 0,
    email: '',
    phone: ''
};
export const RETURN_REFRESH_AUTH_TOKEN = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| LOGOUT                             ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_LOGOUT = '/logout/';
export const PURPOSE_LOGOUT = 'Deauthenticate User.';
export const PERMISSIONS_LOGOUT = [];
export const REQUIREMENTS_LOGOUT = ['Token Required'];
export const PARAMETER_LOGOUT = {};
export const RETURN_LOGOUT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| LOGIN CREATE                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_RESEND = '/user/create/resend/';
export const PURPOSE_USER_RESEND = 'Create a user on login.';
export const PERMISSIONS_USER_RESEND = [];
export const REQUIREMENTS_USER_RESEND = ['Token Required'];
export const PARAMETER_USER_RESEND = {
    companyId: 0,
    email: '',
    phone: '',
    countryCode: '',
    password: ''
};
export const RETURN_USER_RESEND = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| RESET PIN CALL                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_RESET_PIN_CALL = '/user/create/sendvoice/';
export const PURPOSE_RESET_PIN_CALL = 'Send phone call to user to reset password';
export const PERMISSIONS_RESET_PIN_CALL = [];
export const REQUIREMENTS_RESET_PIN_CALL = ['Token Required'];
export const PARAMETER_RESET_PIN_CALL = {
    companyId: 0,
    email: '',
    phone: '',
    countryCode: ''
};
export const RETURN_RESET_PIN_CALL = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| FETCH AUTH PIN                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FETCH_AUTH_PIN = '/user/pin/get/';
export const PURPOSE_FETCH_AUTH_PIN = 'Send phone call to user to reset password';
export const PERMISSIONS_FETCH_AUTH_PIN = [];
export const REQUIREMENTS_FETCH_AUTH_PIN = ['Token Required'];
export const PARAMETER_FETCH_AUTH_PIN = {};
export const RETURN_FETCH_AUTH_PIN = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| SEND AUTH PIN                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SEND_AUTH_PIN = '/user/pin/set/';
export const PURPOSE_SEND_AUTH_PIN = 'Send phone call to user to reset password';
export const PERMISSIONS_SEND_AUTH_PIN = [];
export const REQUIREMENTS_SEND_AUTH_PIN = ['Token Required'];
export const PARAMETER_SEND_AUTH_PIN = {};
export const RETURN_SEND_AUTH_PIN = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| VALIDATE USER                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_VALIDATE_USER = '/login/validate/';
export const PURPOSE_VALIDATE_USER = 'Check if user exists';
export const PERMISSIONS_VALIDATE_USER = [];
export const REQUIREMENTS_VALIDATE_USER = [];
export const PARAMETER_VALIDATE_USER = {
    companyId: 0,
    countryCode: '',
    phone: '',
    email: '',
    deviceUUID: ''
};
export const RETURN_VALIDATE_USER = {
    userState: '',
    passwordState: '',
    loginUrl: ''
};
