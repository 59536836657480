//||||||||||||||||||||||||||||||||||||||||
//|| USER ACTIVATION                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_ACTIVATION = '/user/activate/';
export const PURPOSE_USER_ACTIVATION = 'Send Activation text or email to user.';
export const PERMISSIONS_USER_ACTIVATION = [];
export const REQUIREMENTS_USER_ACTIVATION = ['Token Required'];
export const PARAMETER_USER_ACTIVATION = {
    companyId: 0,
    userId: 0
};
export const RETURN_USER_ACTIVATION = {
    result: 'success'
};
export const ENDPOINT_USER_ACTIVATION_ALL = '/user/activate/all/';

//||||||||||||||||||||||||||||||||||||||||
//|| USER NOTIFY WARNING                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_NOTIFY_WARNING = '/entrymessage/warning/';
export const PURPOSE_NOTIFY_WARNING =
    'When user arrives on site a notification will be sent to all users with receive_site_notifications';
export const PERMISSIONS_NOTIFY_WARNING = [];
export const REQUIREMENTS_NOTIFY_WARNING = ['Token Required'];
export const PARAMETER_NOTIFY_WARNING = {
    userID: 0,
    warningOn: 0
};
export const RETURN_NOTIFY_WARNING = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER UNFREEZE ACCOUNT              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_UNFREEZE_ACCOUNT = '/support/user/unfreeze/';
export const PURPOSE_USER_UNFREEZE_ACCOUNT = 'Unfreeze an account for a user.';
export const PERMISSIONS_USER_UNFREEZE_ACCOUNT = ['support_unfreeze_user'];
export const REQUIREMENTS_USER_UNFREEZE_ACCOUNT = ['Token Required'];
export const PARAMETER_USER_UNFREEZE_ACCOUNT = {
    companyId: 0,
    userId: 0
};
export const RETURN_USER_UNFREEZE_ACCOUNT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER REFRESH PIN                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_REFRESH_PIN = '/support/user/pin/refresh/';
export const PURPOSE_USER_REFRESH_PIN = 'Send Activation text or email to user to refresh their pin.';
export const PERMISSIONS_USER_REFRESH_PIN = ['support_refresh_pin'];
export const REQUIREMENTS_USER_REFRESH_PIN = ['Token Required'];
export const PARAMETER_USER_REFRESH_PIN = {
    companyId: 0,
    id: 0
};
export const RETURN_USER_REFRESH_PIN = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER FETCH PIN                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_FETCH_PIN = '/support/user/view/pin/';
export const PURPOSE_USER_FETCH_PIN = 'Fetch user pin to view.';
export const PERMISSIONS_USER_FETCH_PIN = ['support_view_pins'];
export const REQUIREMENTS_USER_FETCH_PIN = ['Token Required'];
export const PARAMETER_USER_FETCH_PIN = {
    companyId: 0,
    userId: 0
};
export const RETURN_USER_FETCH_PIN = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER FETCH FOBS                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_FETCH_FOBS = '/support/user/fobs/';
export const PURPOSE_USER_FETCH_FOBS = 'Fetch user fobs to view.';
export const PERMISSIONS_USER_FETCH_FOBS = ['support_user_fobs'];
export const REQUIREMENTS_USER_FETCH_FOBS = ['Token Required'];
export const PARAMETER_USER_FETCH_FOBS = {
    userId: 0
};
export const RETURN_USER_FETCH_FOBS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER FETCH SHARES                  ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_FETCH_SHARES = '/user/shares/byid/';
export const PURPOSE_USER_FETCH_SHARES = 'Fetch user shares to view.';
export const PERMISSIONS_USER_FETCH_SHARES = [''];
export const REQUIREMENTS_USER_FETCH_SHARES = ['Token Required'];
export const PARAMETER_USER_FETCH_SHARES = {
    userId: 0
};
export const RETURN_USER_FETCH_SHARES = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER SAVE SITES                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_SAVE_SITES = '/site/manage/';
export const PURPOSE_USER_SAVE_SITES = 'Update a user with access to an array of sites.';
export const PERMISSIONS_USER_SAVE_SITES = [];
export const REQUIREMENTS_USER_SAVE_SITES = ['Token Required'];
export const PARAMETER_USER_SAVE_SITES = {
    companyId: 0,
    user_id: 0,
    roles: [],
    remove_sites: [0]
};
export const RETURN_USER_SAVE_SITES = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER CREATE                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_CREATE = '/user/create/';
export const PURPOSE_USER_CREATE = 'Create a user.';
export const PERMISSIONS_USER_CREATE = [];
export const REQUIREMENTS_USER_CREATE = ['Token Required'];
export const PARAMETER_USER_CREATE = {
    companyId: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: '',
    type: ''
};
export const RETURN_USER_CREATE = {
    companyId: 0,
    defaultSiteId: 0,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    permissions: [],
    phone: '',
    resetPassFlag: false,
    state: '',
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| SERVICE USER CREATE                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SERVICE_USER_CREATE = '/user/serviceaccount/create/';
export const PURPOSE_SERVICE_USER_CREATE = 'Create a service user.';
export const PERMISSIONS_SERVICE_USER_CREATE = [];
export const REQUIREMENTS_SERVICE_USER_CREATE = ['Token Required'];
export const PARAMETER_SERVICE_USER_CREATE = {
    companyId: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: '',
    type: ''
};
export const RETURN_SERVICE_USER_CREATE = {
    companyId: 0,
    defaultSiteId: 0,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    permissions: [],
    phone: '',
    resetPassFlag: false,
    state: '',
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER DELETE                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_DELETE = '/user/delete/';
export const PURPOSE_USER_DELETE = 'Delete a user.';
export const PERMISSIONS_USER_DELETE = [];
export const REQUIREMENTS_USER_DELETE = ['Token Required'];
export const PARAMETER_USER_DELETE = {
    companyId: 0,
    userId: 0
};
export const RETURN_USER_DELETE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER REMOVE FOB                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_REMOVE_FOB = '/support/user/fob/remove/';
export const PURPOSE_USER_REMOVE_FOB = 'Remove a fob from a user.';
export const PERMISSIONS_USER_REMOVE_FOB = ['support_fob_remove_user'];
export const REQUIREMENTS_USER_REMOVE_FOB = ['Token Required'];
export const PARAMETER_USER_REMOVE_FOB = {
    fobId: 0,
    userId: 0
};
export const RETURN_USER_REMOVE_FOB = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER EDIT                          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_EDIT = '/user/edit/';
export const PURPOSE_USER_EDIT = 'Edit a user.';
export const PERMISSIONS_USER_EDIT = [];
export const REQUIREMENTS_USER_EDIT = ['Can only edit non-employees'];
export const PARAMETER_USER_EDIT = {
    companyId: 0,
    UserId: 0,
    firstName: '',
    lastName: ''
};
export const RETURN_USER_EDIT = {
    companyId: 0,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    permissions: [],
    phone: '',
    resetPassFlag: false,
    state: '',
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER ROLE EDIT                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_ROLE_EDIT = '/user/roles/edit/';
export const PURPOSE_USER_ROLE_EDIT = 'Edit a user role.';
export const PERMISSIONS_USER_ROLE_EDIT = [];
export const REQUIREMENTS_USER_ROLE_EDIT = [];
export const PARAMETER_USER_ROLE_EDIT = {
    roleID: 0,
    userID: 0
};
export const RETURN_USER_ROLE_EDIT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER EDIT PHONE                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_EDIT_PHONE = '/support/user/phone/';
export const PURPOSE_USER_EDIT_PHONE = 'Edit a phone number for a user.';
export const PERMISSIONS_USER_EDIT_PHONE = ['support_change_phone'];
export const REQUIREMENTS_USER_EDIT_PHONE = ['Token Required'];
export const PARAMETER_USER_EDIT_PHONE = {
    userId: 0,
    phone: ''
};
export const RETURN_USER_EDIT_PHONE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER PHONE DETAILS                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_PHONE_DETAILS = '/user/device/details/';
export const ENDPOINT_SUPPORT_USER_PHONE_DETAILS = '/support/user/phone/details/';
export const PURPOSE_USER_PHONE_DETAILS = 'Fetch details about a user phone number.';
export const PERMISSIONS_USER_PHONE_DETAILS = ['support_view_phone_details'];
export const REQUIREMENTS_USER_PHONE_DETAILS = ['Token Required'];
export const PARAMETER_USER_PHONE_DETAILS = {
    userId: 0
};
export const RETURN_USER_PHONE_DETAILS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER EDIT PERMISSIONS              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_EDIT_PERMISSIONS = '/support/user/permissions/edit/';
export const PURPOSE_USER_EDIT_PERMISSIONS = 'Edit permissions for a user.';
export const PERMISSIONS_USER_EDIT_PERMISSIONS = ['support_edit_permissions'];
export const REQUIREMENTS_USER_EDIT_PERMISSIONS = ['Token Required'];
export const PARAMETER_USER_EDIT_PERMISSIONS = {
    userId: 0,
    permissions: {
        manageUsers: false,
        manageLocks: false,
        manageFobs: false,
        managePermissions: false,
        manageSites: false,
        viewSiteLocks: false,
        viewUserActivity: false,
        viewUsers: false,
        viewLocks: false,
        searchAllSites: false,
        gateAccess: false,
        manageEmployees: false,
        manageSiteManagers: false,
        manageUnits: false,
        deleteLocks: false,
        deleteUnits: false,
        businessHours: false,
        allHours: false
    }
};
export const RETURN_USER_EDIT_PERMISSIONS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER GATE PERMISSIONS              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_GATE_PERMISSIONS = '/user/permissions/edit/';
export const PURPOSE_USER_GATE_PERMISSIONS = 'Edit gate permissions for a user.';
export const PERMISSIONS_USER_GATE_PERMISSIONS = [];
export const REQUIREMENTS_USER_GATE_PERMISSIONS = ['Token Required'];
export const PARAMETER_USER_GATE_PERMISSIONS = {
    companyId: 0,
    userId: 0,
    permissions: { allHours: false, businessHours: true }
};
export const RETURN_USER_GATE_PERMISSIONS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| COMPANY USER LIST                  ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_COMPANY_USER_LIST = '/company/users/';
export const PURPOSE_COMPANY_USER_LIST = 'Fetch a list of users based on company.';
export const PERMISSIONS_COMPANY_USER_LIST = [];
export const REQUIREMENTS_COMPANY_USER_LIST = ['Token Required'];
export const PARAMETER_COMPANY_USER_LIST = {
    companyId: 0
};
export const RETURN_COMPANY_USER_LIST = {
    users: [
        {
            companyId: 0,
            email: '',
            firstName: '',
            id: 0,
            lastName: '',
            permissions: [],
            phone: '',
            resetPassFlag: false,
            state: '',
            type: ''
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| SITE USER LIST                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SITE_USER_LIST = '/site/users/';
export const PURPOSE_SITE_USER_LIST = 'Fetch a list of users based on site.';
export const PERMISSIONS_SITE_USER_LIST = [];
export const REQUIREMENTS_SITE_USER_LIST = ['Token Required'];
export const PARAMETER_SITE_USER_LIST = {
    companyId: 0
};
export const RETURN_SITE_USER_LIST = {
    users: [
        {
            companyId: 0,
            email: '',
            firstName: '',
            id: 0,
            lastName: '',
            permissions: [],
            phone: '',
            resetPassFlag: false,
            state: '',
            type: ''
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| SUPPORT SITE USER LIST             ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SUPPORT_SITE_USER_LIST = '/support/site/users/';
export const PURPOSE_SUPPORT_SITE_USER_LIST = 'Fetch a list of users based on site that show all users.';
export const PERMISSIONS_SUPPORT_SITE_USER_LIST = [];
export const REQUIREMENTS_SUPPORT_SITE_USER_LIST = ['Token Required'];
export const PARAMETER_SUPPORT_SITE_USER_LIST = {
    companyId: 0
};
export const RETURN_SUPPORT_SITE_USER_LIST = {
    users: [
        {
            companyId: 0,
            email: '',
            firstName: '',
            id: 0,
            lastName: '',
            permissions: [],
            phone: '',
            resetPassFlag: false,
            state: '',
            type: ''
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER DETAILS                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_DETAILS = '/user/';
export const PURPOSE_USER_DETAILS = 'Fetch a user details.';
export const PERMISSIONS_USER_DETAILS = [];
export const REQUIREMENTS_USER_DETAILS = ['Token Required'];
export const PARAMETER_USER_DETAILS = {
    companyId: 0,
    userId: 0
};
export const RETURN_USER_DETAILS = {
    companyId: 0,
    defaultSiteId: 0,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    permissions: [],
    phone: '',
    resetPassFlag: false,
    sites: [
        {
            CompanyId: 0,
            assignedUserId: 0,
            city: '',
            closeTime: '',
            country: '',
            id: 0,
            last_sync_date: '',
            latitude: '',
            longitude: '',
            mainUrl: '',
            name: '',
            openTime: '',
            paymentUrl: '',
            phone: '',
            pms: '',
            postalCode: '',
            state: '',
            streetAddress: '',
            suite: '',
            syncId: '',
            timeZone: ''
        }
    ],
    state: '',
    type: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER CONFIRM                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_CONFIRM = '/user/account/confirm/';
export const PURPOSE_USER_CONFIRM = "Confirm user's account.";
export const PERMISSIONS_USER_CONFIRM = [];
export const REQUIREMENTS_USER_CONFIRM = ['Token Required'];
export const PARAMETER_USER_CONFIRM = {
    companyId: 0,
    userId: 0,
    key: 'token'
};
export const RETURN_USER_CONFIRM = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER LOGIN ATTEMPTS                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_LOGIN_ATTEMPTS = '/support/login/attempts/';
export const PURPOSE_USER_LOGIN_ATTEMPTS = 'Fetches login attempts for a single user.';
export const PERMISSIONS_USER_LOGIN_ATTEMPTS = ['support_view_login_attempts'];
export const REQUIREMENTS_USER_LOGIN_ATTEMPTS = ['Token Required'];
export const PARAMETER_USER_LOGIN_ATTEMPTS = {
    userId: 0
};
export const RETURN_USER_LOGIN_ATTEMPTS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| USER ACKNOWLEDGE TRAINING          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_ACKNOWLEDGE_TRAINING = '/user/send/digital/ack/';
export const PURPOSE_USER_ACKNOWLEDGE_TRAINING = 'Sends acknowledgement of training received';
export const PERMISSIONS_USER_ACKNOWLEDGE_TRAINING = [];
export const REQUIREMENTS_USER_ACKNOWLEDGE_TRAINING = ['Token Required'];
export const PARAMETER_USER_ACKNOWLEDGE_TRAINING = {
    acknowledged: false
};
export const RETURN_USER_ACKNOWLEDGE_TRAINING = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| FETCH USER UNITS                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FETCH_USER_UNITS = '/user/units/portal/';
export const PURPOSE_FETCH_USER_UNITS = 'Fetches user units';
export const PERMISSIONS_FETCH_USER_UNITS = [];
export const REQUIREMENTS_FETCH_USER_UNITS = ['Token Required'];
export const PARAMETER_FETCH_USER_UNITS = {
    userId: 0
};
export const RETURN_FETCH_USER_UNITS = {
    result: 'success'
};
