import * as CONSTANTS from 'constants';

const INIT_STATE = {
    siteCameras: null,
    cameraToken: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_CAMERAS: {
            return {
                ...state,
                siteCameras: action.payload
            };
        }
        case CONSTANTS.SET_CAMERA_TOKEN: {
            return {
                ...state,
                cameraToken: action.payload
            };
        }
        default:
            return state;
    }
};
