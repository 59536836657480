import * as CONSTANTS from 'constants';

const INIT_STATE = {
    releaseList: null,
    searchedReleaseList: null,
    releaseFilters: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_RELEASES: {
            return {
                ...state,
                releaseList: action.payload
            };
        }
        case CONSTANTS.SET_SEARCHED_RELEASES: {
            return {
                ...state,
                searchedReleaseList: action.payload
            };
        }
        case CONSTANTS.SET_RELEASE_FILTERS: {
            return {
                ...state,
                releaseFilters: action.payload
            };
        }
        default:
            return state;
    }
};
