//||||||||||||||||||||||||||||||||||||||||
//|| FEATURE FLAG FETCH                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FEATURE_FLAG_FETCH = '/featureflags/assignments/read/';
export const PURPOSE_FEATURE_FLAG_FETCH = 'Fetch list of feature flags.';
export const PERMISSIONS_FEATURE_FLAG_FETCH = [];
export const REQUIREMENTS_FEATURE_FLAG_FETCH = ['Token Required'];
export const PARAMETER_FEATURE_FLAG_FETCH = {
    userId: 0
};
export const RETURN_FEATURE_FLAG_FETCH = {
    result: 'success'
};
