import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { fadeInDown } from 'react-animations';

import Server from 'components/Server';
class MenuHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            showSwitcher: false
        };
    }
    switchURL = () => {
        let { count } = this.state;
        count = count + 1;
        if (count > 7) {
            this.setState({ showSwitcher: true });
        } else {
            this.setState({ count });
        }
        setTimeout(() => {
            this.setState({ count: 0 });
        }, 5000);
    };
    render() {
        const { alt, src, theme, height, padding } = this.props;
        const { showSwitcher } = this.state;
        this.styles = styles(theme, height, padding);
        return (
            <div className={css(this.styles.imageWrapper, this.styles.fadeInDown)} onClick={this.switchURL}>
                <img
                    alt={alt}
                    src={
                        src ? src : 'https://storage.googleapis.com/janus-nokebucket/assets/images/Themes/Demo/logo.png'
                    }
                    className={css(this.styles.image)}
                />
                <Server showSwitcher={showSwitcher} toggle={(showSwitcher) => this.setState({ showSwitcher })} />
            </div>
        );
    }
}

MenuHeader.defaultProps = {
    padding: '3px 10px 0px 20px',
    height: 60,
    alt: 'SMART ENTRY',
    src: 'assets/images/Themes/Demo/logo.png'
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(connect(mapStateToProps, {})(MenuHeader));

const styles = (theme, height, padding) =>
    StyleSheet.create({
        imageWrapper: {
            width: '100%',
            padding,
            height,
            backgroundColor: theme.color.headerColor,
            overflow: 'hidden'
        },
        image: {
            height: height - 8,
            padding: '0px 0px',
            borderRadius: 5,
            backgroundColor: theme.color.buttonFontColor
        },
        fadeInDown: {
            animationName: fadeInDown,
            animationDuration: '1.2s'
        }
    });
