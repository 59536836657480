import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
class DashboardRecentActivityCell extends React.Component {
    render() {
        const { intl, data, width, authUser, site, userTimezone } = this.props;
        const { userFirstName, userLastName, transferExpirationTime, units } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    Title={`${userFirstName} ${userLastName}`}
                    Description={units && units.map((unit) => unit.unitName).join(', ')}
                    textAlign={'left'}
                    TitleColor={true}
                />
                <CellText
                    padding={'2px 0 2px 10px'}
                    Cellwidth={'134px'}
                    textAlign={'right'}
                    TitleSize={width > 300 ? 11 : 8}
                    Title={UTILS.dateTimeFormatter(transferExpirationTime, authUser, site, userTimezone)}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, userTimezone, authUser, site };
};

export default injectIntl(connect(mapStateToProps)(DashboardRecentActivityCell));
