import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'aphrodite';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { fetchAllUnitNotes } from 'actions/Units';

import { ThermometerIcon } from 'utilities/icons';

import Table from 'components/Table';
import UnitCell from 'components/Table/cell/containers/dashboard/abnormalTempUnits';
import Header from 'components/Header/dashboard';
class DashboardAbnormalTempUnits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unitHeaders: [
                {
                    id: 0,
                    title: 'comp.table.header.name',
                    width: '200px',
                    active: true,
                    sortable: true,
                    order: true,
                    sortTitle: 'name',
                    mobile: true,
                    style: { textAlign: 'left' }
                },
                {
                    id: 1,
                    title: 'comp.table.header.status',
                    active: false,
                    sortable: true,
                    order: true,
                    mobile: true,
                    sortTitle: 'rentalState'
                }
            ]
        };
    }
    handleUnitAction = (data) => {
        this.props.history.push(`/app/units/display/${data.uuid}`);
    };
    render() {
        let { intl, theme, width, temperatureUnits } = this.props;
        const { unitHeaders } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <Header
                    Icon={ThermometerIcon}
                    iconSize={32}
                    title={
                        width > 1435
                            ? 'pages.dashboard.abnormalTemp'
                            : 'pages.dashboard.abnormalTempShort'
                    }
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Table
                        tableHeight={150}
                        data={temperatureUnits}
                        action={(e) => this.handleUnitAction(e)}
                        header={unitHeaders}
                        Cell={UnitCell}
                    />
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, units }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { temperatureUnits } = units;
    return { theme, authUser, width, temperatureUnits };
};

export default withRouter(injectIntl(connect(mapStateToProps, { fetchAllUnitNotes })(DashboardAbnormalTempUnits)));

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 5,
            fontSize: 20
        }
    });
