import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_ACTIVITY_FORWARDING from 'actions/ActivityForwarding';
import * as ACTIONS_APP from 'actions/App';

const getRedirectHeaders = (state) => state.activityForwarding.activityRedirectsHeader;

function* requestFetchActivityForwardingList({ payload }) {
    try {
        yield put(ACTIONS_ACTIVITY_FORWARDING.setActivityRedirects(null));
        let redirects = yield call(API.POST, payload.url, payload.data);
        if (!redirects || !redirects.data || !redirects.data.redirects) {
            yield put(ACTIONS_ACTIVITY_FORWARDING.setActivityRedirects([]));
        } else {
            let activityForwardingHeaders = yield select(getRedirectHeaders);
            let activeHeader = activityForwardingHeaders.find((item) => item.active);
            redirects.data.redirects = UTILS.sortList(!activeHeader.order, redirects.data.redirects, activeHeader.sortTitle);
            yield put(ACTIONS_ACTIVITY_FORWARDING.setActivityRedirects(redirects.data.redirects));
        }
    } catch (error) {
        yield put(ACTIONS_ACTIVITY_FORWARDING.setActivityRedirects(null));
        console.warn(error);
    }
}

function* requestRemoveActivityRedirect({ payload }) {
    try {
        let redirectDelete = yield call(API.POST, payload.url, payload.data);
        if (!redirectDelete) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.failed`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield put(ACTIONS_ACTIVITY_FORWARDING.fetchActivityRedirects());
            yield history.push(`/app/settings/forwarding/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAddActivityRedirect({ payload }) {
    try {
        let redirectAdd = yield call(API.POST, payload.url, payload.data);
        if (!redirectAdd) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.failed`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
            yield history.push(`/app/settings/forwarding/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteEventPreference({ payload }) {
    try {
        yield put(ACTIONS_ACTIVITY_FORWARDING.setSiteEventPreferences(null));
        let events = yield call(API.POST, payload.url, payload.data);
        if (!events || !events.data || !events.data.eventPreferences) {
            yield put(ACTIONS_ACTIVITY_FORWARDING.setSiteEventPreferences([]));
        } else {
            yield put(ACTIONS_ACTIVITY_FORWARDING.setSiteEventPreferences(events.data.eventPreferences));
        }
    } catch (error) {
        yield put(ACTIONS_ACTIVITY_FORWARDING.setSiteEventPreferences(null));
        console.warn(error);
    }
}

function* requestEditSiteEventPreferences({ payload }) {
    try {
        let eventsEdit = yield call(API.POST, payload.url, payload.data);
        if (!eventsEdit) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.failed`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
            yield put(ACTIONS_ACTIVITY_FORWARDING.getSiteEventPreferences());
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectFetchActivityForwardingList() {
    yield takeEvery(CONSTANTS.FETCH_ACTIVITY_REDIRECTS, requestFetchActivityForwardingList);
}
export function* selectRemoveActivityRedirect() {
    yield takeEvery(CONSTANTS.REMOVE_ACTIVITY_REDIRECT, requestRemoveActivityRedirect);
}
export function* selectAddActivityRedirect() {
    yield takeEvery(CONSTANTS.ADD_ACTIVITY_REDIRECT, requestAddActivityRedirect);
}
export function* selectFetchSiteEventPreferences() {
    yield takeEvery(CONSTANTS.GET_SITE_EVENT_PREFERENCES, requestFetchSiteEventPreference);
}
export function* selectEditSiteEventPreferences() {
    yield takeEvery(CONSTANTS.EDIT_SITE_EVENT_PREFERENCES, requestEditSiteEventPreferences);
}

export default function* rootSaga() {
    yield all([
        fork(selectFetchActivityForwardingList),
        fork(selectRemoveActivityRedirect),
        fork(selectAddActivityRedirect),
        fork(selectFetchSiteEventPreferences),
        fork(selectEditSiteEventPreferences)
    ]);
}
