import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { fadeIn } from 'react-animations';

import { SuspiciousActivityIcon } from 'utilities/icons';

import { fetchSuspiciousReportAll } from 'actions/Reports';
import ExpandableTable from './expandableTable';
import Spinner from 'components/Spinner';

class DashboardSuspiciousActivity extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { fetchSuspiciousReportAll } = this.props;
        fetchSuspiciousReportAll('week');
    }
    render() {
        const { intl, theme, suspiciousReportAll } = this.props;
        this.styles = styles(theme);
        return (
            <>
                <Row>
                    <Col>
                        <div className={css(this.styles.titleBox)}>
                            <SuspiciousActivityIcon color={theme.color.themeColor} />
                            <h1 className={css(this.styles.header)}>{intl.formatMessage({ id: 'comp.header.suspiciousActivity' })}</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div style={{maxHeight:407, overflow: 'auto'}}>
                            <Row style={{borderBottom: `solid 1px ${theme.color.borderColor}`, padding: '5px 0px', margin: '0px'}}>
                                <Col xs={2}>
                                    {intl.formatMessage({ id: 'form.facilityName' })}
                                </Col>
                                <Col xs={4}>
                                    {intl.formatMessage({ id: 'form.numberOfEvents' })}
                                </Col>
                                <Col xs={6}>
                                </Col>
                            </Row>
                            {suspiciousReportAll
                            ?
                            suspiciousReportAll.length > 0
                            ?
                            <div>
                                {suspiciousReportAll.map((r,i) => (
                                    <ExpandableTable key={i} report={r} />
                                ))}
                            </div>
                            :
                            <div
                                style={{
                                    minHeight: 110,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    animationName: fadeIn,
                                    animationDuration: '1.2s'
                                }}>
                                <p>
                                    {intl.formatMessage({
                                        id: 'comp.table.cell.empty'
                                    })}
                                </p>
                            </div>
                            :
                            <Spinner />}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, reports }) => {
    const { theme } = app;
    const { suspiciousReportAll } = reports;
    return { theme, suspiciousReportAll };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, { fetchSuspiciousReportAll })(
            DashboardSuspiciousActivity
        )
    )
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.fontColor,
            marginBottom: 15,
            marginLeft: 10
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            borderBottom: `solid 1px ${theme.color.borderColor}`,
        }
    });