import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as UTILS from 'utilities';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_ROLES from 'actions/Roles';
import * as ACTIONS_USERS from 'actions/Users';

const getPermissionHeaders = (state) => state.users.userPermissionHeader;
const getAuthUser = (state) => state.auth.authUser;
const getUsersFilters = (state) => state.users.usersFilters;

function* requestRoleList({ payload }) {
    try {
        yield put(ACTIONS_ROLES.setRoleList(null));
        let roles = yield call(API.POST, payload.url, payload.data);
        if (!roles.data.roles || !roles.data || !roles.data.roles) {
            yield put(ACTIONS_ROLES.setRoleList([]));
        } else {
            roles.data.roles.map((role) => {
                if (!role.permissions) {
                    role.permissions = [];
                }
                if (role.tier === 0) {
                    (role.level = 'Basic'), (role.order = 5);
                }
                if (role.tier === 2) {
                    (role.level = 'Very High'), (role.order = 1);
                }
                if (role.tier === 3) {
                    (role.level = 'High'), (role.order = 2);
                }
                if (role.tier === 4) {
                    (role.level = 'Medium'), (role.order = 3);
                }
                if (role.tier === 5) {
                    (role.level = 'Low'), (role.order = 4);
                }
                if (role.tier === 1000) {
                    (role.level = 'Basic'), (role.order = 6);
                }
                if(!role.level){
                    role.level = 'Basic'
                }
                if(!role.order){
                    role.order = 6
                }
            });
            roles = UTILS.sortList(false, roles.data.roles, 'order');
            yield put(ACTIONS_ROLES.setRoleList(roles));
            let userFilters = roles.map((role) => {
                return {
                    title: role.name,
                    value: [role.name],
                    active: false
                };
            });
            userFilters.unshift({
                title: 'All',
                value: roles.map((role) => role.name),
                active: true
            });
            let currentUsersFilters = yield select(getUsersFilters);
            yield put(
                ACTIONS_USERS.setUserFilters(
                    currentUsersFilters && currentUsersFilters.length > 0 ? currentUsersFilters : userFilters
                )
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRolePermissionsList({ payload }) {
    try {
        yield put(ACTIONS_ROLES.setRolePermissionsList(null));
        let permissions = yield call(API.POST, payload.url, payload.data);
        if (!permissions || !permissions.data || !permissions.data.permissions) {
            yield put(ACTIONS_ROLES.setRolePermissionsList([]));
        } else {
            let permissionHeaders = yield select(getPermissionHeaders);
            let activeHeader = permissionHeaders.find((item) => item.active);
            permissions.data.permissions = UTILS.sortList(
                !activeHeader.order,
                permissions.data.permissions,
                activeHeader.sortTitle
            );
            permissions.data.permissions.map((perm, i) => (perm.id = i + 1));
            permissions.data.permissions.map((perm) => (perm.searchName = perm.permission.split('_').join(' ')));

            // permission groups
            let groups = [...new Set(permissions.data.permissions.map((permission) => permission.group))];

            yield put(ACTIONS_ROLES.setPermissionGroups(groups));
            yield put(ACTIONS_ROLES.setRolePermissionsList(permissions.data.permissions));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateRole({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        let checker = false;
        if (payload.data.role.tier === 0) {
            checker = true;
        } else if (payload.data.role.tier === 1) {
            checker = false;
        } else if (payload.data.role.tier >= authUser.role.tier) {
            checker = true;
        }
        if (!checker) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.permission.deny`, 'warning'));
            return;
        }
        for (var key in payload.required) {
            if (payload.required[key] === undefined || payload.required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const role = yield call(API.POST, payload.url, payload.data);
        if (!role) {
            return;
        } else if (payload.wizard) {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield put(ACTIONS_ROLES.fetchRoleList(payload.data.role.companyId));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.push(`/app/settings/roles/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditRole({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        let checker = false;
        if (payload.data.role.tier === 0) {
            checker = true;
        } else if (payload.data.role.tier === 1) {
            checker = false;
        } else if (payload.data.role.tier >= authUser.role.tier) {
            checker = true;
        }
        if (!checker) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.role.deny`, 'warning'));
            if (payload.silent) yield put(ACTIONS_APP.setSaveLoader(false));
            yield put(ACTIONS_ROLES.fetchRoleList());
            return;
        }
        for (var key in payload.required) {
            if (payload.required[key] === undefined || payload.required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        if (payload.silent) yield put(ACTIONS_APP.setSaveLoader(true));
        const role = yield call(API.POST, payload.url, payload.data);
        if (!role) {
            yield put(ACTIONS_ROLES.fetchRoleList());
            if (payload.silent) yield put(ACTIONS_APP.setSaveLoader(false));
            return;
        } else if (payload.wizard) {
            if (!payload.silent) yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            if (payload.silent) yield put(ACTIONS_APP.setSaveLoader(false));
            yield put(ACTIONS_ROLES.fetchRoleList(payload.data.companyUUID));
        } else {
            if (!payload.silent) yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            if (payload.silent) yield put(ACTIONS_APP.setSaveLoader(false));
            yield put(ACTIONS_ROLES.fetchRoleList(payload.data.companyUUID));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_APP.setSaveLoader(false));
    }
}

function* requestDeleteRole({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        let checker = false;
        if (payload.data.role.tier === 0) {
            checker = true;
        } else if (payload.data.role.tier === 1) {
            checker = false;
        } else if (payload.data.role.tier >= authUser.role.tier) {
            checker = true;
        }
        if (!checker) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.role.deny`, 'warning'));
            yield put(ACTIONS_APP.setSaveLoader(false));
            return;
        }
        const confirm = yield call(API.POST, payload.url, { roleUUID: payload.data.role.uuid });
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            if (payload.wizard) {
                yield history.push(`/app/settings/company/create/roles/${payload.companyId}`);
            } else {
                // dont go to app/settings/roles if the app is already there
                if (!window.location.href.includes('app/settings/roles')) yield history.push(`/app/settings/roles`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectRoleList() {
    yield takeEvery(CONSTANTS.FETCH_ROLE_LIST, requestRoleList);
}

export function* selectRolePermissionsList() {
    yield takeEvery(CONSTANTS.FETCH_ROLE_PERMISSIONS_LIST, requestRolePermissionsList);
}

export function* createRole() {
    yield takeEvery(CONSTANTS.CREATE_ROLE, requestCreateRole);
}

export function* editRole() {
    yield takeEvery(CONSTANTS.EDIT_ROLE, requestEditRole);
}

export function* deleteRole() {
    yield takeEvery(CONSTANTS.DELETE_ROLE, requestDeleteRole);
}

export default function* rootSaga() {
    yield all([
        fork(selectRoleList),
        fork(selectRolePermissionsList),
        fork(createRole),
        fork(editRole),
        fork(deleteRole)
    ]);
}
