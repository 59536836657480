import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const wizardContactSupport = (stepUUID = '') => {
    return {
        type: CONSTANTS.WIZARD_CONTACT_SUPPORT,
        payload: {
            url: ENDPOINTS.ENDPOINT_WIZARD_CONTACT_SUPPORT,
            data: { stepUUID }
        }
    };
};

export const getUserWizardResponses = (userUUID = '') => {
    return {
        type: CONSTANTS.GET_USER_WIZARD_RESPONSES,
        payload: {
            url: ENDPOINTS.ENDPOINT_GET_USER_WIZARD_RESPONSES,
            data: { userUUID }
        }
    };
};

export const setUserWizardResponses = (data) => {
    return {
        type: CONSTANTS.SET_USER_WIZARD_RESPONSES,
        payload: data
    };
};
