import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import Box from 'components/Box';
class Error403 extends React.Component {
    render() {
        const { intl } = this.props;
        return (
            <Container className={css(styles.container)}>
                <Box customHeight={'1vh'} padding={'20vh 2vw'}>
                    <Row>
                        <Col xs={12}>
                            <h1>{intl.formatMessage({ id: 'pages.403.header' })}</h1>
                        </Col>
                    </Row>
                </Box>
            </Container>
        );
    }
}

export default injectIntl(Error403);

const styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        padding: '10vh 10vw'
    }
});
