import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchRoleList = (companyUUID) => {
    return {
        type: CONSTANTS.FETCH_ROLE_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_ROLE_LIST,
            data: {
                companyUUID
            }
        }
    };
};

export const fetchRolePermissionsList = (locale = 'en') => {
    return {
        type: CONSTANTS.FETCH_ROLE_PERMISSIONS_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_ROLE_PERMISSIONS_LIST,
            data: {
                locale
            }
        }
    };
};

export const createRole = (
    { name = '', tier = 0, defaultTenantRole = false, permissions = [] },
    companyUUID,
    wizard
) => {
    return {
        type: CONSTANTS.CREATE_ROLE,
        payload: {
            url: ENDPOINTS.ENDPOINT_ROLE_CREATE,
            required: {
                name,
                permissions
            },
            data: {
                role: {
                    name,
                    tier,
                    siteUUID: companyUUID ? '0' : JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
                    companyUUID: companyUUID
                        ? companyUUID
                        : JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                    defaultTenantRole
                },
                permissions
            },
            wizard
        }
    };
};

export const editRole = (
    {
        uuid = 0,
        name = '',
        tier = 0,
        defaultTenantRole = false,
        permissions = [],
        siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
        companyUUID = JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
    },
    wizard,
    silent = false
) => {
    return {
        type: CONSTANTS.EDIT_ROLE,
        payload: {
            url: ENDPOINTS.ENDPOINT_ROLE_EDIT,
            required: {
                name,
                permissions
            },
            data: {
                role: {
                    uuid,
                    name,
                    tier,
                    siteUUID,
                    companyUUID,
                    defaultTenantRole
                },
                siteUUID,
                companyUUID,
                permissions
            },
            wizard,
            silent
        }
    };
};

export const deleteRole = (
    { uuid = 0, name = '', tier = 0, defaultTenantRole = false, permissions = [] },
    wizard = false,
    companyUUID = 0
) => {
    return {
        type: CONSTANTS.DELETE_ROLE,
        payload: {
            url: ENDPOINTS.ENDPOINT_ROLE_DELETE,
            wizard,
            companyUUID,
            data: {
                role: {
                    uuid,
                    name,
                    tier,
                    siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
                    defaultTenantRole
                },
                permissions
            }
        }
    };
};

export const setRoleList = (data) => {
    return {
        type: CONSTANTS.SET_ROLE_LIST,
        payload: data
    };
};

export const setRoleDetails = (data) => {
    return {
        type: CONSTANTS.SET_ROLE_DETAILS,
        payload: data
    };
};

export const setRolePermissionsList = (data) => {
    return {
        type: CONSTANTS.SET_ROLE_PERMISSIONS_LIST,
        payload: data
    };
};

export const setRoleActive = (id) => {
    return {
        type: CONSTANTS.SET_ROLE_ACTIVE,
        payload: JSON.parse(id)
    };
};

export const setPermissionGroups = (data) => {
    return {
        type: CONSTANTS.SET_PERMISSION_GROUPS,
        payload: data
    };
};
