import * as CONSTANTS from 'constants';

const INIT_STATE = {
    securityList: null,
    securityListStats: null,
    securityObject: null,
    activeSecurity: 'device',
    securityHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: '200px',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'rentalState'
        }
    ],
    securityFilters: [
        {
            title: 'filters.all',
            value: ['available', 'inuse', 'pending'],
            active: true
        },
        {
            title: 'filters.security.available',
            value: ['available'],
            active: false
        },
        {
            title: 'filters.security.occupied',
            value: ['inuse'],
            active: false
        },
        {
            title: 'filters.security.pending',
            value: ['pending'],
            active: false
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_SECURITY_LIST: {
            return {
                ...state,
                securityList: action.payload
            };
        }
        case CONSTANTS.SET_SECURITY_LIST_STATS: {
            return {
                ...state,
                securityListStats: action.payload
            };
        }
        case CONSTANTS.SET_SECURITY: {
            return {
                ...state,
                securityObject: action.payload
            };
        }
        case CONSTANTS.SET_SECURITY_HEADERS: {
            return {
                ...state,
                securityHeader: action.payload
            };
        }
        case CONSTANTS.SET_SECURITY_FILTERS: {
            return {
                ...state,
                securityFilters: action.payload
            };
        }
        case CONSTANTS.SET_SECURITY_ACTIVE: {
            return {
                ...state,
                activeSecurity: action.payload
            };
        }
        default:
            return state;
    }
};
