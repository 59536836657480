import * as CONSTANTS from 'constants';
import * as DATABASE from 'utilities/database';
import moment from 'moment';

const INIT_STATE = {
    activityList: [],
    userActivityList: [],
    unitActivityList: [],
    entryActivityList: [],
    activityCSVList: null,
    activityPageCount: 1,
    activityCurrentIndex: 0,
    activityDates: null,
    tenantActivity: null,
    activityFilterList: null,
    activityFilter: null,
    activityView: null,
    activityPagination: {
        page: 0,
        pages: 0
    },
    activityHeader: [
        {
            id: 0,
            title: 'comp.table.header.user',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'user',
            mobile: true,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.action',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'action',
            width: '20%',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.device',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'device',
            width: '18%',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.date',
            active: true,
            sortable: false,
            order: false,
            mobile: false,
            sortTitle: 'recordedDate',
            width: 180,
            style: { textAlign: 'right' }
        },
        {
            id: 4,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 55,
            deviceDetails: true
        }
    ],
    activityTypesList: DATABASE.ACTIVITY_TYPES,
    activitySubTypesList: DATABASE.ACTIVITY_SUBTYPES,
    supportActivitySubTypesList: DATABASE.SUPPORT_ACTIVITY_SUBTYPES,
    mapActivitySubTypesList: DATABASE.MAP_ACTIVITY_SUBTYPES,
    motionActivitySubTypesList: DATABASE.MOTION_ACTIVITY_SUBTYPES,
    activityLevelList: DATABASE.ACTIVITY_LEVELS,
    activityDeviceList: DATABASE.ACTIVITY_DEVICE,
    activityUserTypesList: DATABASE.ACTIVITY_USER_TYPES,
    activityAccessTypesList: DATABASE.ACTIVITY_ACCESS_TYPES,
    activityDashboardFilters: {
        device: DATABASE.ACTIVITY_DEVICE,
        userType: DATABASE.ACTIVITY_USER_TYPES,
        accessType: DATABASE.ACTIVITY_ACCESS_TYPES
    },
    activityFilters: {
        types: DATABASE.ACTIVITY_TYPES,
        subtypes: DATABASE.ACTIVITY_SUBTYPES,
        level: ['all'],
        date: {
            start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },
        units: [],
        users: [],
        locks: [],
        roles: [],
        id: null
    },
    supportFilters: {
        types: DATABASE.ACTIVITY_TYPES,
        subtypes: DATABASE.SUPPORT_ACTIVITY_SUBTYPES,
        level: ['all'],
        date: {
            start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },
        units: [],
        users: [],
        locks: [],
        roles: [],
        id: null
    },
    mapActivityFilters: {
        types: DATABASE.ACTIVITY_TYPES,
        subtypes: DATABASE.MAP_ACTIVITY_SUBTYPES,
        level: ['all'],
        date: {
            start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },
        units: [],
        users: [],
        locks: [],
        roles: [],
        id: null
    },
    motionActivityFilters: {
        types: DATABASE.ACTIVITY_TYPES,
        subtypes: DATABASE.MOTION_ACTIVITY_SUBTYPES,
        level: ['all'],
        date: {
            start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },
        units: [],
        users: [],
        locks: [],
        roles: [],
        id: null
    },
    recentUsers: null,
    recentUnits: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_ACTIVITY_LIST: {
            return {
                ...state,
                activityList: action.payload
            };
        }
        case CONSTANTS.SET_USER_ACTIVITY_LIST: {
            return {
                ...state,
                userActivityList: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_ACTIVITY_LIST: {
            return {
                ...state,
                unitActivityList: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_ACTIVITY_LIST: {
            return {
                ...state,
                entryActivityList: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_CSV_LIST: {
            return {
                ...state,
                activityCSVList: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_HEADERS: {
            return {
                ...state,
                activityHeader: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_FILTERS: {
            return {
                ...state,
                activityFilters: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_ACTIVITY_FILTERS: {
            return {
                ...state,
                supportFilters: action.payload
            };
        }
        case CONSTANTS.SET_MAP_ACTIVITY_FILTERS: {
            return {
                ...state,
                mapActivityFilters: action.payload
            };
        }
        case CONSTANTS.SET_MOTION_ACTIVITY_FILTERS: {
            return {
                ...state,
                motionActivityFilters: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_DASHBOARD_FILTERS: {
            return {
                ...state,
                activityDashboardFilters: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_PAGE_COUNT: {
            return {
                ...state,
                activityPageCount: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_CURRENT_INDEX: {
            return {
                ...state,
                activityCurrentIndex: action.payload
            };
        }
        case CONSTANTS.SET_RECENT_USERS: {
            return {
                ...state,
                recentUsers: action.payload
            };
        }
        case CONSTANTS.SET_RECENT_UNITS: {
            return {
                ...state,
                recentUnits: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_DATES: {
            return {
                ...state,
                activityDates: action.payload
            };
        }
        case CONSTANTS.SET_TENANT_ACTIVITY: {
            return {
                ...state,
                tenantActivity: action.payload
            };
        }
        case CONSTANTS.SET_USER_ACTIVITY_FILTERS: {
            return {
                ...state,
                activityFilterList: action.payload
            };
        }
        case CONSTANTS.SET_USER_ACTIVITY_FILTER: {
            return {
                ...state,
                activityFilter: action.payload
            };
        }
        case CONSTANTS.SET_ACTIVITY_VIEW: {
            return {
                ...state,
                activityView: action.payload
            };
        }
        default:
            return state;
    }
};
