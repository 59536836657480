import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import { getWithExpiry, setWithExpiry } from 'utilities';

export function ErrorFallback({ error }) {
    // Handles failed lazy loading of a JS/CSS chunk.
    useEffect(() => {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error && error.message && chunkFailedMessage.test(error.message)) {
            if (!getWithExpiry('chunk_failed')) {
                setWithExpiry('chunk_failed', 'true', 10000);
                window.location.reload();
            }
        }
    }, [error]);
    let theme = JSON.parse(localStorage.getItem('nse_theme'));
    return (
        <div
            style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <div
                style={{
                    //margin: '0 auto',
                    marginTop: 15,
                    borderRadius: 5,
                    boxShadow: theme ? theme.color.boxShadowCorner : 'none',
                    backgroundColor: theme ? theme.color.compColor : 'white',
                    color: theme ? theme.color.fontColor : '#252525',
                    minWidth: '30%'
                }}>
                <div style={{ 
                    width: '100%',
                    backgroundColor: theme ? theme.color.themeColor : '#0070cd' ,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    paddingTop: 10
                }}>
                    <i className="material-icons" style={{ 
                        color: theme ? theme.color.themeColor : '#0070cd',
                        backgroundColor: theme ? theme.color.compColor : 'white',
                        borderRadius: '50%',
                        fontSize: 38, 
                        marginBottom: -20, 
                        border: '1px solid' + theme ? theme.color.themeColor : '#0070cd',
                    }}>
                        error_outline
                    </i>
                </div>
                <div style={{
                    padding: '40px 10px 20px 10px',
                }}>
                    <p>
                        <FormattedMessage id="errorCodes.description.2" />
                    </p>
                    <pre style={{ 
                        color: theme ? theme.color.subFontColor : '#252525'
                        }}>
                        {error && error.message && error.message}
                    </pre>
                    <div style={{
                        marginTop: 22
                        }}>
                        <Button
                            style={{
                                color: theme ? theme.color.compColor : '#ffffff',
                                backgroundColor: theme ? theme.color.themeColor : '#0070cd',
                                border: 'none'
                            }}
                            onClick={() => window.location.reload()}>
                            <i className="material-icons" style={{ color: theme ? theme.color.buttonFontColor : 'white', margin: '1px 5px 0 0', fontSize: 18 }}>
                                sync
                            </i>
                            <FormattedMessage id="tooltip.refreshPage" />
                        </Button>
                        <Button
                            style={{
                                color: theme ? theme.color.compColor : '#ffffff',
                                backgroundColor: theme ? theme.color.themeColor : '#0070cd',
                                border: 'none'
                            }}
                            onClick={() => window.history.back()}>
                            <i className="material-icons" style={{ color: theme ? theme.color.buttonFontColor : 'white', margin: '1px 5px 0 0', fontSize: 18 }}>
                                arrow_back
                            </i>
                            <FormattedMessage id="comp.button.goBack" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
