import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import Button from 'components/Button';
class RageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kbArticles: [
                {
                    id: 1,
                    title: 'pages.knowledge.title1',
                    description: 'pages.knowledge.description1',
                    url: 'https://www.janusintl.com/knowledge/understanding-digital-keys',
                    icon: 'vpn_key'
                },
                {
                    id: 2,
                    title: 'pages.knowledge.title2',
                    description: 'pages.knowledge.description2',
                    url: 'https://www.janusintl.com/knowledge/how-do-tenant-know-what-the-process-is-to-first-access-the-app',
                    icon: 'login'
                },
                {
                    id: 3,
                    title: 'pages.knowledge.title3',
                    description: 'pages.knowledge.description3',
                    url: 'https://www.janusintl.com/knowledge/basic-app-device-troubleshooting',
                    icon: 'app_shortcut'
                }
            ]
        };
    }
    viewKnowledgeBase = () => {
        window.location = 'https://www.janusintl.com/knowledge';
    };
    render() {
        const { intl, theme, width } = this.props;
        const { kbArticles } = this.state;
        this.styles = styles(theme, width);
        return (
            <>
                <div
                    style={{
                        width: '100%',
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <div>
                        <h2 className={css(this.styles.title)}>{intl.formatMessage({ id: 'form.login.issues' })}?</h2>
                    </div>
                    <div className={css(this.styles.kbWrapper)}>
                        {kbArticles &&
                            kbArticles.map((knowledge) => (
                                <a
                                    href={knowledge.url}
                                    target="_blank"
                                    key={knowledge.id}
                                    className={css(this.styles.kbItem)}
                                    rel="noreferrer">
                                    <div className={css(this.styles.kbItemLeft)}>
                                        <i className="material-icons" style={{ fontSize: 26 }}>
                                            {knowledge.icon}
                                        </i>
                                    </div>
                                    <div className={css(this.styles.kbItemRight)}>
                                        <p className={css(this.styles.kbItemTitle)}>
                                            {intl.formatMessage({ id: knowledge.title })}
                                        </p>
                                        <p className={css(this.styles.kbItemDescription)}>
                                            {intl.formatMessage({ id: knowledge.description })}
                                        </p>
                                    </div>
                                </a>
                            ))}
                    </div>
                </div>
                <div style={{ float: 'right' }}>
                    <Button
                        text={'pages.contact.header'}
                        action={() => {
                            this.props.history.push('/app/feedback/all');
                            this.props.dismissModal();
                        }}
                        size={'md'}
                    />
                    <Button text={'pages.users.dismissTraining'} action={() => this.props.dismissModal()} size={'md'} />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { authUser } = auth;
    const { theme, width } = app;
    return { authUser, theme, width };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(RageModal)));

const styles = (theme, width) =>
    StyleSheet.create({
        title: {
            color: theme.color.themeColor
        },
        kbWrapper: {
            width: '100%',
            marginTop: 10
        },
        kbItem: {
            width: '100%',
            minHeight: 60,
            margin: '10px 0',
            boxShadow: theme.color.boxShadowCorner,
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 4,
            alignItems: 'space-between',
            textDecoration: 'none',
            transition: '.2s all ease-in-out',
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        kbItemLeft: {
            width: 50,
            height: '100%',
            minHeight: 60,
            background: theme.color.themeColor,
            color: theme.color.buttonFontColor,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        kbItemRight: {
            width: 'calc(100% - 50px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            color: theme.color.fontColor,
            padding: '5px 10px'
        },
        kbItemTitle: {
            margin: 0,
            fontWeight: 600,
            fontSize: width > 475 ? 14 : 9,
            color: theme.color.themeColor
        },
        kbItemDescription: {
            margin: 0,
            fontSize: width > 475 ? 11 : 7,
            lineHeight: 1
        }
    });