export const light = {
    name: 'light',
    pageColor: '#f5f7f9',
    menuColor: '#f5f7f9',
    headerColor: '#ffffff',
    compColor: '#ffffff',
    themeColor: '#0070cd',
    fontColor: '#808080',
    subFontColor: '#c0c0c0',
    buttonFontColor: '#ffffff',
    borderColor: '#d8dcdf',
    boxShadow: '1px 1px 10px rgba(0,0,0,0.2)',
    boxShadowCorner: '2px 2px 11px #dee2e6',
    boxShadowCornerHover: '5px 5px 15px #dee2e6',
    boxShadowIntense: '4px 4px 3px #BCBEC0',
    go: '#00B687'
};

export const light1 = {
    name: 'light1',
    pageColor: '#f1f0ee',
    menuColor: '#f1f0ee',
    headerColor: '#ffffff',
    compColor: '#ffffff',
    themeColor: '#0070cd',
    fontColor: '#949494',
    subFontColor: '#bbbbbb',
    buttonFontColor: '#ffffff',
    borderColor: '#f5f7f7',
    boxShadow: '1px 1px 10px rgba(0,0,0,0.2)',
    boxShadowCorner: '2px 2px 11px #dee2e6',
    boxShadowCornerHover: '5px 5px 15px #dee2e6',
    boxShadowIntense: '4px 4px 3px #BCBEC0',
    go: '#00B687'
};

export const light2 = {
    name: 'light2',
    pageColor: '#D7D8DF',
    menuColor: '#D7D8DF',
    headerColor: '#fafafa',
    compColor: '#fafafa',
    themeColor: '#0070cd',
    fontColor: '#333333',
    subFontColor: '#757677',
    buttonFontColor: '#fafafa',
    borderColor: '#ededed',
    boxShadow: '1px 1px 10px rgba(0,0,0,0.2)',
    boxShadowCorner: '2px 2px 11px #b3b3b5',
    boxShadowCornerHover: '4px 4px 11px #b3b3b5',
    boxShadowIntense: '4px 4px 3px #BCBEC0',
    go: '#00B687'
};

export const contrast = {
    name: 'contrast',
    pageColor: '#f8f9fa',
    menuColor: '#f8f9fa',
    headerColor: '#ffffff',
    compColor: '#ffffff',
    themeColor: '#0070cd',
    fontColor: '#000000',
    subFontColor: '#000000',
    buttonFontColor: '#ffffff',
    borderColor: '#707f8d',
    boxShadow: '1px 1px 10px rgba(0,0,0,0.2)',
    boxShadowCorner: '2px 2px 11px #dee2e6',
    boxShadowCornerHover: '5px 5px 15px #dee2e6',
    boxShadowIntense: '4px 4px 3px #BCBEC0',
    go: '#00B687'
};

export const dark = {
    name: 'dark',
    pageColor: '#11161d',
    menuColor: '#11161d',
    headerColor: '#1c2938',
    compColor: '#1c2938',
    themeColor: '#1ca1f2',
    fontColor: '#ffffff',
    subFontColor: '#707f8d',
    buttonFontColor: '#ffffff',
    borderColor: '#707f8d',
    boxShadow: '1px 1px 10px #141d27',
    boxShadowCorner: '5px 4px 13px #141d27',
    boxShadowCornerHover: '6px 6px 16px #141d27',
    boxShadowIntense: '4px 4px 3px #141d27',
    go: '#00B687'
};

export const dark1 = {
    name: 'dark1',
    pageColor: '#121212',
    menuColor: '#121212',
    headerColor: '#1C1C1C',
    compColor: '#1C1C1C',
    themeColor: '#1ca1f2',
    fontColor: '#d5d5d5',
    subFontColor: '#929292',
    buttonFontColor: '#d5d5d5',
    borderColor: '#3a3a3a',
    boxShadow: '1px 1px 10px #0c0c0c',
    boxShadowCorner: '5px 4px 13px #0c0c0c',
    boxShadowCornerHover: '6px 6px 16px #0c0c0c',
    boxShadowIntense: '4px 4px 3px #0c0c0c',
    go: '#00B687'
};

export const dark2 = {
    name: 'dark2',
    pageColor: '#212121',
    menuColor: '#212121',
    headerColor: '#333333',
    compColor: '#333333',
    themeColor: '#1ca1f2',
    fontColor: '#ebebed',
    subFontColor: '#9b9b9b',
    buttonFontColor: '#ebebed',
    borderColor: '#565656',
    boxShadow: '4px 4px 3px #252626',
    boxShadowCorner: '5px 4px 13px #252626',
    boxShadowCornerHover: '6px 6px 16px #252626',
    boxShadowIntense: '4px 4px 3px #252626',
    go: '#00B687'
};

export const dark3 = {
    name: 'dark3',
    pageColor: '#2b2a40',
    menuColor: '#2b2a40',
    headerColor: '#37364e',
    compColor: '#37364e',
    themeColor: '#e74699',
    fontColor: '#ffffff',
    subFontColor: '#9b9b9b',
    buttonFontColor: '#ffffff',
    borderColor: '#626068',
    boxShadow: '4px 4px 3px #2b2a40',
    boxShadowCorner: '5px 4px 13px #2b2a40',
    boxShadowCornerHover: '6px 6px 16px #2b2a40',
    boxShadowIntense: '4px 4px 3px #2b2a40',
    go: '#00B687'
};
