import React from 'react';
import { connect } from 'react-redux';
import {StyleSheet, css} from 'aphrodite';
class ModalFooter extends React.Component {
  constructor (props) {
    super (props)
  }
  render() {
    const { children, theme, width, height, style } = this.props;
    this.styles = styles(theme, width,height);
    return (
      children && <>
        <div className={css(this.styles.wrapper)} style={style}>
          {children}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ app }) => {
  const { theme, width } = app;
  return { theme, width };
}

export default connect(mapStateToProps)(ModalFooter);

const styles = (theme) => StyleSheet.create({
  wrapper: {
    padding: 15,
    borderTop: `1px solid ${theme && theme.color.borderColor}`,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
